class RegulatoryQuestionsController {
    constructor(atbEmploymentStatuses, $timeout) {
        this.timeout = $timeout;
        this.atbEmploymentStatuses = atbEmploymentStatuses;

        // bindings
        this.formData = null;
        this.accountType = null;
        this.openAccountForm = null;
        this.atbEmploymentStatus = null;
        this.spouseAtbEmploymentStatus = null;
    }

    $onInit() {
        this.formData.needsAnswers = true;
    }

    $onChanges(changes) {
        if (changes.accountType) {
            this.onAccountTypeChanged();
        }

        if (!this.openAccountForm || !this.openAccountForm.$dirty) {
            return;
        }
        if (changes.atbEmploymentStatus || changes.spouseAtbEmploymentStatus) {
            this.onAtbEmploymentStatusChanged();
        }
    }

    onAtbEmploymentStatusChanged() {
        const question = this.formData.questions.find(it => it.questionId === 'q3');
        question && (question.answer = (this.isSecurityDealer() || null));
    }

    isSecurityDealer() {
        const self = this.atbEmploymentStatuses.find(it => it.value === this.atbEmploymentStatus);
        const spouse = this.atbEmploymentStatuses.find(it => it.value === this.spouseAtbEmploymentStatus);
        return Boolean(self && self.isDealer) || Boolean(spouse && spouse.isDealer);
    }

    isDisabled(questionId) {
        return questionId === "q3" && this.isSecurityDealer();
    }

    onAccountTypeChanged() {
        this.formData.questions.forEach((question) => {
            switch (question.questionId) {
                case "q4":
                    if (this.accountType === 'CASH') {
                        question.applicable = true;
                    } else {
                        question.applicable = false;
                        question.answer = null;
                    }
                    break;
                case "q5":
                    question.applicable = true;
                    break;
                default:
                    question.applicable = true;
            }
        });
    }
}

module.exports = RegulatoryQuestionsController;