const template = require('./spousal-information.html');
const controller = require('./spousal-information.controller');
require('./spousal-information.scss');

let spousalInformationComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        formData: "=",
        isJointAccount: "<"
    },
    template,
    controller: ['countries','employmentStatuses','atbEmploymentStatuses','monthOptions','userService','numberDependentsList','$window', controller],
    controllerAs: 'controller'
};

module.exports = spousalInformationComponent;