'strict';

//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');
const angularTranslate = require('angular-translate');

//NON-VENDOR MODULES
const atbConstantsModule = require('../atb-constants/atb-constants');
const convertToNumber = require('../../directives/convert-to-number/convert-to-number');
const goalCalculationComponent = require('./goal-calculation.component.js');
const chartModule = require('../../components/chart/chart');
const minimumValueModule = require('../../directives/minimum-value/minimum-value');
const maximumValueModule = require('../../directives/maximum-value/maximum-value');
const regexValidatorModule = require('../../directives/regex-validator/regex-validator');

let goalCalculationModule = angular
    .module('app.goal-calculation', [
        atbConstantsModule,
        angularMaterial,
        angularTranslate,
        convertToNumber,
        chartModule,
        minimumValueModule,
        maximumValueModule,
        regexValidatorModule
    ])
    .component('goalCalculation', goalCalculationComponent)
    .name;

module.exports = goalCalculationModule;
