const NUMBER_ONLY = '^[0-9]*$';
const REGEX = new RegExp(NUMBER_ONLY);

module.exports = () => ({
    scope: {
        accountLengths: "=bankNumberValidator"
    },
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ngModel) => {
        let prevVal = null;
        // this will ensure only allow certain characters
        ngModel.$parsers.push((value) => {
            if (!value || REGEX.test(value)) {
                prevVal = value;
                return prevVal;
            } else {
                ngModel.$setViewValue(prevVal);
                ngModel.$render();
                return prevVal;
            }
        });

        // Detect pre-populated non-regular account numbers
        ngModel.$validators.validAccount = (value) => {
            return REGEX.test(value);
        };

        ngModel.$validators.validAccountLength = (value) => {
            if (!scope.accountLengths) {
                return true;
            }
            let valid = false;
            angular.forEach(scope.accountLengths, (length) => {
                if (value && length === value.length) {
                    valid = true;
                }
            });
            return valid;
        }
    }
});