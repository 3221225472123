const template = require('./edit-goal.html');
const controller = require('./edit-goal.controller.js');
require('./edit-goal.scss');

let editGoalComponent = {
    restrict: 'E',
    bindings: {
        'onError': '&',
        'selected': '='
    },
    template,
    controller: ['$timeout','$mdDialog','monthOptions','contributionFrequencies','userService', controller],
    controllerAs: 'controller'
};

module.exports = editGoalComponent;
