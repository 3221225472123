const angular = require('angular');
const angularMaterial = require('angular-material');
const myInvestmentsComponent = require('./my-investments.component');
const goalServiceModule = require('../../../services/goal-service/goal-service')
const atbConstantsModule = require('../../../components/atb-constants/atb-constants')

let myInvestmentsModule = angular
    .module('myInvestments', [
        angularMaterial,
        goalServiceModule,
        atbConstantsModule
    ])
    .component('myInvestments', myInvestmentsComponent)
    .name;

module.exports = myInvestmentsModule;