const angular = require('angular');
const localStorageService = require('angular-local-storage');
const ATBInterceptorComponent = require('./ATBInterceptor.service');

let ATBInterceptorModule = angular.module('ATBInterceptor', [
        localStorageService
    ])
    .factory('ATBInterceptor', ['$q', '$location', '$window', 'localStorageService', ATBInterceptorComponent])
    .name;

module.exports = ATBInterceptorModule;