//VENDOR MODULES
const angular = require('angular');
const angularTranslate = require('angular-translate');
const angularMaterial = require('angular-material');
const angularJWT = require('angular-jwt');
require('angular-translate-loader-partial');
const uiRouter = require('@uirouter/angularjs').default;

//NON-VENDOR MODULES
const headerModule = require('../components/header/header');
const loginFooterModule = require('../components/login-footer/login-footer');
const routes = require('./security-question.routes.js');
const ocLazyLoad = require("oclazyload");
const userService = require('../services/user-service/user-service');
const setSecurityQuestions = require('../components/set-security-questions/set-security-questions');
const feedbackModule = require('../components/feedback-message/feedback-message');

let securityQuestionModuleV2 = angular
    .module('app.security-question-v2', [
        angularTranslate,
        angularMaterial,
        ocLazyLoad,
        headerModule,
        loginFooterModule,
        uiRouter,
        userService,
        angularJWT,
        setSecurityQuestions,
        feedbackModule
    ])
    .config(['$stateProvider', routes])
    .name;


module.exports = securityQuestionModuleV2;
