require('./personal-info.template.scss');

module.exports = {
    restrict: 'E',
    bindings: {
        model: '=',
        form: '<'
    },
    template: require('./personal-info.template.html'),
    controller: require('./personal-info.controller'),
    controllerAs: 'controller'
};