module.exports = () => ({
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {
        //Only allow Alphanumeric, space and '-', but not starting or ending with '-', not starting with 0
        const STREET_NUMBER_REGEX = /^(?![-0])[0-9a-zA-Z\s,-]{0,19}[0-9a-zA-Z]{1}$/i;
        ctrl.$validators.validStreetNumber = modelValue =>
            !modelValue
            || STREET_NUMBER_REGEX.test(modelValue);
    }
});