require('./kyc.template.scss');

function routes($stateProvider) {
    $stateProvider.state('kyc', {
        url: '/kyc',
        parent: 'site',
        views: {
            'content@': {
                template: require('./kyc.template.html'),
                controller: 'KycController',
                controllerAs: 'controller'
            }
        },
        resolve: {
            locale: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                $translatePartialLoader.addPart('kyc');
                $translate.refresh();
                return true;
            }],
            controller: ['$ocLazyLoad', $ocLazyLoad => {
                return import(/* webpackChunkName: "kyc" */ './kyc.controller')
                    .then(controller => $ocLazyLoad.load(controller));
            }]
        }
    });
}

module.exports = routes;
