const prosperDashboardTemplate = require('./prosper-dashboard.html');

require('./prosper-dashboard.scss');
require('./conversion-dashboard.scss');

function routes($stateProvider) {
    $stateProvider
        .state('prosper-dashboard', {
            url: '/prosper-dashboard',
            parent: 'site',
            views: {
                'content@': {
                    template: prosperDashboardTemplate,
                    controller: 'ProsperDashboardController',
                    controllerAs: 'prosperDashboard'
                }
            },
            resolve: {
                data: ['$state', 'goalService', ($state, goalService) => {
                    return goalService.getActiveGoals().catch(err => $state.go('landing-page'));
                }],
                localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('prosper-dashboard');
                    $translatePartialLoader.addPart('conversion-dashboard');
                    $translatePartialLoader.addPart('fund-account');
                    $translate.refresh();
                    return true;
                }],
                controller: ['$ocLazyLoad', $ocLazyLoad => {
                    return import(/* webpackChunkName: "prosper-dashboard" */'./prosper-dashboard.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        });
}

module.exports = routes;
