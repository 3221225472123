const template = require('./password.html');
const controller = require('./password.controller');
require('./password.scss');

let passwordComponent = {
    restrict: 'E',
    bindings: {
        formData: '=',
        registrationForm: '='
    },
    template,
    controller: ['$timeout', '$state', '$mdDialog', controller],
    controllerAs: 'registrationPassword'
};

module.exports = passwordComponent;
