const template = require('./question-yes-no.html');
const controller = require('./question-yes-no.controller');
require('./question-yes-no.scss');

let questionYesNoComponent = {
    restrict: 'E',
    bindings: {
        form:'=',
        questionText: '@',
        noTextKey: '@',
        yesTextKey: '@',
        itemId: "@",
        answer: '=',
        disabled: '<',
        onChange: '&',
        toolTipText: '@'
    },
    template,
    controller,
    controllerAs: 'questionYesNo'
};

module.exports = questionYesNoComponent;