const template = require('./header.html');
const controller = require('./header.controller');
require('./header.scss');

let headerComponent = {
    restrict: 'E',
    bindings: {
        selectedGoal: '@',
        displayName: '<'
    },
    template,
    controller: ['goalService','$translate','$mdDialog','userService','$window','$timeout','utilityService','$state','localStorageService','kycService', controller],
    controllerAs: 'header'
};

module.exports = headerComponent;