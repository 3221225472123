const template = require('./contact-information.html');
const controller = require('./contact-information.controller');
require('./contact-information.scss');

let contactInformationComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        formData: "=",
        openAccountModel: "="
    },
    template,
    controller,
    controllerAs: 'contactInformation'
};

module.exports = contactInformationComponent;