const template = require('./portfolio-breakdown-chart.template.html');
const controller = require('./portfolio-breakdown-chart.controller');
require('./portfolio-breakdown-chart.template.scss');

module.exports = {
    restrict: 'E',
    bindings: {
        id: '<',
        size: '<',
        header: '@',
        portfolio: '<',
        primaryColor: '<',
        darkMode: '<'
    },
    template,
    controller: ['$mdDialog', '$window', '$timeout', '$translate', controller],
    controllerAs: 'controller'
}