'use strict';

//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const ocLazyLoad = require('oclazyload');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const formHeader = require('../components/form-header/form-header');
const registrationRoutes = require('./registration.routes');
const userService = require('../services/user-service/user-service');
const createProfileModule = require('./components/create-profile/create-profile');
const footer = require('../components/footer/footer');

let registrationModule = angular
    .module('app.registration-ciam', [
        uiRouter,
        ocLazyLoad,
        angularTranslate,
        createProfileModule,
        formHeader,
        footer,
        userService
    ])
    .config(['$stateProvider', registrationRoutes])
    .name;

module.exports = registrationModule;
