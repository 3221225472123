require('./portfolio-mers.scss');
class PortfolioMersController {
    constructor($mdDialog, $location, $anchorScroll, portfolios) {
        this.dialogService = $mdDialog;
        this.location = $location;
        this.anchorScroll = $anchorScroll;
        this.portfolios = portfolios;
    }

    cancel() {
        this.dialogService.cancel();
    }
}

module.exports = PortfolioMersController;
