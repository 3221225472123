const template = require('./prosper-bonus-agreement.html');
const controller = require('./prosper-bonus-agreement.controller');
require('./prosper-bonus-agreement.scss');

let prosperBonusAgreementComponent = {
    restrict: 'E',
    bindings: {
        form: '=',
        formData: '=',
        includeHeader: '<'
    },
    template,
    controller: ['$mdDialog', controller],
    controllerAs: 'prosperBonusAgreement'
};

module.exports = prosperBonusAgreementComponent;