'use strict';

//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const ocLazyLoad = require('oclazyload');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const formHeader = require('../components/form-header/form-header');
const passwordModule = require('./components/password/password');
const personalInformationModule = require('./components/personal-information/personal-information');
const registrationRoutes = require('./registration.routes');
const securityQuestionsModule = require('../components/set-security-questions/set-security-questions');
const userNameModule = require('./components/register-user-name/register-user-name');
const userService = require('../services/user-service/user-service');

let registrationModule = angular
    .module('app.registration', [
        uiRouter,
        ocLazyLoad,
        angularTranslate,
        formHeader,
        passwordModule,
        personalInformationModule,
        securityQuestionsModule,
        userService,
        userNameModule
    ])
    .config(['$stateProvider', registrationRoutes])
    .name;

module.exports = registrationModule;
