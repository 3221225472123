let linkFunction = function(scope, element, attrs, ctrl){
	let EMAIL_REGEX = /^[\w\.\-]+@[a-zA-Z0-9][a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]{1,})*(\.[a-zA-Z]{2,3}){1,2}$/i;
	ctrl.$validators.validEmail = function (modelValue) {
        return modelValue && EMAIL_REGEX.test(modelValue);
    };
};

let emailValidatorDirective = function(){
    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkFunction
    }
};

module.exports = emailValidatorDirective;