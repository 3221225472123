const template = require('./footer.html');
const controller = require('./footer.controller');
require('./footer.scss');

let footerComponent = {
    restrict: 'E',
    bindings: {},
    template,
    controller: ['$window', controller],
    controllerAs: 'footer'
};

module.exports = footerComponent;