const SocialInsuranceNumber = require('../../../social-insurance-number');

class SpousalInformationController {
    constructor(countries,
                employmentStatuses,
                atbEmploymentStatuses,
                monthOptions,
                userService,
                numberDependentsList,
                $window) {
        this.countries = countries;
        this.employmentStatuses = employmentStatuses;
        this.atbEmploymentStatuses = atbEmploymentStatuses;
        this.monthOptions = monthOptions;
        this.userService = userService;
        this.numberDependentsList = numberDependentsList;
        this.window = $window;

        // bindings
        this.formData = {};
        this.openAccountForm = {};
        this.isJointAccount = true;
    }

    $onInit() {
        this.dayOptions = [];
        this.yearOptions = [];
        for (let day = 1; day <= 31; day++) {
            this.dayOptions.push(day);
        }
        let year = new Date().getFullYear() - 18;
        for (let i = 0; i < 100; i++) {
            this.yearOptions.push(year);
            year--;
        }
        this.calculateAge();

        this.initAtbEmploymentStatus();
    }

    $onChanges() {
        this.formData.sin = parseInt(this.formData.sin, 10);
    }

    get availableAtbEmploymentStatus() {
        return this.atbEmploymentStatuses
            .filter(it => it.statuses.includes('*') || it.statuses.includes(this.formData.employmentStatus));
    }

    initAtbEmploymentStatus() {
        const valid = this.availableAtbEmploymentStatus.find(it => it.value === this.formData.atbCompany);
        if (!valid) {
            this.formData.atbCompany = null;
        }
        if (!this.formData.atbCompany || this.formData.atbCompany === 'No') {
            this.formData.atbEmployeeNumber = null;
        }
    }

    onEmploymentStatusChanged() {
        this.formData.atbCompany = null;
        this.formData.atbEmployeeNumber = null;
        this._resetFormFieldError('spousalAtbEmploymentStatus');
        this._resetFormFieldError('spousalAtbEmployeeNumber');
    }

    onAtbEmploymentStatusChanged() {
        if (!this.formData.atbCompany || this.formData.atbCompany === 'No') {
            this.formData.atbEmployeeNumber = null;
        }
    }

    _resetFormFieldError(fieldName) {
        if (this.openAccountForm && this.openAccountForm[fieldName]) {
            this.openAccountForm[fieldName].$setPristine();
            this.openAccountForm[fieldName].$setUntouched();
        }
    }

    calculateAge() {
        this.age = this.userService.calculateAge(this.formData.birthMonth, this.formData.birthDay, this.formData.birthYear);
    }

    generateSin() {
        this.formData.sin = SocialInsuranceNumber.generate();
    }
}

module.exports = SpousalInformationController;
