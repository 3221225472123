//VENDOR MODULES
const angular = require('angular');

//NON-VENDOR MODULES
const atbConstantsModule = require('../../../components/atb-constants/atb-constants');
const beneficiaryInformationComponent = require('./beneficiary-information.component.js');
const maximumLengthModule = require('../../../directives/maximum-length/maximum-length');
const regexValidatorModule = require('../../../directives/regex-validator/regex-validator');

let beneficiaryInformationModule = angular.module('beneficiaryInformation', [
    atbConstantsModule,
    maximumLengthModule,
    regexValidatorModule
])

    .component('beneficiaryInformation', beneficiaryInformationComponent)
    .name;

module.exports = beneficiaryInformationModule;