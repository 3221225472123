const trimInputDirective = () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: (scope, element, attrs, ctrl) => {
            element.bind('blur', () => {
                ctrl.$setViewValue((ctrl.$viewValue || '').trim());
                ctrl.$render();
            });
        }
    }
};

module.exports = trimInputDirective;