//VENDOR MODULES
const angular = require('angular');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');
const localStorageService = require('angular-local-storage');
const ocLazyLoad = require('oclazyload');
const uiRouter = require('@uirouter/angularjs').default;

//NON-VENDOR MODULES
const openAccountRoutes = require('./open-account.routes.js');

const accountPersonalInformationModule = require('./components/account-personal-information/account-personal-information');
const accountTypeModule = require('./components/account-type/account-type');
const atbolLoginModule = require('../components/atbol-login/atbol-login');
const beneficiaryInformationModule = require('./components/beneficiary-information/beneficiary-information');
const contactInformationModule = require('./components/contact-information/contact-information');
const employmentInformationModule = require('./components/employment-information/employment-information');
const formHeaderModule = require('../components/form-header/form-header');
const greyBackgroundBoxModule = require('../components/grey-background-box/grey-background-box');
const investmentAccountService = require('../services/investment-account-service/investment-account-service');
const investmentExperienceModule = require('./components/investment-experience/investment-experience');
const matchHeightModule = require('../directives/match-height/match-height');
const netWorthModule = require('./components/net-worth/net-worth');
const regulatoryQuestionsModule = require('./components/regulatory-questions/regulatory-questions');
const spousalInformationModule = require('./components/spousal-information/spousal-information');
const userServiceModule = require('../services/user-service/user-service');
const utilityServiceModule = require('../services/utility-service/utility-service');

let openAccountModule = angular
    .module('openAccount', [
        accountPersonalInformationModule,
        accountTypeModule,
        atbolLoginModule,
        angularTranslate,
        beneficiaryInformationModule,
        contactInformationModule,
        employmentInformationModule,
        formHeaderModule,
        greyBackgroundBoxModule,
        investmentAccountService,
        investmentExperienceModule,
        localStorageService,
        matchHeightModule,
        netWorthModule,
        ocLazyLoad,
        regulatoryQuestionsModule,
        spousalInformationModule,
        uiRouter,
        userServiceModule,
        utilityServiceModule
    ])
    .config(['$stateProvider', openAccountRoutes])
    .name;

module.exports = openAccountModule;
