const goalSetUpTemplate = require('./start-investing.html');
require('./start-investing.scss');

function routes($stateProvider) {
    $stateProvider
        .state('start-investing', {
            url: '/start-investing?companyKey',
            parent: 'site',
            params: {
                selectedGoal: null
            },
            views: {
                'content@': {
                    template: goalSetUpTemplate,
                    controller: 'StartInvestingController',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                riskAssessmentQuestions: ['riskAssessmentService', (riskAssessmentService) => {
                    return riskAssessmentService.getRiskAssessmentV2Questions();
                }],
                riskAssessmentAnswerModel: ['riskAssessmentService', (riskAssessmentService) => {
                    return riskAssessmentService.getSavedRiskAssessmentV2Answers();
                }],
                startInvestingScreenTranslations:['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('start-investing');
                    $translate.refresh();
                    return true;
                }],
                StartInvestingController: ['$ocLazyLoad', $ocLazyLoad => {
                    return import(/* webpackChunkName: "start-investing" */'./start-investing.controller')
                    .then(controller => {
                        $ocLazyLoad.load(controller);
                        controller.$inject = [
                            '$state',
                            '$stateParams',
                            'riskAssessmentAnswerModel',
                            'riskAssessmentQuestions',
                            'goalService',
                            'riskAssessmentService',
                            'localStorageService',
                            '$timeout',
                            'userService']
                    })
                }]
            }
        });
}

module.exports = routes;
