const template = require('./account-personal-information.html');
const controller = require('./account-personal-information.controller.js');
require('./account-personal-information.scss');

let accountPersonalInformationComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        formData: "=",
        spousalInformation: "=",
        fullFormData: "=",
        educationGoal: "<",
        isJointAccount: "<",
        primaryUserEmail: "<",
        accountType: "<",
        hasCompletedGoalAsPrimary: "<",
        isGroupGoal: "<"
    },
    template,
    controller: ['countries','employmentStatuses','atbEmploymentStatuses','maritalStatuses','monthOptions','userService','numberDependentsList','$window', controller],
    controllerAs: 'accountPersonalInformation'
};

module.exports = accountPersonalInformationComponent;
