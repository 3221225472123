//VENDOR MODULES
const angular = require('angular');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');
const localStorageService = require('angular-local-storage');
const ocLazyLoad = require('oclazyload');
const uiRouter = require('@uirouter/angularjs').default;

//NON-VENDOR MODULES
const educationBeneficiariesRoutes = require('./education-beneficiaries.routes.js');
const educationBeneficiaryInformationModule = require('./components/education-beneficiary-information/education-beneficiary-information');
const formHeaderModule = require('../components/form-header/form-header');
const investmentAccountService = require('../services/investment-account-service/investment-account-service');
const userServiceModule = require('../services/user-service/user-service');
const utilityServiceModule = require('../services/utility-service/utility-service');

let educationBeneficiariesModule = angular
    .module('educationBeneficiaries', [
        angularTranslate,
        educationBeneficiaryInformationModule,
        formHeaderModule,
        investmentAccountService,
        localStorageService,
        ocLazyLoad,
        uiRouter,
        userServiceModule,
        utilityServiceModule
    ])
    .config(['$stateProvider', educationBeneficiariesRoutes])
    .name;

module.exports = educationBeneficiariesModule;
