class FormHeaderController {
    $onChanges() {
        this.totalSteps = 4;
        if (this.skipIdVerifyPage && !this.isEducationGoal) {
            this.totalSteps--;
        }

        switch (this.currentPage) {
            case 'OPENACCOUNT':
                this.currentStep = 1;
                break;
            case 'EDUCATIONBENEFICIARIES':
                this.currentStep = 2;
                break;
            case 'FUNDACCOUNT':
                this.currentStep = 2;
                if (this.isEducationGoal) {
                    this.currentStep++;
                }
                break;
            case 'IDVERIFICATION':
                this.currentStep = 3;
                if (this.isEducationGoal) {
                    this.currentStep++;
                }
                break;
            case 'DOCUSIGN':
                if (this.isPrimaryApplicant) {
                    this.currentStep = this.totalSteps;
                } else {
                    this.currentStep = 1;
                    this.totalSteps = 1;
                }
                break;
        }
    }
}

module.exports = FormHeaderController;
