require('./verify-email.template.scss');

function routes($stateProvider) {
    $stateProvider.state('verifyEmail', {
        url: '/verify-email?UT&hash',
        parent: 'site',
        views: {
            'content@': {
                template: require('./verify-email.template.html'),
                controller: 'VerifyEmailController',
                controllerAs: 'controller'
            }
        },
        resolve: {
            locale: [
                '$translatePartialLoader',
                '$translate',
                ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('verify-email');
                    $translate.refresh();
                    return true;
                }
            ],
            controller: ['$ocLazyLoad', $ocLazyLoad => {
                return import(/* webpackChunkName: "verify-email" */ './verify-email.controller')
                    .then(controller => $ocLazyLoad.load(controller));
            }]
        }
    });
}

module.exports = routes;
