require('./expandable.template.scss');

module.exports = {
    restrict: 'E',
    bindings: {
        title: '@'
    },
    transclude: true,
    template: require('./expandable.template.html'),
    controller: [require('./expandable.controller')],
    controllerAs: 'controller'
}
