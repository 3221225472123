const template = require('./aic-cta.html');
const controller = require('./aic-cta.controller.js');
require('./aic-cta.scss');

let aicCtaComponent = {
    restrict: 'E',
    bindings: {
    },
    template,
    controller: ['$window', '$mdDialog', '$localStorage', controller],
    controllerAs: 'aicCta'
};

module.exports = aicCtaComponent;
