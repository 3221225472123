//VENDOR MODULES
const angular = require('angular');
const angularTranslate = require('angular-translate');
const angularMaterial = require('angular-material');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const headerModule = require('../components/header/header');
const loginFooterModule = require('../components/login-footer/login-footer');
const userServiceModule = require('../services/user-service/user-service');
const routes = require('./forgot-username.routes.js');

let forgotUsernameModule = angular
    .module('app.forgot-username', [
        angularTranslate,
        angularMaterial,
        headerModule,
        loginFooterModule,
        userServiceModule
    ])
    .config(['$stateProvider', routes])
    .name;


module.exports = forgotUsernameModule;
