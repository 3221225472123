const TIN_REGEX = /^(9\d{2})[- ]{0,1}((5[0-9]{1}|6[0-5]{1}|88{1})|(9[0-2]{1})|(9[4-9]{1}))[- ]{0,1}(\d{4})$/;
const SSN_REGEX = /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$/;

const linkFunction = (scope, element, attrs, ctrl) => {
    const isValidSSN = (str) => {
        const blacklist = ['078051120', '219099999']; // https://www.lexjansen.com/nesug/nesug07/ap/ap19.pdf
        return !blacklist.includes(str) && SSN_REGEX.test(str);
    }

    ctrl.$validators.tinValidator = (modelValue) => {
        return !modelValue || TIN_REGEX.test(modelValue) || isValidSSN(modelValue);
    };
};

const tinValidatorDirective = () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkFunction
    }
};

module.exports = tinValidatorDirective;
