const eSignTemplate = require('./e-sign.html');
require('./e-sign.scss');

function routes($stateProvider) {
    $stateProvider
        .state('e-sign', {
            url: '/e-sign?event',
            parent: 'site',
            views: {
                'content@': {
                    template: eSignTemplate,
                    controller: 'ESignController',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                data: ['$state', 'userService', 'goalService', ($state, userService, goalService) => {
                    userService.removeUserAction();
                    return goalService.verifyValidGoalNavigation('DOCUSIGN')
                        .catch(err => $state.go('prosper-dashboard'));
                }],
                eSignTranslations: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('eSign');
                    $translate.refresh();
                    return true;
                }],
                eSignController: ['$ocLazyLoad', $ocLazyLoad => {
                    return import(/* webpackChunkName: "docu-sign" */'./e-sign.controller')
                        .then(controller => $ocLazyLoad.load(controller))
                }]
            }
        });
}

module.exports = routes;
