const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const investmentLossQuestionComponent = require('./investment-loss-question.component.js');
const horizontalRGModule = require('../../../../components/horizontal-radio-group-v2/horizontal-radio-group');

let investmentLossQuestionModule = angular
    .module('investmentLossQuestion', [
        angularSanitize,
        horizontalRGModule
    ])
    .component('investmentLossQuestion', investmentLossQuestionComponent)
    .name;

module.exports = investmentLossQuestionModule;