const Highcharts = require('highcharts/highcharts');
require('highcharts/modules/no-data-to-display')(Highcharts);
require('highcharts/modules/drilldown')(Highcharts);
require('./my-investments.scss');
const moment = require('moment');

const ATB_LBLUE = '#8FDFFF';
const ATB_WHITE = '#FFFFFF';
const ATB_LGREY = '#CECCCB';
const ATB_ORANGE_500 = '#EF630D';
const ATB_ORANGE_400 = '#FF7F30';
const ATB_ORANGE_300 = '#FF9C35';
const ATB_ORANGE_200 = '#FFC773';
const ATB_YELLOW_700 = '#F1BC00';
const ATB_YELLOW_600 = '#FCCE2B';
const ATB_YELLOW_500 = '#FCDC3E';
const ATB_YELLOW_400 = '#F9E95A';
const ATB_YELLOW_300 = '#FAF182';
const ATB_YELLOW_200 = '#FFF9AC';
const ATB_LBLUE_700 = '#005CC8';
const ATB_LBLUE_600 = '#0079D1';
const ATB_LBLUE_500 = '#01A1E5';
const ATB_LBLUE_400 = '#00BDF9';
const ATB_LBLUE_300 = '#59CFFF';
const ATB_LBLUE_200 = '#8FDFFF';
const ATB_LBLUE_100 = '#D9F4FF';
const ATB_CHART_PALETTE = [
    ATB_YELLOW_700,
    ATB_LBLUE_700,
    ATB_ORANGE_500,
    ATB_LBLUE_100,
    ATB_YELLOW_200,
    ATB_LBLUE_600,
    ATB_ORANGE_200,
    ATB_LBLUE_200,
    ATB_YELLOW_400,
    ATB_LBLUE_300,
    ATB_ORANGE_300,
    ATB_LBLUE_400,
    ATB_YELLOW_500];

class MyInvestmentsController {
  constructor($mdDialog, $timeout, goalService, fundCodes, $window) {
    this.dialogService = $mdDialog;
    this.timeout = $timeout;
    this.goalService = goalService;
    this.fundCodes = fundCodes;
    this.window = $window;

    this.selected = {};
    this.holdings = [];
    this.cusip = null;
  }

  $onChanges() {
    if (this.selected.isFunded) {
      this.holdings = this.selected.holdings
          .sort((a, b) => {
            return a.cusip === 'CASH' ? 1 : b.cusip === 'CASH' ? -1 : b.tdMarketValue - a.tdMarketValue;
          });
      if (this.holdings.length === 1) {
        this.cusip = this.holdings[0].cusip;
      }
      this.timeout(() => {
        let chart = this.createHoldingsChart();
        chart && chart.reflow();
      });
    }
  }

  createHoldingsChart() {
    return document.getElementById('holdingsChart') && new Highcharts.Chart({
      chart: {
        backgroundColor: 'transparent',
        renderTo: 'holdingsChart',
        type: 'pie'
      },
      title: {
        text: `As of ${moment(this.holdings[0].asOfDate).format('MMM DD, YYYY')}, I am invested in...`,
        style: {
          color: ATB_WHITE,
          fontWeight: 'bold',
          fontFamily: 'Inter, Arial, Helvetica, serif'
        }
      },
      plotOptions: {
        pie: {
          shadow: false,
          allowPointSelect: true
        }
      },
      tooltip: {
        enabled: true,
        formatter: function () {
          return this.point.name + ': ' + this.y.toFixed(2) + '%'
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Percentage',
        data: this.getHoldingsChartData(),
        size: '100%',
        dataLabels: {
          enabled: false
        },
        point: {
          events: {
            click: (event) => {
              this.timeout(() => {
                this.select(event.point.options.cusip);
              });
            }
          }
        }
      }]
    });
  }

  select(cusip) {
    this.cusip = this.cusip === cusip ? null : cusip;
  }

  isCompass(fundCode) {
    return this.fundCodes[fundCode] && this.fundCodes[fundCode].isCompass
  }

  isSelected(holding) {
    return this.cusip !== null && this.cusip === holding.cusip;
  }

  get selectedHolding() {
    return this.cusip && this.holdings.filter(it => it.cusip === this.cusip)[0];
  }

    getChartColor(cusip, i) {
        return cusip === 'CASH'
            ? ATB_LGREY
            : ATB_CHART_PALETTE[i % ATB_CHART_PALETTE.length]


    }

  getHoldingsChartData() {
    let data = [];
    for (let i = 0; i < this.holdings.length; i++) {
      let holding = this.holdings[i];
      data.push({
            cusip: holding.cusip,
            color: this.getChartColor(holding.cusip, i),
            name: holding.securityName,
            y: (holding.tdMarketValue * 100) / this.selected.calculatedTotal
          }
      );
    }
    return data;
  }
}

module.exports = MyInvestmentsController;
