class GoalSelectionController {
    constructor(goalService) {
        this.goalService = goalService;
    }

    $onInit() {
        this.selected = this.model.goalType;
        this.onGoalTypeChanged();
    }

    onGoalTypeChanged() {
        this.model = {
            goalType: this.selected,
            isJointAccount: this.selected === 'EDUCATION' ? null : 'NO'
        };
    }
}

module.exports = GoalSelectionController;