//VENDOR MODULES
const angular = require('angular');
const uiMask = require('angular-ui-mask');

//NON-VENDOR MODULES
const atbConstants = require('../../../components/atb-constants/atb-constants');
const consentToShareModule = require('../../../components/consent-to-share/consent-to-share');
const contactInformationComponent = require('./contact-information.component');
const toolTipComponent = require('../../../components/tool-tip/tool-tip');
const questionYesNoComponent = require('../../../components/question-yes-no/question-yes-no');


let contactInformationModule = angular.module('contactInformation', [
    atbConstants,
    consentToShareModule,
    toolTipComponent,
    questionYesNoComponent,
    uiMask
])

    .component('contactInformation', contactInformationComponent)
    .name;

module.exports = contactInformationModule;