const angular = require('angular');
const angularMaterial = require('angular-material');
const userService = require('../../../../services/user-service/user-service');
const emailConfirmationDialogTemplate = require('../../../../components/email-confirmation/email-confirmation.html');
const emailFailureDialogTemplate = require('../../../../components/email-confirmation/email-failure.html');
const emailConfirmationController = require('../../../../components/email-confirmation/email-confirmation.controller.js');
const ContactUsController = require('../../../../components/contact-us/contact-us.controller.js');

class RequestEmailController {
    constructor(userService, $mdDialog, $location) {
        this.userService = userService;
        this.dialogService = $mdDialog;
        this.location = $location;

        this.username = null;
        this.forgotPasswordForm = {};
    }

    showContactUsDetails(event) {
        this.dialogService.show({
                controller: ['$mdDialog', 'description', 'hasEmailSection', ContactUsController],
                controllerAs: 'contactUs',
                template: require('../../../../components/contact-us/contact-us.html'),
                locals: {
                    'description': 'header.contactUsUsernameDescription',
                    'hasEmailSection': false
                },
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true
            }
        )
    }

    setUsernameLockedOutValidity(validity){
        this.forgotPasswordForm.username.$setValidity('usernameLockedOut', validity);
    }

    submitForm() {
        if (this.forgotPasswordForm.$valid && !this.isSendingEmail) {
            this.isSendingEmail = true;
            /* istanbul ignore next */
            const removingFunctionHandler = () => {
                this.isSendingEmail = false;
            };

            let successObject = {
                controller: ['$mdDialog', emailConfirmationController],
                controllerAs: 'emailConfirmation',
                template: emailConfirmationDialogTemplate,
                parent: angular.element(document.body),
                fullscreen: true,
                clickOutsideToClose: true,
                onRemoving: removingFunctionHandler
            };

            let failureObject = {
                controller: ['$mdDialog', emailConfirmationController],
                controllerAs: 'emailFailure',
                template: emailFailureDialogTemplate,
                parent: angular.element(document.body),
                fullscreen: true,
                clickOutsideToClose: true,
                onRemoving: removingFunctionHandler
            };
            this.userService.forgotPasswordSendEmail(this.username)
                .then(() => {
                    this.dialogService.show(successObject);
                })
                .catch((error) => {
                    if(error && error.status === 401) {
                        this.setUsernameLockedOutValidity(false);
                        this.isSendingEmail = false;
                    } else {
                        this.dialogService.show(failureObject);
                    }
                });
        }
    }
}

module.exports = RequestEmailController;
