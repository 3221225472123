const angular = require('angular');
const angularMaterial = require('angular-material');

const requestEmailComponent = require('./request-email.component.js');
const userService = require('../../../../services/user-service/user-service');
const googleCaptchaModule = require('../../../../components/google-captcha/google-captcha');
const feedbackModule = require('../../../../components/feedback-message/feedback-message')

let requestEmailModule = angular.module('requestEmailV2', [
    angularMaterial,
    googleCaptchaModule,
    userService,
    feedbackModule
])
    .component('requestEmailV2', requestEmailComponent)
    .name;

module.exports = requestEmailModule;
