const angular = require('angular');
const kycServiceComponent = require('./kyc-service.service');
const angularResource = require('angular-resource');
const uiRouter = require('@uirouter/angularjs').default;
const devConfigurationModule = require('../dev-configuration/dev-configuration');

let kycServiceModule = angular.module('app.kycService', [
        angularResource,
        uiRouter,
        devConfigurationModule
    ])
    .service('kycService', ['$resource', '$http', 'devConfiguration', '$state', '$window', kycServiceComponent])
    .name;

module.exports = kycServiceModule;