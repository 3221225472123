const ATBF_BANK_NUMBER = '0219';
const BRIGHTSIDE_TRANSIT_NUMBER = '07069';
const ACCEPT_FILE_TYPE = /^.*\.(jpg|pdf|png|jpeg)$/i;

class BankingInformationController {
    constructor($filter, $timeout, atbBranches, bankAccountLengths, bankNames) {
        this.filter = $filter;
        this.timeout = $timeout;
        this.atbBranchArray = atbBranches;
        this.bankAccountLengths = bankAccountLengths;
        this.bankArray = bankNames;
        this.searchText = null;
        this.selectedAtbBranch = null;
        this.selectedBank = null;

        this.formData = {};
        this.fundAccountForm = {};
        this.availableAccounts = [];
    }

    $onInit() {
        // convenience properties used in both HTML and Controller
        this.fundingData = this.formData.fundingData;
        this.accountData = this.formData.uiForm.accountPersonalInformation;

        angular.element(document.getElementById('browseButton')).on('change', event => {
            this.timeout(() => {
                if (event.target.files[0]) {
                    this.selectFile(event.target.files[0]);
                }
            });
        });

        if (this.availableAccounts && this.availableAccounts.length > 0) {
            if (this.isFundingDataDefined()) {
                let existing = this.availableAccounts.filter(it => it.label === this.fundingData.label)[0];
                if (existing) {
                    this.fundingData.useExistingBankAccount = true;
                    this.fundingData.existingBankAccount = existing.label;
                    this.fundingData.existingBankAccountObject = existing;
                    this.clearFundingData();
                    this.clearSelectedFile();
                } else {
                    this.fundingData.useExistingBankAccount = false;
                    this.selectBankFromCode(this.fundingData.bankNumber);
                    this.selectBranchFromCode(this.fundingData.transitNumber);
                }
            } else {
                this.enableExistingBankAccountField(true);
            }
        } else {
            this.selectBankFromCode(this.fundingData.bankNumber);
            this.selectBranchFromCode(this.fundingData.transitNumber);
        }
    }

    get hideVerifyBankField() {
        return this.hideVerifyBankSection || this.accountData.inPersonVerification;
    }

    get hideVerifyBankSection() {
        return this.fundingData.useExistingBankAccount || this.fundingData.bankNumber === ATBF_BANK_NUMBER;
    }

    get isJointResf() {
        return this.formData.goal.goalType === 'EDUCATION' && this.formData.goal.isJointAccount;
    }

    isFundingDataDefined() {
        return !!(
            this.fundingData
            && (this.fundingData.bankNumber
                || this.fundingData.transitNumber
                || this.fundingData.accountNumber
            ));
    }

    onFundingDataChanged() {
        this.clearSelectedFile();
    }

    enableExistingBankAccountField(useExisting) {
        this.fundingData.useExistingBankAccount = useExisting;
        if (useExisting) {
            if (this.availableAccounts.length === 1) {
                this.fundingData.existingBankAccount = this.availableAccounts[0].label;
                this.fundingData.existingBankAccountObject = this.availableAccounts[0];
            }
            this.clearFundingData();
            this.clearSelectedFile();
        } else {
            this.fundingData.existingBankAccount = null;
            this.fundingData.existingBankAccountObject = null;
        }
    }

    clearFundingData() {
        this.selectedBank = null;
        this.searchText = null;
        this.fundingData.bankNumber = null;
        this.fundingData.transitNumber = null;
        this.fundingData.accountNumber = null;
    }

    filterAtbBranchList(branchSearchText) {
        if (branchSearchText) {
            let list = this.filter('filter')(this.atbBranchArray, branchSearchText);
            if (list.length === 1 && branchSearchText.length <= 5 && !isNaN(branchSearchText)) {
                this.selectAtbBranch(list[0]);
            }
            return list;
        } else {
            return this.atbBranchArray;
        }
    }

    filterBankList(searchText) {
        if (searchText) {
            let list = this.filter('filter')(this.bankArray, searchText);
            if (list.length === 1 && searchText.length <= 4 && !isNaN(searchText)) {
                this.selectBank(list[0]);
            }
            return list;
        } else {
            return this.bankArray;
        }
    }

    setAccountLengths(bank) {
        this.acceptedAccountLengths = this.bankAccountLengths[bank.bankCode];
    }

    setAccountLengthForAtbTransit() {
        if (this.selectedAtbBranch.transit === BRIGHTSIDE_TRANSIT_NUMBER) {
            this.acceptedAccountLengths = [11];
        } else {
            this.acceptedAccountLengths = [9];
        }
        if (this.fundAccountForm.accountNumber) {
            this.timeout(() => {
                this.fundAccountForm.accountNumber.$validate();
            });
        }
    }

    selectAtbBranch(atbBranch) {
        if (atbBranch) {
            this.selectedAtbBranch = atbBranch;
            this.fundingData.transitNumber = this.selectedAtbBranch.transit;
            this.setAccountLengthForAtbTransit();
        } else {
            this.fundingData.transitNumber = null;
        }
    }

    selectBank(bank) {
        this.clearSelectedFile();
        if (bank) {
            this.selectedBank = bank;
            this.fundingData.bankNumber = this.selectedBank.bankCode;
            this.setAccountLengths(bank);
        } else {
            this.selectedBank = null;
            this.fundingData.bankNumber = null;
        }

        if (this.fundAccountForm.accountNumber) {
            this.timeout(() => {
                this.fundAccountForm.accountNumber.$validate();
            });
        }
    }

    selectBankFromCode(bankNumber) {
        angular.forEach(this.bankArray, (bank) => {
            if (bank.bankCode === bankNumber) {
                this.selectedBank = bank;
                this.fundingData.bankNumber = this.selectedBank.bankCode;
                this.setAccountLengths(bank);
                if (this.fundAccountForm.accountNumber) {
                    this.timeout(() => {
                        this.fundAccountForm.accountNumber.$validate();
                    });
                }
            }
        });
    }

    selectBranchFromCode(atbTransitNumber) {
        angular.forEach(this.atbBranchArray, (atbBranch) => {
            if (atbBranch.transit === atbTransitNumber) {
                this.selectAtbBranch(atbBranch);
            }
        });
    }

    selectFile(file) {
        this.fundAccountForm.file.$dirty = true;
        if (this.isValidFileType(file) && this.isValidFileSize(file)) {
            this.file = file;
            this.accountData.uploadedFileName = file.name;
        } else {
            this.fundAccountForm.file.$touched = true;
        }
    }

    clearSelectedFile() {
        this.file = null;
        this.accountData.uploadedFileName = null;
        angular.element(document.getElementById('browseButton')).val(null);
        if (this.fundAccountForm && this.fundAccountForm.file) {
            this.fundAccountForm.file.$dirty = true;
        }

        // reset validation errors
        if (this.fundAccountForm.file) {
            this.fundAccountForm.file.$touched = false;
            this.fundAccountForm.file.$setValidity('type', true);
            this.fundAccountForm.file.$setValidity('size', true);
        }
    }

    isValidFileType(file) {
        let isValid = !!file.name.match(ACCEPT_FILE_TYPE);
        this.fundAccountForm.file.$setValidity('type', isValid);
        return isValid;
    }

    isValidFileSize(file) {
        let isValid = file.size <= 5 * 1024 * 1024; // 5 MB
        this.fundAccountForm.file.$setValidity('size', isValid);
        return isValid;
    }
}

module.exports = BankingInformationController;
