const angular = require('angular');
const angularTranslate = require('angular-translate');
const governmentGrantsAgreementComponent = require('./government-grants-agreement.component');

let governmentGrantsAgreementModule = angular.module('governmentGrantsAgreement',[
        angularTranslate
    ])
    .component('governmentGrantsAgreement', governmentGrantsAgreementComponent)
    .name;

module.exports = governmentGrantsAgreementModule;
