const angular = require('angular');
const landingPageGoalComponent = require('./landing-page-goal.component');
const uiRouter = require('@uirouter/angularjs').default;
const utilityService = require('../../services/utility-service/utility-service');

let landingPageGoalModule = angular.module('landingPageGoal', [uiRouter, utilityService])
    .component('landingPageGoal', landingPageGoalComponent)
    .name;

module.exports = landingPageGoalModule;
