const template = require('./review-transactions.html');
const controller = require('./review-transactions.controller.js');
require('./review-transactions.scss');

let reviewTransactionsComponent = {
    restrict: 'E',
    bindings: {
        formData: '<',
        form: '<'
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = reviewTransactionsComponent;