const template = require('./consent-to-share.html');
const controller = require('./consent-to-share.controller');
require('./consent-to-share.scss');

let consentToShareComponent = {
    restrict: 'E',
    bindings: {
        form: '=',
        formData: '='
    },
    template,
    controller,
    controllerAs: 'consentToShare'
};

module.exports = consentToShareComponent;