const whyWeAskDialogController = require('./why-we-ask-dialog.controller.js');

class WhyWeAskController {
    constructor($mdDialog) {
    	this.dialogService = $mdDialog
		this.toolTipImage = require("../../../public/img/toolTip.svg");
    }

	openDialog(event){
		this.dialogService.show({
			template: require('./why-we-ask-dialog.html'),
			controller: ['text', '$mdDialog', whyWeAskDialogController],
			controllerAs: 'whyWeAskDialog',
			ariaLabel: this.label,
			parent: angular.element(document.body),
			targetEvent: event,
			locals: {
                'text': this.text
            },
			multiple: true,
			clickOutsideToClose:true
		});
	}
}

module.exports = WhyWeAskController;
