const template = require('./investment-cost.html');
const controller = require('./investment-cost.controller');
require('./investment-cost.scss');

let investmentCostComponent = {
    restrict: 'E',
    bindings: {},
    template,
    controller: ['$filter', '$mdDialog', 'utilityService', controller],
    controllerAs: 'investmentCost'
};

module.exports = investmentCostComponent;