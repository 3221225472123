class KycServiceService {
    constructor($resource,
                $http,
                devConfiguration,
                $state,
                $window) {
        this.window = $window;
        this.$http = $http;
        this.urlRoot = devConfiguration.getURLRoot(this.window.location.port);
        this.api = (path) => $resource(this.urlRoot + '/' + path);
    }

    getKycInvestorProfile() {
        return this.api('kyc/investorProfile').get().$promise;
    }

    getKycInvestorProfileStatus() {
        return this.$http.get(this.urlRoot + '/kyc/investorProfileStatus');
    }

    submit(investorProfile, delta) {
        return this.api('kyc/investorProfile').save({data: investorProfile, changes: delta}).$promise;
    }

    getInvestorProfileInAnnualReview(){
        return this.$http.get(this.urlRoot + '/kyc/investorProfileInAnnualReview');
    }
}

module.exports = KycServiceService;
