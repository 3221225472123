const template = require('./liquidity-needs-question.html');
const controller = require('./liquidity-needs-question.controller.js');
require('./liquidity-needs-question.scss');

let liquidityNeedsQuestionComponent = {
    restrict: 'E',
    bindings: {
        questionData: '=',
        model: '=',
        onNext: '&',
        onPrev: '&'
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = liquidityNeedsQuestionComponent;