const registrationTemplate = require('./registration.html');
require('./registration.scss');

function routes($stateProvider) {
    $stateProvider
        .state('registration-ciam', {
            url: '/registration-ciam?create&hash&UT',
            parent: 'site',
            views: {
                'content@': {
                    template: registrationTemplate,
                    controller: 'CiamRegistrationController',
                    controllerAs: 'registration'
                }
            },
            onEnter: ['userService', (userService) => {
                userService.removeUserDisplayName();
                userService.removeAuthToken();
            }],
            resolve: {
                localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('ciam-registration');
                    $translate.refresh();
                    return true;
                }],
                controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "registration" */ './registration.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }],
            }
        })
}

module.exports = routes;
