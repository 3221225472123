class InvestmentExperienceController {
    constructor() {
        this.model = {};
        this.form = {};
    }

    setOtherDetailsCheckbox(){
        this.model.otherInvestmentExperienceDetails
            && (this.model.otherInvestmentExperience = true)
            && (this.model.none = false);

    }

    otherDetailsHasError() {
        return this.model.otherInvestmentExperience
                && (!this.model.otherInvestmentExperienceDetails ||
                    this.model.otherInvestmentExperienceDetails.length === 0);
    }

    toggleOtherDetails() {
        if (this.model.otherInvestmentExperience) {
            this.model.otherInvestmentExperienceDetails = '';
        } else {
            this.form.otherInvestmentExperienceDetails.$$element.focus();
        }
    }

    resetInvestmentExperienceFields(){
        this.model.bonds = false;
        this.model.cashOrCashEquivalent = false;
        this.model.commonShares = false;
        this.model.mutualFundsETF = false;
        this.model.preferredShares = false;
        this.model.options = false;
        this.model.newIssues = false;
        this.model.privateSecurities = false;
        this.model.cryptoCurrencies = false;
        this.model.preciousMetals = false;
        this.model.otherInvestmentExperience = false;
        this.model.otherInvestmentExperienceDetails = null;
    }
}

module.exports = InvestmentExperienceController;