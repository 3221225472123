require('./risk-tolerance.template.scss');
const controller = require('./risk-tolerance.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        model: '=',
        form: '<'
    },
    template: require('./risk-tolerance.template.html'),
    controller: ['financialRisks', 'investmentKnowledgeList', 'fluctuatingInvestmentsList', controller],
    controllerAs: 'controller'
};