require('./atbol-login-dialog.template.scss');
const auth0 = require('auth0-js');
const crypto = require('crypto');

class AtbolLoginDialogController {
  constructor($sce, $window, $mdDialog, userService) {
    this.sce = $sce;
    this.window = $window;
    this.dialog = $mdDialog;
    this.userService = userService;
  }

  $onInit() {
    this.auth0 = new auth0.WebAuth({
      domain: this.window.applicationConfiguration.auth0.rebankDomain,
      clientID: this.window.applicationConfiguration.auth0.rebankClientID
    });
  }

  bind() {
    // bind the message listener
    this.handleMessage = this.handleMessage.bind(this);
    this.window.addEventListener('message', this.handleMessage, false);
  }

  init() {
    // bind the loader
    const iframe = document.getElementById('rebank-iframe');
    iframe.addEventListener('load', this.onIframeLoad);
    this.redirect();
  }

  unbind() {
    // clean up the bindings
    this.window.removeEventListener('message', this.handleMessage, false);
    const iframe = document.getElementById('rebank-iframe');
    iframe.removeEventListener('load', this.onIframeLoad);
  }

  onIframeLoad() {
    const iframe = document.getElementById('rebank-iframe');
    const loader = document.getElementById('rebank-loader');
    loader.style.zIndex = '0';
    iframe.style.zIndex = '1';
  }

  redirect(errorCode) {
    this.state = crypto.randomBytes(64).toString('hex');
    const url = this.sce.trustAsResourceUrl(this.auth0.client.buildAuthorizeUrl({
      clientID: this.window.applicationConfiguration.auth0.rebankClientID,
      redirectUri: this.window.location.origin + '/rebank/callback',
      responseType: 'token',
      audience: 'https://api.atb.com/',
      state: this.state,
      loginHint: errorCode
    }));
    document.getElementById('rebank-iframe').setAttribute('src', url);
  }

  handleMessage(message) {
    if (message.origin !== this.window.origin
        || !message.data
        || message.data.type !== 'rebank') {
      return;
    }

    if (['DEV', 'PREPROD'].includes(this.window.__env)) {
      console.log(message.data);
    }

    switch (message.data.status) {
      case 'success': {
        if (this.state !== message.data.state) {
          return;
        }
        this.userService.signInAtbOnlineUser(message.data.token)
            .then((response) => {
              // Success
              this.dialog.hide(response);
            })
            .catch((e) => {
              // Handle backend failure
              this.dialog.cancel(e);
            });
        break;
      }
      case 'failure': {
        if (this.state !== message.data.state) {
          return;
        }
        // Handle auth0 login failure (wrong password)
        this.redirect(message.data.error);
        break;
      }
      default:
        // aborted
        this.dialog.cancel();
    }
  }
}

module.exports = AtbolLoginDialogController;
