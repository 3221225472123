const QuickSaveDialogModalController = require('./quick-save-dialog-modal.controller');
const QuickSaveTimelineModalController = require('./quick-save-timeline-modal.controller');

class QuickSaveController {
    constructor($mdDialog, $timeout) {
        this.dialogService = $mdDialog;
        this.timeout = $timeout;
        this.customAmountForm = {};

        // Declare bindings
        this.selected = {};
        this.onError = angular.noop;
    }

    quickSave(amount) {
        this.dialogService.show({
                controller: ['$window', '$mdDialog', 'userService', 'amount', 'goalKs', 'fundCodes', QuickSaveDialogModalController],
                controllerAs: 'quickSaveDialogModal',
                template: require('./quick-save-dialog-modal.html'),
                parent: angular.element(document.body),
                locals: {
                    'amount': amount,
                    'goalKs': this.selected
                },
                clickOutsideToClose: true
            }
        ).catch(error => {
            this.onError({'error': error});
        });
    }

    showTimelineModal() {
        this.dialogService.show({
                controller: ['$mdDialog', QuickSaveTimelineModalController],
                controllerAs: 'quickSaveTimelineModal',
                template: require('./quick-save-timeline-modal.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }
        ).catch(error => {
            this.onError({'error': error});
        });
    }

    customQuickSave(amount) {
        if (this.customAmountForm.$valid) {
            this.customAmount = null;
            this.customAmountForm.$setPristine(true);
            this.quickSave(amount);
        }
    }
}

module.exports = QuickSaveController;
