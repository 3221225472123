const angular = require('angular');
require('../../../../components/email-confirmation/email-confirmation.html');

class RequestEmailController {
    constructor(userService, $mdDialog, $location, $state, errorCodeList) {
        this.userService = userService;
        this.dialogService = $mdDialog;
        this.location = $location;
        this.state = $state;
        this.errorCodeList = errorCodeList;

        this.username = null;
        this.forgotPasswordForm = {};
        this.feedbackMessage = false;
        this.feedbackStatus = '';
    }

    goToForgotUsernamePage() {
        this.state.go('forgot-username',
            this.location.search().origin === 'mobile' ?
                {origin: 'mobile'} :
                {}
        );
    }

    goToLogin() {
        this.userService.goToLogin();
    }

    setUsernameLockedOutValidity(validity) {
        this.forgotPasswordForm.username.$setValidity('usernameLockedOut', validity);
    }

    sendEmailButtonDisabled() {
        const formValidity = this.forgotPasswordForm.$invalid;
        return !!(this.isSendingEmail && this.feedbackMessage || formValidity);

    }

    submitForm() {
        if (this.forgotPasswordForm.$valid && !this.isSendingEmail) {
            this.isSendingEmail = true;
            this.userService.forgotPasswordSendEmail(this.username)
                .then(() => {
                    this.feedbackMessage = true;
                    this.setFeedbackStatus(200);
                })
                .catch((error) => {
                    if (error && error.status === 401) {
                        this.setUsernameLockedOutValidity(false);
                        this.setFeedbackStatus(error.status);
                    } else {
                        this.setFeedbackStatus(error.status);
                    }
                    this.feedbackMessage = true;
                    this.isSendingEmail = false;
                });
        }
    }

    setFeedbackStatus(errorCode) {
        this.feedbackStatus = (errorCode === 200 ?
            this.errorCodeList.success
            : (errorCode === 401 ?
                this.errorCodeList.warning : this.errorCodeList.error));
        this.setFeedbackMessage();
    }

    setFeedbackMessage() {
        switch (this.feedbackStatus) {
            case 'success':
                this.feedbackTitle = 'feedbackMessage.emailConfirmation.title';
                this.feedbackText = 'feedbackMessage.emailConfirmation.text';
                break;
            case 'warning':
                this.feedbackTitle = 'feedbackMessage.lockedOut.title';
                this.feedbackText = 'feedbackMessage.lockedOut.text';
                break;
            case 'error':
                this.feedbackTitle = 'feedbackMessage.serverErrorMessage.title';
                this.feedbackText = 'feedbackMessage.serverErrorMessage.text';
                break;
            default:
                this.feedbackTitle = '';
                this.feedbackText = '';
        }
    }
}

module.exports = RequestEmailController;
