class LandingPageGoalController {
    constructor($state, $mdDialog, utilityService) {
        this.stateService = $state;
        this.dialogService = $mdDialog;
        this.utilityService = utilityService;
    }

    goToStartInvesting(selectedGoal) {
        this.stateService.go('start-investing', {'selectedGoal': selectedGoal});
    }
}

module.exports = LandingPageGoalController;
