//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');
const angularTranslate = require('angular-translate');

//NON-VENDOR MODULES
const chartComponent = require('./chart.component.js');
const futureValueService = require('../../services/future-value-service/future-value-service');

let chartModule = angular
    .module('app.chart', [
        angularMaterial,
        angularTranslate,
        futureValueService,
    ])
    .component('chart', chartComponent)
    .name;

module.exports = chartModule;
