const template = require('./start-investing.html');
const controller = require('./start-investing.controller');
require('./start-investing.scss');

let startInvestingComponent = {
    restrict: 'E',
    bindings: {},
    template,
    controller,
    controllerAs: 'startInvesting'
};

module.exports = startInvestingComponent;