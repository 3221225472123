const _ = require('lodash');
const Highcharts = require('highcharts/highcharts');

const ATB_BLUE = '#0072F0';
const ATB_GREY_500 = '#44444D';
const ATB_BLUE_BLACK = '#000025';

class PortfolioDetailController {
    constructor($mdDialog, $window, $timeout, $translate) {
        this.window = $window;
        this.timeout = $timeout;
        this.dialog = $mdDialog;
        this.translate = $translate;
        this.chart = null;

        /* bindings */
        this.portfolio = null;
    }

    $onInit() {
        this.size = this.size || 200;
        this.primaryColor = this.primaryColor || ATB_BLUE;
    }

    $onChanges() {
        if (this.portfolio) {
            this.timeout(() => {
                this.growthChart = this.drawGrowthChart();
                this.growthChart && this.growthChart.reflow();
            }, 600);
        }
    }

    drawGrowthChart() {
        Highcharts.setOptions({
            lang: {
                thousandsSep: ','
            },
            chart: {
                style: {
                    fontFamily: 'Inter, Arial, Helvetica, serif'
                }
            }
        });

        return document.getElementById('investmentHoldingPerformanceChart_' + this.portfolio.fund)
            && Highcharts.chart('investmentHoldingPerformanceChart_' + this.portfolio.fund, {
                chart: {
                    backgroundColor: 'transparent'

                },
                title: {
                    text: this.translate.instant('portfolioDetails.title',
                        {'portfolioName': this.portfolio.name}),
                    useHTML: true,
                    align: 'left',
                    style: {
                        fontWeight: 'bold',
                        color: ATB_BLUE_BLACK
                    }
                },
                yAxis: {
                    min: 1000,
                    max: this.getMaxYAxis(),
                    labels: {
                        format: '${value:,.0f}',
                        style: {
                            color: ATB_GREY_500
                        }
                    },
                    title: {
                        style: {
                            display: 'none'
                        }
                    }
                },
                xAxis: {
                    min: this.getMinXAxis(),
                    labels: {
                        style: {
                            color: ATB_GREY_500
                        }
                    },
                    lineColor: '#EFEED'
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    series: {
                        label: {
                            enabled: false,
                            connectorAllowed: false
                        },
                        pointStart: this.getMinXAxis(),
                        color: ATB_BLUE
                    }
                },
                tooltip: {
                    useHTML: true,
                    headerFormat: '',
                    pointFormat: '{point.x}: ${point.y}',
                    borderColor: ATB_BLUE
                },
                series: [{
                    data: this.getGrowthData()
                }]
            });
    }

    /*
     * Get the min value to show in xAxis
     */
    getMinXAxis() {
        return this.portfolio.annualValues[0].year;
    }

    /*
     * Get the max value to show in yAxis
     */
    getMaxYAxis() {
        let max = _.maxBy(this.portfolio.annualValues, (it) => {
            return it.value;
        });
        return (max.value / 100) * 100;
    }

    getGrowthData() {
        return this.portfolio.annualValues.map(it => it.value);
    }
}

module.exports = PortfolioDetailController;
