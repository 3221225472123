let linkFunction = function (scope, element, attrs, ctrl) {
    var minimumValue = attrs.minimumValue;
    var allowZero = attrs.allowZero ? (attrs.allowZero === 'true') : false;

    function validate(modelValue) {
        const value = parseFloat(modelValue);
        return ctrl.$isEmpty(modelValue) || value >= parseFloat(minimumValue) || (allowZero && value === 0);
    }

    attrs.$observe('minimumValue', function (value) {
        minimumValue = value ? value : '0';
        if (attrs.dynamicRange) { // This forcefully re-validates model when minimum value is changed dynamically
            ctrl.$setValidity('minimumValue', validate(ctrl.$viewValue));
        }
    });

    ctrl.$validators.minimumValue = (modelValue) => {
        return validate(modelValue);
    }
};

let minimumValueDirective = function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkFunction
    }
};

module.exports = minimumValueDirective;