const angular = require('angular');
const addressInfoComponent = require('./address-info.component');
const addressLookupModule = require('../address-lookup/address-lookup.module');
const atbConstants = require('../../../components/atb-constants/atb-constants');
const streetNumberValidatorModule = require('../../../directives/street-number-validator/street-number-validator');
const streetNameValidatorModule = require('../../../directives/street-name-validator/street-name-validator');
const zipCodePatternValidatorModule = require('../../../directives/zip-code-pattern-validator/zip-code-pattern-validator');
const trimInputModule = require('../../../directives/trim-input/trim-input');

module.exports = angular
    .module('kyc.address-info', [
        addressLookupModule,
        atbConstants,
        streetNumberValidatorModule,
        streetNameValidatorModule,
        trimInputModule,
        zipCodePatternValidatorModule])
    .component('addressInfo', addressInfoComponent)
    .name;