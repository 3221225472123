const angular = require('angular');
const convertToNumber = require('../../../directives/convert-to-number/convert-to-number');
const startingDateComponent = require('./starting-date.component.js');

let startingDateModule = angular.module('startingDate', [
    convertToNumber
    ])

    .component('startingDate', startingDateComponent)
    .name;

module.exports = startingDateModule;