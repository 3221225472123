let linkFunction = function (scope, element, attrs, ctrl) {
    var maximumValue = attrs.maximumValue;

    function validate(modelValue) {
        return ctrl.$isEmpty(modelValue) || parseFloat(modelValue) <= parseInt(maximumValue);
    }

    attrs.$observe('maximumValue', function (value) {
        maximumValue = value ? value : '0';
        if (attrs.dynamicRange) { // This forcefully re-validates model when maximum value is changed dynamically
            ctrl.$setValidity('maximumValue', validate(ctrl.$viewValue));
        }
    });

    ctrl.$validators.maximumValue = (modelValue) => {
        return validate(modelValue);
    }
};

let maximumValueDirective = function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkFunction
    }
};

module.exports = maximumValueDirective;