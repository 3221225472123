const controller = require('./maximum-length.controller');

let linkFunction = function(scope, element, attrs, ngModel) {
    var maximumLength = attrs.maximumLength;
    var filter = scope.maximumLength.getFilter();

    ngModel.$parsers.push((value) => {
        if(value && value.toString().length > maximumLength) {
            value = (filter('limitTo')(value, maximumLength));
            value = parseInt(value,10);
            ngModel.$setViewValue(value);
            ngModel.$render();
        }
        return value;
    });

};

let maximumLengthDirective = function(){
    return {
        restrict: 'A',
        require: 'ngModel',
        controller: ['$filter', controller],
        controllerAs: 'maximumLength',
        link: linkFunction
    }
};

module.exports = maximumLengthDirective;