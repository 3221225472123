const deleteGoalModalController = require('./delete-goal-modal-controller');
const resendRegistrationNotificationModalController = require('./resend-registration-notification-modal.controller');

class GoalInProgressController {
    constructor($mdDialog, goalService, userService) {
        this.dialogService = $mdDialog;
        this.goalService = goalService;
        this.userService = userService;

        // Bindings
        this.date = {};
        this.selected = {};
        this.isConversionEnabled = false;

        this.onError = angular.noop;
        this.onDeleted = angular.noop;
        this.doConvert = angular.noop;
    }

    //TODO: pass this in as conversion handler
    doContinue() {
        this.userService.removeUserAction();
        this.goalService.goToGoalInProgress(this.selected.goal.currentPage, this.selected.goal.id);
    }

    //TODO: pass this in as conversion handler, so error can be directly handled
    doResend() {
        this.dialogService.show({
            controller: ['$mdDialog', 'userService', 'goalId', resendRegistrationNotificationModalController],
            controllerAs: 'resendCtrl',
            template: require('./resend-registration-notification-modal.html'),
            parent: angular.element(document.body),
            targetEvent: undefined,
            clickOutsideToClose: true,
            goalId: this.selected.goal.id
        }).catch(err => {
            this.onError({error: err});
        });
    }

    //TODO: pass this in as conversion handler, so error can be directly handled
    doDelete() {
        this.dialogService.show({
            controller: ['$mdDialog', 'goalService', 'goalId', 'callbackGoalDeleted', deleteGoalModalController],
            controllerAs: 'deleteGoalModal',
            template: require('./delete-goal-modal.html'),
            parent: angular.element(document.body),
            targetEvent: event,
            clickOutsideToClose: true,
            goalId: this.selected.goal.id,
            // TODO: rather than callback - capture the success
            callbackGoalDeleted: this.onDeleted,
            goalService: this.goalService
        }).catch((error) => {
            this.onError({error: error});
        });
    }

    showDeleteButton() {
        return this.selected.isPrimaryApplicant && this.selected.goal.status !== 'COMPLETED';
    }

    showResendButton() {
        return this.goalService.canResend(this.selected);
    }

    showContinueButton() {
        return this.goalService.canContinue(this.selected) && !this.goalService.canResend(this.selected);
    }

    showConvertButton() {
        //TODO: check if there is a conversion handler defined.
        // Apply the same for the rest of the operations.
        // Timeline: RE-DESIGN
        return this.isConversionEnabled;
    }
}

module.exports = GoalInProgressController;
