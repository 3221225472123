require('./marital-status.template.scss');
const controller = require('./marital-status.controller');

module.exports = {
  restrict: 'E',
  bindings: {
      model: '=',
      form: '<'
  },
  template: require('./marital-status.template.html'),
  controller: ['maritalStatuses', controller],
  controllerAs: 'controller'
};