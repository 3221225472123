require('./permissions.template.scss');

module.exports = {
    restrict: 'E',
    bindings: {
        model: '=',
        form: '<'
    },
    template: require('./permissions.template.html'),
    controller: require('./permissions.controller'),
    controllerAs: 'controller'
};