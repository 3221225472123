'strict';

//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');
const angularTranslate = require('angular-translate');

//NON-VENDOR MODULES
const atbConstantsModule = require('../atb-constants/atb-constants');
const convertToNumber = require('../../directives/convert-to-number/convert-to-number');
const respGoalCalculationComponent = require('./resp-goal-calculation.component.js');
const futureValueService = require('../../services/future-value-service/future-value-service');
const minimumValueModule = require('../../directives/minimum-value/minimum-value');
const maximumValueModule = require('../../directives/maximum-value/maximum-value');
const regexValidatorModule = require('../../directives/regex-validator/regex-validator');
const toolTipComponent = require('../tool-tip/tool-tip');

let respGoalCalculationModule = angular
    .module('app.resp-goal-calculation', [
        atbConstantsModule,
        angularMaterial,
        angularTranslate,
        convertToNumber,
        futureValueService,
        minimumValueModule,
        maximumValueModule,
        regexValidatorModule,
        toolTipComponent
    ])
    .component('respGoalCalculation', respGoalCalculationComponent)
    .name;

module.exports = respGoalCalculationModule;