const template = require('./investment-experience.html');
const controller = require('./investment-experience.controller');

let investmentExperienceComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        openAccount: "="
    },
    template,
    controller: ['$translate', 'investmentExperienceList', controller],
    controllerAs: 'controller'
};

module.exports = investmentExperienceComponent;
