const _ = require('lodash');

class ContactInformationController {
    constructor() {
        this.addressElement = 'address';
        this.spousalAddressElement = 'spousalAddress';
    }

    onSpouseAddressIsSameAsMineChanged() {
        if (this.formData.spousalInformation.spouseAddressIsSameAsMine) {
            this._initJointHolderAddressWithPrimary();
        } else {
            this.formData.spousalInformation.contactInformation.manuallyEntered = false;
            this.formData.spousalInformation.contactInformation.canadaPostAddress = {};
            this.formData.spousalInformation.contactInformation.address = '';
            this.formData.spousalInformation.contactInformation.formattedAddress = null;
            this.formData.spousalInformation.contactInformation.legalLandAddress = null;
        }
    }

    isJointAccount() {
        if (this.openAccountModel && this.openAccountModel.goalData) {
            return this.openAccountModel.goalData.isJointAccount;
        }
        return false;
    }

    $onInit() {
        if (!(this.formData && this.formData.spousalInformation)) {
            return;
        }
        if (this.isJointAccount() && _.isNil(this.formData.spousalInformation.spouseAddressIsSameAsMine)) {
            this.formData.spousalInformation.spouseAddressIsSameAsMine = true;
            this._initJointHolderAddressWithPrimary();
        }
    }

    _initJointHolderAddressWithPrimary() {
        this.formData.spousalInformation.contactInformation.address = this.formData.contactInformation.address;
        this.formData.spousalInformation.contactInformation.manuallyEntered = this.formData.contactInformation.manuallyEntered;
        this.formData.spousalInformation.contactInformation.formattedAddress = this.formData.contactInformation.formattedAddress;
        this.formData.spousalInformation.contactInformation.legalLandAddress = this.formData.contactInformation.legalLandAddress;
        this.formData.spousalInformation.contactInformation.canadaPostAddress = this.formData.contactInformation.canadaPostAddress;
    }
}

module.exports = ContactInformationController;