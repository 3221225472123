const moment = require('moment');

const DEFAULT_PAC_LEAD_TIME = 8;
const RESP_PAC_LEAD_TIME = 10;

class UtilityServiceService {
    constructor($resource, devConfiguration, pages, $filter) {
        this.activeNotificationsResource = $resource(devConfiguration.getURLRoot(window.location.port) + '/active_notifications');
        this.portfoliosResource = $resource(devConfiguration.getURLRoot(window.location.port) + '/portfolios');
        this.portfolios2Resource = $resource(devConfiguration.getURLRoot(window.location.port) + '/dashboard/portfolios');
        this.pages = pages;
        this.filter = $filter;

    }

    getActiveNotifications() {
        return this.activeNotificationsResource.get().$promise;
    }

    getPortfoliosV2() {
        return this.portfolios2Resource.get().$promise;
    }

    getPageByName(pageName) {
        return this.filter('filter')(this.pages, {'name': pageName}, true)[0];
    }

    getPageOrderByName(pageName) {
        return this.getPageByName(pageName).order;
    }

    validatePacDate(goalKS) {
        if (goalKS.contributionData.ongoingInvestment > 0 && goalKS.fundingData) {
            let pacDateAsMoment = moment(goalKS.fundingData.pacStartDate, 'YYYY-MM-DD').startOf('day');
            let minimalPacDateAsMoment = moment(new Date())
                .businessAdd(goalKS.goal.goalType === 'EDUCATION' ? RESP_PAC_LEAD_TIME : DEFAULT_PAC_LEAD_TIME)
                .startOf('day');
            return pacDateAsMoment.isSameOrAfter(minimalPacDateAsMoment);
        }
        return true;
    }

    isDuringAccountOpening(stateCurrentName) {
        return ((stateCurrentName === this.getPageByName('OPENACCOUNT').page) ||
            (stateCurrentName === this.getPageByName('EDUCATIONBENEFICIARIES').page) ||
            (stateCurrentName === this.getPageByName('FUNDACCOUNT').page) ||
            (stateCurrentName === this.getPageByName('IDVERIFICATION').page) ||
            (stateCurrentName === this.getPageByName('DOCUSIGN').page))
    }

    isDuringGwsRiskAssessment(stateCurrentName, companyKey) {
        return ((stateCurrentName === this.getPageByName('STARTINVESTING').page ||
            stateCurrentName === this.getPageByName('SUGGESTEDFUND').page)
            && companyKey);
    }
}

module.exports = UtilityServiceService;
