const template = require('./investment-account-types.template.html');
const controller = require('./investment-account-types.controller.js');
require('./investment-account-types.template.scss');

let investmentAccountTypesComponent = {
    restrict: 'E',
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = investmentAccountTypesComponent;