const angular = require('angular');
const angularTranslate = require('angular-translate');
const prosperBonusAgreementComponent = require('./prosper-bonus-agreement.component.js');

let prosperBonusAgreementModule = angular.module('prosperBonusAgreement', [
        angularTranslate
    ])

    .component('prosperBonusAgreement', prosperBonusAgreementComponent)
    .name;

module.exports = prosperBonusAgreementModule;