let linkFunction = function(scope, element, attrs, ngModel) {
    ngModel.$parsers.push(function(val) {
        return parseInt(val, 10);
    });
    ngModel.$formatters.push(function(val) {
        return '' + val;
    });
};

let convertToNumberDirective = function(){
    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkFunction
    }
};

module.exports = convertToNumberDirective;