const template = require('./set-security-questions.html');
const controller = require('./set-security-questions.controller');
require('./set-security-questions.scss');

let setSecurityQuestionsComponent = {
    restrict: 'E',
    bindings: {
        questions: '<',
        registrationForm: '=',
        answers: '='
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = setSecurityQuestionsComponent;
