module.exports = () => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: (scope, element, attrs, ctrl) => {
            const isLuhn = (str) => {
                return str.split('').reduceRight((prev, curr, idx) => {
                    prev = parseInt(prev, 10);
                    if ((idx + 1) % 2 === 0) {
                        curr = (curr * 2).toString()
                            .split('')
                            .reduce((p, c) => parseInt(p, 10) + parseInt(c, 10));
                    }
                    return prev + parseInt(curr, 10);
                }) % 10 === 0;
            };

            ctrl.$validators.sinValidator = modelValue => {
                const stringValue = modelValue ? modelValue.toString() : '';
                return ctrl.$isEmpty(stringValue)
                    || Boolean(stringValue.match(/^\d{2}\*{5}\d{2}$/g))
                    || (stringValue.length === 9
                        && !stringValue.match(/0{9}/)
                        && isLuhn(stringValue));
            }
        }
    }
};