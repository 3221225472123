const angular = require('angular');

class LandingPageHeaderController {
    constructor($anchorScroll, $location, $window, $mdMedia, $mdDialog, $state, localStorageService, utilityService) {
        this.mdMedia = $mdMedia;
        this.anchorScroll = $anchorScroll;
        this.location = $location;
        this.window = $window;
        this.dialogService = $mdDialog;
        this.state = $state;
        this.localStorageService = localStorageService;
        this.utilityService = utilityService;
        this.logo = require('../../../../public/img/ATB-Prosper_White.svg');
        this.hamburgerMenu = require('../../../../public/img/material_icons/dehazeWhite.svg');
    }

    gotoSection(sectionId) {
        //if this is on a small screen then collapse menu
        if (!this.mdMedia('gt-md')) {
            this.toggleMobileMenu();
        }

        this.anchorScroll.yOffset = this.getHeaderHeight();
        if (this.location.hash() !== sectionId) {
            this.location.hash(sectionId);
        } else {
            this.anchorScroll();
        }
    }

    checkScroll() {
        let element = angular.element(document.getElementById('landing-page-header-container'));
        element.toggleClass('fill', this.window.pageYOffset > 0);
        if (element.hasClass('responsive')) {
            element.removeClass('responsive');
        }

        let errorElement = angular.element(document.getElementById('landing-page-error'));
        errorElement.remove();
    }

    getHeaderHeight() {
        return document.getElementById('landing-page-header-container').clientHeight;
    }

    toggleMobileMenu() {
        let element = angular.element(document.getElementById('landing-page-header-container'));
        element.toggleClass('responsive');
    }

    removeMobileMenu() {
        let element = angular.element(document.getElementById('landing-page-header-container'));
        element.removeClass('responsive');
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    login() {
        //first close hamburger menu
        this.removeMobileMenu();
        this.state.go('login');
    }

    checkForErrorMessages() {
        if (this.localStorageService.isSupported === false) {
            return {'message': 'global.privateBrowsingError'};
        }
        if (this.state.params.error === 'access_denied') {
            return {'message': 'landingPage.header.accessDeniedError'};
        } 
        return this.state.params.error;
    }

    checkForNotifications() {
        this.utilityService.getActiveNotifications().then((response) => {
            this.notifications = response.notificationList;
        });
    }

    $onInit() {
        angular.element(this.window).on('scroll', this.checkScroll);
        angular.element(document.getElementById('landing-page-header-container')).on('click', this.stopPropagation);
        angular.element(document.body).on('click', this.removeMobileMenu);

        this.menuOptions = [{
            text: 'landingPage.header.investmentAccountTypes',
            state: 'investmentAccountTypes'
        },{
            text: 'landingPage.header.investmentHoldingTypes',
            state: 'investmentHoldingTypes'
        }, {
            text: 'landingPage.header.costs',
            state: 'investmentCost'
        }, {
            text: 'landingPage.header.contact',
            state: 'startInvestingToday'
        }, {
            text: 'landingPage.header.logIn',
            login: true
        }, {
            text: 'landingPage.header.startInvesting',
            pageState: 'start-investing'
        }];

        this.error = this.checkForErrorMessages();
        this.checkForNotifications();
    }

    $onDestroy() {
        angular.element(this.window).off('scroll', this.checkScroll);
        angular.element(document.getElementById('landing-page-header-container')).off('click', this.stopPropagation);
        angular.element(document.body).off('click', this.removeMobileMenu);
    }
}

module.exports = LandingPageHeaderController;
