const template = require('./grey-background-box.html');
const controller = require('./grey-background-box.controller');
require('./grey-background-box.scss');

let greyBackgroundBoxComponent = {
    restrict: 'E',
    bindings: {
        text: '@'
    },
    template,
    controller,
    controllerAs: 'greyBackgroundBox'
};

module.exports = greyBackgroundBoxComponent;