const template = require('./how-we-make-it-happen.html');
const controller = require('./how-we-make-it-happen.controller');
require('./how-we-make-it-happen.scss');

let howWeMakeItHappenComponent = {
    restrict: 'E',
    bindings: {},
    template,
    controller,
    controllerAs: 'howWeMakeItHappen'
};

module.exports = howWeMakeItHappenComponent;