require('./contact-us.scss');
class ContactUsController {
    constructor($mdDialog, description, hasEmailSection) {
        this.mdDialog = $mdDialog;
        this.smartphoneImg = require('../../../public/img/material_icons/smartphoneGrey.svg');
        this.emailImg = require('../../../public/img/material_icons/emailGrey.svg');
        this.hasEmailSection = hasEmailSection;
        this.description = description;
    }
    cancel() {
        this.mdDialog.cancel();
    }
}

module.exports = ContactUsController;
