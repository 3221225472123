require('./confirmation.template.scss');
const controller = require('./confirmation.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        changes: '<',
        isInAnnualReview: '<'
    },
    template: require('./confirmation.template.html'),
    controller: ['$window', controller],
    controllerAs: 'controller'
};