const template = require('./income-question.html');
const controller = require('./income-question.controller.js');
require('./income-question.scss');

let incomeQuestionComponent = {
    restrict: 'E',
    bindings: {
        questionData: '=',
        model: '=',
        onNext: '&',
        onPrev: '&'
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = incomeQuestionComponent;