function routes($stateProvider) {
    $stateProvider
        .state('site', {
            abstract: true,
            resolve: {
                globalTranslations: ['$translate','$translatePartialLoader', ($translate, $translatePartialLoader) => {
                    $translatePartialLoader.addPart('app');
                    return $translate.refresh();
                }]
            }
        })
}

module.exports = routes;
