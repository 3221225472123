const SRC_FRAGMENT = 'https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=';
const HREF_FRAGMENT = 'https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=';
let linkFunction = function(scope, element){
    let addressCompleteConfig = window.applicationConfiguration.addressCompleteConfig;

    let linkTag = angular.element(document.createElement('link'));
    linkTag.attr('rel','stylesheet');
    linkTag.attr('type','text/css');
    linkTag.attr('href', HREF_FRAGMENT+addressCompleteConfig.key);
    element.prepend(linkTag);

    let scriptTag = angular.element(document.createElement('script'));
    scriptTag.attr('charset','utf-8');
    scriptTag.attr('type', 'text/javascript');
    scriptTag.attr('src',SRC_FRAGMENT+addressCompleteConfig.key);
    element.prepend(scriptTag);
};

let loadCanadaPostDirective = function(){
    return {
        restrict: 'A',
        link: linkFunction
    }
};

module.exports = loadCanadaPostDirective;