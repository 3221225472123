const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const degreeOfRiskQuestionComponent = require('./degree-of-risk-question.component.js');
const horizontalRGModule = require('../../../../components/horizontal-radio-group-v2/horizontal-radio-group');

let degreeOfRiskQuestionModule = angular
    .module('degreeOfRiskQuestion', [
        angularSanitize,
        horizontalRGModule
    ])
    .component('degreeOfRiskQuestion', degreeOfRiskQuestionComponent)
    .name;

module.exports = degreeOfRiskQuestionModule;