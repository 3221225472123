const landingPageTemplate = require('./landing-page.html');

require('./landing-page.scss');

function routes($stateProvider) {
    $stateProvider
        .state('landing-page', {
            url: '/?inactivityLogout',
            parent: 'site',
            params:{
                error: null
            },
            views:{
                'content@':{
                    template: landingPageTemplate,
                    controller: 'LandingPageController',
                    controllerAs: 'landingPage'
                }
            },
            onEnter: ['userService', (userService) => {
                userService.clearStorage();
            }],
            resolve: {
                localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('landing-page');
                    $translate.refresh();
                    return true;
                }],
                controller: ['$ocLazyLoad', $ocLazyLoad => {
                    return import(/* webpackChunkName: "landing-page" */'./landing-page.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        });
}

module.exports = routes;
