require('./goal-calculation.scss');
const goalCalcController = require('./goal-calculation.controller');

let goalCalculationComponent = {
    restrict: 'E',
    bindings: {
        params: '=',
        enforceRequiredGwsFields: '<'
    },
    template: require('./goal-calculation.html'),
    controller:['$timeout', '$filter', '$translate', 'futureValueService', 'contributionFrequencies', 'monthOptions', 'gwsContributionFrequencies', goalCalcController],
    controllerAs: 'controller'
};

module.exports = goalCalculationComponent;