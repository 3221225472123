const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const respSingleOrJointQuestionComponent = require('./resp-single-or-joint-question.component.js');

let respSingleOrJointQuestionModule = angular
    .module('respSingleOrJointQuestion', [
        angularSanitize
    ])
    .component('respSingleOrJointQuestion', respSingleOrJointQuestionComponent)
    .name;

module.exports = respSingleOrJointQuestionModule;