const contributionDetailsModalController = require('./account-performance-modal.controller');
const controller = require('./contribution-details-modal.controller');

class GrowthAndContributionsController {
    constructor($mdDialog, $timeout, goalService) {
        this.dialog = $mdDialog;
        this.timeout = $timeout;
        this.goalService = goalService;

        /* bindings */
        this.date = {};

        /*
           Bind to GoalKS twice:
           1/ one-way to listen to goal changes
           2/ two-way to cache the ROI returned
        */
        this.model = {};
        this.selected = {};
    }

    $onChanges() {
        if (this.model.isFunded && !this.model.roi) {
            this.loading = true;
            if (!this.processing) {
                this.timeout.cancel(this.processing);
            }
            this.processing = this.timeout(() => {
                this.goalService.getROI(this.selected.goal.id).then(response => {
                    this.model.roi = response;
                    this.loading = false;
                });
                this.goalService.getPacs(this.selected.goal.id).then(pacs => {
                    this.model.recurringContributions = pacs.recurringPacDTOList;
                })
            });
        }
    }

    openDialog(event) {
        this.dialog.show({
            template: require('./contribution-details-modal.html'),
            controller: ['$filter', '$translate', '$mdDialog', 'date', 'selected', controller],
            controllerAs: 'contributionDetails',
            parent: angular.element(document.body),
            fullscreen: true,
            targetEvent: event,
            locals: {
                'date': this.date,
                'selected': this.selected
            },
            clickOutsideToClose: true
        });
    }

    openPerformanceDialog(event) {
        this.dialog.show({
            template: require('./account-performance-modal.html'),
            controller: ['$filter', '$translate', '$mdDialog', 'date', 'selected', contributionDetailsModalController],
            controllerAs: 'controller',
            parent: angular.element(document.body),
            fullscreen: true,
            targetEvent: event,
            locals: {
                'date': this.date,
                'selected': this.selected
            },
            clickOutsideToClose: true
        });
    }
}

module.exports = GrowthAndContributionsController;
