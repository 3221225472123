require('./account-details.template.scss');
const controller = require('./account-details.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        model: '=',
        form: '<',
        newAccount: '=',
        oldAccount: '<'
    },
    template: require('./account-details.template.html'),
    controller: ['educationBeneficiaryRelationships','genders','monthOptions','provinces','$anchorScroll', controller],
    controllerAs: 'controller'
};