//NON-VENDOR MODULES
const investmentHoldingTypesComponent = require('./investment-holding-types.component.js');
const utilityServiceModule = require('../../../services/utility-service/utility-service');
const portfolioDetailModule = require('../../../components/portfolio-detail/portfolio-detail.module');

let investmentHoldingTypesModule = angular
    .module('app.investmentHoldingTypes', [
        utilityServiceModule,
        portfolioDetailModule
    ])
    .component('investmentHoldingTypes', investmentHoldingTypesComponent)
    .name;

module.exports = investmentHoldingTypesModule;
