require('./beneficiary-distribution-modal.scss');

class BeneficiaryDistributionModalController {
    constructor($mdDialog, localStorageService) {
        this.mdDialog = $mdDialog;
        this.localStorageService = localStorageService;
    }

    $onInit() {
        this.equalBeneficiaryAllocation = this.localStorageService.get('equalBeneficiaryAllocation');
    }

    cancel() {
        this.mdDialog.cancel();
    }

    updateBeneficiaryAllocation() {
        this.localStorageService.set('equalBeneficiaryAllocation', this.equalBeneficiaryAllocation);
    }
}

module.exports = BeneficiaryDistributionModalController;
