const template = require('./atbol-login.html');
const controller = require('./atbol-login.controller');
require('./atbol-login.scss');

module.exports = {
    restrict: 'E',
    bindings: {
        openAccountForm: '=',
        formData: '=',
        isGroupGoal: '<'
    },
    template,
    controller: ['userService','$window', '$mdDialog', '$localStorage', controller],
    controllerAs: 'controller'
};
