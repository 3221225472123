const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;

const flagsRoutes = require('./flags.routes');
module.exports = angular
    .module('app.flags', [
        uiRouter
    ])
    .config(['$stateProvider', flagsRoutes])
    .name;
