class FundingDataService {
    constructor($resource, devConfiguration) {
        let baseUrl = devConfiguration.getURLRoot(window.location.port);
        this.fundAccountResource = $resource(baseUrl + '/users/funding_data');
        this.getBankAccountResources = $resource(baseUrl + '/users/bank_accounts');

        this.uploadBankImageApi = $resource(baseUrl + '/funding_data/upload/:goalId', {goalId: '@id'}, {
            put: {
                method: 'POST',
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            }
        });
        this.deleteBankImageApi = $resource(baseUrl + '/funding_data/upload/:goalId', {goalId: '@id'});
    }

    getBankAccounts() {
        return this.getBankAccountResources.get().$promise;
    }

    saveFundingData(fundingData) {
        return this.fundAccountResource.save(fundingData).$promise;
    }

    uploadBankImage(goalId, file) {
        let fd = new FormData();
        fd.append('file', file);
        return this.uploadBankImageApi.put({goalId: goalId}, fd).$promise;
    }

    deleteBankImage(goalId) {
        return this.deleteBankImageApi.delete({goalId: goalId}).$promise;
    }
}

module.exports = FundingDataService;
