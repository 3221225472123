const angular = require('angular');
const moment = require('moment-business-days');
require('./edit-goal-dialog-modal.scss');
const _ = require('lodash');

const DATE_FORMAT = 'yyyy-MM-dd';
const MOMENT_FORMAT = 'YYYY-MM-DD';


const FREQUENCY_ANNUAL_TIMES = {
  'WEEKLY': 52,
  'BIWEEKLY': 26,
  'SEMIMONTHLY': 24,
  'MONTHLY': 12,
  'QUARTERLY': 4,
  'LDMONTHLY': 12,
  'ANNUALLY': 1,
  'SEMIANNUAL': 2
};

class EditGoalDialogModalController {
  constructor($window,
              $mdDialog,
              $timeout,
              $filter,
              userService,
              monthOptions,
              pacFrequencies,
              contributionFrequencies,
              oldGoalCalculations,
              oldRecurringPacDTOList,
              goalKs,
              fundCodes) {
    this.window = $window;
    this.$mdDialog = $mdDialog;
    this.timeout = $timeout;
    this.filter = $filter;
    this.userService = userService;
    this.contributionFrequencies = contributionFrequencies;
    this.monthOptions = monthOptions;
    this.oldGoalCalculations = oldGoalCalculations;
    this.oldRecurringPacDTOList = oldRecurringPacDTOList;
    this.pacFrequencies = pacFrequencies;
    this.pacDateDayOptions = [[], []];

    this.toolTipImage = require('../../../../public/img/toolTip.png');
    this.lightbulbImg = require('../../../../public/img/material_icons/lightbulbWhite.svg');

    this.goalKs = goalKs;
    this.fundCodes = fundCodes;

    //init <-> clean/dirty -> end
    this.state = 'STATE_INIT';
    this.recommendedPayment = 0;
  }

  $onInit() {
    this.initializeHoldingOptions();
    this.initializePacDateYearOptions();
    this.initializeGoalDateYearOptions();
    this.initializeRecurringPacDTOList();
    this.initializeGoalCalculations();
  }

  initializeHoldingOptions() {
    this.holdings = this.goalKs.holdings
        .filter(it => it.cusip !== 'CASH' && this.fundCodes[it.cusip])
        .sort((a, b) => {
          return this.fundCodes[a.cusip].order - this.fundCodes[b.cusip].order;
        });
  }

  prev() {
    switch (this.state) {
      case 'STATE_CLEAN':
      case 'STATE_DIRTY':
        this.state = 'STATE_INIT';
        break;
      default: //ignore
    }
  }

  next() {
    if (this.editGoalCalculationForm.$invalid) {
      return;
    }
    switch (this.state) {
      case 'STATE_INIT':
        this.state = this.isGoalModified || this.isPacListModified ? 'STATE_DIRTY' : 'STATE_CLEAN';
        break;
      case 'STATE_CLEAN':
        this.cancel();
        this.state = 'STATE_END';
        break;
      case 'STATE_DIRTY':
        this.commit(() => {
          this.state = 'STATE_END';
        });
        break;
      default:
        // ignore
    }
  }

  cancel() {
    return this.$mdDialog.hide();
  }

  get obsUrl() {
    return window.applicationConfiguration.obsUrl
  }

  get noGraph() {
    return this.goalType === 'EDUCATION'
        || this.hasMultiplePac
        || !this.newGoalCalculations.rateOfReturn;
  }

  get minNextDate() {
    return moment(new Date()).businessAdd(10).startOf('day');
  }

  get subtitle() {
    let multiplePac = this.oldRecurringPacDTOList.length > 1;
    switch (this.goalType) {
      case 'SAVINGS':
        return multiplePac ? 'editGoalLabels.savingsPacTwoTitle' : 'editGoalLabels.savingsTitle';
      case 'PURCHASE':
        return multiplePac ? 'editGoalLabels.purchasePacTwoTitle' : 'editGoalLabels.purchaseTitle';
      case 'EDUCATION':
        return multiplePac ? 'editGoalLabels.savingsPacTwoTitle' /*intended*/ : 'editGoalLabels.educationTitle';
      case 'RETIREMENT':
        return multiplePac ? 'editGoalLabels.RetirementPacTwoTitle' : 'editGoalLabels.RetirementTitle';
      default:
    }
  }

  initializeRecurringPacDTOList() {
    if (_.isEmpty(this.oldRecurringPacDTOList)) {
      this.oldRecurringPacDTOList = [{
        amount: 0,
        fund: (_.first(this.holdings) || {cusip: null}).cusip,
        frequency: 'MONTHLY'
      }]
    }

    this.newRecurringPacDTOList = [];
    for (let i = 0; i < this.oldRecurringPacDTOList.length; i++) {
      let pac = this.oldRecurringPacDTOList[i];
      pac.id = pac.id || '';
      pac.amount = pac.amount || 0;
      pac.frequency = this.isValidFrequency(pac.frequency) ? pac.frequency : 'MONTHLY';
      pac.nextDate = pac.nextDate ? moment(pac.nextDate, MOMENT_FORMAT) : this.minNextDate;

      pac.date = pac.nextDate.date();
      pac.year = pac.nextDate.year();
      pac.month = pac.nextDate.month();

      pac.bankNumber = pac.bankNumber || this.goalKs.fundingData.bankNumber;
      pac.transitNumber = pac.transitNumber || this.goalKs.fundingData.transitNumber;
      pac.accountNumber = pac.bankAccountNumber || this.goalKs.fundingData.accountNumber;

      // make a copy of the pac list to track changes
      this.newRecurringPacDTOList.push(angular.copy(pac));
    }

    this.newRecurringPacDTOList.forEach((val, index) => {
      this.initializePacDateDayOptions(index);
      this.initializePacFrequencyOptions(val.frequency);
    });
  }

  isValidFrequency(frequencyKey) {
    return this.contributionFrequencies.filter(it => it.key === frequencyKey)[0]
        || this.pacFrequencies.filter(it => it.key === frequencyKey)[0];
  }

  initializePacFrequencyOptions(frequencyKey) {
    if (this.contributionFrequencies.filter(it => it.key === frequencyKey)[0]) {
      return;
    }
    // there are frequency options not open to user unless it is existing
    let absent = this.pacFrequencies.filter(it => it.key === frequencyKey)[0];
    if (absent) {
      this.contributionFrequencies.push(absent);
    }
  }

  initializePacDateDayOptions(index) {
    if (!this.newRecurringPacDTOList[index].nextDate) {
      return;
    }

    let daysInMonth = this.newRecurringPacDTOList[index].nextDate.daysInMonth();
    let frequency = this.newRecurringPacDTOList[index].frequency;
    switch (frequency) {
      case 'LDMONTHLY':
        this.pacDateDayOptions[index] = [daysInMonth];
        break;
      case 'SEMIMONTHLY':
        this.pacDateDayOptions[index] = [1, 15];
        break;
      default:
        this.pacDateDayOptions[index] = [];
        for (let i = 1; i <= daysInMonth; i++) {
          this.pacDateDayOptions[index].push(i);
        }
        break;
    }
    return daysInMonth;
  }

  get isUpdateMultiple() {
    return this.oldRecurringPacDTOList.filter((_, i) => this.isPacModified(i)).length > 1;
  }

  get isPacListModified() {
    return !this.strictEquals(this.oldRecurringPacDTOList, this.newRecurringPacDTOList);
  }

  showPacFund(index) {
    return Boolean(this.getHolding(this.newRecurringPacDTOList[index].fund));
  }

  showFundFact(index) {
    return this.showPacFund(index) && this.isCompass(this.newRecurringPacDTOList[index].fund);
  }

  showHisaAcknowledge(index) {
    return this.showPacFund(index) && this.isHisa(index);
  }

  isPacModified(index) {
    return !this.strictEquals(this.oldRecurringPacDTOList[index], this.newRecurringPacDTOList[index]);
  }

  isPacCancelled(index) {
    return this.newRecurringPacDTOList[index].amount === 0;
  }

  isPacDateModified(index) {
    /*
        adding a pac (change from amount ZERO to NON-ZERO) always treated as change the pac date
     */
    return this.isPacAdded(index) || (!this.isPacCancelled(index) && !this.strictEquals(this.oldRecurringPacDTOList[index].nextDate, this.newRecurringPacDTOList[index].nextDate));
  }

  isPacFrequencyModified(index) {
    /*
        adding a pac (change from amount ZERO to NON-ZERO) always treated as change the pac freq
     */
    return this.isPacAdded(index) || (!this.isPacCancelled(index) && !this.strictEquals(this.oldRecurringPacDTOList[index].frequency, this.newRecurringPacDTOList[index].frequency));
  }

  isPacChangeTimely(index) {
    return this.minNextDate.isSameOrBefore(this.newRecurringPacDTOList[index].nextDate);
  }

  isPacAdded(index) {
    return this.oldRecurringPacDTOList[index].amount === 0 && this.newRecurringPacDTOList[index].amount !== 0;
  }

  isPacAmountModified(index) {
    return !this.strictEquals(this.oldRecurringPacDTOList[index].amount, this.newRecurringPacDTOList[index].amount);
  }

  isPacFundModified(index) {
    return !this.strictEquals(this.oldRecurringPacDTOList[index].fund, this.newRecurringPacDTOList[index].fund);
  }

  isHisa(index) {
    let fund = this.newRecurringPacDTOList[index].fund;
    return fund === 'ALB100' || fund === 'ALB102';
  }

  isCompass(fund) {
    return this.fundCodes[fund].isCompass;
  }

  getHolding(fund) {
    return _.find(this.holdings, it => it.cusip === fund);
  }

  initializeGoalCalculations() {
    let holding = this.getHolding(this.oldRecurringPacDTOList[0].fund);
    this.oldGoalCalculations.rateOfReturn = holding && holding.portfolio && holding.portfolio.performances.find(it => it.span === 'yieldSinceInception').ror;
    this.oldGoalCalculations.portfolioName = holding && holding.portfolio && holding.portfolio.name;
    this.oldGoalCalculations.ongoingInvestment = this.oldRecurringPacDTOList[0].amount;
    this.oldGoalCalculations.currentAge = this.calculateAge();
    this.oldGoalCalculations.frequency = this.getFrequency(this.oldRecurringPacDTOList[0].frequency);
    /*
            Normalize the goal end date to beginning of the month so the date remain as 1 regardless of the month/year change.
            Please note: this deviates from the new major purchase goal where the current date is used to calculate the
            goal end date. In the future, we should look into the 'new goal' process to a consistent behavior.
    */
    this.oldGoalCalculations.goalEndDate = moment(this.oldGoalCalculations.goalEndDate, MOMENT_FORMAT).startOf('month').toDate();

    switch (this.oldGoalCalculations.goalType) {
      case 'EDUCATION':
        this.oldGoalCalculations.beneficiaryList.forEach(beneficiary => {
          beneficiary.age = this.userService.calculateAge(beneficiary.birthMonth, beneficiary.birthDay, beneficiary.birthYear);
        });
        break;
      default:
        // DO NOTHING
    }

    // make a copy of the calculation to track changes
    this.newGoalCalculations = angular.copy(this.oldGoalCalculations);
  }

  calculateAge() {
    let information = this.goalKs.uiForm.accountPersonalInformation;
    return this.userService.calculateAge(information.birthMonth, information.birthDay, information.birthYear);
  }

  onPacFundChange(index) {
    let holding = this.getHolding(this.newRecurringPacDTOList[index].fund);
    this.newGoalCalculations.rateOfReturn = holding && holding.portfolio && holding.portfolio.performances.find(it => it.span === 'yieldSinceInception').ror;
  }

  onPacAmountChange(index) {
    if (this.newGoalCalculations.ongoingInvestment !== this.newRecurringPacDTOList[index].amount) {
      this.newGoalCalculations.ongoingInvestment = this.newRecurringPacDTOList[index].amount;
      if (this.isPacCancelled(index)) {
        this.newRecurringPacDTOList[index].fund = this.oldRecurringPacDTOList[index].fund;
        this.newRecurringPacDTOList[index].year = this.oldRecurringPacDTOList[index].year;
        this.newRecurringPacDTOList[index].date = this.oldRecurringPacDTOList[index].date;
        this.newRecurringPacDTOList[index].month = this.oldRecurringPacDTOList[index].month;
        this.newRecurringPacDTOList[index].nextDate = this.oldRecurringPacDTOList[index].nextDate;
        this.newRecurringPacDTOList[index].frequency = this.oldRecurringPacDTOList[index].frequency;
        this.initializePacDateDayOptions(index);
        this.onPacFundChange(index);
      }
      this.validatePacDate(index, this.newRecurringPacDTOList[index].nextDate);
    }
  }

  /*
   * For all pacs that has been changed, and the fund is COMPASS, check if the
   * `reviewFundFacts` checkmark has been checked.
   *
   * @return {boolean}
   */
  get canSubmit() {
    if (this.showAcknowledge && !this.editGoalCalculationForm.acknowledge) return false;
    for (let i = 0; i < this.newRecurringPacDTOList.length; i++) {
      if (this.isPacModified(i)
          && this.showFundFact(i)
          && !this.editGoalCalculationForm['reviewedFundFacts' + i]) {
        return false;
      }
    }
    return true;
  }

  get showAcknowledge() {
    return _.find(this.newRecurringPacDTOList,
        (it, index) => this.isPacModified(index) && !this.isPacCancelled(index)
    ) || this.isRetirementAgeModified || this.isGoalEndDateModified || this.isGoalAmountModified;
  }

  getFrequency(key) {
    return this.contributionFrequencies.filter(it => it.key === key)[0];
  }

  strictEquals(obj1, obj2) {
    return angular.toJson(obj1) === angular.toJson(obj2);
  }

  getMaterialTimeChangeNoteOrNull() {
    if (!this.isGoalModified) {
      return null;
    }

    let change, newHorizon, oldHorizon;

    if (this.newGoalCalculations.goalType === 'RETIREMENT') {
      change = Math.abs(this.newGoalCalculations.retirementAge - this.oldGoalCalculations.retirementAge);
      newHorizon = this.newGoalCalculations.retirementAge - this.newGoalCalculations.currentAge;
      oldHorizon = this.oldGoalCalculations.retirementAge - this.newGoalCalculations.currentAge;
    } else {
      let oldDate = moment(this.oldGoalCalculations.goalEndDate);
      let newDate = moment(this.newGoalCalculations.goalEndDate);
      change = Math.abs(newDate.diff(oldDate, 'years', true).toFixed(1));
      newHorizon = newDate.diff(moment(), 'years', true).toFixed(1);
      oldHorizon = oldDate.diff(moment(), 'years', true).toFixed(1);
    }
    return this.isMaterialTimeChange(oldHorizon, newHorizon, change) ? {
      previousTimeHorizon: oldHorizon,
      newTimeHorizon: newHorizon
    } : null;
  }

  /* TODO: this logic belongs to backend ... */
  isMaterialTimeChange(oldTimeHorizon, newTimeHorizon, change) {
    return change >= 5 && ((oldTimeHorizon > 10 && newTimeHorizon >= 7 && newTimeHorizon <= 10) || (oldTimeHorizon <= 10 && newTimeHorizon > 10))
        || change >= 3 && ((oldTimeHorizon >= 7 && newTimeHorizon >= 4 && newTimeHorizon < 7) || (oldTimeHorizon < 5 && newTimeHorizon >= 5))
        || change >= 1 && newTimeHorizon < 4 && (newTimeHorizon < oldTimeHorizon || oldTimeHorizon < 0);
  }

  commit(onFinish) {
    this.processing = true;
    let materialTimeChangeNote = this.getMaterialTimeChangeNoteOrNull();
    this.updatePacList(materialTimeChangeNote)
        .then(response => this.updateGoal(response ? null : materialTimeChangeNote))
        .then(() => {
          // This triggers to the updates in GoalHeader and Overview tab
          let params = {
            message: 'editGoalLabels.editGoalSuccessDescription',
            category: 'success'
          };

          if (this.goalKs.goal.goalType === 'PURCHASE'
              && this.goalKs.goal.goalAmount !== this.newGoalCalculations.goalAmount) {
            params.redraw = true;
          }

          let newPacList = angular.copy(this.newRecurringPacDTOList)
          newPacList.forEach(pac => {
            pac.nextDate = pac.nextDate.toISOString();
          });
          this.goalKs.recurringContributions = newPacList;
          this.goalKs.goal.goalAmount = this.newGoalCalculations.goalAmount;
          this.goalKs.goal.goalEndDate = this.newGoalCalculations.goalEndDate;
          this.$mdDialog.cancel(params);
        })
        .catch((err) => {
          this.$mdDialog.cancel({
            category: 'error',
            message: 'editGoalLabels.error'
          });
        })
        .finally(() => {
          this.processing = false;
          onFinish()
        });
  }

  onFrequencyChange(index) {
    if (index === 0) {
      this.newGoalCalculations.frequency = this.getFrequency(this.newRecurringPacDTOList[index].frequency);
    }

    let currentDate = this.newRecurringPacDTOList[index].date;
    let daysInMonth = this.initializePacDateDayOptions(index);
    let newDate;
    switch (this.newRecurringPacDTOList[index].frequency) {
      case 'LDMONTHLY':
        newDate = daysInMonth;
        break;
      case 'SEMIMONTHLY':
        newDate = currentDate === 1 || currentDate === 15 ? currentDate : null;
        break;
      default:
        newDate = currentDate;
    }
    this.setPacDate(index, newDate);
  }

  setPacDate(index, date) {
    this.newRecurringPacDTOList[index].date = date;
    let nextDate = this.newRecurringPacDTOList[index].nextDate;
    this.validatePacDate(index, date ? nextDate.date(date) : null);
  }

  setPacMonth(index, month) {
    this.newRecurringPacDTOList[index].month = month;
    let nextDate = this.newRecurringPacDTOList[index].nextDate;
    nextDate.month(month);
    // change pac year might affect selected pac date
    this.updatePacDate(index, nextDate)
  }

  setPacYear(index, year) {
    this.newRecurringPacDTOList[index].year = year;
    let nextDate = this.newRecurringPacDTOList[index].nextDate;
    nextDate.year(year);
    // change pac year might affect selected pac date
    this.updatePacDate(index, nextDate);
  }

  updatePacDate(index, nextDate) {
    let newDate;
    let oldDate = this.newRecurringPacDTOList[index].date;
    let daysInMonth = this.initializePacDateDayOptions(index);
    switch (this.newRecurringPacDTOList[index].frequency) {
      case 'LDMONTHLY':
        newDate = daysInMonth;
        break;
      case 'SEMIMONTHLY':
        // date is not affected
        newDate = oldDate;
        break;
      default:
        newDate = (!oldDate || oldDate > daysInMonth) ? null : nextDate.date();
    }
    this.setPacDate(index, newDate);
  }

  validatePacDate(index, nextDate) {
    let valid = false;
    if (nextDate !== null) {
      valid = this.isPacCancelled(index) || !this.isPacModified(index) || this.isPacChangeTimely(index);
    }
    this.editGoalCalculationForm[`nextDate_${index}`].$setValidity('required', valid);
  }

  onPacDateFieldChange(index, field) {
    switch (field) {
      case 'date':
        this.setPacDate(index, this.newRecurringPacDTOList[index].date);
        break;
      case 'year':
        this.setPacYear(index, this.newRecurringPacDTOList[index].year);
        break;
      case 'month':
        this.setPacMonth(index, this.newRecurringPacDTOList[index].month);
        break;
      default:
        // DO NOTHING
    }
  }

  set goalAmount(amount) {
    if (this.newGoalCalculations.goalAmount !== amount) {
      this.newGoalCalculations.goalAmount = amount;
      this.validateGoalAmount(amount);
    }
  }

  validateGoalAmount(amount) {
    let invalid = this.isGoalAmountModified && amount < this.minGoalAmount;
    this.editGoalCalculationForm.goalAmount.$setValidity('min', !invalid);
  }

  set goalEndMonth(month) {
    if (this.newGoalCalculations.goalEndDate.getMonth() !== month) {
      this.newGoalCalculations.goalEndDate.setMonth(month);
      this.validateGoalEndDate();
    }
  }

  set goalEndYear(year) {
    if (this.newGoalCalculations.goalEndDate.getFullYear() !== year) {
      this.newGoalCalculations.goalEndDate.setFullYear(year);
      this.validateGoalEndDate();
    }
  }

  get minGoalEndDate() {
    return new moment().startOf('month').startOf('day');
  }

  validateGoalEndDate() {
    let newGoalEndDate = new moment(this.newGoalCalculations.goalEndDate);
    let invalid = this.isGoalEndDateModified && newGoalEndDate.isBefore(this.minGoalEndDate);
    this.editGoalCalculationForm.goalEndDate.$setValidity('required', !invalid);
  }

  set retirementAge(retirementAge) {
    if (this.newGoalCalculations.retirementAge !== retirementAge) {
      this.newGoalCalculations.retirementAge = retirementAge;
      this.validateRetirementAge(retirementAge);
    }
  }

  validateRetirementAge(retirementAge) {
    let invalid = this.isRetirementAgeModified && retirementAge < this.newGoalCalculations.currentAge;
    this.editGoalCalculationForm.retirementAge.$setValidity('min', !invalid);
  }

  setRecommendedPayment(recommendedPayment) {
    this.recommendedPayment =
        (isNaN(recommendedPayment) || recommendedPayment <= this.newGoalCalculations.ongoingInvestment)
            ? 0
            : Math.max(recommendedPayment, 25);
  }

  get goalType() {
    return this.newGoalCalculations.goalType;
  }

  get retirementAge() {
    return this.newGoalCalculations.retirementAge;
  }

  get goalAmount() {
    return this.newGoalCalculations.goalAmount;
  }

  get isGoalModified() {
    return !this.strictEquals(this.oldGoalCalculations, this.newGoalCalculations);
  }

  get isGoalAmountModified() {
    return !this.strictEquals(this.oldGoalCalculations.goalAmount, this.newGoalCalculations.goalAmount);
  }

  get isGoalEndDateModified() {
    return !this.strictEquals(this.oldGoalCalculations.goalEndDate, this.newGoalCalculations.goalEndDate);
  }

  get isRetirementAgeModified() {
    return !this.strictEquals(this.oldGoalCalculations.retirementAge, this.newGoalCalculations.retirementAge);
  }

  get goalEndMonth() {
    return this.newGoalCalculations.goalEndDate.getMonth()
  }

  get goalEndYear() {
    return this.newGoalCalculations.goalEndDate.getFullYear();
  }

  get minGoalAmount() {
    return Math.ceil(this.goalKs.calculatedTotal);
  }

  get hasMultiplePac() {
    return this.oldRecurringPacDTOList.length > 1;
  }

  initializeGoalDateYearOptions() {
    let thisYear = new Date().getFullYear();
    let goalEndDate = new moment(this.oldGoalCalculations.goalEndDate);
    this.goalDateYearOptions = this.getYearOptions(Math.min(thisYear, goalEndDate.year()));
  }

  initializePacDateYearOptions() {
    let thisYear = new Date().getFullYear();
    this.pacDateYearOptions = this.getYearOptions(thisYear);
  }

  getYearOptions(initial) {
    let years = [];
    for (let i = 0; i < 100; i++) {
      years.push(i + initial);
    }
    return years;
  }

  updatePacList(materialTimeChangeNote) {
    let promise = Promise.resolve(false);
    this.newRecurringPacDTOList.forEach((_, index) => {
      if (this.isPacModified(index)) {
        promise = promise.then(response => this.updatePac(index, response ? null : materialTimeChangeNote));
      }
    });
    window.track('Edit PAC', {
      'Increase Projected Amount': this.isProjectedAmountIncrease()
    })
    return promise;
  }

  get projectedAmountReducer() {
    return (total, pac) => total + (pac && pac.amount && pac.frequency
        ? pac.amount * FREQUENCY_ANNUAL_TIMES[pac.frequency] : 0);
  }

  isProjectedAmountIncrease() {
    let before = this.oldRecurringPacDTOList
        ? this.oldRecurringPacDTOList.reduce(this.projectedAmountReducer, 0) : 0;
    let after = this.newRecurringPacDTOList
        ? this.newRecurringPacDTOList.reduce(this.projectedAmountReducer, 0) : 0;
    return after > before;
  }

  updatePac(index, materialTimeChangeNote) {
    window.track('Edit PAC', {
      'Change': this.isPacAdded(index) ? 'Add' : this.isPacCancelled(index) ? 'Delete' : 'Edit',
      'Change Date': this.isPacDateModified(index),
      'Change Amount': this.isPacAmountModified(index),
      'Change Frequency': this.isPacFrequencyModified(index)
    });
    return this.userService.updatePacGoal({
      goalId: this.goalKs.goal.id,
      pacId: this.newRecurringPacDTOList[index].id,
      fund: this.showPacFund(index) ? this.newRecurringPacDTOList[index].fund : null,
      amount: this.newRecurringPacDTOList[index].amount,
      frequency: this.newRecurringPacDTOList[index].frequency,
      startDate: this.formatPacDate(this.newRecurringPacDTOList[index].nextDate),
      prevFund: this.oldRecurringPacDTOList[index].fund,
      prevAmount: this.oldRecurringPacDTOList[index].amount,
      prevFrequency: this.oldRecurringPacDTOList[index].frequency,
      prevStartDate: this.formatPacDate(this.oldRecurringPacDTOList[index].nextDate),
      isBatchUpdate: this.isUpdateMultiple,
      materialTimeChange: materialTimeChangeNote
    })
  }

  updateGoal(materialTimeChangeNote) {
    if (!this.isGoalModified) {
      return Promise.resolve(false);
    }

    let payload = {
      'source': 'WEB',
      'goalId': this.goalKs.goal.id,
      'goalType': this.newGoalCalculations.goalType,
      'materialTimeChange': materialTimeChangeNote
    };

    switch (this.goalType) {
      case 'PURCHASE':
        payload.goalAmount = this.newGoalCalculations.goalAmount;
        payload.previousGoalAmount = this.oldGoalCalculations.goalAmount;
        payload.goalEndDate = this.formatGoalEndDate(this.newGoalCalculations.goalEndDate);
        payload.previousGoalEndDate = this.formatGoalEndDate(this.oldGoalCalculations.goalEndDate);
        break;
      case 'RETIREMENT':
        payload.retirementAge = this.newGoalCalculations.retirementAge;
        payload.previousRetirementAge = this.oldGoalCalculations.retirementAge;
        break;
      default:
        return Promise.resolve(false);
    }
    return this.userService.updateGoal(payload);
  }

  formatPacDate(pacMoment) {
    return pacMoment.format(MOMENT_FORMAT);
  }

  formatGoalEndDate(goalEndDate) {
    return this.filter('date')(goalEndDate, DATE_FORMAT);
  }
}

module.exports = EditGoalDialogModalController;
