//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');

//NON-VENDOR MODULES
const editGoalComponent = require('./edit-goal.component.js');
const regexValidatorModule = require('../../../directives/regex-validator/regex-validator');
const userService = require('../../../services/user-service/user-service');
const utilityService = require('../../../services/utility-service/utility-service');
const chartModule = require('../../../components/chart/chart');
const atbConstantsModule = require('../../../components/atb-constants/atb-constants')

let editGoalModule = angular
    .module('editGoal', [
        angularMaterial,
        regexValidatorModule,
        userService,
        utilityService,
        chartModule,
        atbConstantsModule
    ])
    .component('editGoal', editGoalComponent)
    .name;

module.exports = editGoalModule;
