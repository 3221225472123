const template = require('./degree-of-risk-question.html');
const controller = require('./degree-of-risk-question.controller.js');
require('./degree-of-risk-question.scss');

let degreeOfRiskQuestionComponent = {
    restrict: 'E',
    bindings: {
        questionData: '=',
        model: '=',
        onNext: '&',
        onPrev: '&'
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = degreeOfRiskQuestionComponent;