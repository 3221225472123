//VENDOR MODULES
const angular = require('angular');

//NON-VENDOR MODULES
const atbConstantsModule = require('../../../components/atb-constants/atb-constants');
const employmentInformationComponent = require('./employment-information.component.js');
const pepOrHioSectionComponent = require('./components/pep-or-hio-section/pep-or-hio-section');

module.exports = angular
    .module('employmentInformation', [
        atbConstantsModule,
        pepOrHioSectionComponent
    ])
    .component('employmentInformation', employmentInformationComponent)
    .name;