//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');

//NON-VENDOR MODULES
const atbConstantsModule = require('../../../../components/atb-constants/atb-constants');
const itemListComponent = require('./item-list.component');
const maximumLengthModule = require('../../../../directives/maximum-length/maximum-length');
const regexValidatorModule = require('../../../../directives/regex-validator/regex-validator');


let itemListModule = angular.module('itemList', [
    atbConstantsModule,
    angularMaterial,
    maximumLengthModule,
    regexValidatorModule
])
    .component('itemList', itemListComponent)
    .name;

module.exports = itemListModule;