//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const ocLazyLoad = require('oclazyload');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const goalCalculation = require('../components/goal-calculation/goal-calculation');
const respGoalCalculation = require('../components/resp-goal-calculation/resp-goal-calculation');
const goalsHeader = require('./components/goals-header/goals-header');
const myInvestments = require('./components/my-investments/my-investments');
const growthAndContributions = require('./components/growth-and-contributions-v2/growth-and-contributions');
const landingPageGoal = require('../components/landing-page-goal/landing-page-goal');
const prosperDashboardRoutes = require('./prosper-dashboard.routes.js');
const prosperGoal = require('./components/prosper-goal/prosper-goal');
const quickSave = require('./components/quick-save/quick-save');
const editGoal = require('./components/edit-goal/edit-goal');
const goalInProgress = require('./components/goal-in-progress/goal-in-progress');
const toolTip = require('../components/tool-tip/tool-tip');

let prosperDashboardModule = angular
    .module('app.prosper-dashboard', [
        uiRouter,
        ocLazyLoad,
        angularTranslate,
        goalCalculation,
        goalsHeader,
        myInvestments,
        growthAndContributions,
        landingPageGoal,
        prosperGoal,
        quickSave,
        editGoal,
        goalInProgress,
        toolTip,
        respGoalCalculation
    ])
    .config(['$stateProvider', prosperDashboardRoutes])
    .name;

module.exports = prosperDashboardModule;
