const moment = require('moment');
const SocialInsuranceNumber = require('../../../social-insurance-number');

const PARENT = 'PARENT';

class EducationBeneficiaryInformationController {
    constructor(educationBeneficiaryRelationships,
                genders,
                monthOptions,
                primaryCaregiverOptions,
                legalGuardianOptions,
                $window) {
        this.educationBeneficiaryRelationships = educationBeneficiaryRelationships;

        this.genders = genders || [];
        this.monthOptions = monthOptions || [];
        this.legalGuardianOptions = legalGuardianOptions || [];
        this.primaryCaregiverOptions = primaryCaregiverOptions || [];

        this.keyboardArrowUp = require('../../../../public/img/material_icons/keyboardArrowUpBlue.svg');
        this.keyboardArrowDown = require('../../../../public/img/material_icons/keyboardArrowDownBlue.svg');
        this.window = $window;

        this.goal = {};
        this.formData = {};
        this.dayOptions = [];
        this.yearOptions = [];
    }

    $onInit() {
        this.checkIntegrity();

        this.maxBeneficiaries = false;
        if (this.formData.beneficiaryInformation.beneficiariesAreSiblings == null) {
            this.formData.beneficiaryInformation.beneficiariesAreSiblings = true;
        }

        if (this.formData.beneficiaryInformation.beneficiaryList.length === 0) {
            this.addBeneficiaryRow();
        } else {
            this.parseSINs();
            let index = 0;
            angular.forEach(this.formData.beneficiaryInformation.beneficiaryList, () => {
                this.calculateAge(index);
                index++;
            })
        }

        for (let day = 1; day <= 31; day++) {
            this.dayOptions.push(day);
        }
        let year = new Date().getFullYear();
        for (let i = 0; i < 100; i++) {
            this.yearOptions.push(year);
            year--;
        }
    }

    checkIntegrity() {
        switch (this.formData.accountPersonalInformation.maritalStatus) {
            case 'SINGLE':
            case 'WIDOWED':
            case 'DIVORCED':
            case 'SEPARATED':
                this.primaryCaregiverOptions = this.primaryCaregiverOptions.filter(it => it.key !== 'SPOUSE');
                this.legalGuardianOptions = this.legalGuardianOptions.filter(it => it.key !== 'SPOUSE' && it.key !== 'BOTH');
                angular.forEach(this.formData.beneficiaryInformation.beneficiaryList, (beneficiary) => {
                    if (beneficiary.legalGuardian === 'SPOUSE' || beneficiary.legalGuardian === 'BOTH') {
                        beneficiary.legalGuardian = null;
                        beneficiary.legalGuardianLastName = null;
                        beneficiary.legalGuardianFirstName = null;
                    }

                    if (beneficiary.primaryCaregiver === 'SPOUSE') {
                        beneficiary.primaryCaregiver = null;
                        beneficiary.primaryCaregiverSIN = null;
                        beneficiary.primaryCaregiverLastName = null;
                        beneficiary.primaryCaregiverFirstName = null;
                    }
                });
                break;
            default:
                let spousalInformation = this.formData.spousalInformation;
                angular.forEach(this.formData.beneficiaryInformation.beneficiaryList, (beneficiary) => {
                    if (beneficiary.legalGuardian === 'SPOUSE') {
                        beneficiary.legalGuardianLastName = spousalInformation.lastName;
                        beneficiary.legalGuardianFirstName = spousalInformation.firstName;
                    }

                    if (beneficiary.primaryCaregiver === 'SPOUSE') {
                        if (this.goal.isJointAccount) {
                            beneficiary.primaryCaregiverSIN = spousalInformation.sin;
                        }
                        beneficiary.primaryCaregiverLastName = spousalInformation.lastName;
                        beneficiary.primaryCaregiverFirstName = spousalInformation.firstName;
                    }
                });
        }
    }

    addBeneficiaryRow() {
        let beneficiaryCount = this.formData.beneficiaryInformation.beneficiaryList.length;
        this.maxBeneficiaries = (beneficiaryCount >= 6);
        if (!this.maxBeneficiaries) {
            let newRelationship = beneficiaryCount === 0 ? PARENT : this.formData.beneficiaryInformation.beneficiaryList[beneficiaryCount - 1].relationship;
            this.formData.beneficiaryInformation.beneficiaryList.push({
                show: true,
                firstName: undefined,
                lastName: undefined,
                relationship: newRelationship,
                gender: undefined,
                sin: undefined,
                birthDay: undefined,
                birthMonth: undefined,
                birthYear: undefined,
                copyAddressFromSubscriber: true,
                primaryCaregiver: this.primaryCaregiverOptions[0].key,
                legalGuardian: this.legalGuardianOptions[0].key,
                isGrantsApplicable: true
            });
        }
    }

    calculateAge(index) {
        let beneficiary = this.formData.beneficiaryInformation.beneficiaryList[index];
        if (beneficiary.birthMonth === null ||
            beneficiary.birthDay === null ||
            beneficiary.birthYear === null) {
            delete beneficiary.age;
            return;
        }
        let formattedDate = (parseInt(beneficiary.birthMonth)) + '-' +
            beneficiary.birthDay + '-' +
            beneficiary.birthYear;
        let dobMoment = moment(formattedDate, 'M-D-YYYY', true);
        if (dobMoment.isValid()) {
            beneficiary.age = moment().diff(dobMoment, 'years');
            if (beneficiary.age === 0 && moment().isBefore(dobMoment)) {
                beneficiary.age--;
            }
            if (beneficiary.age > 18) {
                beneficiary.isGrantsApplicable = false;
            }
        } else {
            delete beneficiary.age;
        }
    }

    removeBeneficiaryRow(index) {
        this.formData.beneficiaryInformation.beneficiaryList.splice(index, 1);
        this.maxBeneficiaries = false;
        if (this.formData.beneficiaryInformation.beneficiaryList.length === 0) {
            this.addBeneficiaryRow();
        }
        if (this.formData.beneficiaryInformation.beneficiaryList.length <= 1) {
            this.formData.beneficiaryInformation.beneficiariesAreSiblings = true;
        }
    }

    showHideBeneficiaryRow(index) {
        this.formData.beneficiaryInformation.beneficiaryList[index].show = !this.formData.beneficiaryInformation.beneficiaryList[index].show;
    }

    showBeneficiaryRow(index) {
        this.formData.beneficiaryInformation.beneficiaryList[index].show = true;
    }

    getInputName(inputName, index) {
        return 'beneficiary' + inputName + index;
    }

    parseSINs() {
        for (let x = 0; x < this.formData.beneficiaryInformation.beneficiaryList.length; x++) {
            if (this.formData.beneficiaryInformation.beneficiaryList[x].sin) {
                this.formData.beneficiaryInformation.beneficiaryList[x].sin = parseInt(this.formData.beneficiaryInformation.beneficiaryList[x].sin, 10);
            }
            if (this.formData.beneficiaryInformation.beneficiaryList[x].primaryCaregiverSIN) {
                this.formData.beneficiaryInformation.beneficiaryList[x].primaryCaregiverSIN = parseInt(this.formData.beneficiaryInformation.beneficiaryList[x].primaryCaregiverSIN, 10);
            }
            if (this.formData.beneficiaryInformation.beneficiaryList[x].legalGuardianSIN) {
                this.formData.beneficiaryInformation.beneficiaryList[x].legalGuardianSIN = parseInt(this.formData.beneficiaryInformation.beneficiaryList[x].legalGuardianSIN, 10);
            }
        }
    }

    resetBeneficiaryAddress(index) {
        this.formData.beneficiaryInformation.beneficiaryList[index].beneficiaryContactInformation.canadaPostAddress = null;
        this.formData.beneficiaryInformation.beneficiaryList[index].beneficiaryContactInformation.legalLandAddress = null;
        this.formData.beneficiaryInformation.beneficiaryList[index].beneficiaryContactInformation.address = null;
        this.formData.beneficiaryInformation.beneficiaryList[index].beneficiaryContactInformation.manuallyEntered = false;
    }

    onPrimaryCaregiverChange(index) {
        if (this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiver === 'SPOUSE') {
            this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiverFirstName = this.formData.spousalInformation.firstName;
            this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiverLastName = this.formData.spousalInformation.lastName;
            this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiverSIN = this.formData.spousalInformation.sin;
        } else {
            this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiverFirstName = null;
            this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiverLastName = null;
            this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiverSIN = null;
            this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiverContactInformation = null;
        }
    }

    onLegalGuardianChange(index) {
        if (this.formData.beneficiaryInformation.beneficiaryList[index].legalGuardian === 'SPOUSE') {
            this.formData.beneficiaryInformation.beneficiaryList[index].legalGuardianFirstName = this.formData.spousalInformation.firstName;
            this.formData.beneficiaryInformation.beneficiaryList[index].legalGuardianLastName = this.formData.spousalInformation.lastName;
        } else {
            this.formData.beneficiaryInformation.beneficiaryList[index].legalGuardianFirstName = null;
            this.formData.beneficiaryInformation.beneficiaryList[index].legalGuardianLastName = null;
        }
    }

    showPrimaryCaregiverRow(index) {
        return this.formData.beneficiaryInformation.beneficiaryList[index].primaryCaregiver !== 'MYSELF';
    }

    showLegalGuardianInformationRow(index) {
        return this.formData.beneficiaryInformation.beneficiaryList[index].legalGuardian !== 'MYSELF'
            && this.formData.beneficiaryInformation.beneficiaryList[index].legalGuardian !== 'BOTH';
    }

    generateSin(index) {
        this.formData.beneficiaryInformation.beneficiaryList[index].sin = SocialInsuranceNumber.generate();
    }
}

module.exports = EducationBeneficiaryInformationController;
