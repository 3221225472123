const template = require('./starting-date.html');
const controller = require('./starting-date.controller.js');
require('./starting-date.scss');

let startingDateComponent = {
    restrict: 'E',
    bindings: {
        form: '=',
        formData: '=',
        pacFrequency: '<'
    },
    template,
    controller: ['monthOptions', '$filter', controller],
    controllerAs: 'startingDate'
};

module.exports = startingDateComponent;