const template = require('./education-beneficiary-information.html');
const controller = require('./education-beneficiary-information.controller.js');
require('./education-beneficiary-information.scss');

let educationBeneficiaryInformationComponent = {
    restrict: 'E',
    bindings: {
        educationBeneficiariesForm: '=',
        formData: '=',
        goal: '=',
        accountType: '<'
    },
    template,
    controller: ['educationBeneficiaryRelationships','genders','monthOptions','primaryCaregiverOptions','legalGuardianOptions','$window', controller],
    controllerAs: 'educationBeneficiaryInformation'
};

module.exports = educationBeneficiaryInformationComponent;
