class GoalQuestionController {
    constructor($window) {
        this.window = $window;
    }

    $onInit() {
        this.goals = ['EDUCATION', 'PURCHASE', 'RETIREMENT', 'SAVINGS'];
        if (this.hasGroupGoal) {
            this.goals.push('GROUP');
        }
    }
}
module.exports = GoalQuestionController;