function ATBInterceptorService($q, $location, $window, localStorageService) {
    function request(config) {
        if (localStorageService.get('authToken') !== null) {
            config.headers.Authorization = 'Bearer ' + localStorageService.get('authToken');
        }

        const distinctId = $window.mixpanel
            && $window.mixpanel.get_distinct_id
            && $window.mixpanel.get_distinct_id();
        if (distinctId) {
            config.headers['x-distinct-id'] = distinctId;
        }

        return config;
    }

    function responseError(rejection) {
        if (rejection.status >= 500 && rejection.status < 600) {
            //todo verify all scenarios where 500 errors should be handled differently from redirecting user to error page - Tracker#139016401
            // if((rejection.config.url.indexOf('/wmf/entity') === -1) && (rejection.config.url.indexOf('/users/sign_in_atbol_user') === -1)) {
            //     $window.location.href = "/#/error/"+rejection.status;
            // }
        } else if (rejection.status === 401) {
            if ($location.url().indexOf('forgot_password') === -1
                && $location.url().indexOf('security-questions') === -1) {
                localStorageService.remove('authToken');
                $window.location.href = '/login';
            }
        }
        return $q.reject(rejection);
    }

    return {
        responseError: responseError,
        request: request
    }
}

module.exports = ATBInterceptorService;
