const template = require('./initial-investment.html');
const controller = require('./initial-investment.controller.js');
require('./initial-investment.scss');

let initialInvestmentComponent = {
    restrict: 'E',
    bindings: {
        formData: '=',
        fundAccountForm: '='
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = initialInvestmentComponent;