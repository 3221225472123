class ExpandableController {
    constructor() {
        this.isExpanded = this.default;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }
}

module.exports = ExpandableController;
