class InvestmentTimeQuestionController {
    constructor() {
        this.form = null;
    }

    get onNext() {
        return this.model.timeHorizonSpan < 2 ? this.onHisa : this._onNext;
    }

    set onNext(onNext) {
        this._onNext = onNext;
    }

    resetForm() {
        if (this.form) {
            this.form.$error = [];
            this.form.$setPristine();
        }
    }

    $onChanges() {
        this.resetForm();
    }
}

module.exports = InvestmentTimeQuestionController;