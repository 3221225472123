class PepOrHioSectionController {
    constructor($translate, pepOrHioIdentities, hioOccupations, pepTypes, pepOccupations) {
        this.translationService = $translate;
        this.whoIdentifiesOptions = pepOrHioIdentities;
        this.hioOccupations = hioOccupations;
        this.pepTypeOptions = pepTypes;
        this.pepOccupations = pepOccupations;
    }

    $onInit() {
        this.questionText = this.translationService.instant('openAccount.employmentInformation.'+this.sectionType);
        this.toolTipText = this.translationService.instant('openAccount.employmentInformation.'+this.sectionType+'Info');
        this.whoIdentifiesQuestionText = this.translationService.instant('openAccount.employmentInformation.'+this.sectionType+'WhoIdentifies');
        this.occupationList = this.hioOccupations;
        if(this.formData) {
            this.setupDataFromExistingUiForm();
        }
    }

    clearAnswers() {
        if(this.formData){
            this.formData.whoIdentifiesSelection = null;
            this.clearAnswersOnWhoIdentifiesChange();
        }
    }

    clearAnswersOnWhoIdentifiesChange() {
        if (this.sectionType === 'politicallyExposedPerson') {
            this.formData.pepTypeSelection = null;
        }
        this.clearDropdownAnswers()
    }

    clearDropdownAnswers() {
        this.formData.occupationType = null;
        if(this.form && this.form[this.sectionType + 'DropDown']) {
            this.form[this.sectionType + 'DropDown'].$setUntouched();
        }
    }

    setupDataFromExistingUiForm() {
        if (this.sectionType === 'politicallyExposedPerson') {
            this.updateOccupationList();
        }
        if (this.formData.occupationType && this.formData.occupationType !== null) {
            this.formData.occupationType = this.formData.occupationType.toString();
        }
    }

    updateOccupationList(){
        this.occupationList = this.pepOccupations;
        if(this.formData.pepTypeSelection === 'FOREIGN') {
            this.occupationList = this.pepOccupations.slice(0, 9);
        }
    }
}

module.exports = PepOrHioSectionController;