const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const respEducationComponent = require('./resp-education.component.js');

let respEducationModule = angular
    .module('respEducation', [
        angularSanitize
    ])
    .component('respEducation', respEducationComponent)
    .name;

module.exports = respEducationModule;