const angular = require('angular');
const howWeMakeItHappenComponent = require('./how-we-make-it-happen.component');

let howWeMakeItHappenModule = angular.module('howWeMakeItHappen', [
    ])

    .component('howWeMakeItHappen', howWeMakeItHappenComponent)
    .name;


module.exports = howWeMakeItHappenModule;