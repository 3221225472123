class InvestmentAccountTypesController {
    constructor() {
        this.accountTypes = ['TFSA', 'RRSP', 'RESP', 'CASH'];
        this.state = {
            RRSP: {
                expanded: false
            },
            TFSA: {
                expanded: false
            },
            CASH: {
                expanded: false
            },
            RESP: {
                expanded: false
            }
        }
    }

    toggle(accountType) {
        let expanded = this.state[accountType].expanded;
        this.state[accountType].expanded = !expanded;
    }

    isExpanded(accountType) {
        return this.state[accountType].expanded;
    }
}

module.exports = InvestmentAccountTypesController;
