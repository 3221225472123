const template = require('./resp-education.html');
const controller = require('./resp-education.controller.js');
require('./resp-education.scss');

let respEducationComponent = {
    restrict: 'E',
    bindings: {
        onNext: '&',
        onPrev: '&'
    },
    template,
    controller,
    controllerAs: 'respEducation'
};

module.exports = respEducationComponent;