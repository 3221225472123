const _ = require('lodash');

class AddressInfoController {
    constructor(cities, provinces, countries, states) {
        this.cities = cities;
        this.provinces = provinces;
        this.states = states;
        this.countries = countries;

        // bindings
        this.model = {};
        this.form = {};
    }

    $onInit() {
        this.isCivic = true;
        this.toggleAddressFormat();
    }

    isValidAddress() {
        const isValid = this.model
            && this.model.city
            && this.model.postalCode
            && this.model.province
            && this.model.country;
        if (this.form[this.type + 'AddrLookup']) {
            this.form[this.type + 'AddrLookup'].$setValidity('isValidAddress', Boolean(isValid));
        }
        return isValid;
    }

    get addressLabel() {
        return AddressInfoController.formatAddress(this.provinces, this.model);
    }

    static formatAddress(provinces, model) {
        return [
            AddressInfoController.getLine1(model),
            model.legalLandDescription,
            AddressInfoController.getLine2(provinces, model),
            model.country
        ]
            .filter(it => Boolean(it))
            .join('<br/>');
    }

    static getLine1(model) {
        return [
            model.unitNumber,
            model.streetNumber,
            model.streetName,
            model.box
        ]
            .filter(it => Boolean(it))
            .join(' ');
    }

    static getLine2(provinces, model) {
        const province = _.find(provinces, prov => prov.enum === model.province).value;
        return [model.city, province, model.postalCode]
            .filter(it => Boolean(it))
            .join(' ');
    }

    reset() {
        this.isCivic = true;
        this.model = {
            country: 'Canada',
            province: 'Alberta',
            addressFormat: 'Civic'
        };
    }

    isCountryCanada() {
        return this.model.country === 'Canada';
    }

    isCountryUSA() {
        return this.model.country === 'Usa';
    }

    isCountryOther() {
        return !this.isCountryCanada() && !this.isCountryUSA();
    }

    toggleAddressFormat() {
        this.model.addressFormat = (this.isCivic ? 'Civic' : 'Rural');
    }
}

module.exports = AddressInfoController;
