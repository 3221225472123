//VENDOR MODULES
const angular = require('angular');

//NON-VENDOR MODULES
const addressModule = require('../../../components/address/address');
const atbConstantsModule = require('../../../components/atb-constants/atb-constants');
const educationBeneficiaryInformationComponent = require('./education-beneficiary-information.component.js');
const greyBackgroundBoxModule = require('../../../components/grey-background-box/grey-background-box');
const maximumLengthModule = require('../../../directives/maximum-length/maximum-length');
const regexValidatorModule = require('../../../directives/regex-validator/regex-validator');
const toolTipModule = require('../../../components/tool-tip/tool-tip');

let educationBeneficiaryInformationModule = angular.module('educationBeneficiaryInformation', [
    addressModule,
    atbConstantsModule,
    greyBackgroundBoxModule,
    maximumLengthModule,
    regexValidatorModule,
    toolTipModule
])

    .component('educationBeneficiaryInformation', educationBeneficiaryInformationComponent)
    .name;

module.exports = educationBeneficiaryInformationModule;