const template = require('./register-user-name.html');
const controller = require('./register-user-name.controller.js');
require('./register-user-name.scss');

let userNameComponent = {
    restrict: 'E',
    bindings: {
        registrationForm: '=',
        formData: '='
    },
    template,
    controller,
    controllerAs: 'registerUserName'
};

module.exports = userNameComponent;
