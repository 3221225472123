const _ = require('lodash');

class MaritalStatusController {
    constructor(maritalStatuses) {
        this.maritalStatuses = maritalStatuses;

        this.model = {};
        this.form = {};
    }

    get spouseFirstnameLabel() {
        return this.model.maritalStatus === 'DIVORCED' ? 'kyc.field.formerSpouseFirstname' : 'kyc.field.spouseFirstname';
    }

    get spouseLastnameLabel() {
        return this.model.maritalStatus === 'DIVORCED' ? 'kyc.field.formerSpouseLastname' : 'kyc.field.spouseLastname';
    }

    resetFields() {
        this.model = {
            ...this.model,
            spouse: {
                firstName: null,
                lastName: null,
                workingForAtb: false
            },
            numberOfDependents: null
        };
    }

    validateLastName() {
        this.form.spouseLastName.$setValidity('spouseFirstNameValid', _.size(this.model.spouse.lastName) <= 60);
    }

    validateFirstName() {
        this.form.spouseFirstName.$setValidity('spouseFirstNameValid', _.size(this.model.spouse.firstName) <= 25);
    }

    hasSpouse() {
        return ['MARRIED', 'COMMONLAW'].includes(this.model.maritalStatus);
    }

    isNotSingleOrWidow() {
        return !['SINGLE', 'WIDOWED'].includes(this.model.maritalStatus);
    }

    // noinspection JSUnusedGlobalSymbols
    isSpouseFirstNameRequired() {
        return this.hasSpouse() && _.size(this.model.spouse.lastName) === 0;
    }

    // noinspection JSUnusedGlobalSymbols
    isSpouseLastNameRequired() {
        return this.hasSpouse() && _.size(this.model.spouse.firstName) === 0;
    }
}

module.exports = MaritalStatusController;