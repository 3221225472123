const angular = require('angular');
const angularMaterial = require('angular-material');

const requestEmailComponent = require('./request-email.component.js');
const userService = require('../../../../services/user-service/user-service');
const googleCaptchaModule = require('../../../../components/google-captcha/google-captcha');

let requestEmailModule = angular.module('requestEmail', [
        angularMaterial,
        googleCaptchaModule,
        userService
    ])
    .component('requestEmail', requestEmailComponent)
    .name;

module.exports = requestEmailModule;
