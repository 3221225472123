require('./resend-registration-notification-modal.scss');

class ResendRegistrationNotificationModal {
    constructor($mdDialog, userService, goalId) {
        this.mdDialog = $mdDialog;
        this.userService = userService;
        this.goalId = goalId;
    }
    cancel(message) {
        this.mdDialog.cancel(message);
    }

    resendEmail() {
        this.formData.spouseEmailFromServer = undefined;
        let request = {'goalId': this.goalId, 'email': this.formData.spouseEmail};
        this.userService.resendRegistrationNotificationToSpouse(request)
            .then(() => {
                this.cancel();
            })
            .catch(() => {
                this.cancel({'message': 'resendRegistrationNotificationModal.error'});
            });
    }

    getSpouseEmail() {
        this.userService.getSpousalInformation(this.goalId)
            .then(response => this.formData.spouseEmail = response.body.email);
    }

    $onInit() {
        this.formData = {};
        this.getSpouseEmail();
    }
}

module.exports = ResendRegistrationNotificationModal;
