const angularTranslate = require('angular-translate');
const uiRouter = require('@uirouter/angularjs').default;
const headerComponent = require('./header.component');
const goalServiceModule = require('../../services/goal-service/goal-service');
const userServiceModule = require('../../services/user-service/user-service');
const kycServiceModule = require('../../services/kyc-service/kyc-service');
const localStorageService = require('angular-local-storage');

let headerModule = angular
    .module('header', [
        angularTranslate,
        goalServiceModule,
        localStorageService,
        uiRouter,
        userServiceModule,
        kycServiceModule
    ])
    .component('header', headerComponent)
    .name;

module.exports = headerModule;