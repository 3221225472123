const angular = require('angular');
const angularMaterial = require('angular-material');

const createProfileComponent = require('./create-profile.component');
const userNameValidatorModule = require('../../../directives/username-validator-ciam/username-validator');
const equalToValidator = require('../../../directives/equal-to-validator/equal-to-validator');
const feedbackModule = require('../../../components/feedback-message/feedback-message');

let createProfileModule = angular.module('createProfile', [
    angularMaterial,
    userNameValidatorModule,
    equalToValidator,
    feedbackModule
])
    .component('createProfile', createProfileComponent)
    .name;

module.exports = createProfileModule;
