class EmploymentInformationController {
    constructor(employmentStatuses, atbEmploymentStatuses, industryList) {
        this.industryList = industryList;
        this.employmentStatuses = employmentStatuses;
        this.atbEmploymentStatuses = atbEmploymentStatuses;

        // bindings
        this.formData = {};
        this.isSpouse = null;
        this.isGroupGoal = null;
        this.isJointAccount = null;
        this.openAccountForm = null;
        this.employmentStatus = null;
        this.regulatoryQuestions = null;
        this.atbEmploymentStatus = null;
    }

    $onInit() {
        this.hasGwsEmployerName = this.isGroupGoal && this.formData.employerName !== null;
        this.initEmploymentInformation();
    }

    initEmploymentInformation() {
        if (this.isEmployed()) {
            if (this.isEmployedWithAtbCompany()) {
                // make sure predefined employer name and industry is valid
                this.resetEmploymentInformationIfEmployedWithAtb();
            } else if (this.isGroupGoal) {
                // force group goal user to enter those fields
                this.formData.occupation = null;
                this.formData.natureOfBusiness = null;
            } else {
                // make sure predefined nature of business is valid
                const selected = this.industryList.find(it => it.value === this.formData.natureOfBusiness);
                if (!selected) this.formData.natureOfBusiness = null;
            }
        } else {
            this.resetEmploymentInformationIfUnemployedStatus();
        }
    }

    resetEmploymentInformation() {
        if (this.isEmployed()) {
            if (this.isEmployedWithAtbCompany()) {
                this.resetEmploymentInformationIfEmployedWithAtb();
            } else {
                if (!this.isGroupGoal) this.formData.employerName = null;
                this.formData.natureOfBusiness = null;
            }
            this.formData.occupation = null;
        } else {
            this.resetEmploymentInformationIfUnemployedStatus();
        }
        this.resetEmploymentInformationFieldErrors();
    }

    resetEmploymentInformationIfEmployedWithAtb() {
        const atbCompany = this.atbEmploymentStatuses.find(it => it.value === this.atbEmploymentStatus);
        this.formData.employerName = atbCompany.label;
        this.formData.natureOfBusiness = 'Financial services/banking';
    }

    resetEmploymentInformationIfUnemployedStatus() {
        const unemployedStatus = this.employmentStatuses.find(it => it.key === this.employmentStatus);
        this.formData.employerName = unemployedStatus ? unemployedStatus.value : null;
        this.formData.occupation = unemployedStatus ? unemployedStatus.value : null;
        this.formData.natureOfBusiness = unemployedStatus ? unemployedStatus.value : null;
    }

    resetEmploymentInformationFieldErrors() {
        this._resetFormFieldError('occupation');
        this._resetFormFieldError('employerName');
        this._resetFormFieldError('employedNatureOfBusiness');
    }

    isEmployedWithAtbCompany() {
        const found = this.atbEmploymentStatuses.find(it => it.value === this.atbEmploymentStatus);
        return Boolean(found && found.isAtb);
    }

    isEmployed() {
        return this.employmentStatus === 'EMPLOYED' || this.employmentStatus === 'SELFEMPLOYED';
    }

    showEmployerFields() {
        return this.isJointAccount || this.employmentStatus === 'EMPLOYED';
    }

    showMainSubscriber() {
        return !this.isSpouse && (this.isJointAccount || this.isEmployed());
    }

    disableNatureOfBusinessField() {
        return !this.isEmployed()
            || this.isEmployedWithAtbCompany();
    }

    disableEmployerField() {
        return !this.isEmployed()
            || this.isEmployedWithGwsCompany()
            || this.isEmployedWithAtbCompany();
    }

    disableOccupationField() {
        return !this.isEmployed();
    }

    isEmployedWithGwsCompany() {
        return this.isGroupGoal && this.hasGwsEmployerName;
    }

    _resetFormFieldError(fieldName) {
        if (this.openAccountForm && this.openAccountForm[fieldName]) {
            this.openAccountForm[fieldName].$setPristine();
            this.openAccountForm[fieldName].$setUntouched();
        }
    }

    $onChanges(changes) {
        if (!this.openAccountForm || !this.openAccountForm.$dirty) {
            // skip initial loading
            return;
        }
        if (changes.employmentStatus || changes.atbEmploymentStatus) {
            this.resetEmploymentInformation();
        }
    }
}

module.exports = EmploymentInformationController;
