'strict';

//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const eSignRoutes = require('./e-sign.routes.js');
const footerModule = require('../components/footer/footer');
const formHeader = require('../components/form-header/form-header');
const headerModule = require('../components/header/header');
const userServiceModule = require('../services/user-service/user-service');
const utilityServiceModule = require('../services/utility-service/utility-service');

let eSignModule = angular
    .module('app.eSign', [
        angularTranslate,
        footerModule,
        formHeader,
        headerModule,
        uiRouter,
        userServiceModule,
        utilityServiceModule
    ])
    .config(['$stateProvider', eSignRoutes])
    .name;

module.exports = eSignModule;
