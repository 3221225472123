require('./quick-save-dialog-modal.scss');

class QuickSaveDialogModalController {
  constructor($window, $mdDialog, userService, amount, goalKs, fundCodes) {
    this.window = $window;
    this.mdDialog = $mdDialog;
    this.userService = userService;
    this.quickSaveProcessing = false;
    this.showQuickSaveComplete = false;
    this.lightbulbImg = require('../../../../public/img/material_icons/lightbulbWhite.svg');

    this.quickSaveForm = {};
    this.fundCodes = fundCodes;

    this.amount = amount;
    this.goalKs = goalKs;
  }

  $onInit() {
    // Only support COMPASS and HISA
    if (this.goalKs && this.goalKs.holdings) {
      this.holdings = this.goalKs.holdings
          .filter(it => it.cusip !== 'CASH' && this.fundCodes[it.cusip])
          .sort((a, b) => {
            return this.fundCodes[a.cusip].order - this.fundCodes[b.cusip].order;
          });
      this.selected = this.holdings[0] && this.holdings[0].cusip;
    }
  }

  get obsUrl() {
    return this.window.applicationConfiguration.obsUrl;
  }

  getContributionRange(amount) {
    if (amount < 25) {
      return '< $25';
    } else if (amount >= 25 && amount <= 100) {
      return '$25 - $100';
    } else if (amount > 100 && amount <= 250) {
      return '$101 - $250';
    } else if (amount > 250 && amount <= 500) {
      return '$250 - $500';
    } else if (amount > 500 && amount <= 1000) {
      return '$501 - $1000';
    } else if (amount > 1000 && amount <= 10000) {
      return '$1001 - $10000';
    } else if (amount > 10000) {
      return '> $10000';
    }
  }

  get portfolio() {
    return this.holdings.filter(it => it.cusip === this.selected)[0].portfolio;
  }

  isCompass() {
    return this.selected && this.fundCodes[this.selected].isCompass;
  }

  isHisa() {
    return this.selected === 'ALB100' || this.selected === 'ALB102';
  }

  get canSubmit() {
    return !this.quickSaveProcessing &&
        this.quickSaveForm['acknowledgeNoLifeChanges_quickSave'] === true &&
        (this.quickSaveForm['reviewedFundFactsquickSave'] === true || !this.isCompass());
  }

  quickSave() {
    if (this.quickSaveForm.$valid) {
      if (this.quickSaveProcessing) {
        return;
      }
      this.quickSaveProcessing = true;
      this.userService
          .quickSave({
            goalId: this.goalKs.goal.id,
            amount: this.amount,
            fundCode: this.selected
          })
          .then(() => {
            this.mdDialog.cancel({
              category: 'success',
              message: 'quickSave.quickSaveSuccessDescription'
            });
            window.track('Quick Save', {
              'Amount': this.getContributionRange(this.amount),
              'Has PAC': this.goalKs.contributionData.ongoingInvestment > 0
            });
          })
          .catch(() => {
            this.mdDialog.cancel({
              category: 'error',
              'message': 'quickSave.error'
            });
          })
          .finally(() => {
            this.quickSaveProcessing = false;
          });
    }
  }
}

module.exports = QuickSaveDialogModalController;
