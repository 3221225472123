//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');
const userServiceModule = require('../../../services/user-service/user-service');
const goalServiceModule = require('../../../services/goal-service/goal-service');
const emailValidatorModule = require('../../../directives/email-validator/email-validator');

//NON-VENDOR MODULES
const goalInProgressComponent = require('./goal-in-progress.component.js');
let goalInProgressModule = angular.module('goalInProgress',
        [angularMaterial,
         userServiceModule,
         goalServiceModule,
         emailValidatorModule
        ])
    .component('goalInProgress', goalInProgressComponent)
    .name;

module.exports = goalInProgressModule;