require('../../../../style/modal.scss');

class AicDialogController {
    constructor($window, $mdDialog) {
        this.window = $window;
        this.dialogService = $mdDialog;
    }

    goToAIC() {
        this.window.open(window.applicationConfiguration.aicLinkPing, '_blank');
    }

    cancel() {
        this.dialogService.cancel();
    }
}

module.exports = AicDialogController;
