//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');
const roiItem = require('./roi-item.module')

//NON-VENDOR MODULES
const GoalServiceModule = require('../../../services/goal-service/goal-service');
const growthAndContributionsComponent = require('./growth-and-contributions.component');

let growthAndContributionsModule = angular
    .module('growthAndContributions', [
        angularMaterial,
        GoalServiceModule,
        roiItem
    ])
    .component('growthAndContributions', growthAndContributionsComponent)
    .name;

module.exports = growthAndContributionsModule;