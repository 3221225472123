const errorTemplate = require('./error.html');
require('./error.scss');

function routes($stateProvider) {
    $stateProvider
        .state('error', {
            url: '/error/:errorType',
            parent: 'site',
            views: {
                'content@': {
                    template: errorTemplate,
                    controller: 'ErrorController',
                    controllerAs: 'error'
                }
            },
            resolve: {
                errorTranslations: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('error');
                    $translate.refresh();
                    return true;
                }],
                ErrorController:['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "error" */ './error.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        });
}

module.exports = routes;
