require('./address-lookup.template.scss');
const controller = require('./address-lookup.controller');


module.exports = {
    restrict: 'E',
    bindings: {
        type: '<',
        model: '=',
        form: '<'
    },
    template: require('./address-lookup.template.html'),
    controller: ['$window', '$timeout', 'provinces', controller],
    controllerAs: 'controller'
};