const template = require('./login-footer.html');
const controller = require('./login-footer.controller');
require('./login-footer.scss');

let loginFooterComponent = {
    restrict: 'E',
    bindings: {},
    template,
    controller: ['$window', controller],
    controllerAs: 'footer'
};

module.exports = loginFooterComponent;
