const forgotUsernameTemplate = require('./forgot-username.html');

require('./forgot-username.scss');

function routes($stateProvider) {
    $stateProvider
        .state('forgot-username', {
            url: '/forgot-username?origin',
            parent: 'site',
            views: {
                'content@': {
                    template: forgotUsernameTemplate,
                    controller: 'ForgotUsernameController',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('forgot-username');
                    $translate.refresh();
                    return true;
                }],
                controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "forgot-password" */ './forgot-username.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        });
}

module.exports = routes;
