require("./flags.scss");

function routes($stateProvider) {
  $stateProvider.state('flags', {
    url: '/flags',
    parent: 'site',
    views :{
      'content@': {
        template: require('./flags.template.html'),
        controller: 'FlagsController',
        controllerAs: 'controller'
      }
    },
    resolve: {
      controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
        return import(/* webpackChunkName: "welcome" */ './flags.controller')
            .then(controller => $ocLazyLoad.load(controller));
      }]
    }
  })
}

module.exports = routes;
