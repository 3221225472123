class SetSecurityQuestionsController {
    constructor() {
        this.registrationForm = {};
        this.showAnswer = [false, false];
    }

    onChange() {
        for (let index = 0; index < this.answers.length; index++) {
            const pivot = this.answers[index];
            const found = this.answers.findIndex((answer, idx) => answer.questionId === pivot.questionId && idx !== index);
            const duplicate = (found !== -1);

            this.registrationForm['securityQuestion' + index].$setValidity('duplicateQuestion', !duplicate);
        }
    }

    $onInit() {
        this.answers = [
            {
                questionId: null,
                answer: null
            },
            {
                questionId: null,
                answer: null
            },
            {
                questionId: null,
                answer: null
            }
        ];
    }
    handleShowHide(index) {
        this.showAnswer[index] = !this.showAnswer[index];
        document.getElementById('securityQuestionAnswer' + index).type = (this.showAnswer[index] ? 'text' : 'password');
    }
}

module.exports = SetSecurityQuestionsController;
