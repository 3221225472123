class EmploymentIncomeController {
    constructor(employmentStatuses, industryList, incomeRangeList, atbEmploymentStatuses) {
        this.employmentStatusesList = employmentStatuses;
        this.industryList = industryList;
        this.incomeRangeList = incomeRangeList;
        this.incomeRangeListWithoutNone = this.incomeRangeList.filter(e => e.key !== 'NONE');
        this.atbEmploymentStatusesList = atbEmploymentStatuses;
        this.model = {};
    }

    get employmentStatuses() {
        return this.employmentStatusesList;
    }

    get industries() {
        return this.industryList;
    }

    get availableAtbEmploymentStatuses() {
        return this.atbEmploymentStatusesList
            .filter(it => it.statuses.includes('*') || it.statuses.includes(this.model.employmentStatus));
    }

    get incomeRanges() {
        return this.customerHasIncome() ? this.incomeRangeListWithoutNone : this.incomeRangeList;
    }

    get employerNameLabel() {
        return this.model.employmentStatus === 'EMPLOYED' ? 'kyc.field.employer' : 'kyc.field.sourceIncome';
    }

    get employerNameError() {
        return this.model.employmentStatus === 'EMPLOYED' ? 'kyc.error.invalidEmployerName' : 'kyc.error.invalidSourceIncome';
    }

    resetFields() {
        this.model = {
            ...this.model,
            'annualIncome': null,
            'employer': null,
            'unemployedIncomeSource': null,
            'occupation': null,
            'industry': null
        };
    }

    onEmploymentStatusChanged() {
        this.model = {
            ...this.model,
            'worksForAtbCompany': null,
            'employeeId': null
        }
        this.resetFields();
    }

    onAtbEmploymentStatusChanged() {
        const found = this.atbEmploymentStatusesList.find(it => it.value === this.model.worksForAtbCompany);
        if (found && found.isAtb) {
            this.model.industry = 'FinancialServicesOrBanking';
            this.model.employer = found.label;
            this.model.occupation = null;
        } else {
            this.model.employeeId = this.model.worksForAtbCompany === 'No' ? null : this.model.employeeId;
            this.resetFields();
        }
    }

    customerHasIncome() {
        return ['EMPLOYED', 'UNEMPL_W_INCOME'].includes(this.model.employmentStatus);
    }
}

module.exports = EmploymentIncomeController;