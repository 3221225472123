//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');

//NON-VENDOR MODULES
const quickSaveComponent = require('./quick-save.component.js');
const regexValidatorModule = require('../../../directives/regex-validator/regex-validator');

const atbConstants = require('../../../components/atb-constants/atb-constants')

module.exports = angular
    .module('quickSave', [
      angularMaterial, regexValidatorModule, atbConstants
    ])
    .component('quickSave', quickSaveComponent)
    .name;
