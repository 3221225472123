const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const investmentKnowledgeQuestionComponent = require('./investment-knowledge-question.component.js');
const horizontalRGModule = require('../../../../components/horizontal-radio-group-v2/horizontal-radio-group');

let investmentKnowledgeQuestionModule = angular
    .module('investmentKnowledgeQuestion', [
        angularSanitize,
        horizontalRGModule
    ])
    .component('investmentKnowledgeQuestion', investmentKnowledgeQuestionComponent)
    .name;

module.exports = investmentKnowledgeQuestionModule;