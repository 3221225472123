'strict';

//VENDOR MODULES
const angular = require('angular');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const idVerificationRoutes = require('./id-verification.routes.js');
const footerModule = require('../components/footer/footer');
const headerModule = require('../components/header/header');
const formHeader = require('../components/form-header/form-header');
const utilityServiceModule = require('../services/utility-service/utility-service');

let idVerificationModule = angular
    .module('app.idVerification', [
        angularTranslate,
        footerModule,
        headerModule,
        formHeader,
        headerModule,
        utilityServiceModule
    ])
    .config(['$stateProvider', idVerificationRoutes])
    .name;

module.exports = idVerificationModule;
