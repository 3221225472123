const moment = require('moment');
const angular = require('angular');
const HeaderConfirmationDialogController = require('./header-confirmation-dialog.controller');
const AnnualReviewOverdueDialogController = require('./annual-review-overdue-dialog/annual-review-overdue-dialog.controller');

class HeaderController {
    constructor(goalService,
                $translate,
                $mdDialog,
                userService,
                $window,
                $timeout,
                utilityService,
                $state,
                localStorageService,
                kycService) {
        this.translateService = $translate;
        this.logo = require('../../../public/img/ATB-Prosper_White.svg');
        this.goalService = goalService;
        this.dialogService = $mdDialog;
        this.userService = userService;
        this.kycService = kycService;
        this.window = $window;
        this.timeout = $timeout;
        this.utilityService = utilityService;
        this.state = $state;
        this.localStorageService = localStorageService;

        this.isReviewPending = false;
    }

    getDescription(selectedGoal) {
        let text;
        if (selectedGoal) {
            let goal = this.translateService.instant('goalSelection.' + selectedGoal);
            text = this.translateService.instant('header.myPrimaryGoal') + ' ' + goal;
        }
        return text;
    }

    logOut() {
        this.userService.signOut();
    }

    isUserLoggedOn() {
        return this.userService.isUserLoggedOn();
    }

    goToHomeBase() {
        if (this.isUserLoggedOn() || this.state.params.companyKey) {
            this.goalService.resetAnswers();
            if (this.utilityService.isDuringAccountOpening(this.state.current.name)
                || this.state.current.name === 'kyc'
                || this.utilityService.isDuringGwsRiskAssessment(this.state.current.name, this.state.params.companyKey)
            ) {
                this.dialogService.show({
                    controller: ['$mdDialog', 'companyKey', HeaderConfirmationDialogController],
                    controllerAs: 'confirmNav',
                    template: require('./header-confirmation-dialog.html'),
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    locals: {companyKey: this.state.params.companyKey}
                }).then(() => {
                    this.state.go(this.utilityService.getPageByName('DASHBOARD').page);
                });
            } else {
                this.state.go('redirect');
            }
        } else {
            this.state.go(this.utilityService.getPageByName('LANDINGPAGE').page);
        }
    }

    goToObs() {
        this.window.open(this.window.applicationConfiguration.obsUrl, '_blank');
    }

    goToKyc() {
        this.state.go('kyc');
    }

    showAnnualReviewOverdueDialog(overdueDate) {
        // dialog starts showing 6 months before the overdue date
        return overdueDate.diff(moment(), 'days') <= 180;
    }

    $onInit() {
        if (this.isUserLoggedOn()) {
            this.kycService.getKycInvestorProfileStatus()
                .then(response => {
                    this.isReviewPending = response.data;
                })
                .catch(err => {
                });
            this.displayName = this.userService.getUserDisplayName();
            this.isInvestorProfileLocked = this.userService.getInvestorProfileLocked();
            if (!this.displayName) {
                this.userService.getUserProfile().then(response => {
                    this.displayName = response.displayName;
                    this.lastReviewDate = moment(response.lastReviewDate);
                    this.isInvestorProfileLocked = response.investorProfileLocked;
                    this.overdueDate = this.lastReviewDate.add(3, 'years');
                    if (this.showAnnualReviewOverdueDialog(this.overdueDate) && !this.isReviewPending) {
                        this.dialogService.show({
                            multiple: true,
                            controller: ['$window', '$mdDialog', 'overdueDate', '$state', AnnualReviewOverdueDialogController],
                            controllerAs: 'controller',
                            template: require('./annual-review-overdue-dialog/annual-review-overdue-dialog.template.html'),
                            parent: angular.element(document.body),
                            clickOutsideToClose: false,
                            locals: {overdueDate: this.overdueDate}
                        })
                    }
                    this.localStorageService.set('userDisplayName', response.displayName);
                    this.localStorageService.set('investorProfileLocked', response.investorProfileLocked);
                    this.localStorageService.set('answeredAllRiskQuestionsDate', response.answeredAllRiskQuestionsDate);
                });
            }
        }
    }
}

module.exports = HeaderController;
