class CreateProfileController {
    constructor($timeout, createProfileForm) {
        this.createProfileForm = createProfileForm;
        this.formData = {};
        this.object = Object;
        this.showPassword = false;
        this.showConfirmPassword = false;
        this.formData = {};
        this.timeout = $timeout;

        this.feedbackMessageData = {
            'username': {
                'feedbackTitle': 'registration.feedBackMessage.usernameAlreadyExists.title',
                'feedbackText': 'registration.feedBackMessage.usernameAlreadyExists.text',
                'feedbackIcon': 'error_circle'
            },
            'password': {
                'feedbackTitle': 'registration.feedBackMessage.passwordNotAccepted.title',
                'feedbackText': 'registration.feedBackMessage.passwordNotAccepted.text',
                'feedbackIcon': 'error_circle'
            },
            'system': {
                'feedbackTitle': 'registration.feedBackMessage.systemError.title',
                'feedbackText': 'registration.feedBackMessage.systemError.text',
                'feedbackIcon': 'error'
            }
        };
        this.requirements = [false, false, false, false, false];
        this.passwordContainsInvalidSpecialCharacter = false;
    }

    get feedbackMessageVisible() {
        return ['username', 'password', 'system'].includes(this.feedbackError);
    }

    validPasswordLength() {
        const password = this.createProfileForm.password.$modelValue;
        return password ? password.length >= 8 && password.length <= 32 : false;
    }

    containsUpperCase() {
        const password = this.createProfileForm.password.$modelValue;
        return password ? /[A-Z]/.test(password) : false;
    }

    containsLowerCase() {
        const password = this.createProfileForm.password.$modelValue;
        return password ? /[a-z]/.test(password) : false;
    }

    containsNumber() {
        const password = this.createProfileForm.password.$modelValue;
        return password ? /\d/.test(password) : false;
    }

    containsSpecialCharacter() {
        const password = this.createProfileForm.password.$modelValue;
        /* REGEX: Match any character within the set*/
        const whitelistSpecialCharactersRegex = /[~!@#$%^&*()_+\-=\[\]{}`;':"\\|,.<>\/?]/;

        return password ? whitelistSpecialCharactersRegex.test(password) && !this.containsInvalidSpecialCharacter() : false;
    }

    containsInvalidSpecialCharacter() {
        const password = this.createProfileForm.password.$modelValue;
        /* REGEX: Match any character that is not alphanumeric or one of the allowed special characters*/
        const blacklistDisallowedSpecialCharactersRegex = /[^a-zA-Z0-9~!@#$%^&*()_+\-=\[\]{}`;':"\\|,.<>\/?]/;

        this.passwordContainsInvalidSpecialCharacter = password ? blacklistDisallowedSpecialCharactersRegex.test(password) : false;
        return this.passwordContainsInvalidSpecialCharacter;
    }

    validatePassword() {
       this.requirements = [this.validPasswordLength(), this.containsUpperCase(), this.containsLowerCase(),
            this.containsNumber(), this.containsSpecialCharacter()];
        const isInvalidSpecialCharacter = this.containsInvalidSpecialCharacter();
        const requirementsMet = this.requirements.filter((value) => value).length;
        const isValidPassword = this.requirements[0] && requirementsMet >= 4 && !isInvalidSpecialCharacter;

        this.setPasswordNotAcceptedValidity(isValidPassword);
        this.timeout(() => {
            this.createProfileForm.confirmPassword.$validate();
        });
        return isValidPassword;
    }

    passwordMatch() {
        const password = this.createProfileForm.password.$modelValue;
        const confirmPassword = this.createProfileForm.confirmPassword.$modelValue;
        return confirmPassword && password === confirmPassword;
    }

    setPasswordNotAcceptedValidity(validity) {
        this.createProfileForm.password.$setValidity('passwordNotAcceptedError', validity);
    }

    setUsernameExistsValidity(validity) {
        this.createProfileForm.username.$setValidity('existingUsernameError', validity);
    }
}

module.exports = CreateProfileController;
