const Highcharts = require('highcharts/highcharts');
const GwsRegistrationDialogController = require('../gws-registration-dialog/gws-registration-dialog.controller');
const angular = require('angular');
require('highcharts/modules/no-data-to-display')(Highcharts);

const ATB_ORANGE = '#FF7F30';
const ATB_WHITE = '#FFFFFF';

class ProsperGoalController {
    constructor($mdDialog, $timeout, goalService) {
        this.timeout = $timeout;
        this.goalService = goalService;
        this.dialogService = $mdDialog;

        this.selected = {};
    }

    $onChanges() {
        if (this.selected.goal.goalType === 'PURCHASE') {
            this.timeout(() => {
                this.createChart().reflow();
            });
        }
        // TODO: Enable this when GWS OBS tool is ready
        // if (this.gwsModalRequired(this.selected)) {
        //     this.showGwsRegistrationDialog();
        // }
    }

    gwsModalRequired(selected) {
        return selected.goal.goalType === 'GROUP'
            && selected.isNative
            && !selected.isFunded
            && selected.goal.status === 'COMPLETED';
    }

    showGwsRegistrationDialog() {
        this.dialogService.show({
            multiple: true,
            controller: ['$window', '$mdDialog', GwsRegistrationDialogController],
            controllerAs: 'controller',
            template: require('../gws-registration-dialog/gws-registration-dialog.html'),
            parent: angular.element(document.body),
            targetEvent: undefined,
            clickOutsideToClose: true
        });
    }

    createChart() {
        let progress = this.goalService.getProgress(this.selected);
        let title = '<div layout="column" layout-align="center center" style="text-align:center; font-weight:bold; font-size:60px; color:' + ATB_WHITE + '">' + progress + '%' + '</div>';
        return new Highcharts.Chart({
            chart: {
                backgroundColor: null,
                renderTo: 'goalProgressGraph',
                type: 'pie'
            },
            title: {
                text: title,
                style: {
                    alignItems: 'center',
                    fontFamily: 'Sentinel, \'Sentinel A\', Georgia, serif'
                },
                useHTML: true,
                align: 'center',
                verticalAlign: 'middle',
                floating: true
            },
            plotOptions: {
                pie: {
                    shadow: false
                }
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b><br/>',
                valueSuffix: '%'
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Goal Progress',
                data: [
                    {name: 'Progress complete', y: progress, color: ATB_ORANGE},
                    {name: 'Progress remaining', y: 100 - progress, color: ATB_WHITE}
                ],
                size: '100%',
                innerSize: '80%',
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0
            }]
        });
    }
}

module.exports = ProsperGoalController;
