let linkFunction = function (scope, element, attrs, ctrl) {
    let SPECIAL_CHARACTOR_REGEX =/^(?!.*[!#$%^&*()\-+=,{}[\]|\\;:'"<>\/]).+$/i;
    let PERIOD_REGEX =/^(?!.*[.]{2}).+$/i;
    let WHITESPACE_REGEX =/^(?!.*\s).+$/i;
    
    
    ctrl.$validators.validPeriod = function (modelValue) {
        return modelValue && PERIOD_REGEX.test(modelValue);
    };
    ctrl.$validators.validWhitespace = function (modelValue) {
        return modelValue && WHITESPACE_REGEX.test(modelValue);
    };
    ctrl.$validators.validUsername = function (modelValue) {
        return modelValue && SPECIAL_CHARACTOR_REGEX.test(modelValue);
    };
};

let usernameValidatorDirective = function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkFunction
    }
};

module.exports = usernameValidatorDirective;
