const template = require('./horizontal-radio-group.html');
const controller = require('./horizontal-radio-group.controller');
require('./horizontal-radio-group.scss');

let horizontalRadioGroupComponent = {
    restrict: 'E',
    bindings: {
        options: '=',
        selected: '=',
        name: '@',
        required: '@',
        jointText: '<'
    },
    template,
    controller,
    controllerAs: 'horizontalRadioGroup'
};

module.exports = horizontalRadioGroupComponent;