const angular = require('angular');
const atbConstantsModule = require('../../components/atb-constants/atb-constants');
const regexValidatorDirective = require('./regex-validator.directive');

let regexValidatorModule = angular
    .module('regexValidator', [
        atbConstantsModule
    ])
    .directive('regexValidator', regexValidatorDirective)
    .name;

module.exports = regexValidatorModule;