require('./account-performance-modal.scss');

class AccountPerformanceModalController {
    constructor($filter, $translate, $mdDialog, date, selected) {
        this.filter = $filter;
        this.translate = $translate;
        this.dialogService = $mdDialog;
        this.date = date;
        this.selected = selected;
    }

    cancel() {
        this.dialogService.cancel();
    }
}

module.exports = AccountPerformanceModalController;
