const template = require('./password-confirmation.html');
const controller = require('./password-confirmation.controller');
require('./password-confirmation.scss');

let passwordConfirmationComponent = {
    restrict: 'E',
    bindings: {
        form:'=',
        passwordModel: '='
    },
    template,
    controller: ['$timeout', controller],
    controllerAs: 'passwordConfirmation'
};

module.exports = passwordConfirmationComponent;