const moment = require('moment');
const Address = require('../address-info/address-info.controller');

class AccountDetailsController {
    constructor(educationBeneficiaryRelationships,
                genders,
                monthOptions,
                provinces,
                $anchorScroll) {

        this.model = {};
        this.form = {};
        this.newAccount = {};
        this.oldAccount = {};

        this.educationBeneficiaryRelationships = educationBeneficiaryRelationships;
        this.genders = genders;
        this.monthOptions = monthOptions;
        this.dayOptions = [];
        this.yearOptions = Array.from({length: 100}, (v, i) => moment().year() - i);
        this.provinces = provinces;
        this.currentBeneficiaryIndex = 0;

        this.anchorScroll = $anchorScroll;
        this.anchorScroll.yOffset = 100;
    }

    $onInit() {
        this.setDaysInMonth();
        if (this.newAccount.beneficiaries.length === 0) {
            this.addBeneficiary();
        }
    }

    addBeneficiary() {
        let beneficiaryCount = this.newAccount.beneficiaries.length;
        this.maxBeneficiaries = (beneficiaryCount >= 6);
        if (!this.maxBeneficiaries) {
            let newRelationship = beneficiaryCount === 0 ? 'PARENT' : this.newAccount.beneficiaries[beneficiaryCount - 1].relationship;
            this.newAccount.beneficiaries.push({
                firstName: undefined,
                lastName: undefined,
                relationship: newRelationship,
                gender: undefined,
                maskedOrNewBeneficiarySin: undefined,
                birthDay: undefined,
                birthMonth: undefined,
                birthYear: undefined,
                copyAddressFromSubscriber: true,
                isGrantsApplicable: true,
                newBeneficiary: true
            });
            this.distributeAllocationEvenly();
            this.currentBeneficiaryIndex = beneficiaryCount;
        }
    }

    removeBeneficiary() {
        if (this.getBeneficiary(this.currentBeneficiaryIndex).newBeneficiary) {
            this.newAccount.beneficiaries.splice(this.currentBeneficiaryIndex, 1);
            this.currentBeneficiaryIndex = this.currentBeneficiaryIndex ? this.currentBeneficiaryIndex - 1 : 0;
            this.distributeAllocationEvenly();
            this.maxBeneficiaries = false;
            if (this.newAccount.beneficiaries.length === 0) {
                this.addBeneficiary();
            }
            if (this.newAccount.beneficiaries.length <= 1) {
                this.newAccount.siblingOnlyPlan = true;
            }

            this.revertAllocation();
        }
    }

    distributeAllocationEvenly() {
        let beneficiaryList = this.newAccount.beneficiaries;
        const allocationPercent = (100 / beneficiaryList.length).toFixed(4);
        const allocationPercentLast = (100 - ((beneficiaryList.length - 1) * allocationPercent)).toFixed(4);
        beneficiaryList.forEach((beneficiary, index) => {
            if (index === beneficiaryList.length - 1) {
                beneficiary.allocation = allocationPercentLast;
            }
            else {
                beneficiary.allocation = allocationPercent;
            }
        });
    }

    revertAllocation() {
        if (this.newAccount.beneficiaries.length === this.oldAccount.beneficiaries.length) {
            this.newAccount.beneficiaries.forEach((beneficiary, index) => {
                beneficiary.allocation = this.oldAccount.beneficiaries[index].allocation;
            });
        }
    }

    getBeneficiary(index) {
        return this.newAccount.beneficiaries[index];
    }

    nextBeneficiary() {
        this.currentBeneficiaryIndex++;
        this.anchorScroll('beneficiary-information');
    }

    prevBeneficiary() {
        this.currentBeneficiaryIndex--;
        this.anchorScroll('beneficiary-information');
    }

    get addressLabel() {
        return Address.formatAddress(this.provinces, this.model.primaryAddress);
    }

    get beneficiaryBirthDate() {
        const currentBeneficiary = this.getBeneficiary(this.currentBeneficiaryIndex);
        return moment(`${currentBeneficiary.birthYear}-${currentBeneficiary.birthMonth}-${currentBeneficiary.birthDay}`, 'YYYY-MM-D').format('MMMM D, YYYY');
    }

    setDaysInMonth() {
        this.dayOptions = []
        const birthYear = this.getBeneficiary(this.currentBeneficiaryIndex).birthYear ? this.getBeneficiary(this.currentBeneficiaryIndex).birthYear : moment().year()
        const birthMonth = this.getBeneficiary(this.currentBeneficiaryIndex).birthMonth ? this.getBeneficiary(this.currentBeneficiaryIndex).birthMonth : moment().month();
        const maxDays = moment(`${birthYear} - ${birthMonth}`, 'YYYY-MM').daysInMonth()
        for (let day = 1; day <= maxDays; day++) {
            this.dayOptions.push(day);
        }
    }
}

module.exports = AccountDetailsController;