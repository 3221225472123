require('./email-confirmation.scss');
class EmailConfirmationController {
    constructor($mdDialog) {
        this.mdDialog = $mdDialog;
    }

    cancel() {
        this.mdDialog.cancel();
    }
}

module.exports = EmailConfirmationController;
