class BeneficiaryInformationController {
    constructor(beneficiaryRelationships) {
        this.beneficiaryRelationships = beneficiaryRelationships;
    }

    addBeneficiaryRow() {
        this.maxBeneficiaries = (this.formData.beneficiaryList.length === 3);
        if (!this.maxBeneficiaries) {
            this.formData.beneficiaryList.push({
                firstName: undefined,
                lastName: undefined,
                relationship: undefined,
                allocation: undefined
            });
        }
    }

    removeBeneficiaryRow(index) {
        this.formData.beneficiaryList.splice(index, 1);
        this.maxBeneficiaries = false;
        this.updateAllocationTotal();
        this.setTaxImplicationIndicator();
        this.checkDuplicateName();
        this.checkSpouseRelationshipCount();
    }

    setTaxImplicationIndicator() {
        let hasSpouseBeneficiary = false;
        for (let i = 0; i < this.formData.beneficiaryList.length; i++) {
            if (this.formData.beneficiaryList[i].relationship === 'SPOUSE') {
                hasSpouseBeneficiary = true;
                break;
            }
        }
        this.hasTaxImplications = this.formData.beneficiaryList.length > 1 && hasSpouseBeneficiary && this.accountType === 'TFSA';
    }

    getInputName(inputName, index) {
        return 'beneficiary' + inputName + index;
    }

    updateAllocationTotal() {
        let beneficiaries = this.formData.beneficiaryList;
        const totalAllocation = beneficiaries.reduce((sum, item) => {
            return sum + parseInt(item.allocation)
        }, 0);
        this.isFullyAllocated = totalAllocation === 100;

        let allValidMinimums = true;
        for (let i = 0; i < beneficiaries.length; i++) {
            const allocationInputField = this.openAccountForm[this.getInputName('Allocation', i)];
            allocationInputField.$setValidity('invalidAllocationTotal', this.isFullyAllocated);

            let hasMinimumAllocation = parseInt(beneficiaries[i].allocation) > 0;
            allocationInputField.$setValidity('invalidAllocationPercent', hasMinimumAllocation);

            allValidMinimums = allValidMinimums && hasMinimumAllocation;
        }
        this.hasValidAllocationMinimums = allValidMinimums;
    }

    toggleEstateBeneficiary() {
        this.formData.estateBeneficiary = !this.formData.estateBeneficiary;
        this.formData.beneficiaryList = [];
        this.addBeneficiaryRow();
    }

    checkForSpouseBeneficiary(beneficiary) {
        if (beneficiary.relationship !== 'SPOUSE') {
            return;
        }

        if (this.personalInformation.maritalStatus === 'MARRIED'
            || this.personalInformation.maritalStatus === 'COMMONLAW'
            || this.personalInformation.maritalStatus === 'SEPARATED') {
            beneficiary.firstName = angular.copy(this.spousalInformation.firstName);
            beneficiary.lastName = angular.copy(this.spousalInformation.lastName);
        }
        this.checkDuplicateName();
    }

    checkDuplicateName() {
        let beneficiaries = this.formData.beneficiaryList;
        let names = new Set(beneficiaries.map(it => `${it.firstName}${it.lastName}`));
        this.hasDuplicateName = names.size !== beneficiaries.length;
        for (let i = 0; i < beneficiaries.length; i++) {
            const firstNameInputField = this.openAccountForm[this.getInputName('FirstName', i)];
            firstNameInputField.$setValidity('invalidName', !this.hasDuplicateName);
            const lastNameInputField = this.openAccountForm[this.getInputName('LastName', i)];
            lastNameInputField.$setValidity('invalidName', !this.hasDuplicateName);
        }
    }

    checkSpouseRelationshipCount() {
        let beneficiaries = this.formData.beneficiaryList;
        let spouseBeneficiary = (beneficiaries.filter(it => it.relationship === 'SPOUSE'));
        this.hasMoreThanOneSpouseBeneficiary = spouseBeneficiary.length > 1;
        for (let i = 0; i < beneficiaries.length; i++) {
            const relationshipInputField = this.openAccountForm[this.getInputName('Relationship', i)];
            relationshipInputField.$setValidity('invalidRelationship', !this.hasMoreThanOneSpouseBeneficiary);
        }
    }

    $onInit() {
        this.isFullyAllocated = true;
        this.hasValidAllocationMinimums = true;
        this.maxBeneficiaries = false;
        this.estateBeneficiary = false;
        this.hasTaxImplications = false;
        this.hasDuplicateName = false;
        this.hasMoreThanOneSpouseBeneficiary = false;
    }

    $onChanges(changes) {
        if (this.formData && this.formData.beneficiaryList.length === 0) {
            this.addBeneficiaryRow();
        }

        if (changes.accountType && changes.accountType.currentValue !== changes.accountType.previousValue) {
            this.setTaxImplicationIndicator();
        }
    }
}

module.exports = BeneficiaryInformationController;
