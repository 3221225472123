'strict';

//VENDOR MODULES
const angular = require('angular');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');
const ocLazyLoad = require('oclazyload');

//NON-VENDOR MODULES
const footerModule = require('../../components/footer/footer');
const routes = require('./forgot-password.routes.js');
const googleCaptchaModule = require('../../components/google-captcha/google-captcha');
const headerModule = require('../../components/header/header');
const requestEmailModule = require('./components/request-email/request-email');
// const answerSecurityQuestionsModule = require('./components/answer-security-questions/answer-security-questions');
const userService = require('../../services/user-service/user-service');

let forgotPasswordModule = angular
    .module('app.forgot-password', [
        angularTranslate,
        // answerSecurityQuestionsModule,
        footerModule,
        googleCaptchaModule,
        headerModule,
        requestEmailModule,
        ocLazyLoad,
        userService
    ])
    .config(['$stateProvider', routes])
    .name;

module.exports = forgotPasswordModule;
