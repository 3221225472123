const angular = require('angular');

class InvestmentExperienceController {
    constructor($translate, investmentExperienceList) {
        this.translate = $translate;
        this.investmentExperienceList = investmentExperienceList;
    }

    $onInit() {
        this.openAccount.openAccountModel.investmentExperiences =
            this.openAccount.openAccountModel.investmentExperiences || [];
    }

    toggle(item) {
        if (this.isSelected(item)) {
            this.unselect(item);
        } else {
            this.select(item);
        }
    }

    isSelected(item) {
        return this.openAccount.openAccountModel.investmentExperiences.includes(item);
    }

    select(item) {
        if (item === 'NONE') {
            this.openAccount.openAccountModel.investmentExperiences = ['NONE'];
        } else {
            // tl;dr Need to force a new array reference such that Form Bound Element refresh validation.
            this.openAccount.openAccountModel.investmentExperiences =
                [...this.openAccount.openAccountModel.investmentExperiences, item];
            if (this.isSelected('NONE')) {
                this.openAccount.openAccountModel.investmentExperiences.splice(
                    this.openAccount.openAccountModel.investmentExperiences.indexOf('NONE'), 1
                );
            }
        }
    }

    unselect(item) {
        this.openAccount.openAccountModel.investmentExperiences.splice(
            this.openAccount.openAccountModel.investmentExperiences.indexOf(item), 1
        ).slice(0);
        // tl;dr Need to force a new array reference such that Form Bound Element refresh validation.
        this.openAccount.openAccountModel.investmentExperiences =
            [...this.openAccount.openAccountModel.investmentExperiences];
    }
}

module.exports = InvestmentExperienceController;