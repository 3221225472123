//VENDOR MODULES
const angular = require('angular');
const angularTranslate = require('angular-translate');

//NON-VENDOR MODULES
const accountTypeComponent = require('./account-type.component');
const accountTypeInfoComponent = require('../account-type-info/account-type-info');
require ('./account-type.scss');

let accountTypeModule = angular
    .module('account-type', [
        accountTypeInfoComponent,
        angularTranslate
    ])
    .component('accountType', accountTypeComponent)
    .name;

module.exports = accountTypeModule;