function config($urlRouterProvider, $translateProvider, $httpProvider, $locationProvider, localStorageServiceProvider, angularAuth0Provider, $mdGestureProvider, jwtOptionsProvider) {
    $translateProvider.useSanitizeValueStrategy(null);
    $translateProvider.useLoader('$translatePartialLoader', {
        urlTemplate: '/locale/{lang}/{part}.json'
    });
    $translateProvider.preferredLanguage('en-ca');

    $locationProvider.hashPrefix('');
    $locationProvider.html5Mode(true);

    localStorageServiceProvider.setStorageType('sessionStorage');

    $urlRouterProvider.otherwise((injector, location) => {
        if (location.url() !== '/') {
            location.url('/error/404');
        } else if (location.url() === '/' && !location.$$state) {
            window.location.href = '/';
        }
    });

    //Configure Auth0
    angularAuth0Provider.init({
        clientID: window.applicationConfiguration.auth0.clientID,
        domain: window.applicationConfiguration.auth0.domain,
        redirectUri: window.location.origin + '/redirect'
    });

    window.webAuth = new auth0.WebAuth({
        domain: window.applicationConfiguration.auth0.domain,
        clientID: window.applicationConfiguration.auth0.clientID
    });

    jwtOptionsProvider.config({
        //make sure to white list the DOMAINS!!!
        whiteListedDomains: ['localhost'],
        tokenGetter: ['localStorageService', function (localStorageService) {
            return localStorageService.get('authToken');
        }]
    });

    $httpProvider.interceptors.push('jwtInterceptor', 'ATBInterceptor');
    $mdGestureProvider.skipClickHijack();
}

module.exports = config;
