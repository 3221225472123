const template = require('./employment-information.html');
const controller = require('./employment-information.controller.js');
require('./employment-information.scss');

let employmentInformationComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        formData: "=",
        employmentStatus: "<",
        atbEmploymentStatus: "<",
        accountType: "<",
        isJointAccount: "<",
        regulatoryQuestions: "=",
        isSpouse: "<",
        isGroupGoal: "<"
    },
    template,
    controller: ['employmentStatuses', 'atbEmploymentStatuses', 'industryList', controller],
    controllerAs: 'employmentInformation'
};

module.exports = employmentInformationComponent;
