const angular = require('angular');
const citizenTaxResidencyComponent = require('./citizenship-tax-residency.component');
const toolTipComponent = require('../../../components/tool-tip/tool-tip');
const tinValidatorModule = require('../../../directives/tin-validator/tin-validator')

module.exports = angular
    .module('kyc.citizenship-tax-residency', [
        toolTipComponent,
        tinValidatorModule
    ])
    .component('citizenTaxResidency', citizenTaxResidencyComponent)
    .name;