'strict';

//VENDOR MODULES
const angular = require('angular');
const angularJWT = require('angular-jwt');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');
const ocLazyLoad = require('oclazyload');

//NON-VENDOR MODULES
const footerModule = require('../../components/login-footer/login-footer');
const routes = require('./forgot-password.routes.js');
const googleCaptchaModule = require('../../components/google-captcha/google-captcha');
const headerModule = require('../../components/header/header');
const requestEmailModule = require('./components/request-email/request-email');
const passwordConfirmationModule = require('../../components/password-confirmation-v2/password-confirmation');
const userService = require('../../services/user-service/user-service');

let forgotPasswordModuleV2 = angular
    .module('app.forgot-password-v2', [
        angularTranslate,
        angularJWT,
        footerModule,
        googleCaptchaModule,
        headerModule,
        requestEmailModule,
        ocLazyLoad,
        userService,
        passwordConfirmationModule
    ])
    .config(['$stateProvider', routes])
    .name;

module.exports = forgotPasswordModuleV2;
