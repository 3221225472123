const template = require('./net-worth.html');
const controller = require('./net-worth.controller');
require('./net-worth.scss');

let netWorthComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        formData: "="
    },
    template,
    controller: ['assetTypes', 'liabilityTypes', controller],
    controllerAs: 'netWorth'
};

module.exports = netWorthComponent;