const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const investmentTimeQuestionComponent = require('./investment-time-question.component.js');
const minimumValueModule = require('../../../../directives/minimum-value/minimum-value');
const maximumValueModule = require('../../../../directives/maximum-value/maximum-value');
const regexValidatorModule = require('../../../../directives/regex-validator/regex-validator');

let investmentTimeQuestionModule = angular
    .module('investmentTimeQuestion', [
        angularSanitize,
        minimumValueModule,
        maximumValueModule,
        regexValidatorModule
    ])
    .component('investmentTimeQuestion', investmentTimeQuestionComponent)
    .name;

module.exports = investmentTimeQuestionModule;