const educationBeneficiariesTemplate = require('./education-beneficiaries.html');
require('./education-beneficiaries.scss');

function routes($stateProvider) {
    $stateProvider
        .state('education-beneficiaries', {
            url: '/education-beneficiaries',
            parent: 'site',
            views: {
                'content@': {
                    template: educationBeneficiariesTemplate,
                    controller: 'EducationBeneficiariesController',
                    controllerAs: 'educationBeneficiaries'
                }
            },
            resolve: {
                data: ['$state', 'goalService', ($state, goalService) => {
                    return goalService.verifyValidGoalNavigation('EDUCATIONBENEFICIARIES')
                        .catch(err => $state.go('prosper-dashboard'));
                }],
                educationBeneficiariesScreenTranslations: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('open-account');
                    $translate.refresh();
                    return true;
                }],
                EducationBeneficiariesController: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "resp-beneficiaries" */ './education-beneficiaries.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        });
}

module.exports = routes;
