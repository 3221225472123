//VENDOR MODULES
const angular = require('angular');

let atbConstantsModule = angular
    .module('app.atb-constants', [])
    .constant('googleCaptchConfig', {
        key: '6LdUYSQTAAAAACRmJW5ewq4ti8DZSdk-f2dfqT-8'
    })
    .constant('contributionFrequencies', [
        {
            key: 'WEEKLY',
            number: 52
        },
        {
            key: 'BIWEEKLY',
            number: 26
        },
        {
            key: 'SEMIMONTHLY',
            number: 24
        },
        {
            key: 'MONTHLY',
            number: 12
        },
        {
            key: 'QUARTERLY',
            number: 4
        }

    ])
    .constant('pacFrequencies', [
        {
            key: 'LDMONTHLY',
            number: 12
        },
        {
            key: 'ANNUALLY',
            number: 1
        },
        {
            key: 'SEMIANNUAL',
            number: 2
        }
    ])
    .constant('gwsContributionFrequencies', [
        {
            key: 'DAILY',
            number: 365
        },
        {
            key: 'WEEKLY',
            number: 52
        },
        {
            key: 'BIWEEKLY',
            number: 26
        },
        {
            key: 'SEMIMONTHLY',
            number: 24
        },
        {
            key: 'MONTHLY',
            number: 12
        },
        {
            key: 'BIMONTHLY',
            number: 6
        },
        {
            key: 'QUARTERLY',
            number: 4
        },
        {
            key: 'SEMIANNUAL',
            number: 2
        },
        {
            key: 'ANNUALLY',
            number: 1
        }
    ])
    .constant('monthOptions', [
        {
            text: 'January',
            value: 1
        },
        {
            text: 'February',
            value: 2
        },
        {
            text: 'March',
            value: 3
        },
        {
            text: 'April',
            value: 4
        },
        {
            text: 'May',
            value: 5
        },
        {
            text: 'June',
            value: 6
        },
        {
            text: 'July',
            value: 7
        },
        {
            text: 'August',
            value: 8
        },
        {
            text: 'September',
            value: 9
        },
        {
            text: 'October',
            value: 10
        },
        {
            text: 'November',
            value: 11
        },
        {
            text: 'December',
            value: 12
        }
    ])
    .constant('pages', [
        {name: 'LANDINGPAGE', page: 'landing-page'},
        {name: 'ERROR', page: 'error'},
        {name: 'FORGOTPASSWORD', page: 'forgot-password'},
        {name: 'FORGOTPASSWORD_V2', page: 'v2/forgot-password'},
        {name: 'STARTINVESTING', page: 'start-investing'},
        {name: 'RECOMMENDATION', page: 'recommendation'},
        {name: 'REGISTRATION', page: 'registration'},
        {name: 'OPENACCOUNT', page: 'open-account', order: 1},
        {name: 'EDUCATIONBENEFICIARIES', page: 'education-beneficiaries', order: 2},
        {name: 'FUNDACCOUNT', page: 'fund-account', order: 3},
        {name: 'IDVERIFICATION', page: 'id-verification', order: 4},
        {name: 'DOCUSIGN', page: 'e-sign', order: 5},
        {name: 'DASHBOARD', page: 'prosper-dashboard'},
        {name: 'JOINT_DOCUSIGN', page: 'e-sign', order: 5},
        {name: 'SUGGESTEDFUND', page: 'suggested-fund'}
    ])
    .constant('provinces', [
        {'key': 'AB', 'value': 'Alberta', 'enum': 'Alberta'},
        {'key': 'BC', 'value': 'British Columbia', 'enum': 'BritishColumbia'},
        {'key': 'MB', 'value': 'Manitoba', 'enum': 'Manitoba'},
        {'key': 'NB', 'value': 'New Brunswick', 'enum': 'NewBrunswick'},
        {'key': 'NL', 'value': 'Newfoundland and Labrador', 'enum': 'NewfoundlandAndLabrador'},
        {'key': 'NS', 'value': 'Nova Scotia', 'enum': 'NovaScotia'},
        {'key': 'ON', 'value': 'Ontario', 'enum': 'Ontario'},
        {'key': 'PE', 'value': 'Prince Edward Island', 'enum': 'PrinceEdwardIsland'},
        {'key': 'QC', 'value': 'Québec', 'enum': 'Quebec'},
        {'key': 'SK', 'value': 'Saskatchewan', 'enum': 'Saskatchewan'},
        {'key': 'NT', 'value': 'Northwest Territories', 'enum': 'NorthwestTerritories'},
        {'key': 'NU', 'value': 'Nunavut', 'enum': 'Nunavut'},
        {'key': 'YT', 'value': 'Yukon', 'enum': 'Yukon'}
    ])
    .constant('prosperAllowedProvinces', [
        {'key': 'AB', 'value': 'Alberta', 'enum': 'Alberta'},
        {'key': 'MB', 'value': 'Manitoba', 'enum': 'Manitoba'},
        {'key': 'BC', 'value': 'British Columbia', 'enum': 'BritishColumbia'},
        {'key': 'SK', 'value': 'Saskatchewan', 'enum': 'Saskatchewan'}
    ])
    .constant('cities', {
        'QC': [
            'Acton Vale',
            'Alma',
            'Amos',
            'Amqui',
            'Baie-Comeau',
            'Baie-D\'Urfé',
            'Baie-Saint-Paul',
            'Barkmere',
            'Beaconsfield',
            'Beauceville',
            'Beauharnois',
            'Beaupré',
            'Bécancour',
            'Bedford',
            'Belleterre',
            'Beloeil',
            'Berthierville',
            'Blainville',
            'Boisbriand',
            'Bois-des-Filion',
            'Bonaventure',
            'Boucherville',
            'Lac-Brome',
            'Bromont',
            'Brossard',
            'Brownsburg-Chatham',
            'Candiac',
            'Cap-Chat',
            'Cap-Santé',
            'Carignan',
            'Carleton-sur-Mer',
            'Causapscal',
            'Chambly',
            'Chandler',
            'Chapais',
            'Charlemagne',
            'Châteauguay',
            'Château-Richer',
            'Chibougamau',
            'Clermont',
            'Coaticook',
            'Contrecoeur',
            'Cookshire-Eaton',
            'Côte Saint-Luc',
            'Coteau-du-Lac',
            'Cowansville',
            'Danville',
            'Daveluyville',
            'Dégelis',
            'Delson',
            'Desbiens',
            'Deux-Montagnes',
            'Disraeli',
            'Dolbeau-Mistassini',
            'Dollard-des-Ormeaux',
            'Donnacona',
            'Dorval',
            'Drummondville',
            'Dunham',
            'Duparquet',
            'East Angus',
            'Estérel',
            'Farnham',
            'Fermont',
            'Forestville',
            'Fossambault-sur-le-Lac',
            'Gaspé',
            'Gatineau',
            'Gracefield',
            'Granby',
            'Grande-Rivière',
            'Hampstead',
            'Hudson',
            'Huntingdon',
            'Joliette',
            'Kingsey Falls',
            'Kirkland',
            'La Malbaie',
            'La Pocatière',
            'La Prairie',
            'La Sarre',
            'La Tuque',
            'Lac-Delage',
            'Lachute',
            'Lac-Mégantic',
            'Lac-Saint-Joseph',
            'Lac-Sergent',
            'L\'Ancienne-Lorette',
            'L\'Assomption',
            'Laval',
            'Lavaltrie',
            'Lebel-sur-Quévillon',
            'L\'Épiphanie',
            'Léry',
            'Lévis',
            'L\'Île-Cadieux',
            'L\'Île-Dorval',
            'L\'Île-Perrot',
            'Longueuil',
            'Lorraine',
            'Louiseville',
            'Macamic',
            'Magog',
            'Malartic',
            'Maniwaki',
            'Marieville',
            'Mascouche',
            'Matagami',
            'Matane',
            'Mercier',
            'Métabetchouan–Lac-à-la-Croix',
            'Métis-sur-Mer',
            'Mirabel',
            'Mont-Joli',
            'Mont-Laurier',
            'Montmagny',
            'Montreal',
            'Montreal West',
            'Montréal-Est',
            'Mont-Saint-Hilaire',
            'Mont-Tremblant',
            'Mount Royal',
            'Murdochville',
            'Neuville',
            'New Richmond',
            'Nicolet',
            'Normandin',
            'Notre-Dame-de-l\'Île-Perrot',
            'Notre-Dame-des-Prairies',
            'Otterburn Park',
            'Paspébiac',
            'Percé',
            'Pincourt',
            'Plessisville',
            'Pohénégamook',
            'Pointe-Claire',
            'Pont-Rouge',
            'Port-Cartier',
            'Portneuf',
            'Prévost',
            'Princeville',
            'Québec',
            'Repentigny',
            'Richelieu',
            'Richmond',
            'Rigaud',
            'Rimouski',
            'Rivière-du-Loup',
            'Rivière-Rouge',
            'Roberval',
            'Rosemère',
            'Rouyn-Noranda',
            'Saguenay',
            'Saint-Augustin-de-Desmaures',
            'Saint-Basile',
            'Saint-Basile-le-Grand',
            'Saint-Bruno-de-Montarville',
            'Saint-Césaire',
            'Saint-Charles-Borromée',
            'Saint-Colomban',
            'Saint-Constant',
            'Sainte-Adèle',
            'Sainte-Agathe-des-Monts',
            'Sainte-Anne-de-Beaupré',
            'Sainte-Anne-de-Bellevue',
            'Sainte-Anne-des-Monts',
            'Sainte-Anne-des-Plaines',
            'Sainte-Catherine',
            'Sainte-Catherine-de-la-Jacques-Cartier',
            'Sainte-Julie',
            'Sainte-Marguerite-du-Lac-Masson',
            'Sainte-Marie',
            'Sainte-Marthe-sur-le-Lac',
            'Sainte-Thérèse',
            'Saint-Eustache',
            'Saint-Félicien',
            'Saint-Gabriel',
            'Saint-Georges',
            'Saint-Hyacinthe',
            'Saint-Jean-sur-Richelieu',
            'Saint-Jérôme',
            'Saint-Joseph-de-Beauce',
            'Saint-Joseph-de-Sorel',
            'Saint-Lambert',
            'Saint-Lazare',
            'Saint-Lin–Laurentides',
            'Saint-Marc-des-Carrières',
            'Saint-Ours',
            'Saint-Pamphile',
            'Saint-Pascal',
            'Saint-Pie',
            'Saint-Raymond',
            'Saint-Rémi',
            'Saint-Sauveur',
            'Saint-Tite',
            'Salaberry-de-Valleyfield',
            'Schefferville',
            'Scotstown',
            'Senneterre',
            'Sept-Îles',
            'Shawinigan',
            'Sherbrooke',
            'Sorel-Tracy',
            'Stanstead',
            'Sutton',
            'Témiscaming',
            'Témiscouata-sur-le-Lac',
            'Terrebonne',
            'Thetford Mines',
            'Thurso',
            'Trois-Pistoles',
            'Trois-Rivières',
            'Valcourt',
            'Val-d\'Or',
            'Val-des-Sources',
            'Varennes',
            'Vaudreuil-Dorion',
            'Victoriaville',
            'Ville-Marie',
            'Warwick',
            'Waterloo',
            'Waterville',
            'Westmount',
            'Windsor'
        ],
        'SK': [
            'Estevan',
            'Flin Flon',
            'Humboldt',
            'Lloydminster',
            'Wilton',
            'Martensville',
            'Meadow Lake',
            'Melfort',
            'Melville',
            'Moose Jaw',
            'North Battleford',
            'Prince Albert',
            'Regina',
            'Saskatoon',
            'Swift Current',
            'Warman',
            'Weyburn',
            'Yorkton'
        ],
        'YT': [
            'Whitehorse'
        ],
        'PE': [
            'Charlottetown',
            'Summerside'
        ],
        'ON': [
            'Barrie',
            'Belleville',
            'Brampton',
            'Brant',
            'Brantford',
            'Brockville',
            'Burlington',
            'Cambridge',
            'Clarence-Rockland',
            'Cornwall',
            'Dryden',
            'Elliot Lake',
            'Greater Sudbury',
            'Guelph',
            'Haldimand County',
            'Hamilton',
            'Kawartha Lakes',
            'Kenora',
            'Kingston',
            'Kitchener',
            'London',
            'Markham',
            'Mississauga',
            'Niagara Falls',
            'Norfolk County',
            'North Bay',
            'Orillia',
            'Oshawa',
            'Ottawa',
            'Owen Sound',
            'Pembroke',
            'Peterborough',
            'Pickering',
            'Port Colborne',
            'Prince Edward County',
            'Quinte West',
            'Richmond Hill',
            'Sarnia',
            'Sault Ste. Marie',
            'St. Catharines',
            'St. Thomas',
            'Stratford',
            'Temiskaming Shores',
            'Thorold',
            'Thunder Bay',
            'Timmins',
            'Toronto',
            'Vaughan',
            'Waterloo',
            'Welland',
            'Windsor',
            'Woodstock'
        ],
        'BC': [
            'Abbotsford',
            'Armstrong',
            'Burnaby',
            'Campbell River',
            'Castlegar',
            'Chilliwack',
            'Colwood',
            'Coquitlam',
            'Courtenay',
            'Cranbrook',
            'Dawson Creek',
            'Delta',
            'Duncan',
            'Enderby',
            'Fernie',
            'Fort St. John',
            'Grand Forks',
            'Greenwood',
            'Kamloops',
            'Kelowna',
            'Kimberley',
            'Langford',
            'Langley',
            'Maple Ridge',
            'Merritt',
            'Mission',
            'Nanaimo',
            'Nelson',
            'New Westminster',
            'North Vancouver',
            'Parksville',
            'Penticton',
            'Pitt Meadows',
            'Port Alberni',
            'Port Coquitlam',
            'Port Moody',
            'Powell River',
            'Prince George',
            'Prince Rupert',
            'Quesnel',
            'Revelstoke',
            'Richmond',
            'Rossland',
            'Salmon Arm',
            'Surrey',
            'Terrace',
            'Trail',
            'Vancouver',
            'Vernon',
            'Victoria',
            'West Kelowna',
            'White Rock',
            'Williams Lake'
        ],
        'MB': [
            'Brandon',
            'Dauphin',
            'Flin Flon',
            'Morden',
            'Portage la Prairie',
            'Selkirk',
            'Steinbach',
            'Thompson',
            'Winkler',
            'Winnipeg'
        ],
        'NB': [
            'Bathurst',
            'Campbellton',
            'Dieppe',
            'Edmundston',
            'Fredericton',
            'Miramichi',
            'Moncton',
            'Saint John'
        ],
        'NL': [
            'Corner Brook',
            'Mount Pearl',
            'St. John\'s'
        ],
        'NT': ['Yellowknife'],
        'NU': ['Iqaluit'],
        'AB': [
            'Airdrie',
            'Beaumont',
            'Brooks',
            'Calgary',
            'Camrose',
            'Chestermere',
            'Cold Lake',
            'Edmonton',
            'Fort Saskatchewan',
            'Grande Prairie',
            'Lacombe',
            'Leduc',
            'Lethbridge',
            'Lloydminster',
            'Medicine Hat',
            'Red Deer',
            'Spruce Grove',
            'St. Albert',
            'Wetaskiwin'
        ]
    })

    .constant('states', [
        {'key': 'AL', 'value': 'Alabama'},
        {'key': 'AK', 'value': 'Alaska'},
        {'key': 'AS', 'value': 'American Samoa'},
        {'key': 'AZ', 'value': 'Arizona'},
        {'key': 'AR', 'value': 'Arkansas'},
        {'key': 'CA', 'value': 'California'},
        {'key': 'CO', 'value': 'Colorado'},
        {'key': 'CT', 'value': 'Connecticut'},
        {'key': 'DE', 'value': 'Delaware'},
        {'key': 'DC', 'value': 'District of Columbia'},
        {'key': 'FL', 'value': 'Florida'},
        {'key': 'GA', 'value': 'Georgia'},
        {'key': 'GU', 'value': 'Guam'},
        {'key': 'HI', 'value': 'Hawaii'},
        {'key': 'ID', 'value': 'Idaho'},
        {'key': 'IL', 'value': 'Illinois'},
        {'key': 'IN', 'value': 'Indiana'},
        {'key': 'IA', 'value': 'Iowa'},
        {'key': 'KS', 'value': 'Kansas'},
        {'key': 'KY', 'value': 'Kentucky'},
        {'key': 'LA', 'value': 'Louisiana'},
        {'key': 'ME', 'value': 'Maine'},
        {'key': 'MD', 'value': 'Maryland'},
        {'key': 'MA', 'value': 'Massachusetts'},
        {'key': 'MI', 'value': 'Michigan'},
        {'key': 'MN', 'value': 'Minnesota'},
        {'key': 'MS', 'value': 'Mississippi'},
        {'key': 'MO', 'value': 'Missouri'},
        {'key': 'MT', 'value': 'Montana'},
        {'key': 'NE', 'value': 'Nebraska'},
        {'key': 'NV', 'value': 'Nevada'},
        {'key': 'NH', 'value': 'New Hampshire'},
        {'key': 'NJ', 'value': 'New Jersey'},
        {'key': 'NM', 'value': 'New Mexico'},
        {'key': 'NY', 'value': 'New York'},
        {'key': 'NC', 'value': 'North Carolina'},
        {'key': 'ND', 'value': 'North Dakota'},
        {'key': 'MP', 'value': 'Northern Mariana Islands'},
        {'key': 'OH', 'value': 'Ohio'},
        {'key': 'OK', 'value': 'Oklahoma'},
        {'key': 'OR', 'value': 'Oregon'},
        {'key': 'PA', 'value': 'Pennsylvania'},
        {'key': 'PR', 'value': 'Puerto Rico'},
        {'key': 'RI', 'value': 'Rhode Island'},
        {'key': 'SC', 'value': 'South Carolina'},
        {'key': 'SD', 'value': 'South Dakota'},
        {'key': 'TN', 'value': 'Tennessee'},
        {'key': 'TX', 'value': 'Texas'},
        {'key': 'UM', 'value': 'U.S. Minor Outlying Islands'},
        {'key': 'VI', 'value': 'U.S. Virgin Islands'},
        {'key': 'UT', 'value': 'Utah'},
        {'key': 'VT', 'value': 'Vermont'},
        {'key': 'VA', 'value': 'Virginia'},
        {'key': 'WA', 'value': 'Washington'},
        {'key': 'WV', 'value': 'West Virginia'},
        {'key': 'WI', 'value': 'Wisconsin'},
        {'key': 'WY', 'value': 'Wyoming'}
    ])

    .constant('countries', [
        {'key': 'CAN', 'value': 'Canada', 'displayOrder': 0},
        {'key': 'USA', 'altKey': 'Usa', 'value': 'USA', 'displayOrder': 5},
        {'key': 'AFG', 'value': 'Afghanistan', 'displayOrder': 10},
        {'key': 'ALA', 'altKey': 'AlandIslands', 'value': 'Aland Islands', 'displayOrder': 11},
        {'key': 'ALB', 'value': 'Albania', 'displayOrder': 15},
        {'key': 'DZA', 'value': 'Algeria', 'displayOrder': 16},
        {'key': 'ASM', 'altKey': 'AmericanSamoa', 'value': 'American Samoa', 'displayOrder': 17},
        {'key': 'AND', 'value': 'Andorra', 'displayOrder': 18},
        {'key': 'AGO', 'value': 'Angola', 'displayOrder': 19},
        {'key': 'AIA', 'value': 'Anguilla', 'displayOrder': 20},
        {'key': 'ATA', 'value': 'Antarctica', 'displayOrder': 21},
        {'key': 'ATC', 'altKey': 'AntiguaAndBarbuda', 'value': 'Antigua and Barbuda', 'displayOrder': 25},
        {'key': 'ARG', 'value': 'Argentina', 'displayOrder': 30},
        {'key': 'ARM', 'value': 'Armenia', 'displayOrder': 31},
        {'key': 'ABW', 'value': 'Aruba', 'displayOrder': 35},
        {'key': 'AUS', 'value': 'Australia', 'displayOrder': 40},
        {'key': 'AUT', 'value': 'Austria', 'displayOrder': 45},
        {'key': 'AZE', 'value': 'Azerbaijan', 'displayOrder': 46},
        {'key': 'BHS', 'value': 'Bahamas', 'displayOrder': 50},
        {'key': 'BHR', 'value': 'Bahrain', 'displayOrder': 55},
        {'key': 'BGD', 'value': 'Bangladesh', 'displayOrder': 60},
        {'key': 'BRB', 'value': 'Barbados', 'displayOrder': 65},
        {'key': 'BLR', 'value': 'Belarus', 'displayOrder': 66},
        {'key': 'BEL', 'value': 'Belgium', 'displayOrder': 70},
        {'key': 'BLZ', 'value': 'Belize', 'displayOrder': 75},
        {'key': 'BEN', 'value': 'Benin', 'displayOrder': 76},
        {'key': 'BMU', 'value': 'Bermuda', 'displayOrder': 80},
        {'key': 'BTN', 'value': 'Bhutan', 'displayOrder': 81},
        {'key': 'BOL', 'value': 'Bolivia', 'displayOrder': 85},
        {
            'key': 'BES',
            'altKey': 'BonaireSintEustatiusAndSaba',
            'value': 'Bonaire, Sint Eustatius and Saba',
            'displayOrder': 86
        },
        {'key': 'BIH', 'altKey': 'BosniaAndHerzegovina', 'value': 'Bosnia and Herzegovina', 'displayOrder': 90},
        {'key': 'BWA', 'value': 'Botswana', 'displayOrder': 95},
        {'key': 'BVT', 'altKey': 'BouvetIsland', 'value': 'Bouvet Island', 'displayOrder': 96},
        {'key': 'BRA', 'value': 'Brazil', 'displayOrder': 100},
        {'key': 'IOT', 'value': 'British Indian Ocean Territory', 'displayOrder': 101},
        {'key': 'BRN', 'altKey': 'BouvetIsland', 'value': 'Brunei Darussalam', 'displayOrder': 105},
        {'key': 'BGR', 'value': 'Bulgaria', 'displayOrder': 106},
        {'key': 'BFA', 'value': 'Burkina Faso', 'displayOrder': 107},
        {'key': 'BDI', 'value': 'Burundi', 'displayOrder': 108},
        {'key': 'KHM', 'value': 'Cambodia', 'displayOrder': 109},
        {'key': 'CMR', 'value': 'Cameroon', 'displayOrder': 110},
        {'key': 'CPV', 'value': 'Cape Verde', 'displayOrder': 111},
        {'key': 'CYM', 'altKey': 'CaymanIslands', 'value': 'Cayman Islands', 'displayOrder': 112},
        {'key': 'CAF', 'altKey': 'CentralAfricanRepublic', 'value': 'Central African Republic', 'displayOrder': 113},
        {'key': 'TCD', 'value': 'Chad', 'displayOrder': 114},
        {'key': 'CHL', 'value': 'Chile', 'displayOrder': 115},
        {'key': 'CHN', 'value': 'China', 'displayOrder': 116},
        {'key': 'CXR', 'altKey': 'ChristmasIsland', 'value': 'Christmas Island', 'displayOrder': 117},
        {'key': 'CCK', 'altKey': 'CocosIslands', 'value': 'Cocos (Keeling) Islands', 'displayOrder': 118},
        {'key': 'COL', 'value': 'Colombia', 'displayOrder': 120},
        {'key': 'COM', 'value': 'Comoros', 'displayOrder': 121},
        {'key': 'COD', 'value': 'Congo', 'displayOrder': 122},
        {
            'key': 'COG',
            'altKey': 'CongoTheDemocraticRepublicOfThe',
            'value': 'Congo, the Democratic Republic of the',
            'displayOrder': 123
        },
        {'key': 'COK', 'altKey': 'CookIslands', 'value': 'Cook Islands', 'displayOrder': 125},
        {'key': 'CRI', 'altKey': 'CostaRica', 'value': 'Costa Rica', 'displayOrder': 130},
        {'key': 'CIV', 'altKey': 'CotedIvoire', 'value': 'Cote d’Ivoire', 'displayOrder': 131},
        {'key': 'HRV', 'value': 'Croatia', 'displayOrder': 135},
        {'key': 'CUB', 'value': 'Cuba', 'displayOrder': 140},
        {'key': 'CUW', 'value': 'Curacao', 'displayOrder': 145},
        {'key': 'CYP', 'value': 'Cyprus', 'displayOrder': 150},
        {'key': 'CZE', 'value': 'Czechia', 'displayOrder': 155},
        {'key': 'DNK', 'value': 'Denmark', 'displayOrder': 160},
        {'key': 'DJI', 'value': 'Djibouti', 'displayOrder': 161},
        {'key': 'DMA', 'value': 'Dominica', 'displayOrder': 162},
        {'key': 'DOM', 'altKey': 'DominicanRepublic', 'value': 'Dominican Republic', 'displayOrder': 165},
        {'key': 'ECU', 'value': 'Ecuador', 'displayOrder': 170},
        {'key': 'EGY', 'value': 'Egypt', 'displayOrder': 175},
        {'key': 'SLV', 'altKey': 'ElSalvador', 'value': 'El Salvador', 'displayOrder': 176},
        {'key': 'GNQ', 'altKey': 'EquatorialGuinea', 'value': 'Equatorial Guinea', 'displayOrder': 177},
        {'key': 'ERI', 'value': 'Eritrea', 'displayOrder': 178},
        {'key': 'EST', 'value': 'Estonia', 'displayOrder': 185},
        {'key': 'SWZ', 'altKey': 'Eswatini', 'value': 'Eswatini (Swaziland)', 'displayOrder': 186},
        {'key': 'ETH', 'value': 'Ethiopia', 'displayOrder': 190},
        {'key': 'FLK', 'altKey': 'FalklandIslands', 'value': 'Falkland Islands (Malvinas)', 'displayOrder': 191},
        {'key': 'FRO', 'altKey': 'FaroeIslands', 'value': 'Faroe Islands', 'displayOrder': 192},
        {'key': 'FJI', 'value': 'Fiji', 'displayOrder': 195},
        {'key': 'FIN', 'value': 'Finland', 'displayOrder': 200},
        {'key': 'FRA', 'value': 'France', 'displayOrder': 205},
        {'key': 'GUF', 'altKey': 'FrenchGuiana', 'value': 'French Guiana', 'displayOrder': 206},
        {'key': 'PYF', 'altKey': 'FrenchPolynesia', 'value': 'French Polynesia', 'displayOrder': 207},
        {
            'key': 'ATF',
            'altKey': 'FrenchSouthernTerritories',
            'value': 'French Southern Territories',
            'displayOrder': 208
        },
        {'key': 'GAB', 'value': 'Gabon', 'displayOrder': 209},
        {'key': 'GMB', 'value': 'Gambia', 'displayOrder': 210},
        {'key': 'GEO', 'value': 'Georgia', 'displayOrder': 211},
        {'key': 'DEU', 'value': 'Germany', 'displayOrder': 212},
        {'key': 'GHA', 'value': 'Ghana', 'displayOrder': 215},
        {'key': 'GIB', 'value': 'Gibraltar', 'displayOrder': 220},
        {'key': 'GRC', 'value': 'Greece', 'displayOrder': 225},
        {'key': 'GRL', 'value': 'Greenland', 'displayOrder': 226},
        {'key': 'GRD', 'value': 'Grenada', 'displayOrder': 230},
        {'key': 'GLP', 'value': 'Guadeloupe', 'displayOrder': 231},
        {'key': 'GUM', 'value': 'Guam', 'displayOrder': 235},
        {'key': 'GTM', 'value': 'Guatemala', 'displayOrder': 236},
        {'key': 'GNS', 'value': 'Guernsey', 'displayOrder': 240},
        {'key': 'GIN', 'value': 'Guinea', 'displayOrder': 245},
        {'key': 'GNB', 'value': 'Guinea-Bissau', 'displayOrder': 246},
        {'key': 'GUY', 'value': 'Guyana', 'displayOrder': 250},
        {'key': 'HTI', 'value': 'Haiti', 'displayOrder': 251},
        {
            'key': 'HMD',
            'altKey': 'HeardIslandAndMcDonaldIslands',
            'value': 'Heard Island and McDonald Islands',
            'displayOrder': 252
        },
        {'key': 'VAT', 'altKey': 'HolySee', 'value': 'Holy See (Vatican City State)', 'displayOrder': 253},
        {'key': 'HND', 'value': 'Honduras', 'displayOrder': 255},
        {'key': 'HKG', 'altKey': 'HongKong', 'value': 'Hong Kong', 'displayOrder': 260},
        {'key': 'HUN', 'value': 'Hungary', 'displayOrder': 265},
        {'key': 'ISL', 'value': 'Iceland', 'displayOrder': 270},
        {'key': 'IND', 'value': 'India', 'displayOrder': 275},
        {'key': 'IDN', 'value': 'Indonesia', 'displayOrder': 280},
        {'key': 'IRN', 'value': 'Iran', 'displayOrder': 285},
        {'key': 'IRQ', 'value': 'Iraq', 'displayOrder': 286},
        {'key': 'IRL', 'value': 'Ireland', 'displayOrder': 290},
        {'key': 'GBA', 'altKey': 'IsleOfMan', 'value': 'Isle of Man', 'displayOrder': 295},
        {'key': 'ISR', 'value': 'Israel', 'displayOrder': 300},
        {'key': 'ITA', 'value': 'Italy', 'displayOrder': 305},
        {'key': 'JAM', 'value': 'Jamaica', 'displayOrder': 315},
        {'key': 'JPN', 'value': 'Japan', 'displayOrder': 320},
        {'key': 'JRS', 'value': 'Jersey', 'displayOrder': 325},
        {'key': 'JOR', 'value': 'Jordan', 'displayOrder': 330},
        {'key': 'KAZ', 'value': 'Kazakhstan', 'displayOrder': 335},
        {'key': 'KEN', 'value': 'Kenya', 'displayOrder': 340},
        {'key': 'KIR', 'value': 'Kiribati', 'displayOrder': 341},
        {'key': 'KWT', 'value': 'Kuwait', 'displayOrder': 345},
        {'key': 'KGZ', 'value': 'Kyrgyzstan', 'displayOrder': 350},
        {'key': 'LAO', 'value': 'Laos', 'displayOrder': 355},
        {'key': 'LVA', 'value': 'Latvia', 'displayOrder': 360},
        {'key': 'LBN', 'value': 'Lebanon', 'displayOrder': 365},
        {'key': 'LSO', 'value': 'Lesotho', 'displayOrder': 366},
        {'key': 'LBR', 'value': 'Liberia', 'displayOrder': 370},
        {'key': 'LBY', 'altKey': 'LibyanArabJamahiriya', 'value': 'Libyan Arab Jamahiriya', 'displayOrder': 371},
        {'key': 'LIE', 'value': 'Liechtenstein', 'displayOrder': 375},
        {'key': 'LTU', 'value': 'Lithuania', 'displayOrder': 380},
        {'key': 'LUX', 'value': 'Luxembourg', 'displayOrder': 385},
        {'key': 'MAC', 'value': 'Macao', 'displayOrder': 390},
        {
            'key': 'MKD',
            'altKey': 'Macedonia',
            'value': 'Macedonia, the former Yugoslav Republic of',
            'displayOrder': 391
        },
        {'key': 'MDG', 'value': 'Madagascar', 'displayOrder': 392},
        {'key': 'MWI', 'value': 'Malawi', 'displayOrder': 395},
        {'key': 'MYS', 'value': 'Malaysia', 'displayOrder': 400},
        {'key': 'MDV', 'value': 'Maldives', 'displayOrder': 401},
        {'key': 'MLI', 'value': 'Mali', 'displayOrder': 405},
        {'key': 'MLT', 'value': 'Malta', 'displayOrder': 410},
        {'key': 'MHL', 'altKey': 'MarshallIslands', 'value': 'Marshall Islands', 'displayOrder': 415},
        {'key': 'MTQ', 'value': 'Martinique', 'displayOrder': 420},
        {'key': 'MRT', 'altKey': 'Micronesia', 'value': 'Mauritania', 'displayOrder': 421},
        {'key': 'MUS', 'value': 'Mauritius', 'displayOrder': 422},
        {'key': 'MYT', 'value': 'Mayotte', 'displayOrder': 423},
        {'key': 'MEX', 'value': 'Mexico', 'displayOrder': 425},
        {'key': 'FSM', 'value': 'Micronesia (Federated States of)', 'displayOrder': 426},
        {'key': 'MDA', 'value': 'Moldova', 'displayOrder': 430},
        {'key': 'MCO', 'value': 'Monaco', 'displayOrder': 435},
        {'key': 'MNG', 'value': 'Mongolia', 'displayOrder': 436},
        {'key': 'MNE', 'value': 'Montenegro', 'displayOrder': 437},
        {'key': 'MSR', 'value': 'Montserrat', 'displayOrder': 438},
        {'key': 'MAR', 'value': 'Morocco', 'displayOrder': 440},
        {'key': 'MOZ', 'value': 'Mozambique', 'displayOrder': 441},
        {'key': 'MMR', 'value': 'Myanmar', 'displayOrder': 442},
        {'key': 'NAM', 'value': 'Namibia', 'displayOrder': 443},
        {'key': 'NRU', 'value': 'Nauru', 'displayOrder': 444},
        {'key': 'NPL', 'value': 'Nepal', 'displayOrder': 445},
        {'key': 'NLD', 'value': 'Netherlands', 'displayOrder': 455},
        {'key': 'NCL', 'altKey': 'NewCaledonia', 'value': 'New Caledonia', 'displayOrder': 456},
        {'key': 'NZL', 'altKey': 'NewZealand', 'value': 'New Zealand', 'displayOrder': 460},
        {'key': 'NIC', 'value': 'Nicaragua', 'displayOrder': 461},
        {'key': 'NER', 'value': 'Niger', 'displayOrder': 462},
        {'key': 'NGA', 'value': 'Nigeria', 'displayOrder': 465},
        {'key': 'NIU', 'value': 'Niue', 'displayOrder': 470},
        {'key': 'NFK', 'altKey': 'NorfolkIsland', 'value': 'Norfolk Island', 'displayOrder': 471},
        {'key': 'PRK', 'value': 'North Korea', 'displayOrder': 472},
        {'key': 'MNP', 'altKey': 'NorthernMarianaIslands', 'value': 'Northern Mariana Islands', 'displayOrder': 473},
        {'key': 'NOR', 'value': 'Norway', 'displayOrder': 475},
        {'key': 'OMN', 'value': 'Oman', 'displayOrder': 480},
        {'key': 'PAK', 'value': 'Pakistan', 'displayOrder': 485},
        {'key': 'PLW', 'value': 'Palau', 'displayOrder': 486},
        {'key': 'PSE', 'value': 'Palestine', 'displayOrder': 487},
        {'key': 'PAN', 'value': 'Panama', 'displayOrder': 490},
        {'key': 'PNG', 'altKey': 'PapuaNewGuinea', 'value': 'Papua New Guinea', 'displayOrder': 495},
        {'key': 'PRY', 'value': 'Paraguay', 'displayOrder': 496},
        {'key': 'PER', 'value': 'Peru', 'displayOrder': 500},
        {'key': 'PHL', 'value': 'Philippines', 'displayOrder': 505},
        {'key': 'PCN', 'value': 'Pitcairn', 'displayOrder': 506},
        {'key': 'POL', 'value': 'Poland', 'displayOrder': 510},
        {'key': 'PRT', 'value': 'Portugal', 'displayOrder': 515},
        {'key': 'PRI', 'altKey': 'PuertoRico', 'value': 'Puerto Rico', 'displayOrder': 520},
        {'key': 'QAT', 'value': 'Qatar', 'displayOrder': 525},
        {'key': 'REU', 'value': 'Reunion', 'displayOrder': 526},
        {'key': 'ROM', 'value': 'Romania', 'displayOrder': 530},
        {'key': 'RUS', 'altKey': 'RussianFederation', 'value': 'Russia', 'displayOrder': 535},
        {'key': 'RWA', 'value': 'Rwanda', 'displayOrder': 540},
        {'key': 'BLM', 'altKey': 'SaintBarthelemy', 'value': 'Saint Barthélemy', 'displayOrder': 541},
        {
            'key': 'SHN',
            'altKey': 'SaintHelenaAscensionAndTristanDaCunha',
            'value': 'Saint Helena, Ascension and Tristan da Cunha',
            'displayOrder': 542
        },
        {'key': 'KNA', 'altKey': 'SaintKittsAndNevis', 'value': 'Saint Kitts and Nevis', 'displayOrder': 545},
        {'key': 'LCA', 'altKey': 'SaintLucia', 'value': 'Saint Lucia', 'displayOrder': 546},
        {'key': 'MAF', 'altKey': 'SaintMartin', 'value': 'Saint Martin (French part)', 'displayOrder': 547},
        {'key': 'SPM', 'altKey': 'SaintPierreAndMiquelon', 'value': 'Saint Pierre and Miquelon', 'displayOrder': 548},
        {
            'key': 'VCT',
            'altKey': 'SaintVincentAndTheGrenadines',
            'value': 'Saint Vincent and the Grenadines',
            'displayOrder': 549
        },
        {'key': 'WSM', 'value': 'Samoa', 'displayOrder': 550},
        {'key': 'SMR', 'altKey': 'SanMarino', 'value': 'San Marino', 'displayOrder': 551},
        {'key': 'STP', 'altKey': 'SaoTomeAndPrincipe', 'value': 'Sao Tome and Principe', 'displayOrder': 552},
        {'key': 'SAU', 'altKey': 'SaudiArabia', 'value': 'Saudi Arabia', 'displayOrder': 555},
        {'key': 'SEN', 'value': 'Senegal', 'displayOrder': 560},
        {'key': 'SRB', 'value': 'Serbia', 'displayOrder': 561},
        {'key': 'SYC', 'value': 'Seychelles', 'displayOrder': 565},
        {'key': 'SLE', 'altKey': 'SierraLeone', 'value': 'Sierra Leone', 'displayOrder': 570},
        {'key': 'SGP', 'value': 'Singapore', 'displayOrder': 575},
        {'key': 'SXM', 'altKey': 'SintMaarten', 'value': 'Sint Maarten (Dutch part)', 'displayOrder': 576},
        {'key': 'SVK', 'value': 'Slovakia', 'displayOrder': 580},
        {'key': 'SVN', 'value': 'Slovenia', 'displayOrder': 585},
        {'key': 'SLB', 'altKey': 'SolomonIslands', 'value': 'Solomon Islands', 'displayOrder': 586},
        {'key': 'SOM', 'value': 'Somalia', 'displayOrder': 587},
        {'key': 'ZAF', 'altKey': 'SouthAfrica', 'value': 'South Africa', 'displayOrder': 588},
        {
            'key': 'SGS',
            'altKey': 'SouthGeorgiaAndTheSouthSandwichIslands',
            'value': 'South Georgia and the South Sandwich Islands',
            'displayOrder': 589
        },
        {'key': 'SSD', 'altKey': 'SouthSudan', 'value': 'South Sudan', 'displayOrder': 590},
        {'key': 'KOR', 'value': 'South Korea', 'displayOrder': 591},
        {'key': 'ESP', 'value': 'Spain', 'displayOrder': 592},
        {'key': 'LKA', 'altKey': 'SriLanka', 'value': 'Sri Lanka', 'displayOrder': 595},
        {'key': 'SDN', 'value': 'Sudan', 'displayOrder': 600},
        {'key': 'SUR', 'value': 'Suriname', 'displayOrder': 605},
        {'key': 'SJM', 'altKey': 'SvalbardAndJanMayen', 'value': 'Svalbard and Jan Mayen', 'displayOrder': 606},
        {'key': 'SWE', 'value': 'Sweden', 'displayOrder': 610},
        {'key': 'CHE', 'value': 'Switzerland', 'displayOrder': 615},
        {'key': 'SYR', 'value': 'Syria', 'displayOrder': 620},
        {'key': 'TWN', 'value': 'Taiwan', 'displayOrder': 625},
        {'key': 'TJK', 'value': 'Tajikistan', 'displayOrder': 626},
        {'key': 'TZA', 'value': 'Tanzania', 'displayOrder': 630},
        {'key': 'THA', 'value': 'Thailand', 'displayOrder': 635},
        {'key': 'TLS', 'value': 'Timor-Leste', 'displayOrder': 636},
        {'key': 'TGO', 'value': 'Togo', 'displayOrder': 637},
        {'key': 'TKL', 'value': 'Tokelau', 'displayOrder': 638},
        {'key': 'TON', 'value': 'Tonga', 'displayOrder': 639},
        {'key': 'TTO', 'altKey': 'TrinidadAndTobago', 'value': 'Trinidad and Tobago', 'displayOrder': 640},
        {'key': 'TUN', 'value': 'Tunisia', 'displayOrder': 645},
        {'key': 'TUR', 'value': 'Turkey', 'displayOrder': 650},
        {'key': 'TKM', 'value': 'Turkmenistan', 'displayOrder': 651},
        {'key': 'TCA', 'altKey': 'TurksAndCaicosIslands', 'value': 'Turks and Caicos Islands', 'displayOrder': 655},
        {'key': 'TUV', 'value': 'Tuvalu', 'displayOrder': 656},
        {'key': 'UGA', 'value': 'Uganda', 'displayOrder': 660},
        {'key': 'UKR', 'value': 'Ukraine', 'displayOrder': 665},
        {'key': 'ARE', 'altKey': 'UnitedArabEmirates', 'value': 'United Arab Emirates', 'displayOrder': 670},
        {'key': 'GBR', 'altKey': 'UnitedKingdom', 'value': 'United Kingdom', 'displayOrder': 675},
        {
            'key': 'UMI',
            'altKey': 'UnitedStatesMinorOutlyingIslands',
            'value': 'United States Minor Outlying Islands',
            'displayOrder': 676
        },
        {'key': 'URY', 'value': 'Uruguay', 'displayOrder': 680},
        {'key': 'UZB', 'value': 'Uzbekistan', 'displayOrder': 681},
        {'key': 'VUT', 'altKey': 'Vanuatu', 'value': 'Vanuatu (New Hebrides)', 'displayOrder': 685},
        {'key': 'VEN', 'value': 'Venezuela', 'displayOrder': 690},
        {'key': 'VNM', 'value': 'Vietnam', 'displayOrder': 695},
        {'key': 'VGB', 'altKey': 'VirginIslandsBritish', 'value': 'Virgin Islands , British', 'displayOrder': 700},
        {'key': 'VIR', 'altKey': 'VirginIslandsUs', 'value': 'Virgin Islands', 'displayOrder': 705},
        {'key': 'WLF', 'altKey': 'WallisAndFutuna', 'value': 'Wallis and Futuna', 'displayOrder': 706},
        {'key': 'ESH', 'altKey': 'WesternSahara', 'value': 'Western Sahara', 'displayOrder': 707},
        {'key': 'YEM', 'value': 'Yemen', 'displayOrder': 710},
        {'key': 'ZMB', 'value': 'Zambia', 'displayOrder': 720},
        {'key': 'ZWE', 'value': 'Zimbabwe', 'displayOrder': 725}
    ])
    .constant('maritalStatuses', [
        {'key': 'SINGLE', 'value': 'Single', 'displayOrder': 0},
        {'key': 'COMMONLAW', 'value': 'Common Law', 'displayOrder': 1},
        {'key': 'MARRIED', 'value': 'Married', 'displayOrder': 2},
        {'key': 'SEPARATED', 'value': 'Separated', 'displayOrder': 3},
        {'key': 'DIVORCED', 'value': 'Divorced', 'displayOrder': 4},
        {'key': 'WIDOWED', 'value': 'Widowed', 'displayOrder': 5}])
    .constant('assetTypes', [
        {'key': 'HOME', 'value': 'Home (value if you sold today)'},
        {'key': 'PENSION', 'value': 'Locked in/pension'},
        {'key': 'VEHICLE', 'value': 'Vehicles (value if you sold today)'},
        {'key': 'CHEQUING_SAVINGS', 'value': 'Chq/Savings account'},
        {'key': 'RRSP', 'value': 'RRSP'},
        {'key': 'TFSA', 'value': 'TFSA'},
        {'key': 'LIFE_INSURANCE', 'value': 'Life insurance value'},
        {'key': 'NON_REGISTERED_INVESTMENTS', 'value': 'Non-registered investments'},
        {'key': 'OTHER_ASSET', 'value': 'Other'}])
    .constant('liabilityTypes', [{'key': 'MORTGAGE', 'value': 'Mortgage (incl home equity line of credit)'},
        {'key': 'CAR_LOAN', 'value': 'Car loan'},
        {'key': 'CREDIT_CARD', 'value': 'Credit card balance'},
        {'key': 'LINE_OF_CREDIT', 'value': 'Line of credit balance'},
        {'key': 'PERSONAL_LOAN', 'value': 'Personal loan'},
        {'key': 'OTHER_LIABILITY', 'value': 'Other'}])
    .constant('employmentStatuses', [
        {'key': 'EMPLOYED', 'value': 'Employed', 'displayOrder': 0},
        {'key': 'SELFEMPLOYED', 'value': 'Self-employed', 'displayOrder': 1},
        {'key': 'STUDENT', 'value': 'Student', 'displayOrder': 6},
        {'key': 'HOMEMAKER', 'value': 'Homemaker', 'displayOrder': 3},
        {'key': 'RETIRED', 'value': 'Retired', 'displayOrder': 2},
        {'key': 'UNEMPL_W_INCOME', 'value': 'Unemployed with income', 'displayOrder': 4},
        {'key': 'UNEMPL_WO_INCOME', 'value': 'Unemployed without income', 'displayOrder': 5}])
    .constant('atbEmploymentStatuses', [
        {
            label: "ATB Financial",
            value: "AtbFinancial",
            isAtb: true,
            statuses: ['EMPLOYED', 'SELFEMPLOYED', 'STUDENT'],
            displayOrder: 0,
        },
        {
            label: "ATB Securities Inc.",
            value: "AtbSecuritiesInc",
            isAtb: true,
            isDealer: true,
            statuses: ['EMPLOYED', 'SELFEMPLOYED', 'STUDENT'],
            displayOrder: 1,
        },
        {
            label: "ATB Investment Management",
            value: "AtbInvestmentManagement",
            isAtb: true,
            isDealer: true,
            statuses: ['EMPLOYED', 'SELFEMPLOYED', 'STUDENT'],
            displayOrder: 2,
        },
        {
            label: "ATB Insurance Advisory Inc.",
            value: "AtbInsuranceAdvisoryInc",
            isAtb: true,
            statuses: ['EMPLOYED', 'SELFEMPLOYED', 'STUDENT'],
            displayOrder: 3,
        },
        {
            label: "ATB Agency employee or Board Member/Spouse",
            value: "AtbAgencyOrBoardMemberEmployeeSpouse",
            statuses: ['EMPLOYED', 'SELFEMPLOYED', 'UNEMPL_W_INCOME', 'UNEMPL_WO_INCOME'],
            displayOrder: 4,
        },
        {
            label: "Never Worked for ATB",
            value: "No",
            statuses: ['*'],
            displayOrder: 5,
        },
        {
            label: "Retired ATB Employee",
            value: "RetiredAtbEmployee",
            statuses: ['SELFEMPLOYED', 'HOMEMAKER', 'RETIRED', 'UNEMPL_W_INCOME', 'UNEMPL_WO_INCOME'],
            displayOrder: 6,
        },
        {
            label: "Spouse of Active or Retired ATB Employee",
            value: "SpouseOfActiveOrRetiredAtbEmployee",
            statuses: ['EMPLOYED', 'SELFEMPLOYED', 'HOMEMAKER', 'RETIRED', 'UNEMPL_W_INCOME', 'UNEMPL_WO_INCOME'],
            displayOrder: 7,
        },
        {
            label: "Former ATB Employee",
            value: "FormerAtbEmployee",
            statuses: ['*'],
            displayOrder: 8,
        }
    ])
    .constant('pepOrHioIdentities', [{'key': 'ME', 'value': 'Myself'}, {
        'key': 'FAMILY',
        'value': 'Spouse/partner/family member'
    }, {'key': 'OTHER', 'value': 'Close associate'}])
    .constant('primaryCaregiverOptions', [
        {'key': 'MYSELF', 'value': 'Myself', 'displayOrder': 0},
        {'key': 'SPOUSE', 'value': 'My Spouse', 'displayOrder': 10},
        {'key': 'OTHER', 'value': 'Other', 'displayOrder': 20}])
    .constant('legalGuardianOptions', [
        {'key': 'MYSELF', 'value': 'Myself', 'displayOrder': 0},
        {'key': 'SPOUSE', 'value': 'My Spouse', 'displayOrder': 10},
        {'key': 'BOTH', 'value': 'Both', 'displayOrder': 20},
        {'key': 'OTHER', 'value': 'Other', 'displayOrder': 30}])
    .constant('hioOccupations', [
        {
            'key': 'HEAD_INTERNATIONAL_ORG',
            'value': 'Head of an international organization established by the governments of states'
        },
        {'key': 'HEAD_INSTITUTION', 'value': 'Head of an institution established by an international organization'}
    ])
    .constant('pepTypes', [{'key': 'DOMESTIC', 'value': 'Domestic politically exposed'}, {
        'key': 'FOREIGN',
        'value': 'Foreign politically exposed'
    }])
    .constant('pepOccupations', [
        {'key': 'HEAD_STATE', 'value': 'Head of state or head of government'},
        {'key': 'COUNCIL_MEMBER', 'value': 'Member of the executive council of government or member of a legislature'},
        {'key': 'DEPUTY_MINISTER', 'value': 'Deputy minister or equivalent rank'},
        {'key': 'AMBASSADOR', 'value': 'Ambassador or attaché or counselor of an ambassador'},
        {'key': 'MILITARY_GENERAL', 'value': 'Military officer with a rank of general or above'},
        {'key': 'COMPANY_PRESIDENT', 'value': 'President of a state-owned company or a state-owned bank'},
        {'key': 'AGENCY_HEAD', 'value': 'Head of a government agency'},
        {'key': 'JUDGE', 'value': 'Judge of a supreme court, constitutional court or other court of last resort'},
        {'key': 'PARTY_LEADER', 'value': 'Leader or president of a political party represented in a legislature'},
        {'key': 'GOVERNOR_GENERAL', 'value': 'Governor general, lieutenant governor or head of government'},
        {'key': 'SENATOR', 'value': 'Member of the Senate or House of Commons or member of legislature'},
        {
            'key': 'PRESIDENT_OWNED_CORP',
            'value': 'President of a corporation that is wholly owned directly by her Majesty in right of Canada or a province'
        },
        {
            'key': 'APPELLATE_JUDGE',
            'value': 'Judge of an appellate court in a province, the Federal Court of Appeal or the Supreme Court of Canada'
        },
        {
            'key': 'MAYOR',
            'value': 'Head of a city, town, village or rural or metropolitan municipality (i.e. mayor, reeve etc.)'
        }])
    .constant('beneficiaryRelationships', [{'key': 'SPOUSE', 'value': 'Spouse', 'displayOrder': 0},
        {'key': 'SIBLING', 'value': 'Sibling', 'displayOrder': 1},
        {'key': 'CHILD', 'value': 'Child', 'displayOrder': 2},
        {'key': 'OTHER', 'value': 'Other', 'displayOrder': 3}])
    .constant('educationBeneficiaryRelationships', [{'key': 'PARENT', 'value': 'Parent', 'displayOrder': 1},
        {'key': 'GRANDPARENT', 'value': 'Grandparent', 'displayOrder': 2},
        {'key': 'SIBLING', 'value': 'Sibling', 'displayOrder': 3}])
    .constant('genders', [{'key': 'MALE', 'value': 'Male'},
        {'key': 'FEMALE', 'value': 'Female'}])
    .constant('regexes', [
        {'key': 'NUMBERS_ONLY', 'value': '^[0-9]*$'},
        {'key': 'NO_NUMBERS', 'value': '^[^0-9]*$'}, {
            'key': 'HUMAN_NAME',
            'value': '^([a-zA-ZÀàÂâÇçÉéÈèÊêËëÎîÏïÔôÙùÛûÜü](\\. |[\\-\'\\. ])?)*$'
        },
        {'key': 'POSTAL', 'value': '^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$'}
    ])
    .constant('fundCodes', {
        'ATB101': {
            name: 'Compass Conservative Portfolio',
            color: '#1C3CBE',
            isCompass: true,
            order: 30
        },
        'ATB102': {
            name: 'Compass Conservative Balanced Portfolio',
            color: '#FF9C35',
            isCompass: true,
            order: 40
        },
        'ATB103': {
            name: 'Compass Balanced Portfolio',
            color: '#8FDFFF',
            isCompass: true,
            order: 50
        },
        'ATB104': {
            name: 'Compass Balanced Growth Portfolio',
            color: '#FCDC3E',
            isCompass: true,
            order: 60
        },
        'ATB105': {
            name: 'Compass Growth Portfolio',
            color: '#D9F4FF',
            isCompass: true,
            order: 70
        },
        'ATB106': {
            name: 'Compass Maximum Growth Portfolio',
            color: '#FF7F30',
            isCompass: true,
            order: 80
        },
        'ALB100': {
            name: 'ATB High Interest Savings, Series A',
            color: '#FFFCCF',
            isCompass: false,
            order: 10
        },
        'ALB102': {
            name: 'ATB High Interest Special Savings, Series SA',
            color: '#C33002',
            isCompass: false,
            order: 20
        },
        'CASH': {
            name: 'Cash',
            color: '#F1BC00',
            isCompass: false,
            order: 90
        }
    })
    .constant('bankAccountLengths', {
        '0001': [7],
        '0002': [11, 7],
        '0003': [7],
        '0004': [11, 7],
        '0006': [7],
        '0010': [10, 7],
        '0016': [9],
        '0030': [12, 10, 9],
        '0039': [9],
        '0219': [9],
        '0250': [10],
        '0260': [10],
        '0309': [9],
        '0320': [7],
        '0509': [7],
        '0540': [7],
        '0614': [10],
        '0623': [9],
        '0889': [9, 12],
        '0899': [12, 11, 10, 9, 8],
        '0879': [12],
        '0853': [10],
        '0374': [12],
        '0809': [9, 11, 12]
    })
    .constant('bankNames', [
        {bankCode: '0001', bankName: '0001 - Bank of Montreal (BMO)'},
        {bankCode: '0002', bankName: '0002 - Bank of Nova Scotia (Scotiabank)'},
        {bankCode: '0003', bankName: '0003 - Royal Bank of Canada (RBC)'},
        {bankCode: '0004', bankName: '0004 - Toronto-Dominion Bank (TD)'},
        {bankCode: '0006', bankName: '0006 - National Bank of Canada'},
        {bankCode: '0010', bankName: '0010 - Canadian Imperial Bank of Commerce (CIBC)'},
        {bankCode: '0016', bankName: '0016 - HSBC Canada'},
        {bankCode: '0030', bankName: '0030 - Canadian Western Bank'},
        {bankCode: '0039', bankName: '0039 - Laurentian Bank of Canada'},
        {bankCode: '0219', bankName: '0219 - ATB Financial'},
        {bankCode: '0250', bankName: '0250 - BNP Paribas Canada'},
        {bankCode: '0260', bankName: '0260 - Citibank Canada'},
        {bankCode: '0309', bankName: '0309 - Citizens Bank of Canada'},
        {bankCode: '0320', bankName: '0320 - PC Financial'},
        {bankCode: '0374', bankName: '0374 - MotusBank'},
        {bankCode: '0509', bankName: '0509 - Canada Trust'},
        {bankCode: '0540', bankName: '0540 - Manulife Bank'},
        {bankCode: '0614', bankName: '0614 - Tangerine Bank'},
        {bankCode: '0623', bankName: '0623 - Equitable Bank'},
        {bankCode: '0809', bankName: '0809 - BC Central Credit Unions (Interior Savings)'},
        {bankCode: '0889', bankName: '0889 - Synergy Credit Union'},
        {bankCode: '0899', bankName: '0899 - Credit Union Central Alberta'},
        {bankCode: '0879', bankName: '0879 - Sunrise Credit Union'},
        {bankCode: '0853', bankName: '0853 - Neo Financial'}
    ])
    .constant('atbBranches', [
        {transit: '02259', branchName: '02259 - From Other Institutions - Cal', 'displayOrder': 10},
        {transit: '02369', branchName: '02369 - Investor Services EAP', 'displayOrder': 15},
        {transit: '03109', branchName: '03109 - From Other Institutions - Edm', 'displayOrder': 20},
        {transit: '07059', branchName: '07059 - Cashco Financial', 'displayOrder': 25},
        {transit: '07069', branchName: '07069 - Brightside', 'displayOrder': 28},
        {transit: '07109', branchName: '07109 - Calgary 6th Avenue', 'displayOrder': 30},
        {transit: '07129', branchName: '07129 - Red Deer - First Red Deer Place', 'displayOrder': 35},
        {transit: '07139', branchName: '07139 - Vulcan', 'displayOrder': 40},
        {transit: '07149', branchName: '07149 - Strathmore', 'displayOrder': 45},
        {transit: '07169', branchName: '07169 - Andrew', 'displayOrder': 50},
        {transit: '07179', branchName: '07179 - Rimbey', 'displayOrder': 55},
        {transit: '07189', branchName: '07189 - Forestburg', 'displayOrder': 60},
        {transit: '07199', branchName: '07199 - Grande Prairie Westview', 'displayOrder': 65},
        {transit: '07209', branchName: '07209 - Calgary Westhills', 'displayOrder': 70},
        {transit: '07229', branchName: '07229 - Lethbridge Paramount', 'displayOrder': 75},
        {transit: '07239', branchName: '07239 - Black Diamond', 'displayOrder': 80},
        {transit: '07249', branchName: '07249 - Crossfield', 'displayOrder': 85},
        {transit: '07259', branchName: '07259 - Edmonton Oliver Village', 'displayOrder': 90},
        {transit: '07269', branchName: '07269 - Athabasca', 'displayOrder': 95},
        {transit: '07279', branchName: '07279 - Ryley', 'displayOrder': 100},
        {transit: '07289', branchName: '07289 - Sherwood Park Lakeland Ridge', 'displayOrder': 105},
        {transit: '07299', branchName: '07299 - Peace River', 'displayOrder': 110},
        {transit: '07309', branchName: '07309 - Calgary Chinook Centre', 'displayOrder': 115},
        {transit: '07319', branchName: '07319 - Edmonton Manning Crossing', 'displayOrder': 120},
        {transit: '07329', branchName: '07329 - Medicine Hat 2nd St', 'displayOrder': 125},
        {transit: '07339', branchName: '07339 - Picture Butte', 'displayOrder': 130},
        {transit: '07349', branchName: '07349 - Raymond', 'displayOrder': 135},
        {transit: '07359', branchName: '07359 - Edmonton Skyview', 'displayOrder': 140},
        {transit: '07369', branchName: '07369 - Barrhead', 'displayOrder': 145},
        {transit: '07379', branchName: '07379 - St. Paul', 'displayOrder': 150},
        {transit: '07389', branchName: '07389 - Smoky Lake', 'displayOrder': 155},
        {transit: '07399', branchName: '07399 - Beaverlodge', 'displayOrder': 160},
        {transit: '07409', branchName: '07409 - Calgary North Hill', 'displayOrder': 165},
        {transit: '07429', branchName: '07429 - Bow Island', 'displayOrder': 170},
        {transit: '07439', branchName: '07439 - Oyen', 'displayOrder': 175},
        {transit: '07449', branchName: '07449 - Banff', 'displayOrder': 180},
        {transit: '07459', branchName: '07459 - Calgary Northpointe', 'displayOrder': 185},
        {transit: '07469', branchName: '07469 - Bonnyville', 'displayOrder': 190},
        {transit: '07479', branchName: '07479 - Stettler', 'displayOrder': 195},
        {transit: '07489', branchName: '07489 - Fort Saskatchewan', 'displayOrder': 200},
        {transit: '07499', branchName: '07499 - Fairview', 'displayOrder': 205},
        {transit: '07509', branchName: '07509 - Calgary Entrepreneur Centre', 'displayOrder': 210},
        {transit: '07519', branchName: '07519 - Edmonton Namao', 'displayOrder': 215},
        {transit: '07529', branchName: '07529 - Brooks', 'displayOrder': 220},
        {transit: '07539', branchName: '07539 - Linden', 'displayOrder': 225},
        {transit: '07559', branchName: '07559 - Edmonton Alberta Avenue', 'displayOrder': 230},
        {transit: '07569', branchName: '07569 - Breton', 'displayOrder': 235},
        {transit: '07579', branchName: '07579 - Stony Plain', 'displayOrder': 240},
        {transit: '07589', branchName: '07589 - Spruce Grove', 'displayOrder': 245},
        {transit: '07599', branchName: '07599 - Falher', 'displayOrder': 250},
        {transit: '07609', branchName: '07609 - Calgary Stephen Avenue Centre', 'displayOrder': 255},
        {transit: '07619', branchName: '07619 - Edmonton Southbrook', 'displayOrder': 260},
        {transit: '07629', branchName: '07629 - Cardston', 'displayOrder': 265},
        {transit: '07639', branchName: '07639 - Airdrie', 'displayOrder': 270},
        {transit: '07659', branchName: '07659 - Edmonton City Centre', 'displayOrder': 275},
        {transit: '07669', branchName: '07669 - Camrose', 'displayOrder': 280},
        {transit: '07679', branchName: '07679 - Two Hills', 'displayOrder': 285},
        {transit: '07689', branchName: '07689 - Westlock', 'displayOrder': 290},
        {transit: '07699', branchName: '07699 - Grimshaw', 'displayOrder': 295},
        {transit: '07709', branchName: '07709 - Calgary Forest Lawn', 'displayOrder': 300},
        {transit: '07719', branchName: '07719 - Edmonton Rabbit Hill', 'displayOrder': 305},
        {transit: '07729', branchName: '07729 - Didsbury', 'displayOrder': 310},
        {transit: '07739', branchName: '07739 - Caroline', 'displayOrder': 315},
        {transit: '07749', branchName: '07749 - Calgary Crowfoot', 'displayOrder': 320},
        {transit: '07759', branchName: '07759 - Edmonton Jasper Place', 'displayOrder': 325},
        {transit: '07769', branchName: '07769 - Castor', 'displayOrder': 330},
        {transit: '07779', branchName: '07779 - Vegreville', 'displayOrder': 335},
        {transit: '07789', branchName: '07789 - Redwater', 'displayOrder': 340},
        {transit: '07799', branchName: '07799 - High Prairie', 'displayOrder': 345},
        {transit: '07809', branchName: '07809 - Calgary Brentwood Village Shopping Centre', 'displayOrder': 350},
        {transit: '07819', branchName: '07819 - Red Deer Bower Mall', 'displayOrder': 355},
        {transit: '07829', branchName: '07829 - Drumheller', 'displayOrder': 360},
        {transit: '07839', branchName: '07839 - Cochrane', 'displayOrder': 365},
        {transit: '07849', branchName: '07849 - Lethbridge Mayor Magrath', 'displayOrder': 370},
        {transit: '07859', branchName: '07859 - Edmonton Killarney', 'displayOrder': 375},
        {transit: '07869', branchName: '07869 - Cold Lake', 'displayOrder': 380},
        {transit: '07879', branchName: '07879 - Vermilion', 'displayOrder': 385},
        {transit: '07889', branchName: '07889 - Tofield', 'displayOrder': 390},
        {transit: '07899', branchName: '07899 - Hythe', 'displayOrder': 395},
        {transit: '07909', branchName: '07909 - Calgary Marda Loop', 'displayOrder': 400},
        {transit: '07919', branchName: '07919 - Edmonton - South Edmonton Common', 'displayOrder': 405},
        {transit: '07929', branchName: '07929 - Fort Macleod', 'displayOrder': 410},
        {transit: '07939', branchName: '07939 - Claresholm', 'displayOrder': 415},
        {transit: '07949', branchName: '07949 - Foremost', 'displayOrder': 420},
        {transit: '07959', branchName: '07959 - Edmonton Strathcona', 'displayOrder': 425},
        {transit: '07969', branchName: '07969 - Coronation', 'displayOrder': 430},
        {transit: '07979', branchName: '07979 - Viking', 'displayOrder': 435},
        {transit: '07989', branchName: '07989 - Elk Point', 'displayOrder': 440},
        {transit: '07999', branchName: '07999 - Manning', 'displayOrder': 445},
        {transit: '08109', branchName: '08109 - Canmore', 'displayOrder': 450},
        {transit: '08119', branchName: '08119 - CCC', 'displayOrder': 455},
        {transit: '08129', branchName: '08129 - Granum', 'displayOrder': 460},
        {transit: '08139', branchName: '08139 - Coaldale', 'displayOrder': 465},
        {transit: '08159', branchName: '08159 - Edmonton West Pointe', 'displayOrder': 470},
        {transit: '08169', branchName: '08169 - Drayton Valley', 'displayOrder': 475},
        {transit: '08179', branchName: '08179 - Wainwright', 'displayOrder': 480},
        {transit: '08189', branchName: '08189 - Cold Lake', 'displayOrder': 485},
        {transit: '08199', branchName: '08199 - McLennan', 'displayOrder': 490},
        {transit: '08209', branchName: '08209 - Calgary Sunridge', 'displayOrder': 495},
        {transit: '08219', branchName: '08219 - Calgary Douglas Glen', 'displayOrder': 500},
        {transit: '08229', branchName: '08229 - Hanna', 'displayOrder': 505},
        {transit: '08239', branchName: '08239 - Milk River', 'displayOrder': 510},
        {transit: '08249', branchName: '08249 - Edmonton Hawkstone', 'displayOrder': 515},
        {transit: '08259', branchName: '08259 - Edmonton Oliver Village', 'displayOrder': 520},
        {transit: '08269', branchName: '08269 - Edson', 'displayOrder': 525},
        {transit: '08279', branchName: '08279 - Wetaskiwin', 'displayOrder': 530},
        {transit: '08289', branchName: '08289 - Thorsby', 'displayOrder': 535},
        {transit: '08299', branchName: '08299 - Rycroft', 'displayOrder': 540},
        {transit: '08309', branchName: '08309 - Calgary 5th Avenue Place', 'displayOrder': 545},
        {transit: '08319', branchName: '08319 - St. Albert Trail North', 'displayOrder': 550},
        {transit: '08329', branchName: '08329 - Innisfail', 'displayOrder': 555},
        {transit: '08339', branchName: '08339 - Sundre', 'displayOrder': 560},
        {transit: '08349', branchName: '08349 - Calgary McKenzie Towne', 'displayOrder': 565},
        {transit: '08359', branchName: '08359 - Edmonton Millwoods', 'displayOrder': 570},
        {transit: '08369', branchName: '08369 - Killam', 'displayOrder': 575},
        {transit: '08379', branchName: '08379 - Wildwood', 'displayOrder': 580},
        {transit: '08389', branchName: '08389 - Lamont', 'displayOrder': 585},
        {transit: '08399', branchName: '08399 - Spirit River', 'displayOrder': 590},
        {transit: '08409', branchName: '08409 - Calgary Shawnessy', 'displayOrder': 595},
        {transit: '08419', branchName: '08419 - Edmonton Calgary Trail at Whitemud', 'displayOrder': 600},
        {transit: '08429', branchName: '08429 - Nanton', 'displayOrder': 605},
        {transit: '08439', branchName: '08439 - Magrath', 'displayOrder': 610},
        {transit: '08449', branchName: '08449 - Fort McMurray Downtown', 'displayOrder': 615},
        {transit: '08459', branchName: '08459 - Edmonton Killarney', 'displayOrder': 620},
        {transit: '08469', branchName: '08469 - Lac La Biche', 'displayOrder': 625},
        {transit: '08479', branchName: '08479 - Fort McMurray Downtown', 'displayOrder': 630},
        {transit: '08489', branchName: '08489 - Sherwood Park Wye Garden', 'displayOrder': 635},
        {transit: '08499', branchName: '08499 - High Level', 'displayOrder': 640},
        {transit: '08509', branchName: '08509 - Calgary Deerfoot Meadows', 'displayOrder': 645},
        {transit: '08519', branchName: '08519 - Grande Prairie Southview', 'displayOrder': 650},
        {transit: '08529', branchName: '08529 - Olds', 'displayOrder': 655},
        {transit: '08539', branchName: '08539 - Okotoks', 'displayOrder': 660},
        {transit: '08549', branchName: '08549 - Edmonton The Grange', 'displayOrder': 665},
        {transit: '08559', branchName: '08559 - Edmonton ATB Place', 'displayOrder': 670},
        {transit: '08569', branchName: '08569 - Lacombe', 'displayOrder': 675},
        {transit: '08579', branchName: '08579 - Whitecourt', 'displayOrder': 680},
        {transit: '08589', branchName: '08589 - Calgary Sage Hill', 'displayOrder': 685},
        {transit: '08599', branchName: '08599 - Fort Vermilion', 'displayOrder': 690},
        {transit: '08609', branchName: '08609 - Edmonton Calgary Trail at Whitemud', 'displayOrder': 695},
        {transit: '08619', branchName: '08619 - Calgary SAIT Campus', 'displayOrder': 700},
        {transit: '08629', branchName: '08629 - Pincher Creek', 'displayOrder': 705},
        {transit: '08639', branchName: '08639 - Trochu', 'displayOrder': 710},
        {transit: '08649', branchName: '08649 - Fort McMurray Timberlea', 'displayOrder': 715},
        {transit: '08659', branchName: '08659 - Edmonton Killarney', 'displayOrder': 720},
        {transit: '08669', branchName: '08669 - Lloydminster', 'displayOrder': 725},
        {transit: '08679', branchName: '08679 - Daysland', 'displayOrder': 730},
        {transit: '08689', branchName: '08689 - St. Albert', 'displayOrder': 735},
        {transit: '08699', branchName: '08699 - La Crete', 'displayOrder': 740},
        {transit: '08709', branchName: '08709 - Calgary Westwinds', 'displayOrder': 745},
        {transit: '08719', branchName: '08719 - Calgary Creekside', 'displayOrder': 750},
        {transit: '08729', branchName: '08729 - Rocky Mountain House', 'displayOrder': 755},
        {transit: '08739', branchName: '08739 - Red Deer North Gaetz Crossing', 'displayOrder': 760},
        {transit: '08749', branchName: '08749 - Calgary Saddleridge', 'displayOrder': 765},
        {transit: '08759', branchName: '08759 - Edmonton Entrepreneur Centre', 'displayOrder': 770},
        {transit: '08769', branchName: '08769 - Mayerthorpe', 'displayOrder': 775},
        {transit: '08779', branchName: '08779 - Consort', 'displayOrder': 780},
        {transit: '08789', branchName: '08789 - Boyle', 'displayOrder': 785},
        {transit: '08799', branchName: '08799 - Valleyview', 'displayOrder': 790},
        {transit: '08809', branchName: '08809 - Edmonton West Pointe', 'displayOrder': 795},
        {transit: '08819', branchName: '08819 - Calgary West Springs', 'displayOrder': 800},
        {transit: '08829', branchName: '08829 - Taber', 'displayOrder': 805},
        {transit: '08839', branchName: '08839 - Carstairs', 'displayOrder': 810},
        {transit: '08859', branchName: '08859 - Edmonton West Pointe', 'displayOrder': 815},
        {transit: '08869', branchName: '08869 - Ponoka', 'displayOrder': 820},
        {transit: '08879', branchName: '08879 - Hinton', 'displayOrder': 825},
        {transit: '08889', branchName: '08889 - Bruderheim', 'displayOrder': 830},
        {transit: '08899', branchName: '08899 - Grande Prairie Northview', 'displayOrder': 835},
        {transit: '08919', branchName: '08919 - Edmonton The Meadows', 'displayOrder': 840},
        {transit: '08929', branchName: '08929 - Three Hills', 'displayOrder': 845},
        {transit: '08939', branchName: '08939 - Medicine Hat Carry Drive', 'displayOrder': 850},
        {transit: '08949', branchName: '08949 - Calgary Rocky Ridge', 'displayOrder': 855},
        {transit: '08959', branchName: '08959 - Lethbridge Mayor Magrath', 'displayOrder': 860},
        {transit: '08969', branchName: '08969 - Provost', 'displayOrder': 865},
        {transit: '08979', branchName: '08979 - Leduc', 'displayOrder': 870},
        {transit: '08989', branchName: '08989 - Onoway', 'displayOrder': 875},
        {transit: '08999', branchName: '08999 - Slave Lake', 'displayOrder': 880},
        {transit: '09109', branchName: '09109 - Executive Client Services', 'displayOrder': 885},
        {transit: '09209', branchName: '09209 - Sherwood Park Lakeland Ridge', 'displayOrder': 890},
        {transit: '09219', branchName: '09219 - Edmonton Ellerslie Landing', 'displayOrder': 895},
        {transit: '09229', branchName: '09229 - Jasper', 'displayOrder': 900},
        {transit: '09239', branchName: '09239 - Calgary Stampede Station', 'displayOrder': 905},
        {transit: '09249', branchName: '09249 - Edmonton Hollick Kenyon', 'displayOrder': 910},
        {transit: '09259', branchName: '09259 - Edmonton Windermere', 'displayOrder': 915},
        {transit: '09269', branchName: '09269 - Sylvan Lake', 'displayOrder': 920},
        {transit: '09279', branchName: '09279 - Medicine Hat Northlands', 'displayOrder': 925},
        {transit: '09289', branchName: '09289 - Airdrie Sierra Springs', 'displayOrder': 930},
        {transit: '09299', branchName: '09299 - Edmonton - Mill Creek', 'displayOrder': 935},
        {transit: '09309', branchName: '09309 - Calgary - Walden', 'displayOrder': 940},
        {transit: '09319', branchName: '09319 - Lethbridge Highlands Crossing', 'displayOrder': 945},
        {transit: '09329', branchName: '09329 - Chestermere', 'displayOrder': 950},
        {transit: '09339', branchName: '09339 - Alberta Private Client - Edmonton', 'displayOrder': 955},
        {transit: '09349', branchName: '09349 - Alberta Private Client - Calgary', 'displayOrder': 960},
        {transit: '09359', branchName: '09359 - Calgary 8th Avenue Place', 'displayOrder': 965},
        {transit: '09369', branchName: '09369 - Skypoint Ranch', 'displayOrder': 970},
        {transit: '09389', branchName: '09389 - Calgary Mahogany', 'displayOrder': 975},
        {transit: '09399', branchName: '09399 - Edmonton Mobile Branch', 'displayOrder': 980},
        {transit: '09419', branchName: '09419 - Arts & Culture- Edmonton', 'displayOrder': 985}
    ])
    .constant('numberDependentsList', [...Array(16).keys()])
    .constant('investmentExperienceList', [
        {key: 'CASH', value: 'Cash or cash equivalent', group: 0},
        {key: 'BONDS', value: 'Bonds', group: 0},
        {key: 'COMMON_SHARES', value: 'Common shares', group: 0},
        {key: 'MUTUAL_FUNDS_ETF', value: 'Mutual funds/ETF', group: 0},
        {key: 'PREFERRED_SHARES', value: 'Preferred shares', group: 0},
        {key: 'OPTIONS', value: 'Options', group: 0},
        {key: 'NEW_ISSUES', value: 'New issues', group: 1},
        {key: 'PRIVATE_SECURITIES', value: 'Private securities', group: 1},
        {key: 'CRYPTO_CURRENCIES', value: 'Crypto currencies', group: 1},
        {key: 'PRECIOUS_METALS', value: 'Precious metals', group: 1},
        {key: 'OTHER', value: 'Other', group: 1},
        {key: 'NONE', value: 'None', group: 1}
    ])
    .constant('industryList', [
        {key: 'AccountingAndOrFinance', value: 'Accounting and/or finance'},
        {key: 'AdministrativeOrClerical', value: 'Administrative/clerical'},
        {key: 'AgricultureOrRanchingOrFishing', value: 'Agriculture/ranching/fishing'},
        {key: 'AnimalWelfareOrVeterinarianOrPetShop', value: 'Animal welfare/veterinarian/pet shop'},
        {key: 'ArboristOrBotanistOrHorticulturistOrLawnCare', value: 'Arborist/botanist/horticulturist/lawn care'},
        {key: 'ArtsAndCulture', value: 'Arts and culture'},
        {key: 'BiotechOrEnvironmental', value: 'Biotech or environmental'},
        {key: 'CasinoOrGaming', value: 'Casino/gaming'},
        {key: 'CivilService', value: 'Civil service'},
        {key: 'ConstructionOrMechanical', value: 'Construction or mechanical'},
        {key: 'EducationalInstructionOrSchoolAdministration', value: 'Educational instruction/school administration'},
        {key: 'ElectedOrAppointedGovernmentOfficial', value: 'Elected or appointed government official'},
        {key: 'EngineeringSurveyingOrProjectManagement', value: 'Engineering, surveying or project management'},
        {key: 'FinancialServicesOrBanking', value: 'Financial services/banking'},
        {key: 'HealthcareOrMedicalOrPharmacy', value: 'Healthcare/medical/pharmacy'},
        {key: 'Insurance', value: 'Insurance'},
        {key: 'LawEnforcementOrEms', value: 'Law enforcement or EMS'},
        {key: 'LawyerOrLegalServices', value: 'Lawyer/legal services'},
        {key: 'ManufacturingOrIndustrialOrEquipmentOperation', value: 'Manufacturing/industrial/equipment operation'},
        {key: 'MediaOrEntertainment', value: 'Media or entertainment'},
        {key: 'Military', value: 'Military'},
        {
            key: 'NaturalResourceServiceOrExplorationOrDevelopment',
            value: 'Natural resource service/exploration/development'
        },
        {key: 'NotForProfitOrReligiousGroup', value: 'Not for profit/religious group'},
        {key: 'OilOrGas', value: 'Oil/gas'},
        {key: 'PowerOrEnergyOrUtilities', value: 'Power/energy/utilities'},
        {key: 'PropertyManagementOrJanitorial', value: 'Property management/janitorial'},
        {key: 'RealEstateOrPassiveInvestmentHoldings', value: 'Real estate or passive investment holdings'},
        {key: 'RestaurantHospitalityOrPersonalServices', value: 'Restaurant, hospitality or personal services'},
        {key: 'RetailSalesAndMarketing', value: 'Retail sales and marketing'},
        {key: 'ShippingOrTransportation', value: 'Shipping/transportation'},
        {key: 'SportsOrFitnessOrHockey', value: 'Sports/fitness/hockey'},
        {
            key: 'TechnologyTelecommunicationsOrInformationTechnology',
            value: 'Technology, telecommunications or information tech'
        },
        {key: 'VendingMachineOrCarWashOperator', value: 'Vending machine/car wash operator'},
        {key: 'WholesaleTrade', value: 'Wholesale trade'}
    ])
    .constant('incomeRangeList', [
        {key: 'FiftyThousandOrLess', value: 'Less than $50,000'},
        {key: 'FiftyThousandAndOneToOneHundredThousand', value: '$50,001 - $100,000'},
        {key: 'OneHundredThousandAndOneToOneHundredAndFiftyThousand', value: '$100,001 - $150,000'},
        {key: 'OneHundredAndFiftyThousandAndOneToTwoHundredAndFiftyThousand', value: '$150,001 - $250,000'},
        {key: 'MoreThanTwoHundredAndFiftyThousand', value: 'More than $250,000'}
    ])
    .constant('financialRisks', [
        {key: 'MinimizeRiskToAvoidLoss', value: 'Minimum'},
        {key: 'MinimizeRiskWithHigherReturnLiquidityOverGuaranteedInvestment', value: 'Modest'},
        {key: 'TolerateLossForPotentialHigherReturn', value: 'Moderate'},
        {key: 'AcceptFluctuationsInValueForLongTermPotentialGrowth', value: 'Maximum'}
    ])
    .constant('investmentKnowledgeList', [
        {key: 'Inexperienced', value: 'Very little'},
        {key: 'Fair', value: 'Some'},
        {key: 'Good', value: 'Good'},
        {key: 'Excellent', value: 'Excellent'}
    ])
    .constant('fluctuatingInvestmentsList', [
        {key: 'SellAllInvestments', value: 'Get out!'},
        {key: 'SellAPortionOfInvestments', value: 'Try to cover my bases'},
        {key: 'Hold', value: 'Wait it out'},
        {key: 'BuyMore', value: 'Jump on a potential opportunity'}
    ])
    .constant('errorCodeList', {
        success: 'success',
        error: 'error',
        info: 'info',
        warning: 'warning'
    })
    .name;
module.exports = atbConstantsModule;
