require('./resp-goal-calculation.scss');
const controller = require('./resp-goal-calculation.controller');

let respGoalCalculationComponent = {
    restrict: 'E',
    bindings: {
        form: '=',
        params: '=',
        isRecommendation: '<'
    },
    template: require('./resp-goal-calculation.html'),
    controller: ['$timeout','$filter','$mdDialog','$translate','futureValueService','userService','contributionFrequencies','monthOptions','localStorageService', controller],
    controllerAs: 'controller'
};

module.exports = respGoalCalculationComponent;