const angular = require('angular');
const angularResource = require('angular-resource');
const fundingDataService = require('./funding-data-service.service');
const devConfigurationModule = require('../dev-configuration/dev-configuration');

let fundingDataServiceModule = angular.module('app.fundingDataService', [
        angularResource,
        devConfigurationModule
    ])
    .service('fundingDataService', ['$resource', 'devConfiguration', fundingDataService])
    .name;

module.exports = fundingDataServiceModule;