const template = require('./landing-page-header.html');
const controller = require('./landing-page-header.controller');
require('./landing-page-header.scss');

let landingPageHeaderComponent = {
    restrict: 'E',
    bindings: {
    },
    template,
    controller: ['$anchorScroll', '$location', '$window', '$mdMedia', '$mdDialog', '$state', 'localStorageService', 'utilityService', controller],
    controllerAs: 'landingPageHeader'
};

module.exports = landingPageHeaderComponent;