'use strict';

//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const accessTokenRoutes = require('./access-token.routes.js');

module.exports = angular
    .module('app.accessToken', [
      uiRouter,
      angularTranslate
    ])
    .config(['$stateProvider', accessTokenRoutes])
    .name;
