const template = require('./tool-tip.html');
const controller = require('./tool-tip.controller');
require('./tool-tip.scss');

let toolTipComponent = {
    restrict: 'E',
    bindings: {
    	id: '@',
    	text: '@',
        title: '@'
    },
    template,
    controller: ['$mdDialog', '$timeout', controller],
    controllerAs: 'toolTipController'
};

module.exports = toolTipComponent;