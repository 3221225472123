//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');
const uiRouter = require('@uirouter/angularjs').default;
const userServiceModule = require('../../../services/user-service/user-service');
const goalService = require('../../../services/goal-service/goal-service');


//NON-VENDOR MODULES
const goalsHeaderComponent = require('./goals-header.component');

let goalsHeaderModule = angular
    .module('goalsHeader', [
        angularMaterial,
        uiRouter,
        userServiceModule,
        goalService
    ])
    .component('goalsHeader', goalsHeaderComponent)
    .name;

module.exports = goalsHeaderModule;
