require('./feedback-message.scss');

class FeedbackMessageController {
    constructor() {
        // TODO: replace with material icons
        this.successCircle = require('../../../public/img/green-cricle-checkmark.png');
        this.errorTriangle = require('../../../public/img/errorTriangleRed.png');
        this.infoCircle = require('../../../public/img/warningCircleYellow.png');
        this.warningTriangle = require('../../../public/img/warningTriangleYellow.png');
        this.errorCircle = require('../../../public/img/material_icons/errorCircle.png');
    }

    $onInit() {
        this.getFeedbackResult();
    }

    getFeedbackResult() {
        switch (this.feedbackStatus) {
            case 'success':
                this.feedbackImage = this.successCircle;
                this.divider = 'greenDivider';
                break;
            case 'info':
                this.feedbackImage = this.infoCircle
                this.divider = 'yellowDivider'
                break;
            case 'warning':
                this.feedbackImage = this.warningTriangle
                this.divider = 'yellowDivider'
                break;
            case 'error':
                this.feedbackImage = this.errorTriangle
                this.divider = 'redDivider'
                break;
            case 'error_circle':
                this.feedbackImage = this.errorCircle;
                this.divider = 'redDivider';
            default:
                break;
        }
    }
}

module.exports = FeedbackMessageController;
