const angular = require('angular');
const addressComponent = require('./address.component');
const loadCanadaPostModule = require('../../directives/load-canada-post/load-canada-post');

let addressModule = angular.module('address', [
    loadCanadaPostModule
])

.component('address', addressComponent)
.name;

module.exports = addressModule;