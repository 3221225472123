const template = require('./why-we-ask.html');
const controller = require('./why-we-ask.controller');
require('./why-we-ask.scss');

let whyWeAskComponent = {
    restrict: 'E',
    bindings: {
    	id: '@',
        label: '@',
    	text: '@'
    },
    template,
    controller: ['$mdDialog', controller],
    controllerAs: 'whyWeAskController'
};

module.exports = whyWeAskComponent;