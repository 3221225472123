const angular = require('angular');
const angularCookies = require('angular-cookies');
const atbolLoginComponent = require('./atbol-login.component');
const atbConstantsModule = require('../../components/atb-constants/atb-constants');

module.exports = angular
    .module('atbolLogin', [
      atbConstantsModule,
      angularCookies
    ])
    .component('atbolLogin', atbolLoginComponent)
    .name;
