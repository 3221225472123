const securityQuestionsTemplate = require('./security-question.html');
require('./security-question.scss');

function routes($stateProvider) {
    $stateProvider
        .state('v2/security-question', {
            url: '/v2/security-questions',
            parent: 'site',
            views: {
                'content@': {
                    template: securityQuestionsTemplate,
                    controller: 'SecurityQuestionController',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                data: ['userService', '$location', '$state', (userService, $location, $state) => {
                    if (userService.isUserLoggedOn()) {
                        userService.signOut();
                        $state.go('login');
                    }
                    return userService.getLoginChallenge($location.search().token);
                }],
                localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('security-question-v2');
                    $translate.refresh();
                    return true;
                }],
                controller: ['$ocLazyLoad', $ocLazyLoad => {
                    return import(/* webpackChunkName: "security-question" */'./security-question.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        });
}

module.exports = routes;
