const angular = require('angular');
const angularTranslate = require('angular-translate');
const reviewFundFactsComponent = require('./review-fund-facts.component.js');

let reviewFundFactsModule = angular
    .module('reviewFundFacts', [
        angularTranslate
    ])
    .component('reviewFundFacts', reviewFundFactsComponent)
    .name;

module.exports = reviewFundFactsModule;