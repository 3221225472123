const angular = require('angular');
const investmentAccountServiceComponent = require('./investment-account-service.service.js');
const angularResource = require('angular-resource');
const devConfigurationModule = require('../dev-configuration/dev-configuration');
const ngStorage = require('../../ng-storage.module');

module.exports = angular
    .module('app.investmentAccountService', [
      ngStorage,
      angularResource,
      devConfigurationModule
    ])
    .service('investmentAccountService', ['$resource', '$localStorage', 'devConfiguration', investmentAccountServiceComponent])
    .name;
