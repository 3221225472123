const angular = require('angular');
const angularTranslate = require('angular-translate');
const atbConstantsModule = require('../../../../../components/atb-constants/atb-constants');
const toolTipComponent = require('../../../../../components/tool-tip/tool-tip');
const questionYesNoComponent = require('../../../../../components/question-yes-no/question-yes-no');
const pepOrHioSectionComponent = require('./pep-or-hio-section.component');

let pepOrHioSectionModule = angular.module('pepOrHioSection', [
    angularTranslate,
    atbConstantsModule,
    toolTipComponent,
    questionYesNoComponent
])

    .component('pepOrHioSection', pepOrHioSectionComponent)
    .name;

module.exports = pepOrHioSectionModule;