const angular = require('angular');

class AccountTypeController {
    constructor($translate) {
        this.translate = $translate;
    }

    updateAccountType(accountType) {
        let previousAccountType = this.accountType;
        this.accountType = accountType;

        if (previousAccountType) {
            switch (previousAccountType) {
                case'CASH':
                    this.openAccountModel.accountPersonalInformation.residentOfCanada = null;
                    this.openAccountModel.accountPersonalInformation.residentOfUSA = null;
                    this.openAccountModel.accountPersonalInformation.residentOfOther = null;
                    this.openAccountModel.employmentInformation.headOfInternationalOrganization = null;
                    this.openAccountModel.employmentInformation.politicallyExposedPerson = null;
                    break;
                case 'RESF':
                // when we add other account types to education goals we will need to clear the RESF-specific uiForm fields here
                case 'TFSA':
                case 'RRSP':
                default:
                    break;
            }
        }
    }

    $onInit() {
        this.isRetirement = this.goalType === 'RETIREMENT';
        this.isEducation = this.goalType === 'EDUCATION';
        this.isGroup = this.goalType === 'GROUP';
        this.goalDescription = this.translate.instant('openAccount.accountType.' + this.goalType);
        if (!this.accountType) {
            if(this.isEducation) {
                this.accountType = 'RESF';
            } else {
                this.accountType = null;
            }
        }
    }
}

module.exports = AccountTypeController;
