class InitialInvestmentController {
    constructor() {
    }

    enableExistingBankAccountField(useExisting) {
        if (useExisting !== undefined) {
            this.formData.initialInvestmentAccount = useExisting;
            this.formData.isInitialInvestmentAccountSelected = useExisting;
        }
    }

    $onInit() {
        if (this.formData.fundingData.initialInvestmentAccount === true) {
            this.formData.initialInvestmentAccount = true;
            this.formData.isInitialInvestmentAccountSelected = true;
        } else if (this.formData.fundingData.initialInvestmentAccount === false) {
            this.formData.initialInvestmentAccount = false;
            this.formData.isInitialInvestmentAccountSelected = false;
        } else {
            this.formData.initialInvestmentAccount = null;
            this.formData.isInitialInvestmentAccountSelected = undefined;
        }
    }

    get descriptionInitialInvestment() {
        return {
            initialValue: this.formData.initialInvestment
        }
    }
}

module.exports = InitialInvestmentController;