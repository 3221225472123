const GoogleCaptchaModule = require('../components/google-captcha/google-captcha');
const PortfolioDetailModule = require('../components/portfolio-detail/portfolio-detail.module')
const RiskAssessmentService = require('../services/risk-assessment-service/risk-assessment-service')
const bannerModule = require('../components/banner/banner.module');

const routes = require('./suggested-fund.routes.js');

module.exports = angular
    .module('modules.suggestedFund', [
        GoogleCaptchaModule,
        PortfolioDetailModule,
        RiskAssessmentService,
        bannerModule
    ])
    .config(['$stateProvider', routes])
    .name;
