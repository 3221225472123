const controller = require('./match-height.controller');
let linkFunction = function (scope, element, attrs) {
    let resize = () => {
        let onDesktop = window.matchMedia("(min-width: 961px)").matches;
        let height = scope.matchHeight.getHeight(attrs.matchHeight);
        if(height != null && onDesktop) {
            element.css('height', height);
        } else if (!onDesktop) {
            let elementHeight = scope.matchHeight.getHeight(element);
            element.css('height', elementHeight);
        }
    };

    //to initialize element height
    setTimeout(()=>{
        resize();
    },50);

    //to resize on screen size change
    angular.element(scope.matchHeight.getWindow()).bind('resize', () => {
        resize();
        scope.$digest();
    });

    scope.$on('$destroy', () => {
        angular.element(scope.matchHeight.getWindow()).unbind('resize');
    });
};

let matchHeightDirective = () => {
    return {
        controller: ['$window', controller],
        controllerAs: 'matchHeight',
        restrict: 'A',
        link: linkFunction
    }
};

module.exports = matchHeightDirective;

