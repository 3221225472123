class RiskToleranceController {
    constructor(financialRisks, investmentKnowledgeList, fluctuatingInvestmentsList) {
        this.investmentDecisions = financialRisks;
        this.investmentKnowledge = investmentKnowledgeList;
        this.reactionToDecliningMarkets = fluctuatingInvestmentsList;

        this.model = {};
        this.form = {};
    }
}
module.exports = RiskToleranceController;