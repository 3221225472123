var ContactUsController = require('../../../components/contact-us/contact-us.controller');

class PasswordController {
    constructor($timeout, $state, $mdDialog) {
        this.timeout = $timeout;
        this.stateService = $state;
        this.dialogService = $mdDialog;

        this.registrationForm = {};
    }

    validateConfirmPassword() {
        this.timeout(() => {
            this.registrationForm.confirmPassword.$validate();
        });
    }

    showContactUsDetails(event) {
        this.dialogService.show({
            controller: ['$mdDialog', 'description', 'hasEmailSection', ContactUsController],
            controllerAs: 'contactUs',
            template: require('../../../components/contact-us/contact-us.html'),
            locals: {
                'description': 'header.contactUsDescription',
                'hasEmailSection': true
            },
            parent: angular.element(document.body),
            targetEvent: event,
            clickOutsideToClose: true
        });
    }
}

module.exports = PasswordController;
