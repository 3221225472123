require('./review-changes.template.scss');
const controller = require('./review-changes.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        oldModel: '=',
        newModel: '=',
        changes: '=',
        isInAnnualReview: '='
    },
    template: require('./review-changes.template.html'),
    controller: ['kycService',
        'provinces',
        'countries',
        'assetTypes',
        'atbEmploymentStatuses',
        'industryList',
        'liabilityTypes',
        'financialRisks',
        'pepOccupations',
        'hioOccupations',
        'maritalStatuses',
        'incomeRangeList',
        'employmentStatuses',
        'investmentKnowledgeList',
        'fluctuatingInvestmentsList',
        '$filter',
        controller],
    controllerAs: 'controller'
};