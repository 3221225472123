const template = require('./address.html');
const controller = require('./address.controller');
require('./address.scss');

let addressComponent = {
    restrict: 'E',
    bindings: {
        form:'=',
        formData: '=',
        addressElement: '=',
        albertaAndManitobaOnly: '<'
    },
    template,
    controller: ['$timeout', 'provinces', 'prosperAllowedProvinces', controller],
    controllerAs: 'address'
};

module.exports = addressComponent;