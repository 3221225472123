class AssetsAndLiabilitiesController {
    constructor(assetTypes, liabilityTypes) {
        this.assetTypes = assetTypes;
        this.liabilityTypes = liabilityTypes;

        // bindings below
        this.form = {};
        this.model = {};
    }

    $onInit() {
        this.initializeAssetsIfEmpty();
        this.initializeLiabilitiesIfEmpty();
    }

    initializeAssetsIfEmpty() {
        if (!this.model.assets || this.model.assets.length === 0) {
            this.model.assets = [{key: null, value: null}];
        }
    }

    initializeLiabilitiesIfEmpty() {
        if (!this.model.liabilities || this.model.liabilities.length === 0) {
            this.model.liabilities = [{key: null, value: null}];
        }
    }

    addAsset() {
        this.model.assets.push({key: null, value: null});
    }

    removeAsset(index) {
        this.model.assets.splice(index, 1);
        this.initializeAssetsIfEmpty();
    }

    addLiability() {
        this.model.liabilities.push({key: null, value: null})
    }

    removeLiability(index) {
        this.model.liabilities.splice(index, 1);
        this.initializeLiabilitiesIfEmpty();
    }

    validateAsset(index) {
        const typeField = this.form['assetType_' + index];
        const valueField = this.form['assetValue_' + index];

        // reset errors if change is made
        typeField.$setValidity('required', true);
        valueField.$setValidity('required', true);

        const asset = this.model.assets[index];
        if ((asset.key && !asset.value) || (!asset.key && asset.value)) {
            typeField.$setValidity('required', Boolean(asset.key));
            valueField.$setValidity('required', Boolean(asset.value));
        }
    }

    validateLiability(index) {
        const typeField = this.form['liabilityType_' + index];
        const valueField = this.form['liabilityValue_' + index];

        // reset errors if change is made
        typeField.$setValidity('required', true);
        valueField.$setValidity('required', true);

        const liability = this.model.liabilities[index];
        if ((liability.key && !liability.value) || (!liability.key && liability.value)) {
            typeField.$setValidity('required', Boolean(liability.key));
            valueField.$setValidity('required', Boolean(liability.value));
        }
    }

}

module.exports = AssetsAndLiabilitiesController;