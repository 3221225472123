//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');

//NON-VENDOR MODULES
const aicCtaComponent = require('./aic-cta.component.js');
let aicCtaModule = angular
    .module('aicCta', [angularMaterial])
    .component('aicCta', aicCtaComponent)
    .name;

module.exports = aicCtaModule;