const angular = require('angular');
const emailValidatorModule = require('../../../directives/email-validator/email-validator');
const spousalInformationComponent = require('./spousal-information.component');
const matchHeightModule = require('../../../directives/match-height/match-height');
let spousalInformationModule = angular.module('spousalInformation', [
    emailValidatorModule,
    matchHeightModule
])

    .component('spousalInformation', spousalInformationComponent)
    .name;

module.exports = spousalInformationModule;