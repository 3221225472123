const moment = require('moment-business-days');

const DEFAULT_PAC_LEAD_TIME = 8;
const RESP_PAC_LEAD_TIME = 10;

class StartingDateController {
    constructor(monthOptions, $filter) {
        this.dayOptions = [];
        this.monthOptions = monthOptions;
        this.yearOptions = [];
        this.filter = $filter;
    }

    setPacStartDate() {
        if (this.fundData.startMonth && this.fundData.startDay && this.fundData.startYear) {
            const validMoment = moment(
                `${this.fundData.startMonth}-01-${this.fundData.startYear}`,
                'MM-DD-YYYY'
            );
            const maxDays = validMoment.daysInMonth();
            this.setDaysInMonth(maxDays);
            if (this.fundData.startDay > maxDays) {
                this.fundData.startDay = maxDays;
            }
            const startDateMoment = moment(
                `${this.fundData.startMonth}-${this.fundData.startDay}-${this.fundData.startYear}`,
                'MM-DD-YYYY'
            );
            this.fundData.pacStartDate = this.isStartDateTooClose(startDateMoment) ? null : startDateMoment.toDate();
        } else {
            this.fundData.pacStartDate = null;
        }
    }

    isStartDateTooClose(startDateMoment) {
        const leadTime = this.formData.goal.goalType === 'EDUCATION' ? RESP_PAC_LEAD_TIME : DEFAULT_PAC_LEAD_TIME;
        const minStartDateMoment = moment(new Date()).startOf('day').businessAdd(leadTime);
        return minStartDateMoment.isAfter(startDateMoment);
    }

    setDaysInMonth(maxDays = 31) {
        this.dayOptions = [];
        if (this.pacFrequency.key === 'SEMIMONTHLY') {
            this.dayOptions = [1, 15];
        } else {
            for (let day = 1; day <= maxDays; day++) {
                this.dayOptions.push(day);
            }
        }
    }

    $onChanges() {
        this.setDaysInMonth();
        if (this.pacFrequency.key === 'SEMIMONTHLY' && this.fundData.startDay !== undefined) {
            if (this.fundData.startDay !== this.dayOptions[0] && this.fundData.startDay !== this.dayOptions[1]) {
                this.fundData.pacStartDate = null;
                this.fundData.startDay = null;
            }
        }
    }

    get fundData() {
        return this.formData.fundingData;
    }

    $onInit() {
        let year = new Date().getFullYear();
        for (let i = 0; i < 100; i++) {
            this.yearOptions.push(year);
            year++;
        }
        const date = this.fundData.pacStartDate
            ? moment(this.fundData.pacStartDate)
            : moment(new Date())
                .startOf('day')
                .businessAdd(this.formData.goal.goalType === 'EDUCATION' ? RESP_PAC_LEAD_TIME : DEFAULT_PAC_LEAD_TIME);
        this.fundData.pacStartDate = date.toDate();
        this.fundData.startDay = date.date();
        this.fundData.startMonth = date.month() + 1;
        this.fundData.startYear = date.year();
        this.setDaysInMonth(date.daysInMonth());
        if (this.pacFrequency.key === 'SEMIMONTHLY' &&
            this.fundData.startDay !== this.dayOptions[0] &&
            this.fundData.startDay !== this.dayOptions[1]) {
            this.fundData.pacStartDate = null;
            this.fundData.startDay = null;
        }
    }
}

module.exports = StartingDateController;