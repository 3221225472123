require('./contribution-details-modal.scss');
const moment = require('moment');

class ContributionDetailsModalController {
    constructor($filter, $translate, $mdDialog, date, selected) {
        this.filter = $filter;
        this.translate = $translate;
        this.dialogService = $mdDialog;
        this.date = date;
        this.selected = selected;
    }

    get currentYear() {
        return moment().year();
    }

    cancel() {
        this.dialogService.cancel();
    }
}

module.exports = ContributionDetailsModalController;
