require('./account-type-info-dialog.scss');

class AccountTypeInfoDialogController {
    constructor($mdDialog, isRetirement, isEducation, isGroup) {
        this.mdDialog = $mdDialog;
        this.isRetirement = isRetirement;
        this.isEducation = isEducation;
        this.isGroup = isGroup;
    }

    cancel() {
        this.mdDialog.cancel();
    }

    get title() {
        if (this.isEducation) {
            return 'openAccount.accountType.accountTypeInfoDialog.resp.title';
        }
        return 'openAccount.accountType.accountTypeInfoDialog.title';
    }

    get description() {
        if (this.isEducation) {
            return 'openAccount.accountType.accountTypeInfoDialog.resp.description';
        }
        return 'openAccount.accountType.accountTypeInfoDialog.description';
    }

    subtitle() {
        if (this.isEducation) {
            return 'openAccount.accountType.accountTypeInfoDialog.resp.subtitle';
        }
        return 'openAccount.accountType.accountTypeInfoDialog.tfsa.subtitle';
    }

    list() {
        if (this.isEducation) {
            return 'openAccount.accountType.accountTypeInfoDialog.resp.list';
        }
        return 'openAccount.accountType.accountTypeInfoDialog.tfsa.list';
    }
}

module.exports = AccountTypeInfoDialogController;
