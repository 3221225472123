require('./portfolio-return.scss');

class PortfolioReturnController {
    constructor($mdDialog, portfolioName, performances, performanceAsOfDate, inceptionDate) {
        this.mdDialog = $mdDialog;
        this.portfolioName = portfolioName;
        this.performances = performances;
        this.performanceAsOfDate = performanceAsOfDate;
        this.inceptionDate = inceptionDate;
    }

    cancel() {
        this.mdDialog.cancel();
    }
}

module.exports = PortfolioReturnController;
