require('./address-info.template.scss');
const controller = require('./address-info.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        type: '@',
        model: '=',
        form: '<'
    },
    template: require('./address-info.template.html'),
    controller: ['cities', 'provinces', 'countries', 'states', controller],
    controllerAs: 'controller'
};