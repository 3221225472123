const template = require('./goal-in-progress.html');
const controller = require('./goal-in-progress.controller.js');
require('./goal-in-progress.scss');

let goalInProgressComponent = {
    restrict: 'E',
    bindings: {
        'date': '<',
        'selected': '<',
        'onError': '&',
        'onDeleted': '&',
        'doConvert': '&',
        'isConversionEnabled': '<'
    },
    template,
    controller: ['$mdDialog', 'goalService', 'userService', controller],
    controllerAs: 'goalInProgress'
};

module.exports = goalInProgressComponent;
