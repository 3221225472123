'strict';

//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const ocLazyLoad = require('oclazyload');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const atbConstantsModule = require('../components/atb-constants/atb-constants');
const bankingInformation = require('./components/banking-information/banking-information');
const initialInvestment = require('./components/initial-investment/initial-investment');
const formHeader = require('../components/form-header/form-header');
const fundAccountRoutes = require('./fund-account.routes');
const fundingDataService = require('../services/funding-data-service/funding-data-service');
const goalCalculation = require('../components/goal-calculation/goal-calculation');
const respGoalCalculation = require('../components/resp-goal-calculation/resp-goal-calculation');
const prosperBonusAgreement = require('./components/prosper-bonus-agreement/prosper-bonus-agreement');
const governmentGrantsAgreement = require('./components/government-grants-agreement/government-grants-agreement');
const reviewTransactions = require('./components/review-transactions/review-transactions');
const reviewFundFacts = require('../components/review-fund-facts/review-fund-facts');
const startingDate = require('./components/starting-date/starting-date');
const utilityServiceModule = require('../services/utility-service/utility-service');

let fundAccountModule = angular
    .module('app.fundAccount', [
        angularTranslate,
        ocLazyLoad,
        uiRouter,
        atbConstantsModule,
        bankingInformation,
        initialInvestment,
        formHeader,
        fundingDataService,
        goalCalculation,
        prosperBonusAgreement,
        governmentGrantsAgreement,
        reviewTransactions,
        reviewFundFacts,
        startingDate,
        utilityServiceModule,
        respGoalCalculation
    ])
    .config(['$stateProvider', fundAccountRoutes])
    .name;

module.exports = fundAccountModule;
