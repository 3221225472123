'strict';

//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const ocLazyLoad = require('oclazyload');
const angularTranslate = require('angular-translate');
const localStorageService = require('angular-local-storage');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const startInvestingRoutes = require('./start-investing.routes.js');
const goalServiceModule = require('../../services/goal-service/goal-service');
const riskAssessmentServiceModule = require('../../services/risk-assessment-service/risk-assessment-service');
const header = require('../../components/header/header');
const whyWeAskModule = require('../../components/why-we-ask/why-we-ask');
const goalQuestionModule = require('./components/goal-question/goal-question');
const investmentTimeQuestionModule = require('./components/investment-time-question/investment-time-question');
const liquidityNeedsQuestionModule = require('./components/liquidity-needs-question/liquidity-needs-question');
const investmentKnowledgeQuestionModule = require('./components/investment-knowledge-question/investment-knowledge-question');
const incomeQuestionModule = require('./components/income-question/income-question');
const degreeOfRiskQuestionModule = require('./components/degree-of-risk-question/degree-of-risk-question');
const riskDefinitionQuestionModule = require('./components/risk-definition-question/risk-definition-question');
const fluctuationsQuestionModule = require('./components/fluctuations-question/fluctuations-question');
const investmentLossQuestionModule = require('./components/investment-loss-question/investment-loss-question');
const hisaRecommendationModule = require('./components/hisa-recommendation/hisa-recommendation');
const respSingleOrJointQuestionModule = require('./components/resp-single-or-joint-question/resp-single-or-joint-question');
const respEducationModule = require('./components/resp-education/resp-education')

let startInvestingModule = angular
    .module('app.start-investing', [
        uiRouter,
        ocLazyLoad,
        angularTranslate,
        localStorageService,
        header,
        goalServiceModule,
        riskAssessmentServiceModule,
        whyWeAskModule,
        goalQuestionModule,
        investmentTimeQuestionModule,
        liquidityNeedsQuestionModule,
        investmentKnowledgeQuestionModule,
        incomeQuestionModule,
        degreeOfRiskQuestionModule,
        riskDefinitionQuestionModule,
        fluctuationsQuestionModule,
        investmentLossQuestionModule,
        hisaRecommendationModule,
        respSingleOrJointQuestionModule,
        respEducationModule
    ])
    .config(['$stateProvider', startInvestingRoutes])
    .name;

module.exports = startInvestingModule;
