const idVerificationTemplate = require('./id-verification.html');
require('./id-verification.scss');

function routes($stateProvider) {
    $stateProvider
        .state('id-verification', {
            url: '/id-verification',
            parent: 'site',
            views:{
                'content@':{
                    template: idVerificationTemplate,
                    controller: 'idVerificationController',
                    controllerAs: 'idVerification'
                }
            },
            resolve: {
                data: ['$state', 'goalService', ($state, goalService) => {
                    return goalService.verifyValidGoalNavigation('IDVERIFICATION')
                        .catch(err => $state.go('prosper-dashboard'));

                }],
                idVerificationTranslations: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('id-verification');
                    $translate.refresh();
                    return true;
                }],
                idVerificationController: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "id-verification" */ './id-verification.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        });
}

module.exports = routes;
