'use strict';

//VENDOR MODULES
const angular = require('angular');
const angularCookies = require('angular-cookies');

//NON-VENDOR MODULES
const accountPersonalInformationComponent = require('./account-personal-information.component.js');
const atbConstantsModule = require('../../../components/atb-constants/atb-constants');
const convertToNumber = require('../../../directives/convert-to-number/convert-to-number');
const matchHeightModule = require('../../../directives/match-height/match-height');
const maximumLengthModule = require('../../../directives/maximum-length/maximum-length');
const regexValidatorModule = require('../../../directives/regex-validator/regex-validator');
const sinValidatorModule = require('../../../directives/sin-validator/sin-validator');
const toolTipModule = require('../../../components/tool-tip/tool-tip');
const userServiceModule = require('../../../services/user-service/user-service');

let accountPersonalInformationModule = angular.module('accountPersonalInformation', [
        atbConstantsModule,
        convertToNumber,
        matchHeightModule,
        maximumLengthModule,
        regexValidatorModule,
        sinValidatorModule,
        toolTipModule,
        userServiceModule,
        angularCookies
    ])

    .component('accountPersonalInformation', accountPersonalInformationComponent)
    .name;

module.exports = accountPersonalInformationModule;

