require('./roi-item.scss');

class RoiItemController {
    constructor() {
        // bindings
        this.label = null;
        this.value = null;
    }

    get icon() {
        return this.value > 0 ? 'trending_up' : this.value < 0 ? 'trending_down' : 'trending_flat';
    }
}

module.exports = RoiItemController;