require('./assets-and-liabilities.template.scss');
const controller = require('./assets-and-liabilities.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        model: '=',
        form: '<'
    },
    template: require('./assets-and-liabilities.template.html'),
    controller: ['assetTypes', 'liabilityTypes', controller],
    controllerAs: 'controller'
};