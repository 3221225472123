const template = require('./investment-time-question.html');
const controller = require('./investment-time-question.controller.js');
require('./investment-time-question.scss');

let investmentTimeQuestionComponent = {
    restrict: 'E',
    bindings: {
        questionData: '=',
        model: '=',
        onNext: '&',
        onHisa: '&',
        onPrev: '&',
        companyKey: '<'
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = investmentTimeQuestionComponent;