const fundAccountTemplate = require('./fund-account.html');
require('./fund-account.scss');

function routes($stateProvider) {
    $stateProvider
        .state(
            'fund-account', {
                url: '/fund-account',
                parent: 'site',
                views: {
                    'content@': {
                        template: fundAccountTemplate,
                        controller: 'FundAccountController',
                        controllerAs: 'fundAccount'
                    }
                },
                resolve: {
                    fundAccountScreenTranslations: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                        $translatePartialLoader.addPart('fund-account');
                        $translate.refresh();
                        return true;
                    }],
                    data: ['$state', 'goalService', ($state, goalService) => {
                        return goalService.verifyValidGoalNavigation('FUNDACCOUNT')
                            .catch(err => $state.go('prosper-dashboard'));
                    }],
                    availableAccountsData: ['fundingDataService', (fundingDataService) => {
                        return fundingDataService.getBankAccounts()
                            .then((response) => {
                                angular.forEach(response.accounts, (account) => {
                                    account.label = account.bankNumber + "-" + account.transitNumber + "-" + account.accountNumber;
                                    account.description = account.label.replace("0000000", "");
                                    account.description = account.label.replace("0219", "ATBF");
                                });
                                return response;
                            })
                            .catch(() => {
                                return [];
                            });
                    }],
                    FundAccountController: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                        return import(/* webpackChunkName: "fund-account" */ './fund-account.controller')
                            .then(controller => $ocLazyLoad.load(controller));
                    }]
                }
            });
}

module.exports = routes;
