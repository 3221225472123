'strict';

//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const header = require('../components/header/header');
const footer = require('../components/footer/footer');
const errorRoutes = require('./error.routes.js');

let errorModule = angular
    .module('app.error', [
        footer,
        header,
        uiRouter,
        angularTranslate
    ])
    .config(['$stateProvider', errorRoutes])
    .name;

module.exports = errorModule;