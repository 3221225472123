const template = require('./landing-page-goal.html');
const controller = require('./landing-page-goal.controller');
require('./landing-page-goal.scss');

let landingPageGoalComponent = {
    restrict: 'E',
    bindings: {
        translationSource: '@',
        image: '@'
    },
    template,
    controller: ['$state', '$mdDialog', 'utilityService', controller],
    controllerAs: 'landingPageGoal'
};

module.exports = landingPageGoalComponent;