const _ = require('lodash');

class PersonalInfoController {
    constructor() {
        this.model = {};
        this.form = {};
        this._isMailingAddressSameAsPrimary = false;
    }

    $onInit() {
        this._isMailingAddressSameAsPrimary = _.isEqual(this.model.primaryAddress, this.model.mailingAddress);
    }

    get isMailingAddressSameAsPrimary() {
        if (this._isMailingAddressSameAsPrimary
            && !_.isEqual(this.model.primaryAddress, this.model.mailingAddress)) {
            this.model.mailingAddress = _.cloneDeep(this.model.primaryAddress);
        }
        return this._isMailingAddressSameAsPrimary;
    }

    set isMailingAddressSameAsPrimary(newVal) {
        if (!newVal) {
            this.model.mailingAddress = {};
        }
        this._isMailingAddressSameAsPrimary = newVal;
    }

    validateFirstName() {
        this.model.firstName = (this.model.firstName || '').trim();
        this.form.firstName.$setValidity('validCharacter', true);
        this.form.firstName.$setValidity('validFirstName', true);
        if (this.model.firstName.length === 1) {
            // Weird business requirement: don't allow single character non-alphabetic first name
            this.form.firstName.$setValidity('validCharacter', (/[a-z]/i).test(this.model.firstName));
        } else {
            this.form.firstName.$setValidity('validFirstName', this.model.firstName.length <= 25)
        }
    }

    validateLastName() {
        this.model.lastName = (this.model.lastName || '').trim();
        this.form.lastName.$setValidity('validCharacter', true);
        this.form.lastName.$setValidity('validLastName', true);
        if (this.model.lastName.length === 1) {
            // Weird business requirement: don't allow single character non-alphabetic last name
            this.form.lastName.$setValidity('validCharacter', (/[a-z]/i).test(this.model.lastName));
        } else {
            this.form.lastName.$setValidity('validLastName', this.model.lastName.length <= 60)
        }
    }

    validatePreferredName() {
        this.model.preferredName = (this.model.preferredName || '').trim();
        this.form.preferredName.$setValidity('validPreferredName', this.model.preferredName.length <= 25)
    }

}

module.exports = PersonalInfoController;
