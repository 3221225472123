const dialogController = require('./account-type-info-dialog.controller');

class AccountTypeInfoController {
    constructor($mdDialog) {
        this.mdDialog = $mdDialog
    }

    openDialog(event) {
        this.mdDialog.show({
            template: require('./account-type-info-dialog.html'),
            controller: ['$mdDialog', 'isRetirement', 'isEducation', 'isGroup', dialogController],
            controllerAs: 'accountTypeInfoDialog',
            parent: angular.element(document.body),
            targetEvent: event,
            locals: {
                'isRetirement': this.isRetirement,
                'isEducation': this.isEducation,
                'isGroup': this.isGroup
            },
            clickOutsideToClose: true
        });
    }

    $onInit() {
        if (this.isEducation) {
            this.buttonText = 'openAccount.accountType.helpChooseAPlan';
        } else {
            this.buttonText = 'openAccount.accountType.helpChooseAnAccount';
        }
    }
}

module.exports = AccountTypeInfoController;