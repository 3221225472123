const template = require('./account-type-info.html');
const controller = require('./account-type-info.controller.js');
require('./account-type-info.scss');

let accountTypeInfoComponent = {
    restrict: 'E',
    bindings: {
        isEducation: '<',
        isRetirement: '<',
        isGroup: '<'
    },
    template,
    controller: ['$mdDialog', controller],
    controllerAs: 'accountTypeInfo'
};

module.exports = accountTypeInfoComponent;