class BannerController {
  constructor() {
    this.visible = true;

    ///  binding
    this.type = 'info';
  }

  $onInit() {
    this.type = this.type || 'info';
    if (!['info', 'warning', 'success', 'error'].includes(this.type.toLowerCase())) {
      this.type = 'info';
    }
  }

  get icon() {
    switch (this.type) {
      case 'success':
        return 'check_circle';
      case 'warn':
        return 'warning';
      default:
        return this.type;
    }
  }

  cancel() {
    this.visible = false;
  }
}

module.exports = BannerController;
