require('./header-confirmation-dialog.scss');

class HeaderConfirmationDialogController {
    constructor($mdDialog, companyKey) {
        this.mdDialog = $mdDialog;
        this.companyKey = companyKey;
    }

    cancel() {
        this.mdDialog.cancel();
    }

    confirm() {
        this.mdDialog.hide();
    }

    description() {
        return this.companyKey ? 'goToDashboardDialog.gwsDescription' : 'goToDashboardDialog.description';
    }
}

module.exports = HeaderConfirmationDialogController;
