class InvestmentAccountService {
  constructor($resource, $localStorage, devConfiguration) {
    this.$localStorage = $localStorage;
    let baseUrl = devConfiguration.getURLRoot(window.location.port);
    this.skipSigningApi = $resource(baseUrl + '/e-sign/skipSigning/:goalId');
    this.skipPingSigningApi = $resource(baseUrl + '/v2/e-sign/skipSigning/:goalId');

    //TODO: doesn't belong here. Create DashboardService for handling all calls
    // on dashboard.
    this.accountDataResource = $resource(baseUrl + '/dashboard/account_data/:goalId');

    this.ncafApi = $resource(baseUrl + '/ncaf/:goalId', {goalId: '@goalId'}, {
      put: {method: 'PUT'}
    });
  }

  getAccountDetails(goalId) {
    return this.accountDataResource.get({goalId: goalId}).$promise
  }

  savePartialNcaf(requestParams) {
    return this.ncafApi.put(requestParams).$promise;
  }

  skipSigning(goalId) {
    return this.skipPingSigningApi.get({goalId: goalId}).$promise
  }
}

module.exports = InvestmentAccountService;
