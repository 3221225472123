const template = require('./prosper-goal.html');
const controller = require('./prosper-goal.controller.js');
require('./prosper-goal.scss');

let prosperGoalComponent = {
    restrict: 'E',
    bindings: {
        'date': '<',
        'redraw': '<',
        'selected': '<'
    },
    template,
    controller: ['$mdDialog', '$timeout','goalService', controller],
    controllerAs: 'prosperGoal'
};

module.exports = prosperGoalComponent;
