const template = require('./beneficiary-information.html');
const controller = require('./beneficiary-information.controller.js');
require('./beneficiary-information.scss');

let beneficiaryInformationComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: '=',
        formData: '=',
        accountType: '<',
        personalInformation: '<',
        spousalInformation: '<'
    },
    template,
    controller: ['beneficiaryRelationships', controller],
    controllerAs: 'beneficiaryInformation'
};

module.exports = beneficiaryInformationComponent;
