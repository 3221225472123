//VENDOR MODULES
const angular = require('angular');
const angularMaterial = require('angular-material');

//NON-VENDOR MODULES
const prosperGoalComponent = require('./prosper-goal.component.js');
const minimumValueModule = require('../../../directives/minimum-value/minimum-value');
const maximumValueModule = require('../../../directives/maximum-value/maximum-value');
const regexValidatorModule = require('../../../directives/regex-validator/regex-validator');
const reviewFundFactsComponent = require('../../../components/review-fund-facts/review-fund-facts');
const userServiceModule = require('../../../services/user-service/user-service');
const aicCtaComponent = require('../aic-cta/aic-cta');

let prosperGoalModule = angular
    .module('prosperGoal', [
        angularMaterial,
        minimumValueModule,
        maximumValueModule,
        regexValidatorModule,
        reviewFundFactsComponent,
        userServiceModule,
        aicCtaComponent
    ])
    .component('prosperGoal', prosperGoalComponent)
    .name;

module.exports = prosperGoalModule;