const template = require('./hisa-recommendation.html');
const controller = require('./hisa-recommendation.controller');
require('./hisa-recommendation.scss');

let hisaRecommendationComponent = {
    restrict: 'E',
    bindings: {
        onPrev: '&'
    },
    template,
    controller,
    controllerAs: 'hisaRecommendation'
};

module.exports = hisaRecommendationComponent;