require('./delete-goal-modal.scss');

class DeleteGoalModalController {
    constructor($mdDialog, goalService, goalId, callbackGoalDeleted) {
        this.mdDialog = $mdDialog;
        this.goalService = goalService;
        this.goalId = goalId;
        this.callbackGoalDeleted = callbackGoalDeleted;
        this.deleteGoalProcessing = false;
    }

    cancel(message) {
        this.mdDialog.cancel(message);
        this.deleteGoalProcessing = false;
    }

    deleteGoal() {
        this.deleteGoalProcessing = true;
        this.goalService.deleteGoal(this.goalId)
            .then((response) => {
                this.callbackGoalDeleted({goalId: response.goalId});
                this.cancel({'message': 'deleteGoalModal.success', 'category': 'info'});
            })
            .catch(() => {
                this.cancel({'message': 'deleteGoalModal.error'});
            });
    }
}

module.exports = DeleteGoalModalController;
