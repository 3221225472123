const angular = require('angular');
const toolTipComponent = require('../tool-tip/tool-tip');
const questionYesNoComponent = require('./question-yes-no.component');

let questionYesNoModule = angular
    .module('questionYesNo', [
        toolTipComponent
    ])
    .component('questionYesNo', questionYesNoComponent)
    .name;

module.exports = questionYesNoModule;