const angular = require('angular');
const uiMask = require('angular-ui-mask');
const atbConstants = require('../atb-constants/atb-constants');
const questionYesNoComponent = require('../question-yes-no/question-yes-no');
const consentToShareComponent = require('./consent-to-share.component');

let consentToShareModule = angular.module('consentToShare', [
    atbConstants,
    questionYesNoComponent,
    uiMask
    ])

    .component('consentToShare', consentToShareComponent)
    .name;

module.exports = consentToShareModule;