const SocialInsuranceNumber = require('../../../social-insurance-number');
const GwsAtbEmploymentStatuses = ['AtbAgencyOrBoardMemberEmployeeSpouse', 'No', 'SpouseOfActiveOrRetiredAtbEmployee', 'FormerAtbEmployee'];

class AccountPersonalInformationController {
    constructor(countries,
                employmentStatuses,
                atbEmploymentStatuses,
                maritalStatuses,
                monthOptions,
                userService,
                numberDependentsList,
                $window) {
        this.countries = countries;
        this.employmentStatuses = employmentStatuses;
        this.atbEmploymentStatuses = atbEmploymentStatuses;
        this.maritalStatuses = maritalStatuses;
        this.monthOptions = monthOptions;
        this.userService = userService;
        this.dayOptions = [];
        this.yearOptions = [];
        this.numberDependentsList = numberDependentsList;
        this.window = $window;

        this.formData = {};
        this.fullFormData = {};
        this.openAccountForm = null;
    }

    $onInit() {
        // TODO: Don't bind both formData and fullFormData - it causes confusion and make it harder to test.
        //  Use the following instead: this.formData = this.fullFormData.accountPersonalInformation;
        for (let day = 1; day <= 31; day++) {
            this.dayOptions.push(day);
        }
        let year = new Date().getFullYear() - 18;
        for (let i = 0; i < 100; i++) {
            this.yearOptions.push(year);
            year--;
        }

        this.fullFormData.contactInformation = this.fullFormData.contactInformation || {email: this.primaryUserEmail};

        if (this.isGroupGoal) {
            this.formData.employmentStatus = "EMPLOYED";
        }

        this.initAtbEmploymentStatus();
    }

    initAtbEmploymentStatus() {
        const valid = this.availableAtbEmploymentStatus.find(it => it.value === this.formData.atbCompany);
        if (!valid) {
            this.formData.atbCompany = null;
        }
        if (!this.formData.atbCompany || this.formData.atbCompany === 'No') {
            this.formData.atbEmployeeNumber = null;
        }
    }

    get availableAtbEmploymentStatus() {
        return this.atbEmploymentStatuses
            .filter(it => this.isGroupGoal
                ? GwsAtbEmploymentStatuses.includes(it.value)
                : it.statuses.includes('*') || it.statuses.includes(this.formData.employmentStatus));
    }

    validatePrimaryCitizenship() {
        let valid = true;
        let citizenship = this.formData.citizenship;
        if (citizenship) {
            let found = this.countries.filter(function (x) {
                return x.key === citizenship;
            });

            if (found === undefined || found.length === 0) {
                this.formData.citizenship = null;
                this.formData.citizenshipCountryName = null;
                valid = false;
            }
        }
        return valid;
    }

    get age() {
        return this.userService.calculateAge(this.formData.birthMonth, this.formData.birthDay, this.formData.birthYear);
    }

    maritalStatusesForJointAccount() {
        if (this.isJointAccount) {
            if (this.formData.maritalStatus !== 'MARRIED' && this.formData.maritalStatus !== 'COMMONLAW') {
                this.formData.maritalStatus = null;
            }
            return this.maritalStatuses.filter((status) => {
                return status.key === 'MARRIED' || status.key === 'COMMONLAW';
            });

        } else {
            return this.maritalStatuses;
        }
    }

    checkMaritalStatus() {
        switch (this.formData.maritalStatus) {
            case 'SINGLE':
            case 'DIVORCED':
            case 'WIDOWED':
                this.resetSpouseInformation();
                break;
            default:
            // DO NOTHING
        }
    }

    resetSpouseInformation() {
        this.fullFormData.spousalInformation = {
            employmentInformation: {}
        };
    }

    validateResidencyAnswers() {
        this.openAccountForm.$setValidity(
            'yesToResidency',
            this.formData.residentOfUSA
            || this.formData.residentOfCanada
            || this.formData.residentOfOther
        );
    }

    onEmploymentStatusChanged() {
        this.formData.atbCompany = null;
        this.formData.atbEmployeeNumber = null;
        this.resetFormFieldError('atbCompany');
        this.resetFormFieldError('atbEmployeeNumber');
    }

    onAtbEmploymentStatusChanged() {
        if (!this.formData.atbCompany || this.formData.atbCompany === 'No') {
            this.formData.atbEmployeeNumber = null;
        }
    }

    resetFormFieldError(fieldName) {
        if (this.openAccountForm && this.openAccountForm[fieldName]) {
            this.openAccountForm[fieldName].$setPristine();
            this.openAccountForm[fieldName].$setUntouched();
        }
    }

    generateSin() {
        this.formData.sin = SocialInsuranceNumber.generate();
    }
}

module.exports = AccountPersonalInformationController;

