const template = require('./review-fund-facts.html');
const controller = require('./review-fund-facts.controller.js');
require('./review-fund-facts.scss');

let reviewFundFactsComponent = {
    restrict: 'E',
    bindings: {
        id: '@',
        form: '=',
        formData: '=',
        portfolio: '<',
        includeHeader: '<'
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = reviewFundFactsComponent;
