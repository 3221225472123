const template = require('./chart.html');
const controller = require('./chart.controller');
require('../goal-calculation/goal-calculation.scss');

let chartComponent = {
    restrict: 'E',
    bindings: {
        form: '<',
        params: '<',
        updateRecommendedPayment: '&'
    },
    template,
    controller: ['$filter', '$timeout', '$translate', 'futureValueService', controller],
    controllerAs: 'controller'
};
module.exports = chartComponent;
