const template = require('./request-email.html');
const controller = require('./request-email.controller.js');
require('./request-email.scss');

let requestEmailComponent = {
    restrict: 'E',
    bindings: {},
    template,
    controller: ['userService', '$mdDialog', '$location', controller],
    controllerAs: 'requestEmail'
};

module.exports = requestEmailComponent;