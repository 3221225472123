class AddressController {
    constructor($timeout, provinces, prosperAllowedProvinces) {
        this.timeout = $timeout;
        this.provinces = provinces;
        this.prosperAllowedProvinces = prosperAllowedProvinces;
        this.addressCompleteConfig = window.applicationConfiguration.addressCompleteConfig;
        this.postCodeRegex = new RegExp('^[A-Za-z]\\d[A-Za-z][ -]?\\d[A-Za-z]\\d$');
    }

    changeAddress() {
        this.formData.canadaPostAddress = null;
        this.formData.formattedAddress = null;
    }

    setAddressModel(address) {
        this.timeout(() => {
            // The label returned from the backend is a single line.
            // This regex is required for the label from the CP lookup, which is on 3 lines
            address.Label = address.Label.replace(/(\r\n|\n|\r)/gm, ', ');
            this.formData.address = address.Label;
            this.formData.canadaPostAddress = address;
            this.formData.formattedAddress = address.Label;
            this.checkAddressIntegrity();
        });
    }

    validateAddress(address) {
        if (['AB', 'MB', 'SK', 'BC'].includes(address.Province) || !this.albertaAndManitobaOnly) {
            this.form.formattedAddress.$setValidity('isAlbertaOrManitoba', true);
        } else {
            this.form.formattedAddress.$setValidity('isAlbertaOrManitoba', false);
        }
        if ((!address.Line1 && (!address.BuildingNumber || !address.Street)) || !address.City || !address.PostalCode || !address.Province) {
            this.form.formattedAddress.$setValidity('isValidAddress', false);
        } else {
            this.form.formattedAddress.$setValidity('isValidAddress', true);
        }
    }

    toggleManualAddressSection() {
        this.formData.manuallyEntered = !this.formData.manuallyEntered;
        this.formData.canadaPostAddress = {
            CountryName: 'Canada',
            CountryIso2: 'CA'
        };
        this.formData.address = null;
        this.formData.formattedAddress = null;
    }

    resetAddressErrors() {
        this.form.buildingNumber && this.form.buildingNumber.$setValidity('required', true);
        this.form.legalLandAddress && this.form.legalLandAddress.$setValidity('required', true);
    }

    onProvinceChanged() {
        const province = this.provinces.find(it => it.key === this.formData.canadaPostAddress.ProvinceCode);
        this.formData.canadaPostAddress.Province = province.key;
        this.formData.canadaPostAddress.ProvinceName = province.value;
    }

    checkAddressIntegrity() {
        this.resetAddressErrors();
        if (!this.formData.canadaPostAddress.PostalCode) {
            return;
        }
        if (this.formData.canadaPostAddress.PostalCode.toLowerCase().startsWith('t0')) {
            this.form.legalLandAddress && this.form.legalLandAddress.$setValidity('required', Boolean(this.formData.legalLandAddress));
        } else {
            this.form.buildingNumber && this.form.buildingNumber.$setValidity('required', Boolean(this.formData.canadaPostAddress.BuildingNumber));
        }
    }

    $onInit() {
        this.fields = [{element: this.addressElement, field: 'Label'}];
        this.options = {
            'key': this.addressCompleteConfig.key,
            'culture': 'em-us',
            'inlineMessages': true,
            'bar': {
                'showCountry': false,
                'showLogo': false,
            }
        };

        this.formData = this.formData || {};
        if (this.formData.canadaPostAddress && this.formData.canadaPostAddress.Label) {
            this.formData.address = this.formData.canadaPostAddress.Label;
            this.formData.formattedAddress = this.formData.canadaPostAddress.Label;
        }

        this.timeout(() => {
            this.control = new pca.Address(this.fields, this.options);
            //canada post populate listener
            /* istanbul ignore next */
            this.control.listen('ready', address => {
                address.setCountry('Canada');
            });
            /* istanbul ignore next */
            this.control.listen('populate', address => {
                this.setAddressModel(address);
                this.validateAddress(address);
            });
        }, 2000);
    }
}


module.exports = AddressController;
