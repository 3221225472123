const template = require('./create-profile.html');
const controller = require('./create-profile.controller');
require('./create-profile.scss');

let createProfileComponent = {
    restrict: 'E',
    bindings: {
        createProfileForm: '=',
        formData: '=',
        feedbackError: '<'
    },
    template,
    controller: ['$timeout', controller],
    controllerAs: 'controller'
};

module.exports = createProfileComponent;
