require('./annual-review-overdue-dialog.template.scss');
const moment = require('moment');

class AnnualReviewOverdueDialogController {
    constructor($window, $mdDialog, overdueDate, $state) {
        this.window = $window;
        this.mdDialog = $mdDialog;
        this.overdueDate = overdueDate;
        this.isOverdue = false;
        this.state = $state;
    }

    $onInit() {
        let now = moment();
        if (this.overdueDate.diff(now, 'days') <= 0) {
            this.isOverdue = true;
        } else if (this.overdueDate.diff(now, 'days') <= 30) {
            this.span = this.overdueDate.diff(now, 'days') + ' day(s)';
        } else if (this.overdueDate.diff(now, 'days') <= 90) {
            this.span = '3 months';
        } else if (this.overdueDate.diff(now, 'days') <= 180) {
            this.span = '6 months';
        }
    }

    cancel() {
        this.mdDialog.cancel();
    }

    confirm() {
        this.mdDialog.hide();
        this.window.open(this.window.applicationConfiguration.obsUrl, '_blank');
    }

    goToKyc(){
        this.state.go('kyc');
    }
}

module.exports = AnnualReviewOverdueDialogController;
