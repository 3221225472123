const template = require('./form-header.html');
const controller = require('./form-header.controller');
require('./form-header.scss');

let formHeaderComponent = {
    restrict: 'E',
    bindings: {
        title: '@',
        currentPage: '<',
        isEducationGoal: '<',
        skipIdVerifyPage: '<',
        isPrimaryApplicant: '<'
    },
    template,
    controller,
    controllerAs: 'formHeader'
};

module.exports = formHeaderComponent;
