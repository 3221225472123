const portfolioMersController = require('./../../../components/portfolio-mers/portfolio-mers.controller');
class InvestmentCostController {
    constructor($filter, $mdDialog, utilityService) {
        this.filter = $filter;
        this.dialogService = $mdDialog;
        this.utilityService = utilityService;
        this.checkImg = require('../../../../public/img/checkmark.svg');
        this.bonusLink = require('../../../../public/docs/atb-prosper-bonus-program-terms-and-conditions.pdf');
    }
    $onInit() {
        this.featureList = ['easyToOpen', 'initialInvestment', 'trackProgress', 'access', 'lowCost'];
        this.costDetails = [{
            id: 1,
            investmentAmount: '$1,000',
            bonus: '$5',
            fees: '$5.50',
            over20: '$393.38'
        }, {
            id: 2,
            investmentAmount: '$5,000',
            bonus: '$25',
            fees: '$27.50',
            over20: '$1,966.91'
        }, {
            id: 3,
            investmentAmount: '$10,000',
            bonus: '$50',
            fees: '$55.00',
            over20: '$3,933.82'
        }, {
            id: 4,
            investmentAmount: '$25,000',
            bonus: '$125',
            fees: '$137.50',
            over20: '$9,834.55'
        }, {
            id: 5,
            investmentAmount: '$50,000',
            bonus: '$250',
            fees: '$275.00',
            over20: '$19,669.11'
        }];
        this.currentSelection = this.costDetails[0];
    }

    getCostDetails(id) {
        this.currentSelection = this.filter('filter')(this.costDetails, {'id': id}, true)[0];
    }

    openPortfolioMersDialog(event){
        this.utilityService.getPortfoliosV2().then((response) => {
            this.dialogService.show({
                template: require('./../../../components/portfolio-mers/portfolio-mers.html'),
                controller: ['$mdDialog', '$location', '$anchorScroll', 'portfolios', portfolioMersController],
                controllerAs: 'portfolioMers',
                parent: angular.element(document.body),
                locals: {'portfolios': response.body},
                targetEvent: event,
                clickOutsideToClose:true
            });
        });

    }
}

module.exports = InvestmentCostController;
