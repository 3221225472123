require('./quick-save-timeline-modal.scss');

class QuickSaveTimelineModalController {
    constructor($mdDialog) {
        this.mdDialog = $mdDialog;
        this.panelDisplay = [{'show': false}, {'show': false}, {'show': false}];
    }

    showHidePanel(index) {
        this.panelDisplay[index].show = !this.panelDisplay[index].show;
    }
}

module.exports = QuickSaveTimelineModalController;
