// VENDOR MODULES
const angular = require('angular');
const angularTranslate = require('angular-translate');

// NON-VENDOR MODULES
const investmentExperienceComponent = require('./investment-experience.component');
require('./investment-experience.scss');

let investmentExperience = angular
    .module('investment-experience', [
        angularTranslate
    ])
    .component('investmentExperience', investmentExperienceComponent)
    .directive('investmentExperienceValidation', function() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attrs, ctrl) {
                ctrl.$validators.investmentExperiences = (a) =>  a.length > 0;
            }
        }
    })
    .name;

module.exports = investmentExperience;