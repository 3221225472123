require('./citizenship-tax-residency.template.scss');
const controller = require('./citizenship-tax-residency.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        model: '=',
        form: '<'
    },
    template: require('./citizenship-tax-residency.template.html'),
    controller: ['$window', '$timeout', 'countries', controller],
    controllerAs: 'controller'
};