class RegexValidatorController {
    constructor(regexes) {
        this.regexes = regexes;
    }
    
    getRegex(key){
        for(let i=0; i< this.regexes.length; i++){
            if(this.regexes[i].key === key){
               return  this.regexes[i].value;
            }
        }
        return null;
    }
}

module.exports = RegexValidatorController;