const routes = require('./kyc.routes.js');
const personalInfoModule = require('./components/personal-info/personal-info.module');
const reviewChangesModule = require('./components/review-changes/review-changes.module');
const maritalStatusModule = require('./components/marital-status/marital-status.module');
const employmentIncomeModule = require('./components/employment-income/employment-income.module');
const riskToleranceModule = require('./components/risk-tolerance/risk-tolerance.module');
const assetsAndLiabilitiesModule = require('./components/assets-and-liabilities/assets-and-liabilities.module');
const citizenshipTaxResidencyModule = require('./components/citizenship-tax-residency/citizenship-tax-residency.module');
const arInvestmentExperienceModule = require('./components/investment-experience/investment-experience.module');
const permissionsModule = require('./components/permissions/permissions.module');
const corporateAndPoliticalAffiliations = require('./components/corporate-and-political-affiliations/corporate-and-political-affiliations.module');
const uiRouter = require('@uirouter/angularjs').default;
const kycServiceModule = require('../services/kyc-service/kyc-service');
const confirmationModule = require('./components/confirmation/confirmation.module');
const accountDetailsModule = require('./components/account-details/account-details.module');

module.exports = angular
    .module('modules.kyc', [
        personalInfoModule,
        maritalStatusModule,
        employmentIncomeModule,
        assetsAndLiabilitiesModule,
        riskToleranceModule,
        citizenshipTaxResidencyModule,
        permissionsModule,
        corporateAndPoliticalAffiliations,
        citizenshipTaxResidencyModule,
        arInvestmentExperienceModule,
        uiRouter,
        kycServiceModule,
        reviewChangesModule,
        confirmationModule,
        accountDetailsModule
    ])
    .config(['$stateProvider', routes])
    .name;