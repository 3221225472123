//VENDOR MODULES
const angular = require('angular');

//NON-VENDOR MODULES
const userNameComponent = require('./register-user-name.component.js');
const userNameValidatorModule = require('../../../directives/username-validator/username-validator');

let userNameModule = angular.module('userName', [
        userNameValidatorModule
    ])

    .component('registerUserName', userNameComponent)
    .name;

module.exports = userNameModule;