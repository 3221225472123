const template = require('./feedback-message.html');
const controller = require('./feedback-message.controller');
require('./feedback-message.scss');

let feedbackMessageComponent = {
    restrict: 'E',
    bindings: {
        feedbackStatus: '<',
        feedbackTitle: '@',
        feedbackText: '@',
        username:'<'
    },
    template,
    controller,
    controllerAs: 'controller'
};

module.exports = feedbackMessageComponent;
