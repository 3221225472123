class FooterController {
    constructor($window) {
        this.atbisImg = require('../../../public/img/atb-wealth-logo.png');
        this.instagramImg = require('../../../public/img/instagram.svg');
        this.smartphoneImg = require('../../../public/img/mobile_icon.svg');
        this.emailImg = require('../../../public/img/email_icon.svg');
        this.chevronImg = require('../../../public/img/chevron-circle-blue.svg');
        this.ciroImg = require('../../../public/img/CIRO_Logo.svg');
        this.cipfImg = require('../../../public/img/cipf_member_colour.png');
        this.appStoreBadge = require('../../../public/img/app-store-badge-black.svg');
        this.window = $window;
        this.termsLink = require('../../../public/docs/atb-wealth-terms-and-conditions.pdf');
    }

    scrollToTop() {
        this.window.scrollTo(0, 0);
    }
}
module.exports = FooterController;
