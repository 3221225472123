const angular = require('angular');

function trackPageView(transition) {
    window.trackTime(transition.to().url);
    window.track('Page View', {
        'Current Page': transition.to().url,
        'Previous Page': transition.from().url
    });
}

function run(tmhDynamicLocale,
             tmhDynamicLocaleCache,
             authManager,
             $rootScope,
             localStorageService,
             $state,
             $mdDialog,
             $transitions,
             userService) {

    // Auto-logout after period of inactivity
    var lastUserActivity = new Date();
    setInterval(() => {
        const now = Date.now();
        if (userService.isUserLoggedOn() && now - lastUserActivity > 10*60*1000) {
          userService.signOut(true);
        }
    }, 60*1000);

    // add listeners to body events that should update last user activity
    // unfortunately we can't just watch root scope since we have silent refresh token calls happening
    angular.forEach(['keydown', 'keyup', 'click', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'focus'],
      (event) => { document.addEventListener(event, () => { lastUserActivity = Date.now() }); });


    function getInjectedLocale() {
        const localInjector = angular.injector(['ngLocale']);
        return localInjector.get('$locale');
    }

    require('angular-i18n/en-ca');
    tmhDynamicLocaleCache.put('en-ca', getInjectedLocale());

    require('angular-i18n/fr-ca');
    tmhDynamicLocaleCache.put('fr-ca', getInjectedLocale());

    tmhDynamicLocale.set('en-ca');

    // Use the authManager from angular-jwt to check for
    // the user's authentication state when the page is
    // refreshed and maintain authentication
    authManager.checkAuthOnRefresh();

    $rootScope.$on('$locationChangeStart', () => {
        if (localStorageService.isSupported === false) {
            $state.go('landing-page');
        }
    });

    $rootScope.$on('tokenHasExpired', () => {
        authManager.unauthenticate();
        localStorageService.clearAll();
        $state.go('landing-page');
    });

    $transitions.onSuccess({}, (transition) => {
        if (angular.element(document.body).hasClass('md-dialog-is-showing')) {
            // close any open dialogs
            $mdDialog.cancel();
        }
        trackPageView(transition);
    });
}

module.exports = run;
