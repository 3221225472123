class PasswordConfirmationController {
    constructor($timeout) {
        this.timeout = $timeout;
        this.showPassword = false;
        this.showConfirmation = false;
        this.showPasswordImage = require("../../../public/img/show-password.png");
        this.hidePasswordImage = require("../../../public/img/hide-password.png");
    }

    handleShowHidePassword() {
        this.showPassword = !this.showPassword;
        document.getElementById('userPassword').type = (this.showPassword  ? 'text' : 'password');
    }

    handleShowHideConfirmation() {
        this.showConfirmation = !this.showConfirmation;
        document.getElementById('confirmPassword').type = (this.showConfirmation  ? 'text' : 'password');
    }

    validateConfirmPassword() {
        let ctrl = this;
        this.timeout(() => {
            ctrl.form.confirmPassword.$validate();
        });
    }
}

module.exports = PasswordConfirmationController;