//VENDOR MODULES
const angularMaterial = require('angular-material');
const localStorageService = require('angular-local-storage');
//NON-VENDOR MODULES
const landingPageHeaderComponent = require('./landing-page-header.component');
const userServiceModule = require('../../../services/user-service/user-service');
const utilityServiceModule = require('../../../services/utility-service/utility-service');

module.exports = angular
    .module('landingPageHeader', [
      angularMaterial,
      localStorageService,
      userServiceModule,
      utilityServiceModule
    ])
    .component('landingPageHeader', landingPageHeaderComponent)
    .name;
