const _ = require('lodash');

class AddressLookupController {
    constructor($window, $timeout, provinces) {
        this.window = $window;
        this.timeout = $timeout;
        this.provinces = provinces;

        // bindings
        this.type = 'residential' | 'mailing';
        this.model = {};
    }

    $onInit() {
        angular.element(() => {
            this.fields = [
                {element: this.type + 'AddrLookup', field: 'Label'}
            ];
            this.timeout(() => {
                this.control = new this.window.pca.Address(this.fields, {
                    key: this.window.applicationConfiguration.addressCompleteConfig.key,
                    bar: {
                        showLogo: false,
                        setCursor: true,
                        showCountry: false
                    }
                });

                this.control.listen('ready', address => {
                    address.setCountry('Canada');
                });

                this.control.listen('populate', address => {
                    this.timeout(() => {
                        this.model = AddressLookupController.mapCanadaPostAddressToProsper(
                            this.provinces, address);
                    });
                });
            }, 2000)
        });
    }

    static mapCanadaPostAddressToProsper(provinces, canadaPostAddress) {
        let prosperAddress = {};
        if (AddressLookupController.isRuralAddress(canadaPostAddress)) {
            prosperAddress.addressFormat = 'Rural';
            prosperAddress.box = canadaPostAddress.Line1;
        } else {
            prosperAddress.addressFormat = 'Civic';
            prosperAddress.unitNumber = canadaPostAddress.SubBuilding;
            prosperAddress.streetNumber = canadaPostAddress.BuildingNumber;
            prosperAddress.streetName = canadaPostAddress.Street;
        }
        prosperAddress.city = canadaPostAddress.City;
        prosperAddress.postalCode = canadaPostAddress.PostalCode;
        prosperAddress.province = _.find(provinces, prov => prov.value === canadaPostAddress.ProvinceName).enum;
        prosperAddress.country = canadaPostAddress.CountryName;
        return prosperAddress;
    }

    static isRuralAddress(canadaPostAddress) {
        return canadaPostAddress.PostalCode.charAt(1) === '0'
            || canadaPostAddress.POBoxNumber
            || !canadaPostAddress.Street;
    }
}

module.exports = AddressLookupController;
