const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const incomeQuestionComponent = require('./income-question.component.js');

let incomeQuestionModule = angular
    .module('incomeQuestion', [
        angularSanitize
    ])
    .component('incomeQuestion', incomeQuestionComponent)
    .name;

module.exports = incomeQuestionModule;