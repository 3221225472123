const template = require('./banner.template.html');
const controller = require('./banner.controller');
require('./banner.template.scss');

let bannerComponent = {
  restrict: 'E',
  bindings: {
    type: '@',
    message: '@'
  },
  transclude: true,
  template,
  controller,
  controllerAs: 'controller'
};

module.exports = bannerComponent;
