const Highcharts = require('highcharts/highcharts');
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);


class InvestmentHoldingTypesController {
    constructor($window, $timeout, utilityService) {
        this.window = $window;
        this.timeout = $timeout;
        this.utilityService = utilityService;
        this.riskLevels = [
            'ATB101', 'ATB102', 'ATB103', 'ATB104', 'ATB105', 'ATB106'
        ]
        this.toolTipImage = require('../../../../public/img/toolTip.png');
    }

    $onInit() {
        this.utilityService.getPortfoliosV2().then(response => {
            this.holdings = response.body;
            this.select('ATB101');
        })
    }

    select(fund) {
        this.state = {
            riskLevel: fund,
            holding: this.holdings.find(it => it.fund === fund)
        };
    }

    isExpanded(fund) {
        return this.state && this.state.riskLevel === fund;
    }

    getPortfolio(riskLevel) {
        return this.holdings && this.holdings.find(it => it.fund === riskLevel);
    }

    onSlideValueChange(index) {
        this.select(this.riskLevels[index - 1]);
    }

}

module.exports = InvestmentHoldingTypesController;
