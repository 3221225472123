require('./employment-income.template.scss');
const controller = require('./employment-income.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        model: '=',
        form: '<'
    },
    template: require('./employment-income.template.html'),
    controller: ['employmentStatuses', 'industryList', 'incomeRangeList', 'atbEmploymentStatuses', controller],
    controllerAs: 'controller'
};