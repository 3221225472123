const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const liquidityNeedsQuestionComponent = require('./liquidity-needs-question.component.js');

let liquidityNeedsQuestionModule = angular
    .module('liquidityNeedsQuestion', [
        angularSanitize
    ])
    .component('liquidityNeedsQuestion', liquidityNeedsQuestionComponent)
    .name;

module.exports = liquidityNeedsQuestionModule;