let linkFunction = function (scope, element, attrs, ctrl) {
    let USERNAME_REGEX = /^[\w\.\-@]{6,256}$/i;

    ctrl.$validators.validUsername = function (modelValue) {
        return modelValue && USERNAME_REGEX.test(modelValue);
    };
};

let usernameValidatorDirective = function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: linkFunction
    }
};

module.exports = usernameValidatorDirective;
