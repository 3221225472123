const angular = require('angular');
const utilityServiceComponent = require('./utility-service.service');
const angularResource = require('angular-resource');
const localStorageServiceModule = require('angular-local-storage');
const devConfigurationModule = require('../dev-configuration/dev-configuration');
const atbConstantsModule = require('../../components/atb-constants/atb-constants');

let utilityServiceModule = angular.module('utilityService', [
        angularResource,
        localStorageServiceModule,
        devConfigurationModule,
        atbConstantsModule
    ])

    .service('utilityService', ['$resource', 'devConfiguration', 'pages', '$filter', utilityServiceComponent])
    .name;

module.exports = utilityServiceModule;