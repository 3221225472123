const angular = require('angular');
const passwordConfirmationComponent = require('./password-confirmation.component');
const equalToValidator = require('../../directives/equal-to-validator/equal-to-validator');

let passwordConfirmationModule = angular.module('passwordConfirmation', [
        equalToValidator
    ])
    .component('passwordConfirmation', passwordConfirmationComponent)
    .name;

module.exports = passwordConfirmationModule;