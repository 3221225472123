class HowWeMakeItHappenController {
    constructor() {
        this.oneImg = require('../../img/how-it-works-1.svg');
        this.twoImg = require('../../img/how-it-works-2.svg');
        this.threeImg = require('../../img/how-it-works-3.svg');

        this.deviceImageIndex = 0;
        this.deviceImages = [
            require('../../img/devices-step-1.png'),
            require('../../img/devices-step-2.png'),
            require('../../img/devices-step-3.png')
        ];
        
        this.selectNewDeviceImage = (index) => {
            this.deviceImageIndex = index;
        }
    }
}

module.exports = HowWeMakeItHappenController;