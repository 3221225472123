'strict';

//VENDOR MODULES
const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const ocLazyLoad = require('oclazyload');
const angularTranslate = require('angular-translate');
require('angular-translate-loader-partial');

//NON-VENDOR MODULES
const landingPageRoutes = require('./landing-page.routes.js');
const landingPageHeader = require('./components/landing-page-header/landing-page-header');
const howWeMakeItHappen = require('./components/how-we-make-it-happen/how-we-make-it-happen');
const investmentEducationComponent = require('./components/investment-education/investment-education');
const landingPageGoalComponent = require('../components/landing-page-goal/landing-page-goal');
const investmentCostComponent = require('./components/investment-cost/investment-cost');
const startInvestingComponent = require('./components/start-investing/start-investing');
const userServiceModule = require('../services/user-service/user-service');

const investmentAccountTypesModule = require('./components/investment-account-types/investment-account-types.module')
const investmentHoldingTypesModule = require('./components/investment-holding-types/investment-holding-types.module')

let landingPageModule = angular
    .module('app.landing-page', [
        uiRouter,
        ocLazyLoad,
        angularTranslate,
        landingPageHeader,
        howWeMakeItHappen,
        investmentEducationComponent,
        landingPageGoalComponent,
        investmentCostComponent,
        investmentAccountTypesModule,
        investmentHoldingTypesModule,
        startInvestingComponent,
        userServiceModule
    ])
    .config(['$stateProvider', landingPageRoutes])
    .name;

module.exports = landingPageModule;
