const template = require('./quick-save.html');
const controller = require('./quick-save.controller.js');
require('./quick-save.scss');

let quickSaveComponent = {
    restrict: 'E',
    bindings: {
        'selected': '<',
        'onError': '&'
    },
    template,
    controller: ['$mdDialog', '$timeout', controller],
    controllerAs: 'quickSave'
};

module.exports = quickSaveComponent;
