class ItemListController {
    constructor() {

        this.newItemKey = null;
        this.newItemValue = null;
    }

    addItem() {
        this.formData = this.formData || [];
        this.formData.push({
            key: this.newItemKey,
            value: this.newItemValue
        });

        this.newItemKey = null;
        this.newItemValue = null;
    }

    removeItem(index) {
        this.formData.splice(index, 1);
        this.updateTotals();
    }

    getInputName(inputName, inputType, index) {
        return inputName + inputType + index;
    }

    $onInit(){
        if (!this.formData) {
            this.formData = [];
        }
        this.updateTotals();
    }
}

module.exports = ItemListController;
