const Highcharts = require('highcharts/highcharts');
const _ = require('lodash');
const moment = require('moment');
require('highcharts/modules/no-data-to-display')(Highcharts);

const ATB_BLUE_BLACK = '#000025';
const ATB_DARK_BLUE = '#005EB8';
const ATB_LIGHT_BLUE = '#0072F0';
const ATB_ORANGE = '#FF9C35';

class ChartController {
    constructor($filter, $timeout, $translate, futureValueService) {
        this.filter = $filter;
        this.timeout = $timeout;
        this.translate = $translate;
        this.futureValueService = futureValueService;

        this.prevParams = null;
        this.showAfterRetirement = false;

        this.form = null;
        this.params = null;
        this.updateRecommendedPayment = angular.noop;
    }

    $onInit() {
        this.timeout(() => {
            this.projectedValueChart = this.params.goalType === 'GROUP' ? this.createGWSProjectionGraph() : this.createProjectionGraph();
            if (this.params.goalType === 'RETIREMENT') {
                this.afterRetirementChart = this.createAfterRetirementChart();
            }
        });
    }

    $doCheck() {
        if (!angular.equals(this.params, this.prevParams)) {
            this.prevParams = angular.copy(this.params);

            if (this.params.goalType === 'GROUP') {
                this.calcGWSProjectedValues();
            } else {
                this.calcProjectedValues();
            }

            if (this.inProgress) {
                this.timeout.cancel(this.inProgress);
            }
            this.inProgress = this.timeout(() => {
                if (this.params.goalType === 'GROUP') {
                    this.updateGWSProjectionGraph();
                } else {
                    this.updateProjectionGraph();
                }
                if (this.params.goalType === 'RETIREMENT') {
                    this.updateAfterRetirementChart();
                }
            }, 100);
            if (this.params.goalType === 'PURCHASE') {
                let payment = this.futureValueService.getRecommendedPayment(this.params);
                this.updateRecommendedPayment({recommendedPayment: Math.ceil(payment)});
            }
        }
    }

    get hasData() {
        return this.projection && this.projection.hasData;
    }

    createProjectionGraph() {
        let controller = this;
        let labelFormatter = function () {
            const value = controller.filter('currency')(this.y, '$', 0);
            const label = this.series.index === 0 ? 'Market contribution' : 'My contribution';
            return `Year: ${this.x}<br>${label}: ${value} CAD`;
        };
        let xAxisLabelFormatter = function () {
            if (this.isFirst) {
                return controller.translate.instant('goalCalculationLabels.today');
            }
            if (this.isLast) {
                return controller.params.goalType === 'PURCHASE'
                    ? moment(controller.params.goalEndDate).format('MMM, YYYY')
                    : this.value;
            }
            return null;
        };
        return this.createGraph(
            'futureValueGraph',
            [{
                name: 'Market contributions<sup>*</sup>',
                useHTML: true,
                data: [],
                color: ATB_LIGHT_BLUE
            }, {
                name: 'My contributions',
                data: [],
                color: ATB_DARK_BLUE
            }],
            xAxisLabelFormatter,
            labelFormatter)
    }

    createGWSProjectionGraph() {
        let controller = this;
        let labelFormatter = function () {
            const value = controller.filter('currency')(this.y, '$', 0);
            const label = this.series.index === 0 ? 'Market contribution' : this.series.index === 1 ? 'Employers contribution' : 'Employee contribution';
            return `Year: ${this.x}<br>${label}: ${value} CAD`;
        };
        let xAxisLabelFormatter = function () {
            if (this.isFirst) {
                return controller.translate.instant('goalCalculationLabels.today');
            }
            if (this.isLast) {
                return this.value;
            }
            return null;
        };

        return this.createGraph(
            'futureValueGraph',
            [{
                name: 'Market contributions<sup>*</sup>',
                useHTML: true,
                data: [],
                color: ATB_ORANGE
            }, {
                name: 'My employer\'s contributions',
                data: [],
                color: ATB_LIGHT_BLUE
            }, {
                name: 'My contributions',
                data: [],
                color: ATB_DARK_BLUE
            }
            ],
            xAxisLabelFormatter,
            labelFormatter)
    }

    createAfterRetirementChart() {
        let controller = this;
        let tooltipFormatter = function () {
            const value = controller.filter('currency')(this.y, '$', 0);
            return `Year: ${this.x}<br>Account balance: ${value} CAD`;
        };
        let xAxisLabelFormatter = function () {
            if (this.isFirst) {
                return this.value;
            }
            if (this.isLast) {
                return this.value;
            }
            return null;
        };
        return this.createGraph('afterRetirementGraph', [{
            color: ATB_LIGHT_BLUE
        }], xAxisLabelFormatter, tooltipFormatter)
    }

    createGraph(elementId, series, xAxisLabelFormatter, tooltipFormatter) {
        return new Highcharts.Chart({
            lang: {
                thousandsSep: ','
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: ATB_BLUE_BLACK
                }
            },
            legend: {
                enabled: true,
                symbolPadding: 10,
                symbolHeight: 16,
                symbolWidth: 16,
                symbolRadius: 8,
                itemStyle: {
                    fontSize: 14,
                    fontWeight: 'lighter'
                },
                itemHoverStyle: null,
                itemMarginTop: 5,
                itemMarginBottom: 5
            },
            chart: {
                type: 'areaspline',
                renderTo: elementId,
                alignTicks: false,
                marginRight: 30,
                marginLeft: 35,
                backgroundColor: 'transparent',
                style: {
                    overflow: 'none'
                }
            },
            title: {
                text: null
            },
            yAxis: {
                title: {
                    text: ''
                },
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                labels: {
                    enabled: false
                }
            },
            xAxis: {
                labels: {
                    enabled: true,
                    autoRotation: false,
                    style: {
                        'textOverflow': 'none',
                        'whiteSpace': 'nowrap'
                    },
                    formatter: xAxisLabelFormatter
                },
                tickInterval: 1,
                startOnTick: true,
                tickWidth: 0,
                minorTickWidth: 0
            },
            tooltip: {
                shared: false,
                valueDecimals: 0,
                formatter: tooltipFormatter,
                style: {
                    color: ATB_BLUE_BLACK
                }
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.9
                },
                series: {
                    stacking: 'normal',
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    },
                    marker: {
                        enabled: false
                    }
                }
            },
            series
        });
    }

    updateProjectionGraph() {
        if (!this.projectedValueChart) {
            return;
        }

        this.projectedValueChart.series[0].setData(this.projection.capital);
        this.projectedValueChart.series[1].setData(this.projection.lumpsum);
        this.projectedValueChart.yAxis[0].removePlotLine('benchmark');

        if (this.projection.hasData) {
            this.projectedValueChart.xAxis[0].update({min: this.projection.xMin, max: this.projection.xMax})
            this.projectedValueChart.yAxis[0].update({
                min: 0,
                max: Math.max(this.projection.yMax, this.params.goalAmount)
            })
            let plotLine = this.getPlotLineForProjectionGraph();
            this.projectedValueChart.yAxis[0].addPlotLine({
                id: 'benchmark',
                value: plotLine.value,
                color: ATB_BLUE_BLACK,
                label: {
                    text: plotLine.label,
                    useHTML: true,
                    align: 'left',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: ATB_BLUE_BLACK
                    },
                    x: 0
                },
                width: 2,
                zIndex: 4
            });
        }
        this.projectedValueChart.redraw();
        this.projectedValueChart.setSize(null);
    }

    updateGWSProjectionGraph() {
        if (!this.projectedValueChart) {
            return;
        }
        this.projectedValueChart.series[0].setData(this.projection.capital);
        this.projectedValueChart.series[1].setData(this.projection.employer);
        this.projectedValueChart.series[2].setData(this.projection.employee);

        this.projectedValueChart.yAxis[0].removePlotLine('benchmark');

        if (this.projection.hasData) {
            this.projectedValueChart.xAxis[0].update({min: this.projection.xMin, max: this.projection.xMax})
            this.projectedValueChart.yAxis[0].update({
                min: 0,
                max: Math.max(this.projection.yMax, this.params.goalAmount)
            })
            let plotLine = this.getPlotLineForProjectionGraph();
            this.projectedValueChart.yAxis[0].addPlotLine({
                id: 'benchmark',
                value: plotLine.value,
                color: ATB_BLUE_BLACK,
                label: {
                    text: plotLine.label,
                    useHTML: true,
                    align: 'left',
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: ATB_BLUE_BLACK
                    },
                    x: 0
                },
                width: 2,
                zIndex: 4
            });
        }
        this.projectedValueChart.redraw();
        this.projectedValueChart.setSize(null);
    }

    calcProjectedValues() {
        this.projection = {balance: [], lumpsum: [], capital: [], hasData: false}
        let lumpsum = [], capital = [], balance = [];

        if (this.paramsAreValid()) {
            let thisYear = moment().year();
            let totalYears = this.futureValueService.getYears(this.params);
            for (let years = 0; years <= totalYears; years++) {
                let projection = this.getProjection(years);
                balance.push({
                    x: thisYear + years,
                    y: projection.balance
                });
                lumpsum.push({
                    x: thisYear + years,
                    y: projection.lumpsum
                });
                capital.push({
                    x: thisYear + years,
                    y: projection.capital
                });
            }
            this.projection = {
                balance,
                lumpsum,
                capital,
                xMin: thisYear,
                xMax: thisYear + totalYears,
                yMin: this.params.initialInvestment,
                yMax: this.maxYAxisOrNull(balance),
                hasData: !_.isEmpty(balance)
            };
        }
    }

    calcGWSProjectedValues() {
        this.projection = {employee: [], employer: [], capital: [], hasData: false}
        let employee = [], employer = [], capital = [], total = [];
        let thisYear = moment().year();
        let totalYears = this.futureValueService.getYears(this.params);
        for (let years = 0; years <= totalYears; years++) {
            let projection = this.getGWSProjection(years);
            employee.push({
                x: thisYear + years,
                y: projection.employeeContributions
            });
            employer.push({
                x: thisYear + years,
                y: projection.employerContributions
            });
            capital.push({
                x: thisYear + years,
                y: projection.capital
            });
        }
        const max = this.maxYAxisOrNull(employee) + this.maxYAxisOrNull(employer) + this.maxYAxisOrNull(capital);

        this.projection = {
            employee,
            employer,
            capital,
            xMin: thisYear,
            xMax: thisYear + totalYears,
            yMin: 0,
            yMax: max,
            hasData: true
        };
    }

    maxYAxisOrNull(vals) {
        return (_.last(vals) || {y: null}).y;
    }

    toggleAfterRetirement() {
        this.showAfterRetirement = !this.showAfterRetirement;
        this.timeout(() => {
            this.updateAfterRetirementChart();
        })
    }

    getAfterRetirementData() {
        let data = [];
        if (this.paramsAreValid()) {
            let numberOfYearsOfRetirement = (90 - this.params.retirementAge);
            let year = this.projection.xMax + numberOfYearsOfRetirement;
            let payment = this.futureValueService.getPayment(
                0,
                this.projection.yMax,
                this.getNumberOfPayments(numberOfYearsOfRetirement, this.params.frequency.number),
                this.getInterestRate(this.params.frequency.number)
            );
            for (let i = 0; i <= numberOfYearsOfRetirement; i++) {
                let pv = this.futureValueService.getPresentValue(
                    0,
                    payment,
                    this.getNumberOfPayments(i, this.params.frequency.number),
                    this.getInterestRate(this.params.frequency.number)
                );
                data.push({x: year - i, y: pv});
            }
            return {data: data.reverse(), payment: -Math.ceil(payment), xMin: this.projection.xMax, xMax: year};
        }
        return {data}
    }

    getInterestRate(frequencyNumber) {
        return this.params.rateOfReturn / 100 / frequencyNumber;
    }

    getNumberOfPayments(years, frequencyNumber) {
        return years * frequencyNumber;
    }

    getProjection(years) {
        let numberOfPayments = this.getNumberOfPayments(years, this.params.frequency.number);
        const lumpsum = this.futureValueService.getLumpSum({
            iv: this.params.initialInvestment,
            pmt: this.params.ongoingInvestment,
            n: numberOfPayments
        });
        const balance = this.futureValueService.getFutureValue({
            pv: this.params.initialInvestment,
            pmt: this.params.ongoingInvestment,
            n: numberOfPayments,
            i: this.getInterestRate(this.params.frequency.number)
        });
        return {lumpsum, capital: balance - lumpsum, balance}
    }

    /**
     *  Params to pass for gws this.params.{value}
     *  value:
     *      averagePaycheque - Amount they make
     *      employerMatchMethod - Matching type (enum: [Money, Percent])
     *      employerMatch  - Matching amount
     *      employeeMatchMethod - employee matching type (enum: [Money, Percent])
     *      employeeContribution - Amount employee contributes
     *      employeeFrequency
     *      employerFrequency
     */
    getGWSProjection(years) {
        const numberOfEmployeePayments = this.getNumberOfPayments(years, this.params.employeeFrequency.number);
        const numberOfEmployerPayments = this.getNumberOfPayments(years, this.params.employerFrequency.number);

        const payroll = this.params.averagePaycheque;

        let employeeContribution;
        let employerContribution;
        if (this.params.employeeMatchMethod === 'Percent') {
            const employeePercent = this.params.employeeContribution / 100;
            employeeContribution = payroll * employeePercent;
        } else if (this.params.employeeMatchMethod === 'Money') {
            employeeContribution = this.params.employeeContribution;
        }
        if (this.params.employerMatchMethod === 'Percent') {
            const employerPercent = this.params.employerMatch / 100;
            employerContribution = payroll * employerPercent;
        } else if (this.params.employerMatchMethod === 'Money') {
            employerContribution = this.params.employerMatch;
        }

        const employeeContributions = numberOfEmployeePayments * employeeContribution;
        const employerContributions = numberOfEmployerPayments * employerContribution;

        const balanceEmployee = this.futureValueService.getFutureValue({
            pv: 0,
            pmt: employeeContribution,
            n: numberOfEmployeePayments,
            i: this.getInterestRate(this.params.employeeFrequency.number)
        });

        const balanceEmployer =
            this.futureValueService.getFutureValue({
                pv: 0,
                pmt: employerContribution,
                n: numberOfEmployerPayments,
                i: this.getInterestRate(this.params.employerFrequency.number)
            });

        const capital = balanceEmployee + balanceEmployer - employeeContributions - employerContributions;
        return {employeeContributions, employerContributions, capital}
    }

    get footerParameters() {
        const frequency = this.params.frequency.key === 'LDMONTHLY' ? 'MONTHLY' : this.params.frequency.key;
        return {
            portfolioName: this.params.portfolioName,
            initialInvestment: this.formatMoney(this.params.initialInvestment),
            ongoingInvestment: this.formatMoney(this.params.ongoingInvestment),
            frequency: this.translate.instant(`frequencies.${frequency}`),
            timeHorizon: this.futureValueService.getYears(this.params),
            rateOfReturn: this.params.rateOfReturn
        }
    }

    get footerParametersGWS() {
        const frequency = this.params.employeeFrequency.key === 'LDMONTHLY' ? 'MONTHLY' : this.params.employeeFrequency.key;
        return {
            portfolioName: this.params.portfolioName,
            ongoingInvestment: this.formatMoney(this.params.employeeMatchMethod === 'Money' ? this.params.employeeContribution : this.params.averagePaycheque * this.params.employeeContribution / 100),
            frequency: this.translate.instant(`frequencies.${frequency}`),
            timeHorizon: this.futureValueService.getYears(this.params),
            rateOfReturn: this.params.rateOfReturn
        }
    }

    paramsAreValid() {
        let requiredInputs = ['rateOfReturn', 'frequency', 'ongoingInvestment', 'initialInvestment'];
        switch (this.params.goalType) {
            //now to goal date
            case 'PURCHASE':
                requiredInputs.push('goalAmount');
                break;
            //show current year and retirement year with age
            case 'RETIREMENT':
                requiredInputs.push('currentAge');
                requiredInputs.push('retirementAge');
                break;
            default:
            // DO NOTHING
        }
        return requiredInputs.every(it => it in this.params)
            && this.futureValueService.getYears(this.params) >= 2;
    }

    get frequencyText() {
        return this.translate.instant('frequencies.' + this.params.frequency.key);
    }

    get frequencyTextGWS() {
        return this.translate.instant('frequencies.' + this.params.employeeFrequency.key);
    }

    formatMoney(value) {
        return this.filter('currency')(value, '$', value % 1 === 0 ? 0 : 2);
    }

    updateAfterRetirementChart() {
        if (!this.afterRetirementChart) {
            return;
        }

        let data = this.getAfterRetirementData();
        this.afterRetirementChart.series[0].setData(data.data);
        this.afterRetirementChart.legend.allItems[0].update({name: `Saved amount: ${this.formatMoney(this.projection.yMax)}`});
        this.afterRetirementChart.xAxis[0].update({min: data.xMin, max: data.xMax});
        this.afterRetirementChart.yAxis[0].update({min: 0, max: this.projection.yMax});
        this.afterRetirementChart.yAxis[0].removePlotLine('futureValuePlotLine');
        this.afterRetirementChart.yAxis[0].addPlotLine({
            id: 'futureValuePlotLine',
            value: this.projection.yMax,
            color: ATB_BLUE_BLACK,
            label: {
                text: `
                    <div style="margin-top: -4px; line-height: 14px;">
                        Saved amount: ${this.formatMoney(this.projection.yMax)}
                    </div>
                    <div style="margin-top: 10px; line-height: 14px; text-align: right;">
                        * ${this.frequencyText} withdraw: ${this.formatMoney(data.payment)}
                    </div>
                `,
                useHTML: true,
                align: 'right',
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: ATB_BLUE_BLACK
                },
                x: -43
            },
            width: 2
        });
        this.afterRetirementChart.redraw();
        this.afterRetirementChart.setSize(null);
    }

    getPlotLineForProjectionGraph() {
        return this.params.goalType === 'PURCHASE' ? {
            label: `
                    <div style="margin-top: -4px; line-height: 14px;">
                        Goal amount: ${this.formatMoney(this.params.goalAmount)}
                    </div>
                    <div style="margin-top: 10px; line-height: 14px;">
                        Saved amount: ${this.formatMoney(this.projection.yMax)}
                    </div>
                `,
            value: this.params.goalAmount
        } : this.params.goalType === 'GROUP' ? {
                label: `
                    <div style="margin-top: -4px; line-height: 14px;">
                        Saved amount: ${this.formatMoney(this.projection.yMax)}
                    </div>
                    <div style="margin-top: 10px; line-height: 14px;">
                        My recurring contribution: ${this.formatMoney(this.params.employeeMatchMethod === 'Percent' ? this.params.averagePaycheque * this.params.employeeContribution / 100 : this.params.employeeContribution)}
                    </div>
                `,
                value: this.projection.yMax
            } :
            {
                label: `
                    <div style="margin-top: -4px; line-height: 14px;">
                        Saved amount: ${this.formatMoney(this.projection.yMax)}
                    </div>
                    <div style="margin-top: 10px; line-height: 14px;">
                        ${this.frequencyText} contribution: ${this.formatMoney(this.params.ongoingInvestment)}
                    </div>
                `,
                value: this.projection.yMax
            };
    }
}

module.exports = ChartController;
