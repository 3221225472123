require('./gws-registration-dialog.scss');
class GwsRegistrationDialogController {
    constructor($window, $mdDialog) {
        this.window = $window;
        this.mdDialog = $mdDialog;
    }

    cancel() {
        this.mdDialog.cancel();
    }

    goToObs() {
        this.mdDialog.hide();
        this.window.open(this.window.applicationConfiguration.obsUrl, '_blank');
    }
}

module.exports = GwsRegistrationDialogController;
