const template = require('./pep-or-hio-section.html');
const controller = require('./pep-or-hio-section.controller');
require('./pep-or-hio-section.scss');

let pepOrHioSectionComponent = {
    restrict: 'E',
    bindings: {
        form:'=',
        formData:'=',
        sectionType:'@'
    },
    template,
    controller: ['$translate', 'pepOrHioIdentities', 'hioOccupations', 'pepTypes', 'pepOccupations', controller],
    controllerAs: 'pepOrHioSection'
};

module.exports = pepOrHioSectionComponent;