class NetWorthController {
    constructor(assetTypes, liabilityTypes) {
        this.assetTypes = assetTypes;
        this.liabilityTypes = liabilityTypes;
    }

    sumAssetTotals(){
        var assetTotals = 0;
        angular.forEach(this.formData.assetList, (asset) =>{
            assetTotals+=asset.value;
        });
        this.formData.totalAssets = assetTotals;
        this.calculateNetWorth();
    }

    sumLiabilityTotals(){
        var liabilityTotals = 0;
        angular.forEach(this.formData.liabilityList, (liability) =>{
            liabilityTotals+=liability.value;
        });
        this.formData.totalLiabilities = liabilityTotals;
        this.calculateNetWorth();
    }

    calculateNetWorth() {
        this.formData.netWorthValue = (this.formData.totalAssets - this.formData.totalLiabilities);
    }
}

module.exports = NetWorthController;