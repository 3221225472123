class RiskAssessmentServiceService {
    constructor($resource, devConfiguration, localStorageService) {
        this.riskAssessmentV1Resource = $resource(devConfiguration.getURLRoot(window.location.port) + '/:type');
        this.riskAssessmentV2Resource = $resource(devConfiguration.getURLRoot(window.location.port) + '/risk' + '/:operation');
        this.localStorageService = localStorageService;
    }

    getRiskAssessmentV1Questions() {
        return this.riskAssessmentV1Resource.get({type: 'risk_questions'}).$promise;
    }

    postRiskAssessmentV1Answers(params) {
        return this.riskAssessmentV1Resource.save({type: 'risk_answers'}, params).$promise;
    }

    getSavedRiskAssessmentV1Answers() {
        let model = this.localStorageService.get('riskAssessmentV1AnswerModel');
        if (!model) {
            model = {
                goalType: null,
                answers: {}
            };
            this.localStorageService.set('riskAssessmentV1AnswerModel', model);
        }
        return model;
    }

    buildV1RequestJSON(originalRequestObj) {
        let formattedJson = angular.copy(originalRequestObj);
        formattedJson.answers = [];
        angular.forEach(originalRequestObj.answers, (answer) => {
            if (answer.submitted) {
                const formattedAnswer = angular.copy(answer);
                if (!Array.isArray(formattedAnswer.submitted)) {
                    formattedAnswer.submitted = [{id: formattedAnswer.submitted.id}]
                }
                formattedJson.answers.push(formattedAnswer);
            }
        });
        return formattedJson;
    }

    // ==========================
    // VERSION 2 RISK MODEL BELOW

    getRiskAssessmentV2Questions() {
        return this.riskAssessmentV2Resource.query({operation: 'questions'}).$promise;
    }

    postRiskAssessmentV2Answers(params) {
        return this.riskAssessmentV2Resource.save({operation: 'answers'}, params).$promise;
    }

    getSavedRiskAssessmentV2Answers() {
        return this.localStorageService.get('riskAssessmentV2AnswerModel') || {};
    }
}

module.exports = RiskAssessmentServiceService;
