class MaximumLengthController {
    constructor($filter){
        this.filter = $filter;
    }

    getFilter(){
        return this.filter;
    }
}

module.exports = MaximumLengthController;