const template = require('./google-captcha.html');
const controller = require('./google-captcha.controller');
require('./google-captcha.scss');

let googleCaptchaComponent = {
    restrict: 'E',
    bindings: {
    	'form': '='
    },
    template,
    controller: ['googleCaptchConfig', '$window', controller],
    controllerAs: 'controller'
};

module.exports = googleCaptchaComponent;