const EditGoalDialogModalController = require('./edit-goal-dialog-modal.controller');

class EditGoalController {
    constructor($timeout, $mdDialog, monthOptions, contributionFrequencies, userService) {
        this.dialogService = $mdDialog;
        this.monthOptions = monthOptions;
        this.contributionFrequencies = contributionFrequencies;
        this.userService = userService;
        this.timeout = $timeout;

        this.selected = {};
        this.onError = angular.noop;
    }

    editGoal() {
        this.isEditGoalModalOpen = true;
        this.userService.getEditGoalDetails(this.selected.goal.id)
            .then(response => {
                this.editGoalData = {
                    recurringPacDTOList: response.recurringPacDTOList,
                    editGoalCalculations: {
                        goalType: response.goalType,
                        goalAmount: response.goalAmount,
                        goalEndDate: response.goalEndDate,
                        retirementAge: response.retirementAge,
                        initialInvestment: this.selected.calculatedTotal,
                        beneficiaryList: response.beneficiaryList
                    }
                };
                return this.dialogService
                    .show({
                        controller: ['$window',
                            '$mdDialog',
                            '$timeout',
                            '$filter',
                            'userService',
                            'monthOptions',
                            'pacFrequencies',
                            'contributionFrequencies',
                            'oldGoalCalculations',
                            'oldRecurringPacDTOList',
                            'goalKs',
                            'fundCodes',
                            EditGoalDialogModalController],
                        controllerAs: 'controller',
                        template: require('./edit-goal-dialog-modal.html'),
                        parent: angular.element(document.body),
                        locals: {
                            'goalKs': this.selected,
                            'contributionFrequencies': angular.copy(this.contributionFrequencies),
                            'oldGoalCalculations': angular.copy(this.editGoalData.editGoalCalculations),
                            'oldRecurringPacDTOList': angular.copy(this.editGoalData.recurringPacDTOList)
                        },
                        clickOutsideToClose: true
                    }).then(() => {
                        this.isEditGoalModalOpen = false
                    });
            })
            .catch(error => {
                this.onError({error: error});
                this.isEditGoalModalOpen = false;
            });
    }
}

module.exports = EditGoalController;
