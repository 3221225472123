const angular = require('angular');

const passwordComponent = require('./password.component');
const passwordConfirmationModule = require('../../../components/password-confirmation/password-confirmation');

let passwordModule = angular.module('password', [
        passwordConfirmationModule
    ])
    .component('password', passwordComponent)
    .name;

module.exports = passwordModule;
