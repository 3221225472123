require('./suggested-fund.template.scss');

function routes($stateProvider) {
    $stateProvider.state('suggested-fund', {
        url: '/suggested-fund',
        parent: 'site',
        params: {
            'companyKey': null
        },
        views: {
            'content@': {
                template: require('./suggested-fund.template.html'),
                controller: 'SuggestedFundController',
                controllerAs: 'controller'
            }
        },
        resolve: {
            data: ['riskAssessmentService', (riskAssessmentService) => {
                let answers = riskAssessmentService.getSavedRiskAssessmentV2Answers();
                return riskAssessmentService.postRiskAssessmentV2Answers(answers);
            }],
            locale: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                $translatePartialLoader.addPart('suggested-fund');
                $translate.refresh();
                return true;
            }],
            controller: ['$ocLazyLoad', $ocLazyLoad => {
                return import(/* webpackChunkName: "suggested-fund" */ './suggested-fund.controller')
                    .then(controller => $ocLazyLoad.load(controller));
            }]
        }
    });
}

module.exports = routes;
