const _ = require('lodash');
const Address = require('../address-info/address-info.controller');

class ReviewChangesController {
    constructor(kycService,
                provinces,
                countries,
                assetTypes,
                atbEmploymentStatuses,
                industryList,
                liabilityTypes,
                financialRisks,
                pepOccupations,
                hioOccupations,
                maritalStatuses,
                incomeRangeList,
                employmentStatuses,
                investmentKnowledgeList,
                fluctuatingInvestmentsList,
                $filter) {
        this.kycService = kycService;
        this.filter = $filter;

        this.translations = {
            provinces: provinces,
            assetType: assetTypes,
            industry: industryList,
            worksForAtbCompany: atbEmploymentStatuses,
            annualIncome: incomeRangeList,
            liabilityType: liabilityTypes,
            primaryCitizenship: countries,
            maritalStatus: maritalStatuses,
            secondaryCitizenship: countries,
            investmentDecision: financialRisks,
            employmentStatus: employmentStatuses,
            hioListOfPositionsHeld: hioOccupations,
            dpepListOfPositionsHeld: pepOccupations,
            fpepListOfPositionsHeld: pepOccupations,
            investmentKnowledge: investmentKnowledgeList,
            reactionToDecliningMarkets: fluctuatingInvestmentsList
        }

        this.oldModel = {};
        this.newModel = {};
    }

    $onInit() {
        this.sections = [
            {
                name: 'personal',
                fields: [
                    {name: 'firstName'},
                    {name: 'lastName'},
                    {name: 'preferredName'},
                    {
                        name: 'email',
                        diff: (oldEmail, newEmail) =>
                            this.getCheckIfEmailsAreSameDiff('email', oldEmail, newEmail)
                    },
                    {name: 'homePhone'},
                    {
                        name: 'primaryAddress',
                        diff: (oldAddress, newAddress) =>
                            this.getAddressDiff('primaryAddress', oldAddress, newAddress)

                    },
                    {name: 'isMailingAddressSameAsPrimary'},
                    {
                        name: 'mailingAddress',
                        diff: (oldAddress, newAddress) =>
                            this.getAddressDiff('mailingAddress', oldAddress, newAddress)
                    }
                ]
            },
            {
                name: 'permissions',
                fields: [
                    {
                        name: 'consentToShareInfoWithAtbf',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('consentToShareInfoWithAtbf', oldValue, newValue)
                    },
                    {
                        name: 'consentToEMarketing',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('consentToEMarketing', oldValue, newValue)
                    },
                    {
                        name: 'consentToEmailMeMyAccountInfo',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('consentToEmailMeMyAccountInfo', oldValue, newValue)
                    }
                ]
            },
            {
                name: 'family',
                fields: [
                    {
                        name: 'maritalStatus',
                        diff: (oldVal, newVal) => this.getDropdownDiff('maritalStatus', oldVal, newVal)
                    },
                    {
                        name: 'spouse',
                        diff: (oldSpouse, newSpouse) => this.getSpouseChildObjectDiff('spouse', oldSpouse, newSpouse)
                    },
                    {name: 'numberOfDependents'}
                ]
            },
            {
                name: 'taxResidency',
                fields: [
                    {
                        name: 'primaryCitizenship',
                        diff: (oldValue, newValue) => this.getDropdownDiff('primaryCitizenship', oldValue, newValue)
                    },
                    {
                        name: 'secondaryCitizenship',
                        diff: (oldValue, newValue) => this.getDropdownDiff('secondaryCitizenship', oldValue, newValue)
                    },
                    {name: 'maskedOrNewSin'},
                    {
                        name: 'taxReporting',
                        diff: (oldTax, newTax) => this.getChildObjectDiff('taxReporting', oldTax, newTax)
                    }
                ]
            },
            {
                name: 'employment',
                fields: [
                    {
                        name: 'employmentStatus',
                        diff: (oldValue, newValue) => this.getDropdownDiff('employmentStatus', oldValue, newValue)
                    },
                    {
                        name: 'annualIncome',
                        diff: (oldValue, newValue) => this.getDropdownDiff('annualIncome', oldValue, newValue)
                    },
                    {
                        name: 'worksForAtbCompany',
                        diff: (oldValue, newValue) => this.getAtbCompanyDiff('worksForAtbCompany', oldValue, newValue)
                    },
                    {name: 'employeeId'},
                    {
                        name: 'industry',
                        diff: (oldValue, newValue) => this.getDropdownDiff('industry', oldValue, newValue)
                    },
                    {name: 'occupation'},
                    {name: 'employer'}
                ]
            },
            {
                name: 'investmentExperience',
                fields: [
                    {
                        name: 'none',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('none', oldValue, newValue)
                    },
                    {
                        name: 'bonds',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('bonds', oldValue, newValue)
                    },
                    {
                        name: 'cashOrCashEquivalent',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('cashOrCashEquivalent', oldValue, newValue)
                    },
                    {
                        name: 'commonShares',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('commonShares', oldValue, newValue)
                    },
                    {
                        name: 'mutualFundsETF',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('mutualFundsETF', oldValue, newValue)
                    },
                    {
                        name: 'preferredShares',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('preferredShares', oldValue, newValue)
                    },
                    {
                        name: 'options',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('options', oldValue, newValue)
                    },
                    {
                        name: 'newIssues',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('newIssues', oldValue, newValue)
                    },
                    {
                        name: 'privateSecurities',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('privateSecurities', oldValue, newValue)
                    },
                    {
                        name: 'cryptoCurrencies',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('cryptoCurrencies', oldValue, newValue)
                    },
                    {
                        name: 'preciousMetals',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('preciousMetals', oldValue, newValue)
                    },
                    {
                        name: 'otherInvestmentExperience',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('otherInvestmentExperience', oldValue, newValue)
                    },
                    {name: 'otherInvestmentExperienceDetails'}
                ]
            },
            {
                name: 'assets',
                fields: [
                    {
                        name: 'assets',
                        diff: (oldAssets, newAssets) => {
                            let oldAssetsAsMap = this.collectToMap(oldAssets);
                            let newAssetsAsMap = this.collectToMap(newAssets);
                            return this.getAssetsAndLiabilitiesDiff('assetType', oldAssetsAsMap, newAssetsAsMap);
                        }
                    },
                    {
                        name: 'liabilities',
                        diff: (oldLiabilities, newLiabilities) => {
                            let oldLiabilitiesAsMap = this.collectToMap(oldLiabilities);
                            let newLiabilitiesAsMap = this.collectToMap(newLiabilities);
                            return this.getAssetsAndLiabilitiesDiff('liabilityType', oldLiabilitiesAsMap, newLiabilitiesAsMap);
                        }
                    }
                ]
            },
            {
                name: 'risk',
                fields: [
                    {
                        name: 'investmentDecision',
                        diff: (oldValue, newValue) => this.getDropdownDiff('investmentDecision', oldValue, newValue)
                    },
                    {
                        name: 'reactionToDecliningMarkets',
                        diff: (oldValue, newValue) => this.getDropdownDiff('reactionToDecliningMarkets', oldValue, newValue)
                    },
                    {
                        name: 'investmentKnowledge',
                        diff: (oldValue, newValue) => this.getDropdownDiff('investmentKnowledge', oldValue, newValue)
                    },
                    {
                        name: 'largeWithdrawalIndicator',
                        diff: (oldValue, newValue) => this.getDropdownDiffLargeWithdrawalIndicator('largeWithdrawalIndicator', oldValue, newValue)
                    }
                ]
            },
            {
                name: 'affiliations',
                fields: [
                    {
                        name: 'proClient',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('proClient', oldValue, newValue)
                    },
                    {
                        name: 'insider',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('insider', oldValue, newValue)
                    },
                    {
                        name: 'significantShareholder',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('significantShareholder', oldValue, newValue)
                    },
                    {
                        name: 'dpep',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('dpep', oldValue, newValue)
                    },
                    {
                        name: 'dpepListOfPositionsHeld',
                        diff: (oldValue, newValue) => this.getDropdownDiff('dpepListOfPositionsHeld', oldValue, newValue)
                    },
                    {
                        name: 'fpep',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('fpep', oldValue, newValue)
                    },
                    {
                        name: 'fpepListOfPositionsHeld',
                        diff: (oldValue, newValue) => this.getDropdownDiff('fpepListOfPositionsHeld', oldValue, newValue)
                    },
                    {
                        name: 'dpepFamilyMemberCloseAssociate',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('dpepFamilyMemberCloseAssociate', oldValue, newValue)
                    },
                    {
                        name: 'hio',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('hio', oldValue, newValue)
                    },
                    {
                        name: 'hioListOfPositionsHeld',
                        diff: (oldValue, newValue) => this.getDropdownDiff('hioListOfPositionsHeld', oldValue, newValue)
                    },
                    {
                        name: 'hioFamilyMemberCloseAssociate',
                        diff: (oldValue, newValue) => this.getCheckboxDiff('hioFamilyMemberCloseAssociate', oldValue, newValue)
                    }
                ]
            }
        ]
        this.changes = this.getDiff(this.oldModel, this.newModel)
    }

    getDropdownDiffLargeWithdrawalIndicator(name, oldVal, newVal) {
        let changes = []
        if (oldVal !== newVal) {
            if (!_.isBoolean(oldVal)) {
                changes.push({
                    field: name,
                    oldVal: ['New'],
                    newVal: [newVal ? 'Yes' : 'No']
                })
            } else {
                changes.push({
                    field: name,
                    oldVal: [oldVal ? 'Yes' : 'No'],
                    newVal: [newVal ? 'Yes' : 'No']
                })
            }
        }
        return changes
    }

    getAtbCompanyDiff(name, oldVal, newVal) {
        let changes = []
        if (oldVal !== newVal) {
            changes.push({
                field: name,
                oldVal: [this.translateAtbCompany(name, oldVal)],
                newVal: [this.translateAtbCompany(name, newVal)]
            })
        }
        return changes
    }

    getCheckIfEmailsAreSameDiff(name, oldEmail, newEmail) {
        let changes = []
        if (oldEmail.toLowerCase() !== newEmail.toLowerCase()) {
            changes.push({
                field: name,
                oldVal: [oldEmail],
                newVal: [newEmail]
            })
        }
        return changes;
    }

    translate(name, value) {
        return value
            && this.translations[name]
            && this.translations[name]
                .find(it =>
                    it.key === value
                    || it.altKey === value
                    || it.value === value)
                .value;
    }

    translateAtbCompany(name, value) {
        return value
            && this.translations[name]
            && this.translations[name]
                .find(it => it.value === value)
                .label;
    }

    getCheckboxDiff(name, oldVal, newVal) {
        let changes = []
        if (oldVal !== newVal) {
            changes.push({
                field: name,
                oldVal: [oldVal ? 'Yes' : 'No'],
                newVal: [newVal ? 'Yes' : 'No']
            })
        }
        return changes
    }

    getDropdownDiff(name, oldVal, newVal) {
        let changes = []
        if (oldVal !== newVal) {
            changes.push({
                field: name,
                oldVal: [this.translate(name, oldVal)],
                newVal: [this.translate(name, newVal)]
            })
        }
        return changes
    }

    getChildObjectDiff(name, oldObj, newObj) {
        let changes = [];
        let fields = Object.keys(oldObj);
        fields.forEach(field => {
            if (oldObj[field] !== newObj[field]) {
                if (typeof oldObj[field] === 'boolean' || typeof newObj[field] === 'boolean') {
                    changes = changes.concat(this.getCheckboxDiff(field, oldObj[field], newObj[field]));
                } else {
                    changes.push({
                        field: field,
                        oldVal: [oldObj[field]],
                        newVal: [newObj[field]]
                    })
                }
            }
        })
        return changes;
    }

    getAddressDiff(name, oldAddress, newAddress) {
        let oldLabel = this.formatAddress(oldAddress);
        let newLabel = this.formatAddress(newAddress);
        if (oldLabel !== newLabel) {
            return {
                field: name,
                oldVal: [oldLabel],
                newVal: [newLabel]
            }
        }
        return null;
    }

    collectToMap(assets) {
        const assetMap = new Map()
        for (let i = 0; i < assets.length; i++) {
            if (!assets[i].key) {
                continue;
            }
            assetMap.set(assets[i].key, assets[i].value)
        }
        return assetMap;
    }

    getAssetsAndLiabilitiesDiff(name, oldMap, newMap) {
        let changes = []
        let changesOldValues = []
        let changesNewValues = []
        oldMap.forEach((value, key) => {
            if (newMap.has(key)) {
                // Changed
                if (value !== newMap.get(key)) {
                    changesOldValues.push(this.translate(name, key) + ': ' + this.formatMoneyValue(value))
                    changesNewValues.push(this.translate(name, key) + ': ' + this.formatMoneyValue(newMap.get(key)))
                }
            } else {
                // Deleted
                changesOldValues.push(this.translate(name, key) + ': ' + this.formatMoneyValue(value))
                changesNewValues.push(null)
            }
        })
        newMap.forEach((value, key) => {
            if (!oldMap.has(key)) {
                // Added
                changesOldValues.push(null)
                changesNewValues.push(this.translate(name, key) + ': ' + this.formatMoneyValue(newMap.get(key)))
            }
        })
        if (changesOldValues.length > 0) {
            changes.push({
                field: name,
                oldVal: changesOldValues,
                newVal: changesNewValues
            })
        }
        return changes
    }

    formatMoneyValue(value) {
        return this.filter('currency')(value, '$', 0);
    }

    formatAddress(address) {
        return Address.formatAddress(this.translations.provinces, address);
    }

    getDiff(oldModel, newModel) {
        let changes = [];
        for (let i = 0; i < this.sections.length; i++) {
            let diff = {
                section: this.sections[i].name,
                fields: []
            }
            this.sections[i].fields.forEach(field => {
                if (field.diff) {
                    let item = field.diff(oldModel[field.name], newModel[field.name]);
                    if (item) {
                        diff.fields = diff.fields.concat(item);
                    }
                } else {
                    if (oldModel[field.name] !== newModel[field.name]) {
                        diff.fields.push({
                            field: field.name,
                            oldVal: [oldModel[field.name]],
                            newVal: [newModel[field.name]]
                        })
                    }
                }
            });
            if (diff.fields.length !== 0) {
                changes.push(diff);
            }
        }
        return changes;
    }

    changesMade() {
        return this.changes.length > 0;
    }

    getSpouseChildObjectDiff(name, oldSpouse, newSpouse) {
        let isNewStatusSingleOrWidow = this.isMaritalStatusSingleOrWidow(this.newModel.maritalStatus)
        let isNewStatusDivorcedOrSeparated = this.isMaritalStatusDivorcedOrSeparated(this.newModel.maritalStatus)
        if (!oldSpouse) {
            let spouseEmptyFields = {firstName: '', lastName: '', workingForAtb: false};
            let spouseDivorcedOrSeparatedEmptyFields = {firstName: '', lastName: ''};
            if (isNewStatusSingleOrWidow || !newSpouse) {
                return null;
            }
            return (isNewStatusDivorcedOrSeparated ?
                this.getChildObjectDiff(name, spouseDivorcedOrSeparatedEmptyFields, newSpouse) :
                this.getChildObjectDiff(name, spouseEmptyFields, newSpouse));

        }
        return this.getChildObjectDiff(name, oldSpouse, newSpouse);
    }

    isMaritalStatusSingleOrWidow(value) {
        return ['SINGLE', 'WIDOWED'].includes(value);
    }

    isMaritalStatusDivorcedOrSeparated(value) {
        return ['DIVORCED', 'SEPARATED'].includes(value);
    }
}

module.exports = ReviewChangesController;