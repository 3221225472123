require('./roi-item.scss');

module.exports = {
    restrict: 'E',
    bindings: {
        label: '@',
        value: '<',
        loading: '<'
    },
    template: require('./roi-item.template.html'),
    controller: require('./roi-item.controller'),
    controllerAs: 'controller'
};
