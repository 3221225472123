const template = require('./my-investments.html');
const controller = require('./my-investments.controller');
require('./my-investments.scss');

let myInvestmentsComponent = {
    restrict: 'E',
    bindings: {
        selected: '<'
    },
    template,
    controller: ['$mdDialog', '$timeout', 'goalService', 'fundCodes', '$window', controller],
    controllerAs: 'controller'
};

module.exports = myInvestmentsComponent;
