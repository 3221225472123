class MatchHeightController {
    constructor($window) {
        this.window = $window;
    }

    getWindow() {
     return this.window;
    }

    getHeight(element) {
        try {
            return document.getElementById(element).offsetHeight + "px";
        }catch (e) {
            return null;
        }
    }
}

module.exports = MatchHeightController;