const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const fluctuationsQuestionComponent = require('./fluctuations-question.component.js');
const UtilityServiceModule = require('../../../../services/utility-service/utility-service');

let fluctuationsQuestionModule = angular
    .module('fluctuationsQuestion', [
        angularSanitize,
        UtilityServiceModule
    ])
    .component('fluctuationsQuestion', fluctuationsQuestionComponent)
    .name;

module.exports = fluctuationsQuestionModule;