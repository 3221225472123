const aicDialogController = require('./aic-dialog.controller.js');

class AicCtaController {
  constructor($window, $mdDialog, $localStorage) {
    this.window = $window;
    this.dialogService = $mdDialog;
    this.localStorageService = $localStorage;
  }

  showAICModal() {
      this.dialogService.show({
          template: require('./aic-dialog.html'),
          controller: ['$window', '$mdDialog', aicDialogController],
          controllerAs: 'aicDialog',
          parent: angular.element(document.body),
          targetEvent: event,
          clickOutsideToClose: true,
          openFrom: { left: 0 },
          closeTo: { left: 0 }
      });
  }

  goToAIC() {
    this.window.open(window.applicationConfiguration.aicLinkPing, '_blank');
  }
}

module.exports = AicCtaController;
