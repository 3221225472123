const template = require('./personal-information.html');
const controller = require('./personal-information.controller');
require('./personal-information.scss');

let personalInformationComponent = {
    restrict: 'E',
    bindings: {
        formData: '=',
        registrationForm: '='
    },
    template,
    controller,
    controllerAs: 'personalInformation'
};

module.exports = personalInformationComponent;