const angular = require('angular');
const moment = require('moment');

const MATCH_METHODS = ['Percent', 'Money'];

class GoalCalculationController {
    constructor($timeout, $filter, $translate, futureValueService, contributionFrequencies, monthOptions, gwsContributionFrequencies) {
        this.timeout = $timeout;
        this.filter = $filter;
        this.translate = $translate;
        this.futureValueService = futureValueService;
        this.contributionFrequencies = contributionFrequencies;
        this.gwsContributionFrequencies = gwsContributionFrequencies;
        this.monthOptions = monthOptions;
        this.lightbulbImg = require('../../../public/img/material_icons/lightbulbWhite.svg');
        this.recommendedPayment = 0;
        this.yearOptions = [];
        this.params = {};
        this.matchOptions = {};
    }

    $onInit() {
        this.initializePacYearOptions();
        this.initializePacFrequencies();
        this.params.goalEndDate = this.params.goalEndDate
            && moment(this.params.goalEndDate).startOf('month').toDate();
        this.params.frequency = this.params.frequency || this.contributionFrequencies[0];
        this.initializeGwsFields();
    }

    get goalEndMonth() {
        return this.params.goalEndDate.getMonth();
    }

    set goalEndMonth(month) {
        if (month !== this.params.goalEndDate.getMonth()) {
            this.params.goalEndDate.setMonth(month);
            this.validateGoalEndDate();
        }
    }

    get goalEndYear() {
        return this.params.goalEndDate.getFullYear();
    }

    set goalEndYear(year) {
        if (year !== this.params.goalEndDate.getFullYear()) {
            this.params.goalEndDate.setFullYear(year);
            this.validateGoalEndDate();
        }
    }

    initializePacYearOptions() {
        let thisYear = moment().year();
        for (let i = 2; i < 100; i++) {
            this.yearOptions.push(thisYear + i);
        }
    }

    initializePacFrequencies() {
        this.params.frequency = this.contributionFrequencies.filter(
            it => angular.equals(it, this.params.frequency)
                || it.key === this.params.frequency
        )[0];
    }

    initializeGwsFields() {
        const gwsContribution = this.params.gwsContribution;
        this.params.employeeContribution = gwsContribution ? gwsContribution.employeeContribution : 0;
        this.params.employerMatch = gwsContribution ? gwsContribution.employerContribution : 0;
        this.params.averagePaycheque = gwsContribution ? gwsContribution.employeeAvgPaycheque : 0;
        this.params.employerFrequency = gwsContribution ? this.gwsContributionFrequencies.find(match => match.key === gwsContribution.employerMatchFrequency) : this.gwsContributionFrequencies[2];
        this.params.employeeFrequency = gwsContribution ? this.gwsContributionFrequencies.find(match => match.key === gwsContribution.employeePaychequeFrequency) : this.gwsContributionFrequencies[2];
        this.onFrequencyChange("employee");
        this.onFrequencyChange("employer");
        this.params.employeeMatchMethod = gwsContribution ? gwsContribution.employeeMatchMethod : 'Percent';
        this.params.employerMatchMethod = gwsContribution ? gwsContribution.employerMatchMethod : 'Percent';
    }

    setRecommendedPayment(payment) {
        this.recommendedPayment = (isNaN(payment) || payment <= this.params.ongoingInvestment)
            ? 0
            : Math.max(payment, 25);
    }

    get initialInvestment() {
        return this.params.initialInvestment;
    }

    set initialInvestment(initialInvestment) {
        if (this.params.initialInvestment !== initialInvestment) {
            this.params.initialInvestment = initialInvestment;
            this.timeout(() => {
                this.form.goalAmount && this.form.goalAmount.$validate();
            })
        }
    }

    get minGoalEndDate() {
        return moment().add(2, 'years').startOf('month').toDate();
    }

    validateGoalEndDate() {
        this.form.$setValidity('minEndDate', this.minGoalEndDate <= this.params.goalEndDate);
    }

    onFrequencyChange(role) {
        if (['BIMONTHLY', 'QUARTERLY', 'SEMIANNUAL', 'ANNUALLY'].includes(this.params[role + 'Frequency'].key)) {
            this.matchOptions[role] = ['Money'];
            this.params[role + 'MatchMethod'] = 'Money';
        } else {
            this.matchOptions[role] = ['Percent', 'Money'];
        }
    }

    /**
     * Determine whether or not if a min is needed and what the min is depending on MatchMethod
     * @returns {number} else nothing if not required
     */
    gwsEmployeeContributionMin() {
        return this.params.employeeMatchMethod === 'Percent' ? 1 : 25;
    }

    gwsContributionMax(role) {
        return this.params[role + 'MatchMethod'] === 'Percent' ? 100 : this.params.averagePaycheque;
    }
}

module.exports = GoalCalculationController;
