require('./access-token.scss');
require('../components/loading/loading.scss');

function routes($stateProvider) {
  $stateProvider
      .state('login', {
        url: '/login',
        parent: 'site',
        resolve: {
          transition: ['userService',
            (userService) => {
              userService.pingSignIn({})
            }]
        }
      })
      ///////////////
      .state('rebank', {
        url: '/rebank/callback',
        parent: 'site',
        resolve: {
          transition: ['$window', '$location', ($window, $location) => {
            const params = new URLSearchParams($location.hash());
            if (params.get('access_token')) {
              $window.parent.postMessage({
                type: 'rebank',
                status: 'success',
                state: params.get('state'),
                token: params.get('access_token')
              }, $window.origin);
            } else if (params.get('error')) {
              $window.parent.postMessage({
                type: 'rebank',
                status: 'failure',
                state: params.get('state'),
                error: params.get('error_description')
              }, $window.origin);
            }
          }]
        }
      })
      .state('rebank-logout', {
        url: '/rebank/logout',
        parent: 'site',
        resolve: {
          transition: ['$window', ($window) => {
            $window.parent.postMessage({
              type: 'rebank',
              status: 'aborted'
            }, $window.origin);
          }]
        }
      })
      .state('redirect', {
        url: '/redirect',
        parent: 'site',
        views: {
          'content@': {
            template: require('../components/loading/loading.html'),
          }
        },
        resolve: {
          transition: ['$state', '$stateParams', 'userService',
            ($state, $stateParams, userService) => {
              userService.auth0Authenticate($stateParams.hash)
                  .then(() => userService.postLogin(false))
                  .then(() => $state.go('communication-preferences'))
                  .catch(e => $state.go('landing-page', e));
            }]
        }
      })
      ///////////////
      .state('pingLoginSuccess', {
        url: '/pingLoginSuccess?code&state',
        parent: 'site',
        params: {
          code: null,
          state: null
        },
        views: {
          'content@': {
            template: require('../components/loading/loading.html'),
          }
        },
        resolve: {
          transition: ['$state', '$stateParams', 'userService',
            ($state, $stateParams, userService) => {
              userService.pingAuthenticate()
                  .then(() => userService.postLogin(true))
                  .then(() => $state.go('communication-preferences'))
                  .catch(e => {
                    window.mixpanel.track('Login - Failure', {
                      'loginFailureReason': 'Landing Page Load Error',
                    });
                    $state.go('landing-page', e);
                  });
            }]
        }
      })
      .state('communication-preferences', {
        url: '/communication-preferences',
        parent: 'site',
        views: {
          'content@': {
            template: require('../components/communication-preferences/communication-preferences.html'),
            controller: 'ConsentController',
            controllerAs: 'controller'
          }
        },
        resolve: {
          localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
            $translatePartialLoader.addPart('consent');
            $translate.refresh();
            return true;
          }],
          controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
            return import(/* webpackChunkName: "welcome" */ '../components/communication-preferences/communication-preferences.controller')
                .then(controller => $ocLazyLoad.load(controller));
          }]
        }
      });
}

module.exports = routes;
