require('./portfolio-detail.template.scss');
const controller = require('./portfolio-detail.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        portfolio: '<'
    },
    template: require('./portfolio-detail.template.html'),
    controller: ['$mdDialog', '$window', '$timeout', '$translate', controller],
    controllerAs: 'controller'
}