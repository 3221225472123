class CorporateAndPoliticalAffiliationsController {
    constructor(pepOccupations, hioOccupations, atbEmploymentStatuses) {

        this.model = {};
        this.form = {};

        this.domesticPepOccupations = pepOccupations;
        this.foreignPepOccupations = pepOccupations.slice(0, 9);
        this.hioOccupations = hioOccupations;
        this.atbEmploymentStatusList = atbEmploymentStatuses;
    }

    $onInit() {
        this.accountTypeIsCash = this.isAccountTypeCash(this.model);
        if (this.isSecurityDealer()) {
            this.model.proClient = true;
        }
    }

    isSecurityDealer() {
        const self = this.atbEmploymentStatusList.find(it => it.value === this.model.worksForAtbCompany);
        return Boolean(self && self.isDealer);
    }

    isAccountTypeCash(model) {
        return model.financialAccounts.find(it => it.financialAccountType === 'Cash');
    }
}

module.exports = CorporateAndPoliticalAffiliationsController;
