const Highcharts = require('highcharts/highcharts');
require('highcharts/modules/no-data-to-display')(Highcharts);

const ATB_ORANGE = '#ed8900';
const ATB_WHITE = '#ffffff';

class GoalsHeaderController {
    constructor($state, $timeout, userService, goalService) {
        this.state = $state;
        this.timeout = $timeout;
        this.userService = userService;
        this.goalService = goalService;
        this.data = {};
        this.resetCurrentView = angular.noop;
    }

    $onChanges() {
        for (let i = 0; i < this.data.goals.length; i++) {
            let goalKs = this.data.goals[i];
            if (goalKs.type === 'PURCHASE') {
                this.timeout(() => {
                    let chart = this.createChart(goalKs);
                    chart.reflow()
                });
            }
        }
        this.doSelect(this.data.selected);
    }

    createChart(goalKs) {
        let progress = this.goalService.getProgress(goalKs);
        return new Highcharts.Chart({
            chart: {
                backgroundColor: null,
                renderTo: 'goalHeader' + goalKs.id,
                type: 'pie',
                margin: [0, 0, 0, 0],
                spacingTop: 15,
                spacingBottom: 15,
                spacingLeft: 15,
                spacingRight: 15
            },
            title: {
                text: '',
                style: {
                    display: 'none'
                }
            },
            plotOptions: {
                series: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                pie: {
                    slicedOffset: 0,
                    shadow: false
                }
            },
            tooltip: {
                pointFormat: '<b>{point.y}</b><br/>',
                valueSuffix: '%'
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Goal Progress',
                data: [
                    {name: 'Progress complete', y: progress, color: ATB_ORANGE},
                    {name: 'Progress remaining', y: 100 - progress, color: ATB_WHITE}
                ],
                size: '100%',
                innerSize: '90%',
                dataLabels: {
                    enabled: false
                },
                borderWidth: 0
            }]
        });
    }

    isSelected(goalKs) {
        if (goalKs && goalKs.isNative === this.data.selected.isNative) {
            if (goalKs.isNative) {
                return goalKs.goal.id === this.data.selected.goal.id;
            }
            return goalKs.account.id === this.data.selected.account.id;
        }
        return false;
    }

    showAccountBalance(goalKs) {
        return goalKs && (!goalKs.isNative || goalKs.goal.status === 'COMPLETED');
    }

    showContinueButton(goalKs) {
        return goalKs && this.goalService.canContinue(goalKs);
    }

    doAdd() {
        window.unregister(['Goal Type', 'Account Type']);
        window.track('Add Goal');

        this.userService.removeUserAction();
        this.goalService.removeDocuSignStatus();
        this.goalService.setHasGroupGoal(this.hasGroupGoal());

        this.state.go('start-investing');
    }

    hasGroupGoal() {
        return this.data.goals ?
            this.data.goals
            .filter(item => item.goal)
            .filter(item => item.goal.goalType === 'GROUP')
            .length > 0 :
                false;
    }

    doSelect(goalKs) {
        if (goalKs && goalKs.goal) {
            window.register({
                'Goal Type': goalKs.goal.goalType,
                'Account Type': goalKs.goal.accountType
            });
        }
        window.track('Select Goal');

        this.data.selected = goalKs;
        this.resetCurrentView();
    }

    doContinue(goalKs) {
        if (goalKs) {
            this.userService.removeUserAction();
            this.goalService.goToGoalInProgress(goalKs.goal.currentPage, goalKs.goal.id);
        }
    }
}

module.exports = GoalsHeaderController;
