const template = require('./goal-question.html');
const controller = require('./goal-question.controller.js');
require('./goal-question.scss');

let goalQuestionComponent = {
    restrict: 'E',
    bindings: {
        model: '=',
        onNext: '&',
        hasGroupGoal: '<'
    },
    template,
    controller: ['$window', controller],
    controllerAs: 'goalQuestion'
};

module.exports = goalQuestionComponent;