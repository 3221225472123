const toolTipDialogController = require('./tool-tip-dialog.controller.js');

class ToolTipController {
    constructor($mdDialog, $timeout) {
    	this.dialogService = $mdDialog;
		this.timeout = $timeout;
    }

	openDialog(event){
		this.timeout( () => {
			this.dialogService.show({
				template: require('./tool-tip-dialog.html'),
				controller: ['text', '$mdDialog', toolTipDialogController],
				controllerAs: 'toolTipDialog',
				ariaLabel: this.text,
				parent: angular.element(document.body),
				targetEvent: event,
				locals: {
					'text': this.text
				},
				clickOutsideToClose:true
			});
		},50);
	}
}

module.exports = ToolTipController;