const Highcharts = require('highcharts/highcharts');
const _ = require('lodash');
const portfolioReturnController = require('../portfolio-return/portfolio-return.controller');
require('highcharts/modules/drilldown')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

const ATB_BLUE = '#8FDFFF';
const ATB_PRIMARY_BLUE = '#0072F0';
const ATB_ORANGE_300 = '#FF9C35';
const ATB_LIGHT_BLUE = '#8FDFFF';
const ATB_YELLOW_500 = '#FCDC3E';
const ATB_LIGHT_BLUE_100 = '#D9F4FF';
const ATB_ORANGE = '#FF7F30';
const ATB_YELLOW_300 = '#FAF182';
const ATB_WHITE = '#FFFFFF';
const ATB_BLUE_BLACK = '#000025';
const ATB_QUALITATIVE_PALETTE = [ATB_PRIMARY_BLUE, ATB_ORANGE_300, ATB_LIGHT_BLUE,
    ATB_YELLOW_500, ATB_LIGHT_BLUE_100, ATB_ORANGE, ATB_YELLOW_300];

class PortfolioBreakdownChartController {
  constructor($mdDialog, $window, $timeout, $translate) {
    this.window = $window;
    this.timeout = $timeout;
    this.dialog = $mdDialog;
    this.translateService = $translate;
    this.chart = null;

    /* bindings */
    this.id = null;
    this.size = null;
    this.header = null;
    this.portfolio = null;
    this.primaryColor = null;
    this.darkMode = false;
    this.labelColor = ATB_WHITE;

    this.toolTipImage = require('../../../public/img/toolTip.svg');
  }

  $onInit() {
    this.size = this.size || 200;
    this.primaryColor = this.primaryColor || ATB_BLUE;
    this.labelColor = this.darkMode ? ATB_BLUE_BLACK : ATB_WHITE;
  }

  $onChanges() {
    if (this.portfolio) {
      this.id = this.id || 'breakdownChart_' + this.portfolio.fund;
      this.breakdown = [this.getEquityData(), this.getFixedIncomeData(), this.getCashAndOtherData()];
      this.timeout(() => {
        this.chart = this.drawBreakdownChart();
        if (this.chart) {
          this.chart.reflow();
        }
      });
    }
  }

  drawBreakdownChart() {
    Highcharts.setOptions({
      chart: {
        style: {
          fontFamily: 'Inter, Arial, Helvetica, serif'
        }
      }
    });

    return document.getElementById(this.id) && Highcharts.chart(this.id, {
      chart: {
        backgroundColor: 'transparent',
        type: 'pie'
      },
      title: {
        text: this.header && this.translateService.instant('portfolioBreakdown.title'),
        useHTML: true,
        align: 'left',
        style: {
          fontWeight: 'bold',
          color: '#000025'
        }
      },
      colors: ATB_QUALITATIVE_PALETTE,
      plotOptions: {
        pie: {
          shadow: false,
          allowPointSelect: false,
          dataLabels: {
            enabled: true,
            color: this.labelColor,
            style: {
              textOutline: 'none'
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      series: [{
        name: 'Asset Mix',
        data: this.getBreakdownData(),
        size: this.size,
        innerSize: '80%',
        dataLabels: {
          formatter: function () {
            return Math.round(this.y * 1000) / 1000 + '% ' + this.point.name;
          }
        }
      }],
      drilldown: {
        activeAxisLabelStyle: {
          color: this.labelColor
        },
        activeDataLabelStyle: {
          color: this.labelColor
        },
        series: [
          this.getEquityData(),
          this.getFixedIncomeData(),
          this.getCashAndOtherData()
        ],
        drillUpButton: {
          renderTo: 'spacingBox',
          position: {
            verticalAlign: 'bottom',
            x: -10,
            y: -20
          },
          theme: {
            borderRadius: 15
          }
        }
      }
    });
  }

  getCashAndOtherData() {
    let data = {
      name: 'Cash & Other',
      percent: this.portfolio.assetClasses
          .filter(it => it.assetType === 'CASH_AND_OTHER')[0]
          .percent
    };
    return data.percent > 0 && data;
  }

  getEquityData() {
    let data = {
      id: 'Equities',
      name: 'Equities',
      size: this.size,
      innerSize: '80%',
      percent: 0,
      dataLabels: {
        enabled: true,
        color: this.labelColor,
        style: {
          textOutline: 'none'
        },
        formatter: function () {
          return Math.round(this.y * 100) / 100 + '% ' + this.point.name;
        }
      },
      data: []
    }
    this.portfolio.assetSectors
        .filter(it => it.percent > 0 && it.assetType === 'EQUITY')
        .forEach((it) => {
          data.percent += it.percent;
          data.data.push({
            name: it.name,
            y: it.percent
          });
        })
    return data.percent > 0 && data;
  }

  getFixedIncomeData() {
    let data = {
      name: 'Fixed Income',
      id: 'Fixed Income',
      size: this.size,
      innerSize: '80%',
      percent: 0,
      dataLabels: {
        enabled: true,
        formatter: function () {
          return Math.round(this.point.y * 100) / 100 + '% ' + this.point.name;
        }
      },
      data: []
    }
    this.portfolio.assetClasses
        .filter(it => it.percent > 0 && it.assetType === 'FIXED_INCOME')
        .forEach((it, i) => {
          data.percent += it.percent;
          data.data.push({
            name: it.name,
            y: it.percent
          });
        })
    return data.percent > 0 && data;
  }

  getBreakdownData() {
    return _.map(this.breakdown, (it) => {
      return {
        name: it.name,
        y: it.percent,
        drilldown: it.id
      };
    });
  }

  showPortfolioReturn(event) {
    this.dialog.show({
      controller: ['$mdDialog', 'portfolioName', 'performances', 'performanceAsOfDate', 'inceptionDate', portfolioReturnController],
      controllerAs: 'portfolioReturn',
      template: require('../portfolio-return/portfolio-return.html'),
      locals: {
        'portfolioName': this.portfolio.name,
        'performances': this.portfolio.performances,
        'performanceAsOfDate': this.portfolio.performanceAsOfDate,
        'inceptionDate': this.portfolio.inceptionDate
      },
      parent: document.body,
      fullscreen: true,
      targetEvent: event,
      clickOutsideToClose: true
    });
  }
}

module.exports = PortfolioBreakdownChartController;
