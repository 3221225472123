const angular = require('angular');
const uiRouter = require('@uirouter/angularjs').default;
const angularResource = require('angular-resource');
const localStorageServiceModule = require('angular-local-storage');
const utilityServiceComponent = require('../utility-service/utility-service');
const goalService = require('./goal-service.service');
const devConfigurationModule = require('../dev-configuration/dev-configuration');
const ngStorage = require('../../ng-storage.module');

module.exports = angular
    .module('goalService', [
      ngStorage,
      angularResource,
      uiRouter,
      devConfigurationModule,
      utilityServiceComponent,
      localStorageServiceModule
    ])
    .service('goalService', ['$state', '$localStorage', '$resource', 'devConfiguration', 'utilityService', 'localStorageService', goalService])
    .name;
