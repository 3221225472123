class ToolTipDialogController {
    constructor(text, $mdDialog) {
        this.text = text;
        this.dialogService = $mdDialog;
    }

    cancel() {
        this.dialogService.cancel();
    }
}

module.exports = ToolTipDialogController;
