const angular = require('angular');
const angularSanitize = require('angular-sanitize');
const riskDefinitionQuestionComponent = require('./risk-definition-question.component.js');

let riskDefinitionQuestionModule = angular
    .module('riskDefinitionQuestion', [
        angularSanitize
    ])
    .component('riskDefinitionQuestion', riskDefinitionQuestionComponent)
    .name;

module.exports = riskDefinitionQuestionModule;