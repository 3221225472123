const template = require('./item-list.html');
const controller = require('./item-list.controller');
require('./item-list.scss');

let itemListComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        formData: "=",
        itemId: "@",
        itemHeader: "@",
        itemType: "@",
        itemTypes: "<",
        atLeastOneItem: "<",
        updateTotals: "&"
    },
    template,
    controller,
    controllerAs: 'itemList'
};

module.exports = itemListComponent;