const template = require('./growth-and-contributions.html');
const controller = require('./growth-and-contributions.controller.js');
require('./growth-and-contributions.scss');

let growthAndContributionsComponent = {
    restrict: 'E',
    bindings: {
        'date': '<',
        'selected': '<',
        'model': '='
    },
    template,
    controller: ['$mdDialog', '$timeout', 'goalService', controller],
    controllerAs: 'controller'
};

module.exports = growthAndContributionsComponent;
