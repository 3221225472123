class CitizenshipTaxResidencyController {
    constructor($window, $timeout, countries) {
        this.window = $window;
        this.countries = countries;
        this.countriesWithEmptySelection = [{'key': 'None', 'value': '', 'displayOrder': 0}, ...countries];
        this.timeout = $timeout;

        this.model = {};
        this.form = {};
    }

    $onInit() {
        this.timeout(() => {
            // There's an odd race condition here that causes this.form.taxResident... to be undefined onInit
            this.validateTaxReporting();
        })
    }

    validateTaxReporting() {
        let isValid = this.model.taxReporting.taxResidentOfCanada
            || this.model.taxReporting.taxResidentOfUnitedStates
            || this.model.taxReporting.taxResidentOfOtherCountry;
        this.form.taxResidentOfCanada.$setValidity('required', isValid);
        this.form.taxResidentOfUnitedStates.$setValidity('required', isValid);
        this.form.taxResidentOfOtherCountry.$setValidity('required', isValid);
    }

    get obsUrl() {
        return this.window.applicationConfiguration.obsUrl;
    }

    clearTinIfNeeded() {
        // clear TIN if not USA citizenship and 'I am a tax resident of the United States' is unchecked.
        if (this.model.primaryCitizenship !== 'USA'
            && this.model.taxReporting.taxResidentOfUnitedStates === false) {
            this.model.taxReporting.tin = null;
        }
    }

    validateTin() {
        this.form.tin.$setValidity('match', this.model.maskedOrNewSin !== this.model.taxReporting.tin);
    }
}

module.exports = CitizenshipTaxResidencyController;