const PER_CHILD_YEARLY_CONTRIBUTION_TO_MAX_GRANTS = 2496;
const moment = require('moment');

class FutureValueServiceService {
    /**
     * @param {number} pv    present value
     * @param {number} pmt   payment
     * @param {number} n     number of payments
     * @param {number} i     interested rate
     * @return {number} fv = pv*(1+i)^n + pmt/i*((1+i)^n-1)
     */
    getFutureValue({pv, pmt, n, i}) {
        return Math.round(pv * Math.pow(1 + i, n) + pmt / i * (Math.pow(1 + i, n) - 1));
    }

    /**
     * @param {number} fv   future value
     * @param {number} pv   present value
     * @param {number} n    number of payments
     * @param {number} i    compound interest rate
     * @return {number} pmt = (fv - pv*(1+i)^n) * i / ((1+i)^n - 1)
     **/
    getPayment(fv, pv, n, i) {
        return (fv - pv * Math.pow(1 + i, n)) * i / (Math.pow(1 + i, n) - 1);
    }

    /**
     * @param {number} fv   future value
     * @param {number} pmt  payment
     * @param {number} n    number of payments
     * @param {number} i    interested rate
     * @return {number} pv = ( fv - payment / i * ((1+i)^n-1) ) / (i+i)^n
     **/
    getPresentValue(fv, pmt, n, i) {
        return Math.round((fv - (pmt / i) * (Math.pow(1 + i, n) - 1)) / (Math.pow(1 + i, n)));
    }

    /**
     * @param {number} iv initial value
     * @param {number} pmt payment
     * @param {number} n number of payments
     * @return {number}
     **/
    getLumpSum({iv, pmt, n}) {
        return iv + pmt * n;
    }

    /* TODO: move to chart.controller */
    getTotalPeriods(params) {
        let frequency = parseFloat(params.frequency.number);
        return this.getYears(params) * frequency;
    }

    /* TODO: move to chart.controller */
    getYears(params) {
        switch (params.goalType) {
            case 'SAVINGS':
            case 'PURCHASE':
                return Math.ceil(moment(params.goalEndDate).diff(moment(new Date()), 'years', true));
            case 'GROUP':
                return params.timeHorizonSpan;
            case 'RETIREMENT':
                return params.retirementAge - params.currentAge;
            default:
                return 0;
        }
    }

    /* TOOD: clean up this method, break to two methods for RESP and PURCHASE */
    getRecommendedPayment(params) {
        let recommendedPayment;
        if (params.goalType === 'EDUCATION') {
            recommendedPayment = (PER_CHILD_YEARLY_CONTRIBUTION_TO_MAX_GRANTS / params.frequency.number) * params.beneficiaryList.length;
        } else {
            let totalPeriods = this.getTotalPeriods(params);
            const i = params.rateOfReturn / 100 / params.frequency.number
            recommendedPayment = this.getPayment(params.goalAmount, params.initialInvestment, totalPeriods, i);
        }
        return recommendedPayment;
    }
}

module.exports = FutureValueServiceService;
