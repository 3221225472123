//VENDOR MODULES
const angular = require('angular');

//NON-VENDOR MODULES
const netWorthComponent = require('./net-worth.component');
const itemListModule = require('./item-list/item-list');
const atbConstants = require('../../../components/atb-constants/atb-constants');

let netWorthModule = angular.module('netWorth', [
        itemListModule,
        atbConstants
    ])
    .component('netWorth', netWorthComponent)
    .name;

module.exports = netWorthModule;