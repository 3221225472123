require('./corporate-and-political-affiliations.template.scss');
const controller = require('./corporate-and-political-affiliations.controller');

module.exports = {
    restrict: 'E',
    bindings: {
        model: '=',
        form: '<'
    },
    template: require('./corporate-and-political-affiliations.template.html'),
    controller: ['pepOccupations', 'hioOccupations', 'atbEmploymentStatuses', controller],
    controllerAs: 'controller'
};