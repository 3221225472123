const angular = require('angular');
const regulatoryQuestionsComponent = require('./regulatory-questions.component');
const questionYesNoComponent = require('../../../components/question-yes-no/question-yes-no');
const atbConstants = require('../../../components/atb-constants/atb-constants')

module.exports = angular
    .module('regulatoryQuestions', [
        questionYesNoComponent,
        atbConstants
    ])
    .component('regulatoryQuestions', regulatoryQuestionsComponent)
    .name;