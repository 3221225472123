const template = require('./goal-selection.html');
const controller = require('./goal-selection.controller');
require('./goal-selection.scss');

let goalSelectionComponent = {
    restrict: 'E',
    bindings: {
        goals: '=',
        model: '=',
        form: '='
    },
    template,
    controller: ['goalService', controller],
    controllerAs: 'controller'
};

module.exports = goalSelectionComponent;