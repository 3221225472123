const template = require('./account-type.html');
const controller = require('./account-type.controller.js');

let accountTypeComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        accountType: "=",
        goalType: "<",
        openAccountModel: "="
    },
    template,
    controller: ['$translate', controller],
    controllerAs: 'accountType'
};

module.exports = accountTypeComponent;