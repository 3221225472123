const angular = require('angular');
require('angular-recaptcha');

const googleCaptchaComponent = require('./google-captcha.component');
const atbConstants = require('../atb-constants/atb-constants');

let googleCaptchaModule = angular.module('googleCaptchaModule', [atbConstants, 'vcRecaptcha'])
    .component('googleCaptcha', googleCaptchaComponent)
    .name;

module.exports = googleCaptchaModule;