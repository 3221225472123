let randomIntBetween = function(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
const SIN_LENGTH = 9;
const TEMPORARY_RESIDENT_FIRST_DIGIT = 9;
const BUSINESS_NUMBER_FIRST_DIGIT = 8;
// Map Canadian provinces to associated first SIN digits
const PROVINCES = {
    'AB': [6],
    'BC': [7],
    'MB': [6],
    'NB': [1],
    'NF': [1],
    'NS': [1],
    'NT': [6],
    'NU': [6],
    'ON': [4, 5],
    'PE': [1],
    'QC': [2, 3],
    'SK': [6],
    'YT': [7]
};

const SocialInsuranceNumber = function (sin) {
    this.sin = sin;
};

// Fast Luhn checksum code from luhn.js:
// https://gist.github.com/ShirtlessKirk/2134376
const luhnChecksum = function(sin) {
    let len = SIN_LENGTH,
        mul = 0;
    const luhnArr = [
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
    ];
    let sum = 0;
    while (len--) {
        sum += luhnArr[mul][parseInt(sin.charAt(len), 10)];
        mul = mul ^ 1;
    }
    return sum % 10;
};
// `partialSin` has first 8 digits of the SIN for which to calculate check digit.
let checkDigit = function(partialSin) {
    const checksum = luhnChecksum(partialSin.join('') + '0');
    return checksum % 10 === 0 ? 0 : 10 - checksum;
};

const generateStartsWith = function(startsWith, doesNotStartWith, province) {
    if (startsWith) {return startsWith;}
    if (doesNotStartWith) {
        // convert a single entry to a list
        doesNotStartWith = [].concat(doesNotStartWith);
        let possibleSinPrefixes = Array.from(new Set([].concat.apply([], Object.values(PROVINCES))));

        if (province) {
            possibleSinPrefixes = PROVINCES[province];
        }

        const filter = function (prefix) {
            return !doesNotStartWith.includes(String(prefix))
        };

        const validStartsWithChoices = possibleSinPrefixes.filter(filter);

        if (!validStartsWithChoices.length) {
            throw 'Cannot find a valid number to start with.';
        }

        return randomChoice(validStartsWithChoices);
    } 
        province = province || randomChoice(Object.keys(PROVINCES));
        return randomChoice(PROVINCES[province]);
    
};

SocialInsuranceNumber.generate = function(options) {
    options = options || {};
    const startsWith = generateStartsWith(options.startsWith, options.doesNotStartWith, options.province);
    const sinArray = String(startsWith).substring(0, (SIN_LENGTH - 1)).split('');
    // Generate the next digits randomly
    while(sinArray.length < (SIN_LENGTH - 1)) {
        sinArray.push(randomIntBetween(0, 9));
    }
    sinArray.push(checkDigit(sinArray));
    return sinArray.join('');
};

SocialInsuranceNumber.prototype.normalizedValue = function() {
    this._normalizedValue = this._normalizedValue || String(this.sin).replace(/[^\d]/g, '');
    return this._normalizedValue;
};

SocialInsuranceNumber.prototype.isValid = function() {
    return luhnChecksum(this.normalizedValue()) % 10 === 0;
};

SocialInsuranceNumber.prototype.isTemporary = function() {
    return this.firstDigit() === TEMPORARY_RESIDENT_FIRST_DIGIT;
};

SocialInsuranceNumber.prototype.isBusinessNumber = function() {
    return this.firstDigit() === BUSINESS_NUMBER_FIRST_DIGIT;
};

SocialInsuranceNumber.prototype.provinces = function() {
    const provinces = [];
    for(let province in PROVINCES) {
        if (PROVINCES[province].indexOf(this.firstDigit()) >= 0) {
            provinces.push(province);
        }
    }
    return provinces;
};

SocialInsuranceNumber.prototype.firstDigit = function() {
    return parseInt(this.normalizedValue().substring(0, 1), 10);
};

const randomChoice = function(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
};

SocialInsuranceNumber.PROVINCES = PROVINCES;
SocialInsuranceNumber.SIN_LENGTH = SIN_LENGTH;

module.exports = SocialInsuranceNumber;