require('./atbol-login.scss');
const angular = require('angular');
const controller = require('./atbol-login-dialog.controller');

class AtbolLoginController {
  constructor(userService, $window, $mdDialog, $localStorage) {
    this.userService = userService;
    this.window = $window;
    this.dialog = $mdDialog;
    this.localStorage = $localStorage;
  }

  isPingEnabled() {
    return true;
  }

  $onInit() {
    this.atbOnlineStates = {NONE: 0, AUTHENTICATED: 1, CHALLENGE: 2, ERROR: 3};
    this.currentAtbOnlineState = this.atbOnlineStates.NONE;

    if (this.isPingEnabled()) {
      this.userService.completePingAuthorizationFlow(
          this.window.applicationConfiguration.ping.rebankClientID,
          this.window.location.origin + '/open-account')
          .then((tokenResponse) => {
            return this.userService.signInAtbOnlineUser(tokenResponse.accessToken);
          })
          .then((response) => {
            this.onSuccess(response);
          })
          .catch((e) => {
            this.onError(e);
          });
    }
  }

  checkMaritalStatus() {
    let maritalStatus = this.formData.accountPersonalInformation.maritalStatus;
    if (['SINGLE', 'DIVORCED', 'WIDOWED'].includes(maritalStatus)) {
      this.formData.spousalInformation.firstName = null;
      this.formData.spousalInformation.lastName = null;
    }
  }

  signInAtbOnlineUser() {
    if (this.isPingEnabled()) {
      this.userService.pingAuthorization(
          this.window.applicationConfiguration.ping.rebankClientID,
          this.window.origin + '/open-account',
      );
    } else {
      this.dialog.show({
        controller: ['$sce', '$window', '$mdDialog', 'userService', controller],
        controllerAs: 'controller',
        template: require('./atbol-login-dialog.template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        onShowing: (scope) => {
          scope.controller.bind();
        },
        onComplete: (scope) => {
          scope.controller.init();
        },
        onRemoving: (element) => {
          element.scope().controller.unbind();
        }
      }).then(
          (response) => {
            this.onSuccess(response);
          },
          (error) => {
            this.onError(error);
          });
    }
  }

  onError(error) {
    if (error) {
      this.currentAtbOnlineState = this.atbOnlineStates.ERROR;
    }
  }

  onSuccess(response) {
    if (response && response.accountPersonalInformation && response.accountTypeInformation) {
      this.currentAtbOnlineState = this.atbOnlineStates.AUTHENTICATED;
      let accountType = this.formData.accountTypeInformation.accountType;
      let firstName = this.formData.accountPersonalInformation.firstName;
      let lastName = this.formData.accountPersonalInformation.lastName;
      let employmentInformation = this.formData.employmentInformation;
      let employmentStatus = this.formData.accountPersonalInformation.employmentStatus;

      this.formData = response;
      // Need to retain original accountType as it is not returned in uiForm of
      // atbol response. Retain firstName and lastName
      this.formData.accountTypeInformation.accountType = accountType;
      this.formData.accountPersonalInformation.firstName = firstName;
      this.formData.accountPersonalInformation.lastName = lastName;

      if (accountType === 'CASH') {
        let q4 = this.formData.regulatoryQuestions.questions.find(q => q.questionId === 'q4');
        q4.applicable = true;
        q4.answer = null;
      }

      // Need to retain the original employment information section for GROUP
      // goal
      if (this.isGroupGoal) {
        this.formData.employmentInformation = employmentInformation;
        this.formData.accountPersonalInformation.employmentStatus = employmentStatus;
      }

      // Needed to ensure the employment information is not reset due to a
      // change in employment status
      this.formData.employmentInformation.atbolFlag = true;
    } else {
      this.currentAtbOnlineState = this.atbOnlineStates.ERROR;
    }
  }
}

module.exports = AtbolLoginController;
