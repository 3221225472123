const template = require('./goals-header.html');
const controller = require('./goals-header.controller.js');
require('./goals-header.scss');

let goalsHeaderComponent = {
    restrict: 'E',
    bindings: {
        data: '=',
        // flag to trigger $onChanges for redraw
        redraw: '<',
        resetCurrentView: '&'
    },
    template,
    controller: ['$state', '$timeout', 'userService', 'goalService', controller],
    controllerAs: 'goalsHeader'
};

module.exports = goalsHeaderComponent;
