module.exports = () => ({
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, ctrl) => {
        const STREET_NAME_REGEX = /^[a-z0-9][a-z0-9\s\-?:()./,'+]*$/i;
        const excludeWordsArray = ['twp', 'township', ' rr', 'rural road'];
        ctrl.$validators.validStreetName = modelValue =>
            !modelValue
            || STREET_NAME_REGEX.test(modelValue)
            && modelValue.length >= 2 && modelValue.length <= 35
            && excludeWordsArray.every(word => !modelValue.toLowerCase().includes(word));
    }
});