const angular = require('angular');

const atbConstantsModule = require('../../../components/atb-constants/atb-constants');
const bankingInformationComponent = require('./banking-information.component');
const bankNumberValidator = require('../../../directives/bank-number-validator/bank-number-validator');
const toolTipModule = require('../../../components/tool-tip/tool-tip');

let bankingInformationModule = angular
    .module('bankingInformation', [
        atbConstantsModule,
        bankNumberValidator,
        toolTipModule
    ])
    .component('bankingInformation', bankingInformationComponent)
    .name;

module.exports = bankingInformationModule;
