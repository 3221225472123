const angular = require('angular');
const angularResource = require('angular-resource');
const localStorageService = require('angular-local-storage');
const riskAssessmentServiceComponent = require('./risk-assessment-service.service');
const devConfigurationModule = require('../dev-configuration/dev-configuration');

let riskAssessmentServiceModule = angular.module('riskAssessmentService', [
        angularResource,
        devConfigurationModule,
        localStorageService
    ])

    .service('riskAssessmentService', ['$resource', 'devConfiguration', 'localStorageService', riskAssessmentServiceComponent])
    .name;

module.exports = riskAssessmentServiceModule;