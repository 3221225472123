const template = require('./government-grants-agreement.html');
const controller = require('./government-grants-agreement.controller');
require('./government-grants-agreement.scss');

let governmentGrantsAgreementComponent = {
    restrict: 'E',
    bindings: {
        form: '=',
        formData: '='
    },
    template,
    controller,
    controllerAs: 'governmentGrantsAgreement'
};

module.exports = governmentGrantsAgreementComponent;