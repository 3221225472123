const angular = require('angular');
const goalSelectionComponent = require('./goal-question.component.js');
const goalSelectionModule = require('../../../../components/goal-selection-v2/goal-selection');

let goalQuestionModule = angular
    .module('goalQuestion', [
        goalSelectionModule
    ])
    .component('goalQuestion', goalSelectionComponent)
    .name;

module.exports = goalQuestionModule;