const template = require('./banking-information.html');
const controller = require('./banking-information.controller');
require('./banking-information.scss');

let bankingInformationComponent = {
    restrict: 'E',
    bindings: {
        file: '=',
        formData: '=',
        fundAccountForm: '=',
        availableAccounts: '<',
        isGroupGoal: '<'
    },
    template,
    controller: ['$filter', '$timeout', 'atbBranches', 'bankAccountLengths', 'bankNames', controller],
    controllerAs: 'bankingInformation'
};

module.exports = bankingInformationComponent;
