const angular = require('angular');
const passwordConfirmationComponent = require('./password-confirmation.component');
const equalToValidator = require('../../directives/equal-to-validator/equal-to-validator');

let passwordConfirmationModuleV2 = angular.module('passwordConfirmationV2', [
        equalToValidator
    ])
    .component('passwordConfirmationV2', passwordConfirmationComponent)
    .name;

module.exports = passwordConfirmationModuleV2;