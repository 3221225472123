class PasswordConfirmationController {
    constructor($timeout) {
        this.timeout = $timeout;
        this.showPassword = false;
        this.showConfirmPassword = false;
    }

    validateConfirmPassword() {
        let ctrl = this;
        this.timeout(() => {
            ctrl.form.confirmPassword.$validate();
        });
    }
}

module.exports = PasswordConfirmationController;