const template = require('./regulatory-questions.html');
const controller = require('./regulatory-questions.controller');
require('./regulatory-questions.scss');

let regulatoryQuestionsComponent = {
    restrict: 'E',
    bindings: {
        openAccountForm: "=",
        formData: "=",

        accountType: "<",
        atbEmploymentStatus: '<',
        spouseAtbEmploymentStatus: '<'
    },
    template,
    controller: ['atbEmploymentStatuses', '$timeout', controller],
    controllerAs: 'controller'
};

module.exports = regulatoryQuestionsComponent;