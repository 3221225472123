class ConfirmationController {
    constructor($window) {
        this.window = $window;

        this.changes = {};
    }

    $onInit() {
        this.changesRequireDocuments = this.getChangesRequireDocumentsIfAny();
    }

    requireDocument() {
        return this.changesRequireDocuments.length > 0;
    }

    getChangesRequireDocumentsIfAny() {
        return this.changes
            .flatMap(change => {
                switch (change.section) {
                    case 'personal':
                        return change.fields.filter(field => ['firstName', 'lastName'].includes(field.field));
                    case 'family':
                        return change.fields.filter(field => ['maritalStatus'].includes(field.field));
                    default:
                        return [];
                }
            })
            .map(field => field.field);
    }

    bookAppointment() {
        window.open(this.window.applicationConfiguration.obsUrl, '_blank');
    }
}

module.exports = ConfirmationController;