let linkFunction = function(scope, element, attrs, ctrl){
    ctrl.$validators.equalTo = function (modelValue) {
        return modelValue === scope.otherModelValue
    }
};

let equalToValidatorDirective = function(){
    return {
        scope: {
            otherModelValue: "=equalToValidator"
        },
        restrict: 'A',
        require: 'ngModel',
        link: linkFunction
    }
};

module.exports = equalToValidatorDirective;