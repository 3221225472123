const controller = require('./regex-validator.controller');

let linkFunction = function(scope, element, attrs, ngModel){
    var previousValue = null;
    var regexKey = attrs.regexConstantKey;
    var regexString = regexKey ? scope.regexValidator.getRegex(regexKey) : attrs.regexValidator;
    var regex = new RegExp(regexString);
    ngModel.$parsers.push((value) => {
        if (value && typeof(value) == 'string') {
            value = value.replace("\\", "\\\\");
        }
        if(value != null &&!regex.test(value)){
            ngModel.$setViewValue(previousValue);
            ngModel.$render();
            return (isNaN(previousValue) || !previousValue) ? previousValue : parseInt(previousValue, 10);
        }else{
            previousValue = value;
            return (isNaN(value) || !value) ? value : parseInt(value, 10);
        }
    });
};

let regexValidatorDirective = function(){
    return {
        restrict: 'A',
        require: 'ngModel',
        controller: ['regexes', controller],
        controllerAs: 'regexValidator',
        link: linkFunction
    }
};

module.exports = regexValidatorDirective;