const forgotPasswordTemplate = require('./forgot-password.html');
const resetPasswordTemplate = require('./components/reset-password/reset-password.html');
const resetPasswordAuthTemplate = require('./components/answer-security-questions/answer-security-questions.html');

require('./forgot-password.scss');

function routes($stateProvider) {
    $stateProvider
        .state('forgot-password', {
            url: '/forgot-password',
            parent: 'site',
            views: {
                'content@': {
                    template: forgotPasswordTemplate,
                    controller: 'ForgotPasswordController',
                    controllerAs: 'forgotPassword'
                }
            },
            resolve: {
                localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('forgot-password');
                    $translate.refresh();
                    return true;
                }],
                controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "forgot-password" */ './forgot-password.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        })
        .state('forgot-password.challenge', {
            params: {
              token: null
            },
            views: {
                'content@': {
                    template: resetPasswordAuthTemplate,
                    controller: 'AnswerSecurityQuestionsController',
                    controllerAs: 'answerSecurityQuestions'
                }
            },
            resolve: {
                data:['userService', '$stateParams', (userService, $stateParams) => {
                    return userService.getResetPasswordChallenge($stateParams.token);
                }],
                controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "forgot-password.challenge" */ './components/answer-security-questions/answer-security-questions.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        })
        .state('forgot-password.reset', {
            // need to be independent url for direct hit from Auth0
            url: '/reset?token',
            views: {
                'content@': {
                    template: resetPasswordTemplate,
                    controller: 'ResetPasswordController',
                    controllerAs: 'resetPassword'
                }
            },
            resolve: {
                controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "forgot-password.reset" */ './components/reset-password/reset-password.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }]
            }
        });
}

module.exports = routes;
