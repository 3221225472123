const registrationTemplate = require('./registration.html');
require('./registration.scss');

function routes($stateProvider) {
    $stateProvider
        .state('conversion', {
            url: '/conversion?hash',
            onEnter: ['userService', '$stateParams', (userService, $stateParams) => {
                userService.getConversionData($stateParams.hash)
                    .then(response => {
                        // TODO: Re-visit how to handle conversion with v2 login
                        // const auth0Params = {
                        //     key: 'sign_up',
                        //     hash: $stateParams.hash,
                        //     email: response.email,
                        //     names: [response.username],
                        //     conversion: true
                        // };
                        userService.signIn('prosper-dashboard');
                    })
                    .catch(err => {
                        // const auth0Params = {
                        //     key: 'sign_up',
                        //     hash: $stateParams.hash
                        // };
                        userService.signIn('prosper-dashboard');
                    });
            }]
        })
        .state('registration', {
            url: '/registration?create&hash&UT',
            parent: 'site',
            views: {
                'content@': {
                    template: registrationTemplate,
                    controller: 'RegistrationController',
                    controllerAs: 'registration'
                }
            },
            resolve: {
                localization: ['$translatePartialLoader', '$translate', ($translatePartialLoader, $translate) => {
                    $translatePartialLoader.addPart('registration');
                    $translate.refresh();
                    return true;
                }],
                controller: ['$q', '$ocLazyLoad', ($q, $ocLazyLoad) => {
                    return import(/* webpackChunkName: "registration" */ './registration.controller')
                        .then(controller => $ocLazyLoad.load(controller));
                }],
            }
        });
}

module.exports = routes;
