const angular = require('angular');

class InvestmentEducationController {
    constructor() {
       this.investmentEducationImages = [
           require('../../img/articles-read_more.jpg'),
           require('../../img/articles-marketvolatility.jpg'),
           require('../../img/articles-tfsa&rrsp.jpg')
       ];
    }
}

module.exports = InvestmentEducationController;
