const angular = require('angular');
const angularMaterial = require('angular-material');
const footerComponent = require('./footer.component');

let footerModule = angular.module('footer', [
        angularMaterial
    ])

    .component('footer', footerComponent)
    .name;

module.exports = footerModule;