const angular = require('angular');
const Highcharts = require('highcharts/highcharts');
require('highcharts/modules/no-data-to-display')(Highcharts);

const ToolTipDialogController = require('../tool-tip/tool-tip-dialog.controller');
const BeneficiaryDistributionModalController = require('./beneficiary-distribution-modal/beneficiary-distribution-modal.controller');

const ATB_BLUE = '#0072F0';
const ATB_DARK_GREY = '#858a8d';
const ATB_YELLOW_500 = '#FCDC3E';
const ATB_ORANGE_300 = '#FF9C35';
const DEFAULT_AGE = 2;
const DEFAULT_END_AGE = 17;
const GOVERNMENT_GRANT_RATE = 0.2;
const MAX_PER_CHILD_LIFETIME_CONTRIBUTION = 7200;
const MAX_PER_CHILD_YEARLY_CONTRIBUTION = 500;
const PER_CHILD_YEARLY_CONTRIBUTION_TO_MAX_GRANTS = 2496;

/**
 * TODO: refactoring suggestion: this has lots of duplicated code
 *  with the chart.controller. Maybe consolidate the two?
 */
class RespGoalCalculationController {
    constructor($timeout,
                $filter,
                $mdDialog,
                $translate,
                futureValueService,
                userService,
                contributionFrequencies,
                monthOptions,
                localStorageService) {
        this.timeout = $timeout;
        this.filter = $filter;
        this.dialogService = $mdDialog;
        this.translate = $translate;
        this.futureValueService = futureValueService;
        this.contributionFrequencies = contributionFrequencies;
        this.monthOptions = monthOptions;
        this.userService = userService;
        this.lightbulbImg = require('../../../public/img/material_icons/lightbulbWhite.svg');
        this.respGraphColours = [ATB_ORANGE_300, ATB_YELLOW_500, ATB_BLUE];
        this.localStorageService = localStorageService;
    }

    $onInit() {
        this.params.beneficiaryList = this.params.beneficiaryList || [];
        this.params.frequency = this.setInitialFrequency(this.params.frequency);
        if (this.isRecommendation) {
            this.addChildRow();
        }
        this.updateCalculations();
    }

    addChildRow() {
        if (!this.maxChildren) {
            this.params.beneficiaryList.push({age: DEFAULT_AGE});
        }
        this.maxChildren = this.params.beneficiaryList.length > 5;
        this.updateCalculations();
    }

    calculateAges() {
        this.params.beneficiaryList.forEach((beneficiary) => {
            beneficiary.age = this.userService.calculateAge(beneficiary.birthMonth, beneficiary.birthDay, beneficiary.birthYear);
        });
    }

    calculateRecommendedPAC() {
        let perChildPerPeriodContribution = PER_CHILD_YEARLY_CONTRIBUTION_TO_MAX_GRANTS / this.params.frequency.number;
        this.recommendedPayment = perChildPerPeriodContribution * this.params.beneficiaryList.length;
        this.recommendedPaymentText = this.filter('currency')(Math.ceil(this.recommendedPayment), '$', 0);
        this.frequencyText = this.translate.instant('frequencies.' + this.params.frequency.key).toLowerCase();
    }

    calculateTotals(child) {
        if (this.overDefaultEndAge(child)) {
            return initialInvestment => ({
                total: initialInvestment,
                accumulatedContribution: initialInvestment,
                accumulatedGovContribution: 0,
                investmentIncome: 0
            });
        }
        /*
         * TODO: refactoring suggestion: move this logic to FutureValueService?
         */
        return (initialInvestment, ongoingInvestment) => {
            let totalYears = DEFAULT_END_AGE - child.age;
            let periodsPerYear = parseFloat(this.params.frequency.number);
            let totalPeriods = periodsPerYear * totalYears;
            let interestRate = (parseFloat(this.params.rateOfReturn) / 100);
            let periodicInterestRate = Math.pow((1 + interestRate), (1 / periodsPerYear)) - 1;
            let accumulatedContribution = initialInvestment;
            let accumulatedGovContribution = 0;
            let endPeriodValue = 0;
            let contributionPerPeriod = ongoingInvestment;
            let beginningPeriodValue = initialInvestment;
            for (let x = 1; x <= totalPeriods; x++) {
                let govContributionMax = this.getMaxGovernmentContributionForPeriod(x, periodsPerYear);
                let govContributionForPeriod = this.getGovContributionForPeriod(contributionPerPeriod, govContributionMax, accumulatedGovContribution);
                accumulatedContribution += ongoingInvestment;
                accumulatedGovContribution += govContributionForPeriod;
                endPeriodValue = Math.round((beginningPeriodValue + contributionPerPeriod + govContributionForPeriod) * (1 + periodicInterestRate));
                beginningPeriodValue = endPeriodValue;
            }

            let investmentIncome = endPeriodValue - accumulatedContribution - accumulatedGovContribution;

            return {
                total: endPeriodValue,
                accumulatedContribution: accumulatedContribution,
                accumulatedGovContribution: accumulatedGovContribution,
                investmentIncome: investmentIncome
            };
        };
    }

    createGraph(element, title, data) {
        let chart = null;
        let filter = this.filter;
        if (element) {
            //label formatter used within high charts
            /* istanbul ignore next */
            let labelFormatter = function () {
                return filter('currency')(this.y, '$', 0);
            };
            let chartConfig = {
                chart: {
                    backgroundColor: 'rgba(255, 255, 255, 0.0)',
                    renderTo: element,
                    spacingTop: 0,
                    spacingLeft: -35,
                    spacingRight: 0,
                    type: 'pie',
                    style: {
                        fontFamily: 'Inter, Arial, Helvetica, serif'
                    }
                },
                title: this.getTitleConfig(title),
                legend: {
                    enabled: false
                },
                plotOptions: {
                    pie: {
                        shadow: false,
                        showInLegend: true
                    }
                },
                tooltip: {
                    shared: false,
                    valueDecimals: 0,
                    style: {
                        color: ATB_DARK_GREY,
                        fontWeight: 'bold'
                    },
                    backgroundColor: 'rgba(247,247,247,0.9)',
                    useHTML: false,
                    padding: 8,
                    formatter: labelFormatter
                },
                credits: {
                    enabled: false
                },
                series: [{
                    name: 'Projected growth',
                    data: data,
                    size: '100%',
                    innerSize: '80%',
                    dataLabels: {
                        enabled: false
                    }
                }]
            };

            chart = new Highcharts.Chart(chartConfig);

            if (chart.chartWidth >= 500) {
                chart.title.update({style: {fontSize: '350%'}});
            } else if (chart.chartWidth >= 200) {
                chart.title.update({style: {fontSize: '250%'}});
            } else {
                chart.title.update({y: 4, style: {fontSize: '130%'}});
            }
        }
        return chart;
    }

    deleteChildRow(index) {
        if (this.params.beneficiaryList.length > 1) {
            this.params.beneficiaryList.splice(index, 1);
        }
        this.maxChildren = this.params.beneficiaryList.length > 5;
        this.updateCalculations();
    }

    formatInitialInvestment() {
        let minimumInitialInvestment = (this.params.initialInvestment > 100) ? this.params.initialInvestment : 100;
        return this.filter('currency')(minimumInitialInvestment, '$', 0);
    }

    getTitleConfig(title) {
        return {
            text: title,
            style: {
                color: '#000000',
                fontFamily: 'Inter, Arial, Helvetica, serif'
            },
            useHTML: false,
            verticalAlign: 'middle',
            horizontalAlign: 'middle',
            floating: true
        };
    }

    getGovContributionForPeriod(recurringContribution, govContributionMax, accumulatedGovContribution) {
        return Math.min((recurringContribution * GOVERNMENT_GRANT_RATE), (govContributionMax - accumulatedGovContribution));
    }

    getMaxGovernmentContributionForPeriod(period, frequency) {
        return Math.min(Math.ceil(period / frequency) * MAX_PER_CHILD_YEARLY_CONTRIBUTION, MAX_PER_CHILD_LIFETIME_CONTRIBUTION);
    }

    // NOTE:  if beneficiary.age == 0 then zero is "falsy"  so we need to check for the type instead of just the presence of a value.
    overDefaultEndAge(beneficiary) {
        return Boolean(beneficiary && typeof (Number(beneficiary.age)) === 'number' && beneficiary.age >= DEFAULT_END_AGE);
    }

    notOverDefaultEndAge(beneficiary) {
        return Boolean(beneficiary && typeof (Number(beneficiary.age)) === 'number' && beneficiary.age < DEFAULT_END_AGE);
    }

    /* TODO: refactoring suggestion: this is duplicated with chart.controller */
    get footerParameters() {
        const frequency = this.params.frequency.key === 'LDMONTHLY' ? 'MONTHLY' : this.params.frequency.key;
        return {
            portfolioName: this.params.portfolioName,
            initialInvestment: this.formatMoney(this.params.initialInvestment),
            ongoingInvestment: this.formatMoney(this.params.ongoingInvestment),
            frequency: this.translate.instant(`frequencies.${frequency}`),
            timeHorizon: this.futureValueService.getYears(this.params),
            rateOfReturn: this.params.rateOfReturn
        }
    }

    /* TODO: refactoring suggestion: this is duplicated with chart.controller */
    formatMoney(value) {
        return this.filter('currency')(value, '$', value % 1 === 0 ? 0 : 2);
    }

    /* TODO: refactoring suggestion: this is duplicated with chart.controller */
    setInitialFrequency(frequency) {
        /**
         * TODO: refactoring suggestion: normalize frequency before bind to
         * this component. No need to deal with various cases like: key, null,
         * object. Imagine TypeScript, it is better to define a type rather
         * than using any.
         */
        if (!frequency || !frequency.key) {
            let frequencyIndex = 0;
            angular.forEach(this.contributionFrequencies, (frequencyObject, index) => {
                if (frequencyObject.key == frequency) {
                    frequencyIndex = index;
                }
            });
            return this.contributionFrequencies[frequencyIndex];
        }
        return frequency;
    }

    showDisclaimer(event) {
        this.dialogService.show({
                clickOutsideToClose: true,
                controller: ['text','$mdDialog', ToolTipDialogController],
                controllerAs: 'toolTipDialog',
                locals: {
                    'text': this.translate.instant('goalCalculationMessages.respDisclaimer')
                },
                parent: angular.element(document.body),
                targetEvent: event,
                template: require('../tool-tip/tool-tip-dialog.html')
            }
        );
    }

    showBeneficiaryDistributionModal() {
        this.dialogService.show({
                controller: ['$mdDialog', 'localStorageService', BeneficiaryDistributionModalController],
                controllerAs: 'beneficiaryDistributionModal',
                template: require('./beneficiary-distribution-modal/beneficiary-distribution-modal.html'),
                parent: angular.element(document.body),
                clickOutsideToClose: true
            }
        );
    }

    updateCalculations() {
        if (!this.isRecommendation) {
            this.calculateAges();
        }
        this.calculateRecommendedPAC();
        this.updateGraphs();
    }

    updateGraphs() {
        this.timeout(() => {
            if (this.params.beneficiaryList.length) {
                this.updateGraphsForEachChild()
            }
        });
    }

    /* TODO: refactoring suggestion: this will be duplicated with chart.controller */
    paramsAreValid() {
        /**
         * TODO: We need to show no-data graph when the params are not valid
         */
        return true;
    }

    updateGraphsForEachChild() {
        let graphData = [];
        let titleData = [];

        if (this.paramsAreValid()) {
            let beneficiaryNotOverDefaultEndAge = this.params.beneficiaryList.filter(this.notOverDefaultEndAge);
            let initialInvestment = this.params.initialInvestment / this.params.beneficiaryList.length;
            let ongoingInvestment = 0;
            if (beneficiaryNotOverDefaultEndAge.length > 0) {
                ongoingInvestment = this.params.ongoingInvestment / beneficiaryNotOverDefaultEndAge.length;
            }

            this.params.beneficiaryList.forEach((child, index) => {
                let calculatedValues = this.calculateTotals(child)(initialInvestment, ongoingInvestment);

                // covering edge case:  When all beneficiaries are over default end age (e.g. over 18), the total can be
                // off by $1 (over or under); The following do an adjustment applied to first beneficiary.
                let beneficiaryOverDefaultEndAge = this.params.beneficiaryList.filter(this.overDefaultEndAge);
                if (index === 0 && beneficiaryOverDefaultEndAge.length === this.params.beneficiaryList.length) {
                    let total = Number(this.params.initialInvestment / beneficiaryOverDefaultEndAge.length).toFixed(0) * beneficiaryOverDefaultEndAge.length;
                    calculatedValues.accumulatedContribution += this.params.initialInvestment - total;
                    calculatedValues.total = calculatedValues.accumulatedContribution;
                }

                let title = this.filter('currency')(calculatedValues.total, '$', 0);
                titleData.push(title);
                let childData = [];
                childData.push({
                    name: 'My contribution',
                    y: calculatedValues.accumulatedContribution,
                    color: this.respGraphColours[0]
                });
                childData.push({
                    name: 'Government contribution',
                    y: calculatedValues.accumulatedGovContribution,
                    color: this.respGraphColours[1]
                });
                childData.push({
                    name: 'Investment Income',
                    y: calculatedValues.investmentIncome,
                    color: this.respGraphColours[2]
                });
                graphData.push(childData);
            });
        }

        this.timeout(() => {
            graphData.forEach((graph, index) => {
                this.createGraph(document.getElementById('futureValueGraph' + index), titleData[index], graph);
            });
        }, 100);
    }
}

module.exports = RespGoalCalculationController;
