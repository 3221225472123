const template = require('./investment-education.html');
const controller = require('./investment-education.controller.js');
require('./investment-education.scss');

let investmentEducationComponent = {
    restrict: 'E',
    template,
    controller,
    controllerAs: 'investmentEducation'
};

module.exports = investmentEducationComponent;