class GoogleCaptchaController {
    constructor(googleCaptchConfig, $window) {
        this.googleCaptchaKey = googleCaptchConfig.key;
        this.window = $window;
    }

    $onInit() {
        this.removeCaptcha = window.applicationConfiguration.google.removeCaptcha;
    }

    show() {
        return ['PROD'].includes(this.window.__env);
    }
}

module.exports = GoogleCaptchaController;
